import {
  TOGGLE_SHOW_LISTING_PRODUCT_MODAL,
  SET_SELECTED_FILE_MODAL,
  TOGGLE_SHOW_FILE_PREVIEW_MODAL,
  SET_ERROR_FILE_MODAL,
  TOGGLE_SHOW_HANDLE_G2C_OBJECT_MODAL,
  SET_SELECTED_G2C_OBJECT_MODAL,
  SET_LOADING_G2C_OBJECT_MODAL,
  SET_SELECTED_PRODUCT_FOR_MODAL,
  TOGGLE_SHOW_TRANSFER_PRODUCT_MODAL,
  TOGGLE_SHOW_HANDLE_COLLECTIONS_MODAL,
  SET_LOADING_COLLECTIONS_MODAL,
  SET_SELECTED_COLLECTION_MODAL,
  CREATE_COLLECTION,
  TOGGLE_SHOW_REDEEM_PRODUCT_MODAL,
  TOGGLE_SHOW_MAKE_OFFER_MODAL,
  TOGGLE_SHOW_CHECKOUT_SESSION,
  SET_SELECTED_BENEFICIARY_MODAL,
  TOGGLE_SHOW_HANDLE_BENEFICIARY_MODAL,
  SET_SELECTED_ROYALTY_MODAL,
  TOGGLE_SHOW_HANDLE_ROYALTY_MODAL,
  TOGGLE_SHOW_AUCTION_PAY_MODAL,
  TOGGLE_SHOW_CREATE_QR_MODAL,
  TOGGLE_SHOW_TICKET_OPTIONS_MODAL,
  SET_SELECTED_REDEEMER_MODAL,
  TOGGLE_SHOW_DELIST_OFFERING_MODAL,
  TOGGLE_SHOW_HANDLE_OWNER_COLLECTIONS_MODAL,
  SET_OWN_OWNER_COLLECTIONS,
  TOGGLE_SHOW_HANDLE_ADDRESS_MODAL,
  SET_SELECTED_ADDRESS_MODAL,
  SET_OFFERING_PROCESS,
  SET_LISTING_STATUS,
  SET_TRANSFER_STATUS,
  SET_REDEEM_STATUS,
  SET_SELECTED_ACCESS_OPTION,
  SET_SELECTED_ACCESS_INVITATIONS,
  TOGGLE_SHOW_HANDLE_INVITATION_OFFERING_MODAL,
  SET_OFFERING_TO_LIST,
  SHOW_CONFIRM_LISTING_MODAL,
} from '../vars/store/mutations';

import {
  removeBeneficiary,
  removeCollection,
  removeFileG2CObject,
  removeRedeemer,
  removeRoyalty,
  setListingStatus,
  setOfferingProcess,
  setRedeemStatus,
  setSelectedAccessInvitations,
  setSelectedAccessOption,
  setTransferStatus,
  showCheckoutSession,
  showCreateQrModal,
  showDelistOfferingModal,
  showFilePreviewModal,
  showHandleInvitationOfferingModal,
  showListProductModal,
  showRedeemProductModal,
  showTransferProductModal,
  setListingOffering,
} from '../vars/store/actions';
export const state = () => ({
  showListProductModal: false,
  selectedProductForModal: '',
  showTransferProductModal: false,
  showHandleInvitationOfferingModal: false,
  showDelistOfferingModal: false,
  showCheckoutSession: false,
  selectedPaymentCheckoutSession: null,
  showRedeemProductModal: false,
  showCreateQrModal: false,
  showTicketOptionsModal: false,
  showFilePreviewModal: false,
  selectedFileModal: null,
  errorFileModal: null,
  showHandleG2cObjectModal: false,
  selectedG2cObject: null,
  loadingHandleG2cObjectModal: false,
  selectedAccessOption: 'public',
  selectedAccessInvitations: null,
  showHandleCollectionsModal: false,
  showHandleOwnerCollectionsModal: false,
  ownerCollectionList: null,
  selectedCollection: null,
  loadingCollections: false,
  collections: {},
  showHandleRoyaltyModal: false,
  selectedRoyalty: null,
  showHandleBeneficiaryModal: false,
  selectedBeneficiary: null,
  selectedRedeemer: null,
  showHandleAddressModal: false,
  selectedActionAddress: null,
  selectedTypeAddress: null,
  selectedAddress: null,
  offeringsInProcess: [],
  offeringsToList: null,
  showConfirmListingModal: { stampId: null, show: false },
  listingStatus: null,
  transferStatus: { status: 'loading', counter: 0 },
  redeemStatus: { status: 'loading', counter: 0 },
});

export const getters = {
  showListProductModal: (state) => state.showListProductModal,
  showTransferProductModal: (state) => state.showTransferProductModal,
  showHandleInvitationOfferingModal: (state) =>
    state.showHandleInvitationOfferingModal,
  showDelistOfferingModal: (state) => state.showDelistOfferingModal,
  showCheckoutSession: (state) => state.showCheckoutSession,
  selectedPaymentCheckoutSession: (state) =>
    state.selectedPaymentCheckoutSession,
  showRedeemProductModal: (state) => state.showRedeemProductModal,
  showCreateQrModal: (state) => state.showCreateQrModal,
  selectedProductForModal: (state) => state.selectedProductForModal,
  showFilePreviewModal: (state) => state.showFilePreviewModal,
  selectedFileModal: (state) => state.selectedFileModal,
  errorFileModal: (state) => state.errorFileModal,
  showHandleG2cObjectModal: (state) => state.showHandleG2cObjectModal,
  selectedG2cObject: (state) => state.selectedG2cObject,
  loadingHandleG2cObjectModal: (state) => state.loadingHandleG2cObjectModal,
  selectedAccessOption: (state) => state.selectedAccessOption,
  selectedAccessInvitations: (state) => state.selectedAccessInvitations,
  showHandleCollectionsModal: (state) => state.showHandleCollectionsModal,
  showHandleOwnerCollectionsModal: (state) =>
    state.showHandleOwnerCollectionsModal,
  ownerCollectionList: (state) => state.ownerCollectionList,
  selectedCollection: (state) => state.selectedCollection,
  loadingCollectionsModal: (state) => state.loadingCollections,
  showHandleRoyaltyModal: (state) => state.showHandleRoyaltyModal,
  selectedRoyalty: (state) => state.selectedRoyalty,
  showHandleBeneficiaryModal: (state) => state.showHandleBeneficiaryModal,
  selectedBeneficiary: (state) => state.selectedBeneficiary,
  showTicketOptionsModal: (state) => state.showTicketOptionsModal,
  selectedRedeemer: (state) => state.selectedRedeemer,
  showHandleAddressModal: (state) => state.showHandleAddressModal,
  selectedActionAddress: (state) => state.selectedActionAddress,
  selectedTypeAddress: (state) => state.selectedTypeAddress,
  selectedAddress: (state) => state.selectedAddress,
  offeringsInProcess: (state) => state.offeringsInProcess,
  areOfferingsToList: (state) => state.offeringsToList === null,
  offeringToList: (state) => (id) => state.offeringsToList[id],
  showConfirmListingModal: (state) => state.showConfirmListingModal,
  listingStatus: (state) => (id) => state.listingStatus[id],
  transferStatus: (state) => state.transferStatus,
  redeemStatus: (state) => state.redeemStatus,
};

export const actions = {
  [showListProductModal]({ commit }, product) {
    commit(SET_SELECTED_PRODUCT_FOR_MODAL, product);
    commit(TOGGLE_SHOW_LISTING_PRODUCT_MODAL, true);
  },
  [showTransferProductModal]({ commit }, product) {
    commit(SET_SELECTED_PRODUCT_FOR_MODAL, product);
    commit(TOGGLE_SHOW_TRANSFER_PRODUCT_MODAL, true);
  },
  [showHandleInvitationOfferingModal]({ commit }, product) {
    commit(SET_SELECTED_PRODUCT_FOR_MODAL, product);
    commit(TOGGLE_SHOW_HANDLE_INVITATION_OFFERING_MODAL, true);
  },
  [showDelistOfferingModal]({ commit }, product) {
    commit(SET_SELECTED_PRODUCT_FOR_MODAL, product);
    commit(TOGGLE_SHOW_DELIST_OFFERING_MODAL, true);
  },
  [showCheckoutSession]({ commit }) {
    // commit(SET_SELECTED_PRODUCT_FOR_MODAL, offering);
    commit(TOGGLE_SHOW_CHECKOUT_SESSION, { show: true });
  },
  [showRedeemProductModal]({ commit }, product) {
    commit(SET_SELECTED_PRODUCT_FOR_MODAL, product);
    commit(TOGGLE_SHOW_REDEEM_PRODUCT_MODAL, true);
  },
  [showCreateQrModal]({ commit }, product) {
    commit(SET_SELECTED_PRODUCT_FOR_MODAL, product);
    commit(TOGGLE_SHOW_CREATE_QR_MODAL, true);
  },
  [showFilePreviewModal]({ commit }, file) {
    commit(TOGGLE_SHOW_FILE_PREVIEW_MODAL, true);
    commit(SET_SELECTED_FILE_MODAL, file);
  },
  [removeFileG2CObject]({ commit }) {
    commit(SET_SELECTED_G2C_OBJECT_MODAL, null);
    commit(SET_SELECTED_FILE_MODAL, null);
  },
  [setSelectedAccessOption]({ commit }, selectedAccessOption) {
    commit(SET_SELECTED_ACCESS_OPTION, selectedAccessOption);
    if (selectedAccessOption === 'public')
      commit(SET_SELECTED_ACCESS_INVITATIONS, null);
  },
  [setSelectedAccessInvitations]({ commit }, accessInvitations) {
    commit(SET_SELECTED_ACCESS_INVITATIONS, accessInvitations);
  },
  [removeCollection]({ commit }) {
    // commit(SET_SELECTED_G2C_OBJECT_MODAL, null);
    commit(SET_SELECTED_COLLECTION_MODAL, null);
  },
  [removeRoyalty]({ commit }) {
    commit(SET_SELECTED_ROYALTY_MODAL, null);
  },
  [removeBeneficiary]({ commit }) {
    commit(SET_SELECTED_BENEFICIARY_MODAL, null);
  },
  [removeRedeemer]({ commit }) {
    commit(SET_SELECTED_REDEEMER_MODAL, null);
  },
  [setOfferingProcess]({ commit }, { offeringId, tokenSerieId, status }) {
    commit(SET_OFFERING_PROCESS, { offeringId, tokenSerieId, status });
  },
  [setListingOffering]({ commit }, data) {
    commit(SET_OFFERING_TO_LIST, data);
  },
  [setListingStatus]({ commit }, data) {
    commit(SET_LISTING_STATUS, data);
  },
  [setTransferStatus]({ commit }, data) {
    commit(SET_TRANSFER_STATUS, data);
  },
  [setRedeemStatus]({ commit }, data) {
    commit(SET_REDEEM_STATUS, data);
  },
};

export const mutations = {
  [SET_SELECTED_PRODUCT_FOR_MODAL](state, productId) {
    state.selectedProductForModal = productId;
  },
  [TOGGLE_SHOW_LISTING_PRODUCT_MODAL](state, show) {
    state.showListProductModal = show;
  },
  [TOGGLE_SHOW_TRANSFER_PRODUCT_MODAL](state, show) {
    state.showTransferProductModal = show;
  },
  [TOGGLE_SHOW_HANDLE_INVITATION_OFFERING_MODAL](state, show) {
    state.showHandleInvitationOfferingModal = show;
  },
  [TOGGLE_SHOW_DELIST_OFFERING_MODAL](state, show) {
    state.showDelistOfferingModal = show;
  },
  [TOGGLE_SHOW_CHECKOUT_SESSION](state, { show, payment = null }) {
    state.showCheckoutSession = show;
    // state.selectedPaymentCheckoutSession = payment;
  },
  [TOGGLE_SHOW_REDEEM_PRODUCT_MODAL](state, show) {
    state.showRedeemProductModal = show;
  },
  [TOGGLE_SHOW_CREATE_QR_MODAL](state, show) {
    state.showCreateQrModal = show;
  },
  [TOGGLE_SHOW_TICKET_OPTIONS_MODAL](state, show) {
    state.showTicketOptionsModal = show;
  },
  [SET_SELECTED_FILE_MODAL](state, file) {
    state.selectedFileModal = file ? Object.assign({}, file) : file;
  },
  [TOGGLE_SHOW_FILE_PREVIEW_MODAL](state, show) {
    state.showFilePreviewModal = show;
  },
  [SET_SELECTED_ADDRESS_MODAL](state, address) {
    state.selectedAddress = address;
  },
  [TOGGLE_SHOW_HANDLE_ADDRESS_MODAL](state, { show, action, type }) {
    state.showHandleAddressModal = show;
    state.selectedActionAddress =
      typeof action !== 'undefined' && show === true ? action : null;
    state.selectedTypeAddress =
      typeof type !== 'undefined' && show === true ? type : null;
  },
  [SET_ERROR_FILE_MODAL](state, error) {
    state.errorFileModal = error;
  },
  [TOGGLE_SHOW_HANDLE_G2C_OBJECT_MODAL](state, show) {
    state.showHandleG2cObjectModal = show;
  },
  [SET_SELECTED_G2C_OBJECT_MODAL](state, selected) {
    state.selectedG2cObject = selected ? Object.assign({}, selected) : selected;
  },
  [SET_LOADING_G2C_OBJECT_MODAL](state, loading) {
    state.loadingHandleG2cObjectModal = loading;
  },
  [TOGGLE_SHOW_HANDLE_COLLECTIONS_MODAL](state, show) {
    state.showHandleCollectionsModal = show;
  },
  [TOGGLE_SHOW_HANDLE_OWNER_COLLECTIONS_MODAL](state, show) {
    state.showHandleOwnerCollectionsModal = show;
  },
  [SET_OWN_OWNER_COLLECTIONS](state, ownerCollectionList) {
    state.ownerCollectionList = ownerCollectionList;
  },
  [SET_SELECTED_COLLECTION_MODAL](state, selected) {
    state.selectedCollection = selected
      ? Object.assign({}, selected)
      : selected;
  },
  [SET_LOADING_COLLECTIONS_MODAL](state, loading) {
    state.loadingCollections = loading;
  },
  [CREATE_COLLECTION](state, collection) {
    state.collections[collection.symbol] = collection;
  },
  [TOGGLE_SHOW_HANDLE_ROYALTY_MODAL](state, show) {
    state.showHandleRoyaltyModal = show;
  },
  [SET_SELECTED_ROYALTY_MODAL](state, selected) {
    state.selectedRoyalty = selected;
  },
  [TOGGLE_SHOW_HANDLE_BENEFICIARY_MODAL](state, show) {
    state.showHandleBeneficiaryModal = show;
  },
  [SET_SELECTED_BENEFICIARY_MODAL](state, selected) {
    state.selectedBeneficiary = selected;
  },
  [SET_SELECTED_REDEEMER_MODAL](state, selected) {
    state.selectedRedeemer = selected;
  },
  [SET_SELECTED_ACCESS_OPTION](state, selected) {
    state.selectedAccessOption = selected;
  },
  [SET_SELECTED_ACCESS_INVITATIONS](state, selected) {
    state.selectedAccessInvitations = selected;
  },
  [SET_OFFERING_PROCESS](state, { offeringId, tokenSerieId, status }) {
    if (status === null) {
      const offerings = state.offeringsInProcess;
      state.offeringsInProcess = offerings.filter(
        (offering) => offering.offeringId !== offeringId,
      );
    } else state.offeringsInProcess.push({ offeringId, status, tokenSerieId });
  },
  [SET_OFFERING_TO_LIST](state, data) {
    if (
      data.remove === true &&
      state.offeringsToList !== null &&
      typeof state.offeringsToList[data.stampId] !== 'undefined'
    ) {
      delete state.offeringsToList[data.stampId];
    } else {
      if (state.offeringsToList === null) state.offeringsToList = {};
      let offering = state.offeringsToList[data.stampId];
      if (typeof offering === 'undefined') offering = {};
      state.offeringsToList[data.stampId] = Object.assign(offering, data);
    }
  },
  [SHOW_CONFIRM_LISTING_MODAL](state, data) {
    state.showConfirmListingModal = data;
  },
  [SET_LISTING_STATUS](state, data) {
    if (state.listingStatus === null) state.listingStatus = {};
    if (
      data.status === 'error' &&
      typeof state.listingStatus[data.stampId] !== 'undefined'
    )
      delete state.listingStatus[data.stampId];
    else {
      switch (data.status) {
        case 'loading':
          data['counter'] = 5;
          break;
        case 'preparing':
          data['counter'] = 5 + 40 * (data.done / data.total);
          break;
        case 'uploading':
          data['counter'] = 45;
          break;
        case 'listing':
          data['counter'] = 48;
          break;
        case 'checking':
          data['counter'] = 50 + 40 * (data.done / data.total);
          break;
        case 'publishing':
          data['counter'] = 90;
          break;
        case 'done':
          data['counter'] = 100;
          break;

        default:
          data.status = 'loading';
          data['counter'] = 0;
          break;
      }
      state.listingStatus[data.stampId] = data;
    }
  },
  [SET_TRANSFER_STATUS](state, data) {
    switch (data.status) {
      case 'loading':
        data.counter = 5;
        break;
      case 'transferred':
        data.counter = 5 + 80 * (data.done / data.total);
        break;
      case 'uploading':
        data.counter = 90;
        break;
      case 'done':
        data.counter = 100;
        break;

      default:
        data.status = 'loading';
        data.counter = 0;
        break;
    }
    state.transferStatus = data;
  },
  [SET_REDEEM_STATUS](state, data) {
    switch (data.status) {
      case 'loading':
        data.counter = 5;
        break;
      case 'redeemed':
        data.counter = 5 + 80 * (data.done / data.total);
        break;
      case 'uploading':
        data.counter = 90;
        break;
      case 'done':
        data.counter = 100;
        break;

      default:
        data.status = 'loading';
        data.counter = 0;
        break;
    }
    state.redeemStatus = data;
  },
};
