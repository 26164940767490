import Vue from 'vue';

import { CONNECTION_STATUS_ACCEPTED } from '~/vars/api';

import {
  RESET_STATE_CONNECTIONS,
  ADD_NEW_MSG,
  ADD_UPDATE_MSG,
  CLEAN_NEW_MSGS,
  CLEAN_MSGS_TO_UPDATE,
  TOGGLE_LOADING_CONNECTIONS,
  SET_CONNECTIONS,
  ADD_CONNECTION,
  UPDATE_CONNECTION,
  SET_LOADING_CONNECTION,
} from '../vars/store/mutations';

import {
  setConnections,
  addConnection,
  updateConnection,
  setLoadingConnection,
} from '../vars/store/actions';

const getDefaultState = () => ({
  newMessagesByUserId: {},
  messagesToUpdate: {},
  newMsgs: 0,
  msgsToUpdate: 0,
  loadingConnections: true,
  connections: {},
  loading: [],
});

export const state = getDefaultState();

export const getters = {
  getNewMsgsByUserId: (state) => (userId) => {
    return state.newMessagesByUserId[userId] || [];
  },
  getMessagesToUpdateByUserId: (state) => (userId) => {
    return state.messagesToUpdate[userId] || [];
  },
  newMsgs: (state) => state.newMsgs,
  msgsToUpdate: (state) => state.msgsToUpdate,
  isLoadingConnections: (state) => state.loadingConnections,
  isLoading: (state) => (userId) => {
    const loading = state.loading.find(({ id }) => id === userId);
    return loading ? loading : false;
  },
  getConnections: (state) => state.connections,
  getConnection: (state) => (userId) => state.connections[userId],
  acceptedConnections: (state) =>
    Object.values(state.connections).filter(
      ({ status }) => status === CONNECTION_STATUS_ACCEPTED,
    ),
};

export const actions = {
  [setConnections]({ commit }, connections) {
    commit('SET_CONNECTIONS', connections);
    commit('TOGGLE_LOADING', false);
  },
  [addConnection]({ commit }, { userId, connectionInfo }) {
    commit('ADD_CONNECTION', { userId, connectionInfo });
  },
  [updateConnection]({ commit }, { userId, connectionInfo }) {
    commit('UPDATE_CONNECTION', { userId, connectionInfo });
  },
  [setLoadingConnection]({ commit }, { userId, option, status }) {
    commit('SET_LOADING_CONNECTION', { userId, option, status });
  },
};

export const mutations = {
  [RESET_STATE_CONNECTIONS](state) {
    Object.assign(state, getDefaultState());
  },
  [ADD_NEW_MSG](state, msg) {
    const userId = msg.sourceid;
    const newMsgsWithUser = state.newMessagesByUserId[userId]
      ? [...state.newMessagesByUserId[userId]]
      : [];
    Vue.set(state.newMessagesByUserId, userId, [...newMsgsWithUser, msg]);
    state.newMsgs = state.newMsgs + 1;
  },
  [ADD_UPDATE_MSG](state, { msg, ownUserId }) {
    const sourceId = msg.messageid.split('-')[1];
    const destinationId = msg.messageid.split('-')[2];
    const userId = sourceId === ownUserId ? destinationId : sourceId;
    const updateMsgsWithUser = state.messagesToUpdate[userId]
      ? [...state.messagesToUpdate[userId]]
      : [];
    updateMsgsWithUser.push(msg);
    Vue.set(state.messagesToUpdate, userId, updateMsgsWithUser);
    state.msgsToUpdate = state.msgsToUpdate + 1;
  },
  [CLEAN_NEW_MSGS](state, userId) {
    const oldNewMessagesOfUserId = state.newMessagesByUserId[userId].length;
    state.newMessagesByUserId[userId] = [];
    state.newMsgs = state.newMsgs - oldNewMessagesOfUserId;
  },
  [CLEAN_MSGS_TO_UPDATE](state, userId) {
    const oldMessagesToUpdateofUserId = state.messagesToUpdate[userId].length;
    state.messagesToUpdate[userId] = [];
    state.msgsToUpdate = state.msgsToUpdate - oldMessagesToUpdateofUserId;
  },
  [TOGGLE_LOADING_CONNECTIONS](state, loading = true) {
    state.loadingConnections = loading;
  },
  [SET_CONNECTIONS](state, connections) {
    state.connections = Object.assign({}, connections);
  },
  [ADD_CONNECTION](state, { userId, connectionInfo }) {
    const newRefOfConnections = { ...state.connections };
    newRefOfConnections[userId] = Object.assign({}, connectionInfo);
    state.connections = newRefOfConnections;
  },
  [UPDATE_CONNECTION](state, { userId, connectionInfo }) {
    const newRefOfConnections = { ...state.connections };
    const newRefOfConnectionUserId = newRefOfConnections[userId]
      ? newRefOfConnections[userId]
      : {};
    newRefOfConnections[userId] = Object.assign(
      newRefOfConnectionUserId,
      connectionInfo,
    );
    state.connections = newRefOfConnections;
  },
  [SET_LOADING_CONNECTION](state, { userId, option, status }) {
    if (status) {
      const loading = { ['positive']: false, ['negative']: false };
      loading[option] = status;
      state.loading.push({ id: userId, loading });
    } else {
      state.loading = state.loading.filter(({ id }) => id !== userId);
    }
  },
};
