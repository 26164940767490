
import { SESSION_RECOVER_PASSWORD, SESSION_SIGN_UP } from '~/vars/api';
import { mapGetters } from 'vuex';
import { TOGGLE_SHOW_SESSION_MODAL } from '~/vars/store/mutations';
import BaseInput from '~/components/base/BaseInput.vue';
import PinVerify from '../create-account/PinVerify.vue';
import { EventBus } from '../eventBus/EventBus';

export default {
  components: { BaseInput, PinVerify },
  data() {
    return {
      form: {
        nick: null,
        password: null,
        number: null,
      },
      rules: {
        nick: this.$rules.nick,
        password: this.$rules.passwordLogin,
      },
      loginInG2c: true,
      valid: false,
      showPassword: false,
      isFocus: false,
      feedback: null,
      errorValidation: null,
      loginData: null,
      step: 0,
    };
  },
  props: {
    loginIn: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      showSessionModal: 'session/showSessionModal',
    }),
    controlShowIcon() {
      if (this.showPassword) {
        return this.isFocus ? 'show_primary' : 'show';
      } else {
        return this.isFocus ? 'hide_primary' : 'hide';
      }
    },
    loading: {
      get() {
        return this.loginIn;
      },
      set(value) {
        this.$emit('update:loginIn', value);
      },
    },
    shouldShowModal: {
      get() {
        return this.showSessionModal;
      },
      set(value) {
        if (this.loading === false)
          this.$store.commit(`session/${TOGGLE_SHOW_SESSION_MODAL}`, value);
      },
    },
    SESSION_RECOVER_PASSWORD() {
      return SESSION_RECOVER_PASSWORD;
    },
    SESSION_SIGN_UP() {
      return SESSION_SIGN_UP;
    },
    title() {
      const steps = {
        0: 'welcome',
        1: 'email.validation',
        2: 'phone.validation',
        3: 'login_in',
      };
      return this.$t(`login.${steps[this.step]}.title`);
    },
    subtitle() {
      const steps = {
        1: 'email',
        2: 'phone',
      };
      return this.step !== 1 && this.step !== 2
        ? null
        : this.$t(`login.${steps[this.step]}.validation.info`);
    },
    subtitle2() {
      return this.step !== 1 ? null : this.$t(`login.email.validation.info_2`);
    },
    loginInTitle() {
      return this.$t('login.login_in.subtitle');
    },
  },
  watch: {
    'form.nick'(value) {
      if (
        value !== '' &&
        this.form.password !== '' &&
        this.form.password !== null
      )
        this.$refs.nick.$refs.nick.validate();
    },
    'form.password'(value) {
      if (value !== '' && this.form.nick !== '' && this.form.nick !== null)
        this.$refs.password.$refs.password.validate();
    },
    loading(value) {
      if (value === true) this.errorValidation = null;
    },
  },
  methods: {
    controlShowPassword() {
      return (this.showPassword = !this.showPassword);
    },
    async prelogin() {
      this.$refs.form.validate();
      this.loading = true;
      this.feedback = this.$t('login.loading');

      this.form['login'] = 'standard_login';

      try {
        const logged = await this.$api.session.loginStandard(this.form);
        if (logged.error === true) throw new Error('Login error');
        this.step++;
        this.loading = false;
      } catch (error) {
        this.onError(error);
      }
    },
    async login() {
      this.step++;
      this.loading = true;
      this.feedback = this.$t('login.loading');
      try {
        const response = await this.$api.session.loginWithoutWords(
          this.form,
          this.loginData,
        );
        EventBus.$on('logged-in', () => {
          this.loading = false;
          EventBus.$off('logged-in');
        });
      } catch (error) {
        this.onError(error);
      }
    },
    onError(error) {
      this.loading = false;
      this.feedback = null;
      if (error.message.includes('status code 401'))
        this.errorValidation = 'Login error';
      else this.errorValidation = error.message;
    },
    handleLoginData(type, data) {
      if (type === 'email') this.loginData = [];
      this.loginData.push(data.message);
      if (type === 'email') {
        this.form = Object.assign(this.form, { number: data.number });
        this.step++;
      }
      if (type === 'phone') {
        this.form = Object.assign(this.form, { code: data.code });

        this.login();
      }
    },
  },
  mounted() {},
};
