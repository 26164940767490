const middleware = {}

middleware['check_g2c_in_process'] = require('../middleware/check_g2c_in_process.js')
middleware['check_g2c_in_process'] = middleware['check_g2c_in_process'].default || middleware['check_g2c_in_process']

middleware['dev_middleware'] = require('../middleware/dev_middleware.js')
middleware['dev_middleware'] = middleware['dev_middleware'].default || middleware['dev_middleware']

middleware['g2c_middleware'] = require('../middleware/g2c_middleware.js')
middleware['g2c_middleware'] = middleware['g2c_middleware'].default || middleware['g2c_middleware']

middleware['marketplace_middleware'] = require('../middleware/marketplace_middleware.js')
middleware['marketplace_middleware'] = middleware['marketplace_middleware'].default || middleware['marketplace_middleware']

middleware['minter_middleware'] = require('../middleware/minter_middleware.js')
middleware['minter_middleware'] = middleware['minter_middleware'].default || middleware['minter_middleware']

middleware['session_middleware'] = require('../middleware/session_middleware.js')
middleware['session_middleware'] = middleware['session_middleware'].default || middleware['session_middleware']

export default middleware
