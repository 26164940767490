export default (context, inject) => {
  const addMetaData = (data) => [
    { hid: 'og:title', property: 'og:title', content: data.title },
    { hid: 'description', name: 'description', content: data.description },
    {
      hid: 'og:description',
      property: 'og:description',
      content: data.description.substring(0, 150),
    },
    { hid: 'og:image', property: 'og:image', content: data.image },
    { hid: 'og:image:width', property: 'og:image:width', content: '400' },
    { hid: 'og:image:height', property: 'og:image:height', content: '400' },
    { hid: 'og:type', property: 'og:type', content: data.type },
    { hid: 'url', property: 'url', content: data.url },
    { hid: 'og:url', property: 'og:url', content: data.url },
  ];
  inject('addMetaData', addMetaData);
};
