
import { USER_OPTION_DEFAULT } from '~/vars/api';
import BaseVerifyIcon from './BaseVerifyIcon.vue';
export default {
  components: { BaseVerifyIcon },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    userName() {
      if (this.user.name !== '')
        return (
          this.user.name +
          (this.isUser && this.user.surname ? ` ${this.user.surname}` : '')
        );
      return this.userNick;
    },
    isUser() {
      return this.user.user_option === USER_OPTION_DEFAULT;
    },
  },
};
