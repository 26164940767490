import { PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH } from '~/vars/session';

export default {
  error: {
    default: 'Error introducing data',
    generic: 'An error has occurred',
    promocode: 'The promocode is wrong or not exists',
    invitation_code: {
      exists: "The invitation code is wrong or doesn't exists",
      required: 'The invitation code is required',
    },
    email: {
      exists: 'Email already exists',
      sending: 'Error sending the validation code',
      verification: 'Error verifying the code',
      required: 'Email required',
      valid: 'Enter address in the correct format',
    },
    phone: {
      exists: 'Phone already exists',
      sending: 'Error sending the validation code',
      verification: 'Error verifying the code',
      required: 'Phone required',
      waiting:
        'Updating sms code validation system. Please, try again in a couple of minutes...',
      valid: 'Mobile phone number format is not valid',
    },
    nick: {
      required: 'The username is required',
      exists: 'The username is already taken',
      not_available: 'The username is not available',
      invalid: 'Username can only use lowercase letters and numbers',
      max_length: 'Max length is 64 chars',
      min_length: 'Min length is 3 chars',
    },
    code_company: {
      required: 'Signup code required',
      not_valid: 'Signup code not valid',
    },
    password: {
      required: 'The password is required',
      length: 'Password min length 6',
      identical: 'Passwords must be identical',
    },
    terms_and_conditions: 'Please check this box if you want to proceed.',
    creating: {
      account: 'Error creating the account.',
      status: 'Waiting account to be settle',
      unexpected: 'Error while checking account to be settle, please wait',
    },
    redirecting: 'Error while redirecting, please try again in Login page',
    message:
      'Error validating account, please try again later or contact with support team',
    username: 'Username does not exist',
    app: 'Username or password invalid',
    words: 'Security 12 words invalid',
    server: 'Server error',
    recover: {
      default: 'There was an error recovering your password',
      not_found: 'There is no account associated with this email',
      sending: 'Error sending recover password email',
      token: 'Invalid code to reset password',
    },
  },
  validation: {
    nick: 'Username field is required',
    surname: 'Surname field is required',
    account_type: 'Account type is required.',
    password: {
      required: 'Password field is required',
      length: `Password min length ${PASSWORD_MIN_LENGTH}`,
      max_length: `Password max length ${PASSWORD_MAX_LENGTH}`,
    },
    words: {
      valid: 'Security 12 words must contain 12 words without any symbol',
      required: 'Login with your wallet requires the Security 12 words.',
    },
    email: {
      required: 'Email field is required',
      valid: 'Introduce a valid Email format',
    },
    name: 'Name required',
    recommended: 'Please select an option',
    contact_email: {
      required: 'Email to contact required',
      valid: 'Please enter an email valid format',
    },
    contact_name: 'Contact Name required',
    business_name: 'Business Name required',
    phone_number: 'Phone Number to contact required',
    phone: {
      valid: 'Enter a valid Phone number',
      required: 'Phone is required',
    },
    amount: {
      valid: 'Minimun value',
      required: 'Please set a valid numeric amount',
      investment: 'Select Investment Amount',
      valid_amount: 'Please enter a valid amount',
      valid_min: 'Minimum amount allowed {amount}',
      valid_max: 'Maximum amount allowed {amount}',
      verify_required: 'Verify your identity for amounts above {amount}',
    },
    banking_account: {
      required: 'Bank account required',
    },
    banking_name: {
      required: 'Full name required',
    },
    address: {
      required: 'Paymail address is required',
      valid: 'Please use a valid paymail address format',
    },
    user_handle: {
      required: 'Handle is required',
      valid: 'Please use a valid Handle',
    },
    image: {
      size: 'Image size should be less than 8 MB ',
      type: 'Image Should have a valid image file type',
    },
    title: {
      max_length: 'Title have limit of 20 characters',
      user_max_length: 'Title have limit of 25 characters',
      company_max_length: 'Slogan have limit of 60 characters',
      examples: 'Artist, Musician, Artisan ...',
    },
    summary: {
      max_length: 'Summary have limit of 255 characters',
      required: 'Summary is required',
    },
    social: {
      valid: 'Please enter a valid social media link',
    },
    size: 'Header Image size should be less than 2 MB ',
    failed: 'Error uploading Image',
    link: 'Please follow examples to enter link',
    field_required: 'Field required',
  },
};
