
import ConfirmLogoutModal from '~/components/modals/ConfirmLogoutModal.vue';
import { USER_OPTION_COMPANY } from '~/vars/api';

export default {
  components: {
    ConfirmLogoutModal,
  },
  data() {
    return {
      showLogoutModal: false,
      showEditProfileModal: false,
    };
  },
  computed: {
    isCompany() {
      return this.$auth.user.data.user_option === USER_OPTION_COMPANY;
    },
    onlyCompanyMinter() {
      return this.$api.commons.onlyCompanyMinter();
    },
    items() {
      return [
        {
          icon: 'profile_circled',
          label: this.$t(
            `account.user_menu.profile.${this.$auth.user.data.user_option}`,
          ),
          route:
            !this.onlyCompanyMinter ||
            (this.onlyCompanyMinter && this.isCompany)
              ? this.localePath({
                  name: 'usernick',
                  params: { usernick: this.$auth.user.data.nick },
                })
              : null,
          action: () => {
            !this.onlyCompanyMinter ||
            (this.onlyCompanyMinter && this.isCompany)
              ? null
              : (this.showEditProfileModal = true);
          },
        },
        {
          icon: 'line_chart',
          label: this.$t(`account.user_menu.activity`),
          route: this.localePath({
            name: 'account-activity-slug',
            params: {
              slug: this.userPaidFees ? 'wallet' : 'market',
            },
          }),
          action: () => {},
        },
        {
          icon: 'settings',
          label: this.$t(`account.user_menu.settings`),
          route: this.localePath({
            name: 'account-settings-general',
          }),
          action: () => {},
        },
        {
          icon: 'logout',
          label: this.$t(`account.user_menu.logout`),
          route: null,
          action: () => {
            this.confirmLogout();
          },
        },
      ];
    },
    userPaidFees() {
      return this.$api.commons.userPaidFees();
    },
  },
  methods: {
    confirmLogout() {
      this.showLogoutModal = !this.showLogoutModal;
    },
  },
};
