
import { handleErrors } from '~/utils/functions';
import { mapActions, mapGetters } from 'vuex';
import { CUSTOM_ERROR, SECOND } from '~/vars/api';

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
    meeting: {
      type: Object,
    },
    type: {
      type: String,
      required: true,
    },
    dismissBtn: {
      type: Boolean,
      default: true,
    },
    parentNode: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      getConnection: 'connections/getConnection',
    }),
    currentConnection() {
      return this.getConnection(this.user.id);
    },
    satsEnabled() {
      return this.$api.commons.satsEnabled();
    },
  },
  data() {
    return {
      selectRetryByType: {
        connection: this.retryConnection,
        one2one: this.retryOneToOne,
      },
      selectDismissByType: {
        connection: this.dismiss,
      },
      loading: false,
    };
  },
  methods: {
    ...mapActions('notifications', ['feedbackNotificationHandler']),
    toggleLoading() {
      if (this.loading) {
        setTimeout(() => {
          this.feedbackNotificationHandler({
            userId: this.user.id,
            type: this.type,
            show: false,
          });
          this.loading = !this.loading;
          this.$emit('loading', this.loading);
        }, SECOND * 2);
      } else {
        this.loading = !this.loading;
        this.$emit('loading', this.loading);
      }
    },
    async retryConnection() {
      try {
        this.toggleLoading();
        this.feedbackNotificationHandler({
          userId: this.user.id,
          message: this.$t('connections.base.retry'),
          type: this.type,
          status: 'info',
          show: true,
        });
        const response = await this.$api.connections.updateExpiration(
          this.currentConnection,
        );
        if (response.error) throw new Error(response.error);
        this.feedbackNotificationHandler({
          userId: this.user.id,
          message: this.$t('connections.base.retry_success'),
          type: this.type,
          status: 'success',
          show: true,
        });
        this.toggleLoading();
      } catch (error) {
        this.feedbackNotificationHandler({
          userId: this.user.id,
          message: this.$t('connections.errors.retry.feedback'),
          type: this.type,
          status: 'error',
          show: true,
        });
        let errorValidation;
        if (error.name.includes(CUSTOM_ERROR)) {
          errorValidation = error.message;
        } else {
          handleErrors(error);
          errorValidation = this.$t('connections.errors.retry.feedback');
        }
        this.$toast.error(errorValidation);
        this.toggleLoading();
      }
    },
    async dismiss() {
      try {
        this.toggleLoading();
        this.feedbackNotificationHandler({
          userId: this.user.id,
          message: this.$t('connections.base.dismiss'),
          type: this.type,
          status: 'info',
          show: true,
        });
        const paymentAuth = this.currentConnection.paymentauth;
        if (this.satsEnabled && paymentAuth && paymentAuth !== '') {
          const walletDismiss = await this.$api.connections.walletDismiss(
            paymentAuth,
          );
          if (walletDismiss.error) throw new Error(walletDismiss.error);
        }
        const rejectConnectionResult =
          await this.$api.connections.cancelConnection({
            userId: this.user.id,
            destinationid: this.currentConnection.destinationid,
            sourceid: this.currentConnection.sourceid,
            connectionid: `${this.currentConnection.sourceid}-${this.currentConnection.destinationid}`,
          });
        this.feedbackNotificationHandler({
          userId: this.user.id,
          message: this.$t('connections.base.dismiss_success'),
          type: this.type,
          status: 'success',
          show: true,
        });
        this.toggleLoading();
      } catch (error) {
        this.feedbackNotificationHandler({
          userId: this.user.id,
          message: this.$t('connections.errors.dismiss.feedback'),
          type: this.type,
          status: 'error',
          show: true,
        });
        handleErrors(error);
        this.toggleLoading();
      }
    },
    async retryOneToOne() {
      this.$emit('retry-meeting', this.user);
    },
  },
};
