export default {
  forgot_title: 'Forgot password?',
  forgot_body:
    'Please, enter your email address. You will receive a link to create a new password via email.',
  check_title: 'Check your email',
  check_body: `We've send you an email with instructions to recover your password.`,
  info_text: `Didn't receive the email?`,
  second_info_text: 'Check the spam folder or ',
  reset: 'Reset password',
  updated: 'Password updated',
  updated_body:
    'Your password has been changed successly. Use your new password to sign in.',
  forgot_password: 'I forgot my password',
};
