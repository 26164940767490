
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      validator: (val) =>
        ['xsmall', 'small', 'medium', 'large', 'larger', 'extra-large'].indexOf(
          val,
        ) !== -1,
      default: 'medium',
    },
  },
};
