import { waitNseconds } from '~/utils/functions';
import {
  G2C_MESSAGES_COMMISION_TYPE,
  G2C_MESSAGES_TYPE,
  G2C_MESSAGES_PERMISSIONS,
  G2C_MESSAGES_ENCODING,
  MESSAGES_STREAM_IN,
  MESSAGES_STREAM_OUT,
  CustomError,
} from '~/vars/api';

export default ({ app }) => ({
  response: null,
  /**
   * Get user object to get text from message
   * @param Object
   * @returns user object
   */
  async getUserObject({ message, retries, versions }) {
    try {
      if (typeof retries !== 'number') retries = 0;
      if (retries > 10)
        throw new Error(`Object not found after ${retries} retries`);
      else retries = retries + 1;
      const nick = app.$auth.user.data.nick;
      const path =
        nick === message.from
          ? `/${MESSAGES_STREAM_OUT}`
          : `/${MESSAGES_STREAM_IN}`;
      const name = nick === message.from ? `${message.to}` : `${message.from}`;
      const g2ctoken = app.$api.g2cSession.getToken();
      return await app.$api.g2cProvider.getUserObjectHistoric({
        g2ctoken,
        path,
        name,
        versions,
      });
    } catch (error) {
      if (
        typeof error.error !== 'undefined' &&
        !error.error.includes('No versions to show')
      ) {
        // eslint-disable-next-line no-console
        console.log('Retry object read #', retries);
        setTimeout(async () => {
          return await this.getUserObject({
            message,
            retries,
            versions,
          });
        }, retries * 250);
      } else throw error;
    }
  },

  // Create message by streaming in or out
  async createMessageObject({ from, to, message, datestamp, streaming }) {
    try {
      const g2ctoken = app.$api.g2cSession.getToken();
      const type = G2C_MESSAGES_TYPE;
      const path = '/' + streaming;
      const permissions = G2C_MESSAGES_PERMISSIONS;
      const encoding = G2C_MESSAGES_ENCODING;
      const timestamp = datestamp;
      const counterPart = streaming === MESSAGES_STREAM_OUT ? to : from;
      const data = {
        from,
        to,
        message,
        timestamp,
      };
      const checkPath = await app.$api.g2cCommons.createPathIfNotFound({
        path: '/',
        name: streaming,
      });
      if (checkPath !== 'Already exists') await waitNseconds(3);

      return await app.$api.g2cProvider.updateUserObject({
        g2ctoken,
        type,
        path,
        name: `${counterPart}`,
        data: JSON.stringify(data),
        encoding,
        permissions,
      });
    } catch (error) {
      console.log('error triggered from promise', error);
      throw new Error(error);
    }
  },
});
