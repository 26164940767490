import {
  SET_PUBLIC_PROFILE_USER,
  SET_PUBLIC_PROFILE_THEME,
  SET_PUBLIC_PROFILE_FEATURES,
  SET_PUBLIC_PROFILE_GRID,
  SET_PUBLIC_PROFILE_PREVIEW_STYLE,
  SET_PUBLIC_PROFILE_PREVIEW_PROGRESS,
  SET_PUBLIC_PROFILE_SEO,
  SET_PUBLIC_PROFILE_IS_OWNER,
  SET_PUBLIC_PROFILE_HEADER_IMAGE,
  INCREASE_PUBLIC_PROFILE_PREVIEW_PROGRESS,
  SET_PUBLIC_PROFILE_THEMES,
  PURCHASE_PUBLIC_PROFILE_THEME,
  SET_PUBLIC_PROFILE_STATUS,
  PURCHASE_PUBLIC_PROFILE_VIDEO,
  PURCHASE_PUBLIC_PROFILE_VISITS,
  SET_PUBLIC_PROFILE_VIDEO_LINK,
} from '../vars/store/mutations';

import { setPublicProfile } from '../vars/store/actions';

const getDefaultState = () => ({
  user: {},
  theme: {},
  features: {},
  grid: [],
  previewStyle: null,
  previewProgress: 0,
  isOwnerOfProfile: false,
  themes: [],
});

export const state = getDefaultState();

export const getters = {
  user: (state) => state.user,
  theme: (state) => state.theme,
  features: (state) => state.features,
  grid: (state) => state.grid,
  previewStyle: (state) => state.previewStyle,
  previewProgress: (state) => state.previewProgress,
  seo: (state) => state.seo,
  isOwnerOfProfile: (state) => state.isOwner,
  themes: (state) => state.themes,
};

export const actions = {
  [setPublicProfile](
    { commit, getters },
    {
      user,
      theme,
      features,
      grid,
      previewStyle,
      previewProgress,
      seo,
      isOwner,
    },
  ) {
    commit(SET_PUBLIC_PROFILE_USER, user);
    commit(SET_PUBLIC_PROFILE_THEME, theme);
    commit(SET_PUBLIC_PROFILE_FEATURES, features);
    commit(SET_PUBLIC_PROFILE_GRID, grid);
    commit(SET_PUBLIC_PROFILE_PREVIEW_STYLE, previewStyle);
    commit(SET_PUBLIC_PROFILE_PREVIEW_PROGRESS, previewProgress);
    commit(SET_PUBLIC_PROFILE_SEO, seo);
    commit(SET_PUBLIC_PROFILE_IS_OWNER, isOwner);
  },
};

export const mutations = {
  [SET_PUBLIC_PROFILE_USER](state, user) {
    state.user = user;
  },
  [SET_PUBLIC_PROFILE_THEME](state, theme) {
    state.theme = theme;
  },
  [SET_PUBLIC_PROFILE_FEATURES](state, features) {
    state.features = features;
  },
  [SET_PUBLIC_PROFILE_GRID](state, grid) {
    state.grid = grid;
  },
  [SET_PUBLIC_PROFILE_PREVIEW_STYLE](state, previewStyle) {
    state.previewStyle = previewStyle;
  },
  [SET_PUBLIC_PROFILE_PREVIEW_PROGRESS](state, previewProgress) {
    state.previewProgress = previewProgress;
  },
  [INCREASE_PUBLIC_PROFILE_PREVIEW_PROGRESS](state) {
    state.previewProgress++;
  },
  [SET_PUBLIC_PROFILE_SEO](state, seo) {
    state.seo = seo;
  },
  [SET_PUBLIC_PROFILE_IS_OWNER](state, isOwner) {
    state.isOwner = isOwner;
  },
  [SET_PUBLIC_PROFILE_HEADER_IMAGE](state, headerImage) {
    const user = Object.assign({}, state.user);
    user.header_image = headerImage;
    state.user = user;
  },
  [SET_PUBLIC_PROFILE_THEMES](state, themes) {
    state.themes = themes;
  },
  [SET_PUBLIC_PROFILE_STATUS](state, status) {
    const user = Object.assign({}, state.user);
    user.status = status;
    state.user = user;
  },
  [SET_PUBLIC_PROFILE_VIDEO_LINK](state, link) {
    const features = Object.assign({}, state.features);
    features.video.data = link;
    state.features = features;
  },
};
