
export default {
  props: {
    feedback: {
      type: String,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    success: {
      type: String,
      default: null,
    },
    addClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      variant: '',
      text: '',
    };
  },
  watch: {
    feedback(newValue) {
      console.log('feedback ', newValue);
      if (newValue !== null) {
        this.variant = 'loading';
        this.text = newValue;
      } else {
        this.text = '';
      }
    },
    error(newValue) {
      console.log('error ', newValue);
      if (newValue !== null) {
        this.variant = 'error';
        this.text = newValue;
      } else {
        this.text = '';
      }
    },
    success(newValue) {
      console.log('success ', newValue);
      if (newValue !== null) {
        this.variant = 'success';
        this.text = newValue;
      } else {
        this.text = '';
      }
    },
  },
  computed: {
    getModifier() {
      return this.variant;
    },
    getContent() {
      return this.text;
    },
  },
};
