
/*
   Handle g2cInProcess store variable
*/
import { SET_G2C_IN_PROCESS } from '~/vars/store/mutations';
export default {
  data() {
    return {};
  },
  watch: {
    loading(value) {
      let loading = false;
      if (typeof value === 'boolean') loading = value;
      else if (typeof value === 'object') {
        loading = Object.keys(value).reduce((total, key) => {
          return total || value[key];
        }, false);
      }
      this.$store.commit(`processing/${SET_G2C_IN_PROCESS}`, loading);
    },
  },
  methods: {},
};
