import { EventBus } from '~/components/eventBus/EventBus';

export default ({ app, $axios }) => ({
  /*
   * SIDEBAR
   */
  /**
   * Get sidebar notifications from a date or last ones
   * @param {Number} startfrom if null get last ones
   * @returns Object with sidebar notifications by id
   */
  async getSidebarNotifications(startfrom, mode) {
    const route =
      `notifications/${app.$auth.user.data.id}` +
      (startfrom !== null ? `/${startfrom}` : '') +
      (mode !== undefined ? `/${mode}` : '');
    return await $axios
      .get(route)
      .then((response) => response.data.data)
      .catch((e) => {});
  },

  /**
   * Get number of sidebar notifications with 'read' property false
   * @returns Number
   */
  async getNewSidebarNotifications(mode) {
    if (!app.$auth.$state.loggedIn) return [];
    const route =
      `notifications/${app.$auth.user.data.id}/unread` +
      (mode !== undefined ? `/${mode}` : '');
    return await $axios
      .get(route)
      .then((response) => response.data.data)
      .catch((e) => 0);
  },
  /**
   * Update sidebar notification read property
   * @param {String} id
   * @param {Boolean} read
   * @returns
   */
  async updateReadSidebarNotifications(notifications, read, mode) {
    return new Promise(async (resolve, reject) => {
      try {
        let readNotifications = false;
        Promise.allSettled(
          notifications.map((notification) => {
            if (
              readNotifications === false &&
              mode === 'messages' &&
              notification.activity === 'messages_request'
            ) {
              readNotifications = true;
            }
            return this.updateReadSidebarNotification({
              id: notification.id,
              read,
              activity: notification.activity,
              mode,
            });
          }),
        ).then((data) => {
          if (readNotifications === true) EventBus.$emit('read-notifications');
          resolve(true);
        });
      } catch (error) {
        reject(error);
      }
    });
  },
  async updateReadSidebarNotification({ id, read, activity, mode }) {
    return new Promise(async (resolve, reject) => {
      await $axios
        .put(`notifications/${app.$auth.user.data.id}/${id}`, {
          read,
          activity,
          mode,
        })
        .then((response) => resolve(response.data));
    });
  },
  async markAllNotificationsAsRead() {
    return new Promise(async (resolve, reject) => {
      await $axios
        .get(`notifications/${app.$auth.user.data.id}/sidebarmarkallread`)
        .then((response) => resolve(response.data));
    });
  },
});
