export default {
  title: 'Buy and sell digital and physical products, tickets and services',
  subtitle: 'Help us build it faster ',
  description:
    'Contributing with a minimum amount of $1 you will see a preview (non functional) of what will be Market.',
  button: 'Contribute',
  sent: 'Contribution done ',
  validation: {
    required: 'Please enter a valid numeric amount',
    valid: 'Minimum amount allowed $ 1',
  },
};
