import { render, staticRenderFns } from "./UserCardBtns.vue?vue&type=template&id=6fe0fecb&scoped=true&"
import script from "./UserCardBtns.vue?vue&type=script&lang=js&"
export * from "./UserCardBtns.vue?vue&type=script&lang=js&"
import style0 from "./UserCardBtns.vue?vue&type=style&index=0&id=6fe0fecb&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fe0fecb",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UserCardsBtnsUnfollow: require('/tmp/build_fc5c3f7c/components/user/cards/btns/UserCardsBtnsUnfollow.vue').default,UserCardsBtnsFollow: require('/tmp/build_fc5c3f7c/components/user/cards/btns/UserCardsBtnsFollow.vue').default,UserPendingRequestBtns: require('/tmp/build_fc5c3f7c/components/user/cards/btns/UserPendingRequestBtns.vue').default,BaseBtn: require('/tmp/build_fc5c3f7c/components/base/BaseBtn.vue').default})
