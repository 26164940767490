import { MARKETPLACE_NAME } from '~/vars/api';

export default {
  base: {
    title: 'Notifications',
    no_more: 'No more notifications',
    end: '---',
    news: 'Recent activity',
    all_read: 'Mark all as read',
    empty: "You don't have notification yet",
  },
  section: {
    minter: {
      title: `${MARKETPLACE_NAME} Marketplace Validation`,
      description: {
        pending: `Continue validating your ${MARKETPLACE_NAME} Marketplace account`,
      },
    },
    direct: {
      title: 'Wallet',
      description: {
        toast: 'Received payment from @{usernick}',
        payment:
          "You've received {amountSats} (~ {amountUSD}) in your wallet from",
      },
    },
    purchase: {
      title: 'Purchase',
      description: {
        wiva: 'Feature Purchased',
      },
    },
    update: {
      title: 'Market',
      description: {
        order: 'Payment in course',
      },
    },
    connection: {
      title: 'Network',
      description: {
        request: 'has sent you a Connection Request',
        accepted: 'has accepted your connection',
        // rejected: 'Connection request dismissed',
        // canceled: 'Connection request canceled',
      },
    },
    message: {
      title: 'Message',
      description: {
        request: 'New message from',
        // accepted: '{type} Message accepted',
        // rejected: '{type} Message dismissed',
      },
      type: {
        volatile: 'Free',
        privacy: 'Blockchain',
        paid: 'Payment and',
      },
    },
    room: {
      title: 'Room',
      description: {
        pending: 'Invitation to {access} Room received',
        request: 'Request to join {access}  Room received',
        accepted: '{access} Room {request} accepted',
        rejected: '{access} Room {request} dismissed',
        canceled: '{access} Room canceled',
        soon: '{access} Room will start soon',
        started: '{access} Room started',
      },
      request: {
        invitation: 'invitation',
        request: 'request',
      },
      access: {
        public: 'Public',
        private: 'Private',
      },
    },
    asset: {
      title: 'My Items',
      description: {
        transfer: 'has transfered you {tokenName}',
      },
    },
    market: {
      title: 'Market',
      royalty: '({amount} for royalties)',
      redirect: {
        stripe: 'Stripe Account',
        wallet: 'Activity',
      },
      description: {
        bidcreated_client: 'Your offer on {tokenName} has been outbid',
        bidcreated_owner: {
          t_1: {
            text: 'In 24h your {tokenName} Auction will end',
            button: 'Check it out',
          },
          t_2: {
            text: '6h for your {tokenName} Auction to end',
            button: 'See how it goes',
          },
          t_3: {
            text: 'Your {tokenName} Auction is about to end in 10 minutes',
            button: 'Watch the final',
          },
        },
        orderpaid_client: "You've got {tokenName} for {amount}",
        orderpaid_owner: {
          stripe:
            'has bought your {tokenName} for {amount} {fees}. See your income in your',
          wallet_fiat:
            'has bought your {tokenName} for {amount} (paid with Sats) {fees}. See your income in your',
          wallet:
            'has bought your {tokenName} for {amount} {fees}. See your income in your',
        },
        royaltypaid: {
          stripe:
            'has bought {tokenName}, {amount} royalties for you {fees}. See your income in your',
          wallet_fiat:
            'has bought {tokenName}, {amount} (paid with Sats) royalties for you {fees}. See your income in your',
          wallet:
            'has bought {tokenName}, {amount} royalties for you {fees}. See your income in your',
        },
        beneficiarypaid: {
          stripe:
            'has bought {tokenName}, {amount} benefits for you {fees}. See your income in your',
          wallet_fiat:
            'has bought {tokenName}, {amount} (paid with Sats) benefits for you {fees}. See your income in your',
          wallet:
            'has bought {tokenName}, {amount} benefits for you {fees}. See your income in your',
        },
        ordercancelled:
          'It has not been possible to complete your payment for {tokenName}.',
        // ordercreated: 'Payment process started',
        auction_near_win: '{tokenName} auction is about to end.',
        auction_near_notwin:
          '{tokenName} auction is about to end. You still have time to make a higher bid',
        auction_win: "You've won {tokenName} auction",
        auction_notwin: '{tokenName} has end and your bid is not the winner',
        auction_substitute:
          '{tokenName} has end and your bid is not the winner. However you still have a chance!',
        invitation: {
          auction: ' has invited you to bid for {tokenName}',
          buynow: ' has invited you to buy in {tokenName}',
        },
        new_offering: {
          auction:
            ' has added {tokenName} to market. You can make an offer for it now.',
          buynow: ' has added {tokenName} to market. You can buy it now.',
        },
      },
    },
    verify_identity: {
      success: {
        toast: 'Identity verification successfully',
      },
      warning: {
        toast: 'Identity verification successfully',
      },
      fail: {
        toast: 'Identity verification failed, please try again',
      },
    },
  },
};
