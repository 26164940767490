import { MARKETPLACE_NAME } from '~/vars/api';

export default {
  base: {
    title: 'Reporting {type}',
    subtitle: `If you have seen any abuse or trespassing of ${MARKETPLACE_NAME} Marketplace principles and good behaviours, please select a reason of your reporting and add any observation you may consider needed`,
    type: {
      product: 'Item',
      user: 'User',
      offering: 'Offering',
    },
    btn: 'Report',
    reasons: {
      fraudulent_use: {
        value: 'Fraudulent use',
        text: `Fraudulent use of ${MARKETPLACE_NAME} Marketplace or any of its functionalities`,
      },
      identity_fraud: {
        value: 'Identity fraud',
        text: 'Identity fraud',
      },
      obscene: {
        value: 'Abuse',
        text: 'Insulting, abusing or obscene behaviour, content or information',
      },
      spam: {
        value: 'SPAM',
        text: `Send or create massively, harming the correct functioning of ${MARKETPLACE_NAME} Marketplace or any of its users`,
      },
    },
    success: 'Report sent successfully',
  },
  fields: {
    observations: {
      label: 'Observations',
    },
    reason: {
      label: 'Reason',
    },
    btn: {
      send: 'Send Report',
    },
  },
  validation: {
    observations: {
      required: 'Any observation will be appreciated',
    },
    reason: {
      required: 'Please select a suitable reason',
    },
  },
  errors: {
    generic: 'Error sending report',
  },
};
