import { EventBus } from '~/components/eventBus/EventBus';
export default ({ app }) => ({
  socket: null,
  getConnection() {
    return this.socket;
  },
  setConnection() {
    if (!this.socket) {
      this.socket = io(app.context.env.socketServer, {
        autoConnect: false,
        reconnection: true,
        reconnectionDelay: 1000,
        //  reconnectionDelayMax: 10000,
        //  reconnectionAttempts: 100,
        // auth: {
        //   token: {}, //app.$auth.$storage._state['_token.g2c_user'],
        // },
        query: {
          userid: app.$auth.loggedIn ? app.$auth.user.data.id : '<annon>',
        },
      });
      this.setAuthHeader();
      this.socket.on('connect_error', (err) => {
        console.log('Connect error', err);
      });
      this.socket.on('disconnect', (x) => {
        console.log('DISCONNECTED, wait until recoonection.', x);
        app.$socket.handlers.setChannels();
      });
      this.socket.on('connect', () => {
        console.log('CONNECTED TO SOCKET');
        setTimeout(
          () => EventBus.$emit('socket-instance-setted', this.getConnection()),
          3000,
        );
        app.$socket.handlers.setChannels();
      });
    } else {
      /*
       ** We make this, to update query in existing instance of socket io, for this purpose, we need to disconnect then connect
       */
      this.socket.io.opts.query.userid = app.$auth.loggedIn
        ? app.$auth.user.data.id
        : '<annon>';
      this.socket.disconnect();
      this.setAuthHeader();
    }
    this.socket.connect();
    app.$socket.handlers.setChannels();
  },
  unsetConnection() {
    app.$socket.handlers.setChannels();
  },
  setAuthHeader() {
    if (app.$auth.loggedIn) {
      const token = app.$auth.getToken('g2c_user');
      app.$axios.defaults.headers.common['Authorization'] = token;
      this.socket.io.opts.transportOptions = {
        polling: {
          extraHeaders: {
            Authorization: token,
          },
        },
      };
    }
  },
  errorsHandler(error) {
    this.unsetConnection();
    this.setConnection();
  },
});
