
export default {
  props: {
    items: {
      type: Array,
      default: [],
    },
    header: {
      type: Boolean,
      default: false,
    },
    hideSlider: {
      type: Boolean,
      default: true,
    },
    tabIndex: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    tab: {
      get() {
        this.tabIndex;
      },
      set(value) {
        this.$emit('tab', value);
      },
    },
  },
};
