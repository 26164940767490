export default ({ $axios, app }) => ({
  async verifyIdentity() {
    try {
      return $axios.get('/kyc/verify-identity').then(({ data }) => {
        console.log({ data });
        if (data.error) {
          if (data.message.includes('Mobile number already in use'))
            data.message = app.i18n.t('stripe.onboarding.errors.phone_use');
          else {
            if (data.message.includes('Email address already in use'))
              data.message = app.i18n.t('stripe.onboarding.errors.email_used');
            else
              data.message = app.i18n.t('stripe.onboarding.errors.refreshing');
            app.$sentry.captureException(data.message);
          }
        }
        return data;
      });
    } catch (error) {
      app.$sentry.captureException(error);
      throw error;
    }
  },
  async validationResult() {
    try {
      return $axios.get('/kyc/validation-result').then(({ data }) => {
        if (data.error) {
          if (data.message.includes('Mobile number already in use'))
            data.message = app.i18n.t('stripe.onboarding.errors.phone_use');
          else {
            if (data.message.includes('Email address already in use'))
              data.message = app.i18n.t('stripe.onboarding.errors.email_used');
            else
              data.message = app.i18n.t('stripe.onboarding.errors.refreshing');
            app.$sentry.captureException(data.message);
          }
        }
        return data;
      });
    } catch (error) {
      app.$sentry.captureException(error);
      throw error;
    }
  },
});
