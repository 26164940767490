import {
  BOOKING_FEE_MINUTES,
  MINUTE,
  RANDOM_STRING_LENGTH,
  TIME_TO_HANDLE_REQUEST,
} from '~/vars/api';
import { SET_SELECTED_FILE_MODAL } from '~/vars/store/mutations';

export const handleErrors = (error) => console.log(error);

export const convertMiliseconds = (miliseconds, format) => {
  const totalSeconds = parseInt(Math.floor(miliseconds / 1000));
  const totalMinutes = parseInt(Math.floor(totalSeconds / 60));
  const totalHours = parseInt(Math.floor(totalMinutes / 60));
  const days = parseInt(Math.floor(totalHours / 24));

  const seconds = parseInt(totalSeconds % 60);
  const minutes = parseInt(totalMinutes % 60);
  const hours = parseInt(totalHours % 24);

  switch (format) {
    case 's':
      return totalSeconds;
    case 'm':
      return totalMinutes;
    case 'h':
      return totalHours;
    case 'd':
      return days;
    default:
      return { d: days, h: hours, m: minutes, s: seconds };
  }
};

export const objectToArray = (object) => {
  return Object.keys(object).map((key) => object[key]);
};

// This merthod is for calculate time can handle request, numbers represents 14 days
export const timeToHandleRequest = (requestDate) =>
  requestDate + TIME_TO_HANDLE_REQUEST - new Date().getTime();
export const percentToHandleRequest = (remainingTime) => {
  const timeToHandleRequests = TIME_TO_HANDLE_REQUEST;
  const timeToHandleRequest = 100 * (remainingTime / timeToHandleRequests);
  return timeToHandleRequest < 0
    ? 100
    : timeToHandleRequest >= 100
    ? 0
    : timeToHandleRequest;
};
export const timeToHandleMeetingRequest = (startDate) =>
  startDate - new Date().getTime();
export const percentToHandleMeetingRequest = (
  timeToHandleMeetingRequest,
  startDate,
  requestDate,
) => {
  const timeToHandleRequest =
    100 * (timeToHandleMeetingRequest / (startDate - requestDate));
  return timeToHandleRequest < 0 ? 100 : timeToHandleRequest;
};
export const timeToJoin = (startDate) =>
  startDate + BOOKING_FEE_MINUTES * MINUTE - new Date().getTime();

export const percentToJoin = (requestDate, startDate, remainingTime) => {
  const timeToJoin =
    100 *
    (remainingTime / (startDate + BOOKING_FEE_MINUTES * MINUTE - requestDate));
  return timeToJoin < 0 ? 100 : timeToJoin;
};

export const remainingToJoinHumanString = (remainingTime) => {
  if (remainingTime.d === 0) {
    if (remainingTime.h === 0) {
      if (remainingTime.m === 0) {
        return `${remainingTime.s} second${remainingTime.s > 1 ? 's' : ''}`;
      } else {
        const minutes =
          remainingTime.s > 0 ? remainingTime.m + 1 : remainingTime.m;
        return `${minutes} minute${minutes > 1 ? 's' : ''}`;
      }
    } else {
      return `${remainingTime.h} hour${remainingTime.h > 1 ? 's' : ''}`;
    }
  } else {
    let time = `${remainingTime.d} day${remainingTime.d > 1 ? 's' : ''}`;
    if (remainingTime.h > 10) {
      time += ` ${remainingTime.h} hour${remainingTime.h > 1 ? 's' : ''}`;
    }
    return time;
  }
};

export const convertToInternationalCurrencySystem = (value) => {
  let result = Math.abs(Number(value));
  if (Math.abs(Number(value)) >= 1.0e9) {
    // Nine Zeroes for Billions
    result = (Math.abs(Number(value)) / 1.0e9).toFixed(0) + 'B';
  } else if (Math.abs(Number(value)) >= 1.0e6) {
    // Six Zeroes for Millions
    result = (Math.abs(Number(value)) / 1.0e6).toFixed(0) + 'M';
  } else if (Math.abs(Number(value)) >= 1.0e3) {
    // Three Zeroes for Thousands
    const temp = (Math.abs(Number(value)) / 1.0e3).toFixed(1);
    result = String(temp).replace(/\.0+$/, '') + 'K';
  }
  return result;
};

// random string of length characters with min 1 and max 10
export const randomString = (length) => {
  if (length > 10) length = 10;
  if (length < 1) length = 1;
  const placeByLength = RANDOM_STRING_LENGTH[length];
  return (Math.random() + 1).toString(36).substring(placeByLength);
};

export const capitalize = (string, separator = ' ', joint = ' ') => {
  return string
    .split(separator)
    .map((substring) => substring.charAt(0).toUpperCase() + substring.slice(1))
    .join(joint);
};

export const encryptContent = async (keyname, content) => {
  if (typeof content !== 'string' || content === '') return '';
  const fullkeyname = Buffer.from(keyname);
  const bindata = Buffer.from(content, 'utf8');
  // eslint-disable-next-line no-undef
  const rawdata = await aesCrypt(
    bindata.toString('base64'),
    fullkeyname.toString('hex'),
    true,
  );
  return rawdata;
};

export const decryptContent = async (keyname, rawContent) => {
  if (typeof rawContent !== 'string' || rawContent === '') return '';
  const fullkeyname = Buffer.from(keyname);
  // eslint-disable-next-line no-undef
  const rawdata = await aesDecrypt(
    rawContent,
    fullkeyname.toString('hex'),
    true,
  );
  return Buffer.from(rawdata, 'base64').toString('utf8');
};

export const encryptContentCrypto = async (keyname, content) => {
  if (typeof content !== 'string' || content === '') return '';
  const fullkeyname = Buffer.from(keyname);
  const bindata = Buffer.from(content, 'utf8');
  // eslint-disable-next-line no-undef
  const rawdata = await CryptoJS.AES.encrypt(
    bindata.toString('base64'),
    fullkeyname.toString('hex'),
  ).toString();
  return rawdata;
};

export const decryptContentCrypto = async (keyname, rawContent) => {
  if (typeof rawContent !== 'string' || rawContent === '') return '';
  const fullkeyname = Buffer.from(keyname);
  // eslint-disable-next-line no-undef
  const rawdata = await CryptoJS.AES.decrypt(
    rawContent,
    fullkeyname.toString('hex'),
  ).toString(CryptoJS.enc.Utf8);
  return Buffer.from(rawdata, 'base64').toString('utf8');
};

// Converter from sats to usd
export const sat2Usd = (amount, change) => {
  return (amount / 100000000) * parseFloat(change);
};

// Converter from bsv to usd
export const bsv2Usd = (amount, change) => {
  return ((amount / 100000000) * parseFloat(change)).toFixed(4);
};
// Return hash code
export const stringHashCode = (string) => {
  if (process.client)
    return bsv.crypto.Hash.sha1(Buffer.from(string, 'utf-8')).toString('hex');
};

export const convertObjectContent2File = ({ tokenObject, objectType }) => {
  // Conversion of hexadecimal string to integer array
  // function hexToBytes(hexStr) {
  //   let bytes = [];
  //   for (let c = 0; c < hexStr.length; c += 2)
  //     bytes.push(parseInt(hexStr.substr(c, 2), 16));
  //   return bytes;
  // }
  // Call the server method to get the hexadecimal string res
  // const uint8Array = new Uint8Array(hexToBytes(tokenObject));
  const uint8Array = new Uint8Array(tokenObject);
  let type = objectType;
  if (
    window.navigator.userAgent.includes('Firefox') &&
    objectType.includes('zip')
  )
    type = 'octet-stream';
  const blob = new Blob([uint8Array], {
    type,
  });
  const href = window.URL.createObjectURL(blob);

  return { blob, href };
};

export const convertFile2ObjectContent = (arrayBuffer) => {
  let buffer = new Buffer.alloc(arrayBuffer.byteLength);
  let view = new Uint8Array(arrayBuffer);
  for (let i = 0; i < buffer.length; ++i) {
    buffer[i] = view[i];
  }
  return buffer;
};

export const getNickFromPaymail = ({ paymail, application }) => {
  return paymail.replace(`@${application}`, '');
};
export const calculateCommission = ({ amount, schema }) => {
  if (schema.type === 'percent') {
    return (schema.amount / 100) * amount;
  }
};

export const fileSize2HumanString = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};
export const capacities = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return {
    value: parseFloat((bytes / Math.pow(k, i)).toFixed(dm)),
    size: sizes[i],
  };
};

export const isNumber = (e) => {
  const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.'];
  const keyPressed = e.key;

  if (!keysAllowed.includes(keyPressed)) {
    e.preventDefault();
  }
};

export const showPreviewModal = ($store, images) =>
  $store.dispatch('ui/setImgsToPreview', [images]);

export const waitNseconds = (sec) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      return resolve(true);
    }, 1000 * sec);
  });
};

/**
 * connections and messages
 */
export const decryptMessage = async ({ prefix, from, to, message }) => {
  const keyname = `${prefix}-${from}-${to}`;
  return await decryptContent(keyname, message);
};
export const encryptMessage = async ({ prefix, from, to, message }) => {
  const keyname = `${prefix}-${from}-${to}`;
  return await encryptContent(keyname, message);
};
