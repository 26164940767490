import { setLoadingBids, setOfferingBids } from '~/vars/store/actions';
import {
  RESET_STATE_BIDS,
  SET_HIGHEST_BID,
  SET_LOADING_BIDS,
  SET_MINIMUM_BID,
  SET_OFFERING_BIDS,
} from '~/vars/store/mutations';

const getDefaultState = () => ({
  bidsByOffering: {},
  highestBid: {},
  minimBid: {},
  loadingBids: false,
});
export const state = getDefaultState();

export const getters = {
  loadingBids: (state) => state.loadingBids,
  getOfferingBids: (state) => (id) => state.bidsByOffering[id],
  // getOfferingOutBids: (state) => (id) => state.bidsByOffering[id].outbids,
  getOfferingUniqueNFTBids: (state) => (id, nftUniqueId) =>
    state.bidsByOffering[id].find(
      (bidToken) => bidToken.nftuniqueid === nftUniqueId,
    ),
  getOfferingHighestBid: (state) => (id) => state.highestBid[id] || {},
  getOfferingMinimBid: (state) => (id) => state.minimBid[id] || {},
  getTokenFreeBid: (state) => (id) =>
    state.bidsByOffering[id].find(
      (bidToken) =>
        !bidToken.bidid || (bidToken.status && bidToken.status !== 'winner'),
    ),
};

export const actions = {
  [setOfferingBids]({ commit }, { bidlist, highestBid, minimBid, id }) {
    commit(SET_OFFERING_BIDS, { bidlist, id });
    commit(SET_HIGHEST_BID, { id, highestBid });
    commit(SET_MINIMUM_BID, { id, minimBid });
  },
  [setLoadingBids]({ commit }, loading) {
    commit(SET_LOADING_BIDS, loading);
  },
};
export const mutations = {
  [RESET_STATE_BIDS](state) {
    Object.assign(state, getDefaultState());
  },
  [SET_OFFERING_BIDS](state, { bidlist, id }) {
    state.bidsByOffering[id] = bidlist;
  },
  [SET_MINIMUM_BID](state, { id, minimBid }) {
    state.minimBid[id] = minimBid;
  },
  [SET_HIGHEST_BID](state, { id, highestBid }) {
    state.highestBid[id] = highestBid;
  },
  [SET_LOADING_BIDS](state, loading) {
    state.loadingBids = loading;
  },
};
