import {
  CustomError,
  G2C_MARKET_ENCODING,
  G2C_MARKET_OBJECT_PATH,
  G2C_MARKET_PERMISSIONS,
  PERMISSIONS_TRANSFER,
  USER_OPTION_COMPANY,
} from '~/vars/api';

export default ({ app }) => ({
  /*
   * NFT handlers
   */
  async getNFTs({ tokenSerieId, mode }) {
    try {
      const g2ctoken = app.$api.g2cSession.getToken();
      return await app.$api.g2cProvider.getToken({
        g2ctoken,
        mode,
        tokenSerieId,
      });
    } catch (error) {
      console.log('error triggered from promise - tokenGet', error);
      throw new Error(error);
    }
  },
  async createNFT({
    description,
    nftname,
    symbol,
    imageurl,
    transferrable,
    commissionschema,
    issuerEmail,
    legalterms,
    amountEmission,
    name,
    path,
  }) {
    await app.$auth.fetchUser();
    if (
      app.$api.commons.onlyCompanyMinter() &&
      app.$auth.user.data.user_option !== USER_OPTION_COMPANY
    )
      throw new CustomError(
        app.i18n.t('market.create_product.errors.create.forbidden'),
      );
    try {
      const g2ctoken = app.$api.g2cSession.getToken();

      return await app.$api.g2cProvider.createToken({
        g2ctoken,
        description,
        nftname,
        symbol,
        imageurl,
        transferrable,
        commissionschema,
        issuerEmail,
        legalterms,
        amountEmission,
        name, //Object to share name or undefined if no object related
        path, //Path of object to share or undefined if no object related
      });
    } catch (error) {
      console.log('error triggered from promise - tokentCreate', error);
      throw error;
    }
  },
  async transferNFT({
    destinationnick,
    nftuniqueidlist,
    tokenSerieId,
    description,
  }) {
    try {
      const g2ctoken = app.$api.g2cSession.getToken();

      return await app.$api.g2cProvider.transferToken({
        g2ctoken,
        destinationnick,
        nftuniqueidlist,
        tokenSerieId, //All tokens of this serie have the same
        description,
      });
    } catch (error) {
      console.log('error triggered from promise - tokenTransfer', error);
      throw error;
    }
  },
  async transferNFTConditionalPropose({
    destinationnick,
    nftuniqueidlist,
    tokenSerieId,
    expirationdatestamp,
    description,
  }) {
    try {
      const g2ctoken = app.$api.g2cSession.getToken();

      return await app.$api.g2cProvider.transferTokenPropose({
        g2ctoken,
        destinationnick,
        nftuniqueidlist,
        tokenSerieId,
        permissions: PERMISSIONS_TRANSFER,
        expirationdatestamp, // IMPORTANT
        description,
      });
    } catch (error) {
      console.log(
        'error triggered from promise - tokenConditionalPropose',
        error,
      );
      if (error.errordata) return error;
      else throw error;
    }
  },
  async transferNFTConditionalAccept({
    tokenSerieId,
    conditionalTransferAuth,
  }) {
    try {
      const g2ctoken = app.$api.g2cSession.getToken();

      return await app.$api.g2cProvider.transferTokenAccept({
        g2ctoken,
        tokenSerieId,
        conditionalTransferAuth, // IMPORTANT
      });
    } catch (error) {
      console.log(
        'error triggered from promise - tokenConditionalAccept',
        error,
      );
      throw error;
    }
  },
  async transferNFTConditionalReject({
    tokenSerieId,
    conditionalTransferAuth,
  }) {
    try {
      const g2ctoken = app.$api.g2cSession.getToken();

      return await app.$api.g2cProvider.transferTokenReject({
        g2ctoken,
        tokenSerieId,
        conditionalTransferAuth, // IMPORTANT
      });
    } catch (error) {
      console.log(
        'error triggered from promise - tokenConditionalReject',
        error,
      );
      throw error;
    }
  },
  async redeemNFT({ nftuniqueid, nftuniqueidlist, tokenSerieId }) {
    try {
      const g2ctoken = app.$api.g2cSession.getToken();
      // eslint-disable-next-line no-undef
      return await app.$api.g2cProvider.deleteToken({
        g2ctoken,
        nftuniqueid,
        nftuniqueidlist,
        tokenSerieId,
      });
    } catch (error) {
      console.log('error triggered from promise - tokenRedeem', error);
      throw error;
    }
  },
  /*
   * OBJECT BASED NFT HANDLERS
   */
  createTokenFolder() {
    return app.$api.g2cCommons
      .createPathIfNotFound({
        path: '/',
        name: G2C_MARKET_OBJECT_PATH,
      })
      .catch((error) => {
        if (error.error && error.error.includes('Path already exist'))
          return true;
        else throw error;
      });
  },
  async createMarketObject({ file, fileName, type }) {
    try {
      const g2ctoken = app.$api.g2cSession.getToken();

      const path = `/${G2C_MARKET_OBJECT_PATH}`;
      const permissions = G2C_MARKET_PERMISSIONS;
      const encoding = G2C_MARKET_ENCODING;
      const name = fileName;
      await this.createTokenFolder();
      const objectCreated = await app.$api.g2cProvider.createUserObject({
        g2ctoken,
        type,
        path,
        name,
        data: file,
        encoding,
        permissions,
      });
      const response = Object.assign({ name }, objectCreated);
      return response;
    } catch (error) {
      console.log('error triggered from createMarketObject', error);
      throw new Error(error);
    }
  },
  /**
   * Retrieves a market user's object
   * @param {Object} mame, mode
   * @returns
   */
  async getMarketObject({ name, mode }) {
    try {
      const path = `/${G2C_MARKET_OBJECT_PATH}`;
      const g2ctoken = app.$api.g2cSession.getToken();
      return await app.$api.g2cProvider.getUserObject({
        g2ctoken,
        path,
        name,
        mode,
      });
    } catch (error) {
      console.log('error triggered from getMarketObject', error);
      throw new Error(error);
    }
  },
  /**
   * Retrieves a market user's object list
   * @returns
   */
  async getMarketObjectList() {
    try {
      const g2ctoken = app.$api.g2cSession.getToken();
      const path = `/${G2C_MARKET_OBJECT_PATH}`;
      return await app.$api.g2cCommons.getUserObjectList({
        g2ctoken,
        path,
      });
    } catch (error) {
      console.log('error triggered from promise', error);
      throw error;
    }
  },
});
