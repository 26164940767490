import { SESSION_WELCOME } from '~/vars/api';
import { showSessionModal, showVerifyEntityModal } from '~/vars/store/actions';
import {
  SET_SELECTED_ACTION_MODAL,
  TOGGLE_SHOW_SESSION_MODAL,
  TOGGLE_SHOW_VERIFY_ENTITY_MODAL,
  SET_LOGGING_OUT,
} from '~/vars/store/mutations';

export const state = () => ({
  selectedAction: undefined,
  showSessionModal: false,
  showVerifyEntityModal: false,
  verifiedMobile: false,
  verifiedEmail: false,
  loggingOut: false,
});

export const getters = {
  selectedActionModal: (state) =>
    state.selectedActionModal === undefined
      ? SESSION_WELCOME
      : state.selectedActionModal,
  showSessionModal: (state) => state.showSessionModal,
  showVerifyEntityModal: (state) => state.showVerifyEntityModal,
  userIsVerified: (state) => state.verifiedMobile && state.verifiedEmail,
  loggingOut: (state) => {
    return state.loggingOut;
  },
};

export const actions = {
  [showSessionModal]({ commit }, action) {
    commit(SET_SELECTED_ACTION_MODAL, action);
    commit(TOGGLE_SHOW_SESSION_MODAL, true);
  },
  [showVerifyEntityModal]({ commit }, action) {
    commit(TOGGLE_SHOW_VERIFY_ENTITY_MODAL, true);
  },
};

export const mutations = {
  [SET_SELECTED_ACTION_MODAL](state, action) {
    state.selectedActionModal = action;
  },
  [TOGGLE_SHOW_SESSION_MODAL](state, show) {
    state.showSessionModal = show;
  },
  [TOGGLE_SHOW_VERIFY_ENTITY_MODAL](state, show) {
    state.showVerifyEntityModal = show;
  },
  SET_VERIFY_MOBILE(state, status) {
    state.verifiedMobile = status;
  },
  SET_VERIFY_EMAIL(state, status) {
    state.verifiedEmail = status;
  },
  [SET_LOGGING_OUT](state, data) {
    state.loggingOut = data;
  },
};
