
const DEFAULT_AVATAR = '/svg/default_avatar.svg';

import { mapGetters } from 'vuex';
import { STATUS_AVAILABLE, STATUS_INVISIBLE, USER_PLATFORM } from '~/vars/api';
import UserMixinHandlerLoggedUser from '~/components/user/UserMixinHandleLoggedUser.vue';
export default {
  mixins: [UserMixinHandlerLoggedUser],
  props: {
    user: {
      type: Object,
      required: true,
    },
    showStatus: {
      type: Boolean,
      default: true,
    },
    size: {
      type: Number,
      default: 130,
    },
    showAccountProfile: {
      type: Boolean,
      default: true,
    },
    stopPropagation: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      userAreOnline: ['users/userAreOnline'],
    }),
    img() {
      if (this.user.id === USER_PLATFORM.id)
        return this.user.profile_image.fullpath;
      return this.user.profile_image
        ? `${this.user.profile_image.fullpath}?alt=media`
        : DEFAULT_AVATAR;
    },
    isOnline() {
      return this.userAreOnline(this.user.id);
    },
    DEFAULT_AVATAR() {
      return DEFAULT_AVATAR;
    },
    statusStyle() {
      // if finnally border radius 5%
      // `right: ${this.statusPosition}%; bottom: ${this.statusPosition}%;` +
      // ` transform: translate(${this.statusPosition}%, ${this.statusPosition}%);` +
      const statusSize = this.size * 0.1;
      return `width: ${statusSize > 12 ? statusSize : 12}px; height: ${
        statusSize > 12 ? statusSize : 12
      }px; right: ${this.size * 0.02}px; bottom: ${this.size * 0.2}px;`;
    },
    // Status position if avatar has not border radius 5%
    statusSize() {
      // rel aspect 130px size 15px width/height === 11.54%
      const statusSize = this.size * 0.1154;
      const maxSize = 22;
      const minSize = 15;
      return statusSize < minSize
        ? minSize
        : statusSize < maxSize
        ? statusSize
        : maxSize;
    },
    isInvisible() {
      return this.statusColour === STATUS_INVISIBLE;
    },
    statusColour() {
      return this.isOnline
        ? typeof this.user.is_online === 'boolean'
          ? STATUS_AVAILABLE
          : this.user.is_online
        : STATUS_INVISIBLE;
    },
  },
  methods: {
    clicked(evt) {
      if (this.stopPropagation) {
        evt.stopPropagation();
      }
      if (this.isLoggedUser && this.showAccountProfile) {
        this.$router.push(
          this.localePath({
            name: 'usernick',
            params: {
              usernick: this.user.nick,
            },
          }),
        );
      } else {
        this.$emit('avatar-clicked');
      }
    },
  },
};
