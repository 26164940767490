import { MARKETPLACE_NAME } from '~/vars/api';

export default {
  redirecting: {
    head: 'Redirecting Stripe',
    title: 'Redirecting to Stripe',
    info_1: 'If everything goes well you will be redirected to Stripe.',
    info_2: `Otherwise, if you are not being redirected please check if you allow ${MARKETPLACE_NAME} Marketplace to show popups.`,
  },
  onboarding: {
    cta: {
      info: 'To {action} you need to onboard for a merchant account to receive and handle future payments',
      button: 'Verify Identity',
      actions: {
        connect: 'connect with other users',
        product_create: 'mint',
        product_buy: 'buy product',
        product_bid: 'make an offer',
        product_list: 'list a product to market',
      },
    },
    become_minter: {
      title: 'Verify your identity',
      // boton
      button: 'Verify your Identity',
      // info de la modal que se abre
      info_minter:
        'To Mint an Item we need to verify your identity. This helps us make sure every Item belongs to a real person',
      info_seller:
        'To Sell an Item we need to verify your identity. This helps us make sure every sale is by a real person',
      step_1: {
        subtitle: 'First step is to know your Country of residence',
        country_title: 'Select your country of residence',
      },
      step_2: {
        title:
          'Great! You can continue verifying your account with our partner Stripe.',
        subtitle: `Remember it's important for all our users to be verified to make sure every sale in ${MARKETPLACE_NAME} Marketplace is by a real person.`,
      },
      step_3: {
        title: `We don't have an automatic verification for your country of residency.`,
        subtitle: 'Continue your verification manualy.',
        message: `Thank you! We'll contact you shortly.`,
        residency: 'Country',
        country_required: 'Country field is required',
      },
    },
    continue_onboarding: {
      // boton
      button: 'Continue Verifying Identity',
      // titulo de la pagina si se vuelve de stripe antes de terminar el formulario y/o no aceptando TOS
      title: 'Complete process to verify your Identity',
      // info de la modal que se abre | tambien sirve para la pagina si vuelve antes de terminar de rellenar el form de strip y aceptar TOS
      info: `We want honest offerings by real people in ${MARKETPLACE_NAME} Marketplace.`,
      close:
        'Close this tab and click Continue Verification when you are ready ',
    },
    minter: {
      // boton
      button: 'Minter Account',
      // info de la modal que se abre
      info: 'You will be redirected to Stripe where you can check or update your info as Minter',
    },
    // cuando vuelve y se esta cargando la info de si está pendiente o verifying
    loading: {
      checking: 'Checking your information',
      wait: 'Please, wait a moment',
      title: 'Checking your information',
      info: 'Please, wait a moment',
      close: `Closing this tab you will go back to ${MARKETPLACE_NAME} Marketplace`,
    },
    // cuando vuelve y ya nos dice stripe que esta todo ok
    success: {
      title: 'Awesome, your account has been verified!',
      info: 'You can now start Minting Items',
      button: 'Almost verified...',
      close: `Close this tab and come back to ${MARKETPLACE_NAME} Marketplace or start Minting Items`,
    },
    // cuando vuelve y stripe aun está verificando la información que ha metido (ya ha aceptado TOS)
    verifying: {
      title: 'Stripe is verifying your info',
      info: 'We will send an email when this process is finished.',
      button: 'Verifying your info...',
      close:
        'Close this tab and wait till we notify you to continue the verification',
    },
    // cuando stripe ya ha terminado de verificar y sabe que tiene que pedir los documentos de domicilio y de identidad
    pending_email: {
      title: 'You are very close to verify your Identity',
      info: 'Please check your email looking for a mail from Stripe.',
      button: 'Continue Verification',
      close:
        'Close this tab and wait till we notify you to continue the verification',
    },
    // cuando stripe ya ha terminado de verificar y sabe que tiene que pedir los documentos de domicilio y de identidad
    pending: {
      title: 'You are very close to verify your Identity',
      info: 'You will be redirected to Stripe to finish your validation.',
      button: 'Continue Verification',
      close:
        'Close this tab and wait till we notify you to continue the verification',
    },
    errors: {
      refreshing: 'Error fetching Stripe Session',
      pending: 'Stripe is verifying your info',
      generic: 'Service not available',
      phone_use: 'Phone number already in use',
      email_used: 'Email address already in use',
    },
  },
};
