import { USER_OPTION_COMPANY } from '~/vars/api';

export default ({ app, redirect, route }) => {
  if (
    !app.$auth.loggedIn ||
    (app.$auth.loggedIn &&
      (route.path.includes('/mint-form') ||
        route.path.includes('/account/wallet/mymints')) &&
      (app.$auth.user.data.is_verified === false ||
        app.$auth.user.data.is_verified === null ||
        typeof app.$auth.user.data.is_verified === 'undefined' ||
        (app.$api.commons.onlyCompanyMinter() &&
          app.$auth.user.data.user_option !== USER_OPTION_COMPANY) ||
        (app.$api.commons.vipProfileEnabled &&
          !app.$api.commons.vipProfile(app.$auth.user.data.nick))))
  ) {
    if (route.path.includes('/account/wallet/mymints'))
      return redirect('/account/wallet/mytokens');
    return redirect('/');
  }
  if (
    !app.$auth.loggedIn ||
    (app.$auth.loggedIn &&
      (route.path.includes('/my-tokens/list-form') ||
        route.path.includes('/market/preview/myofferings')) &&
      (app.$auth.user.data.onboarding_status !== 'complete' ||
        (app.$api.commons.onlyCompanySeller() &&
          app.$auth.user.data.user_option !== USER_OPTION_COMPANY) ||
        (app.$api.commons.vipProfileEnabled &&
          !app.$api.commons.vipProfile(app.$auth.user.data.nick))))
  ) {
    if (route.path.includes('/my-tokens/list-form'))
      return redirect('/account/wallet/mytokens');
    if (route.path.includes('/market/preview/myofferings'))
      return redirect('/market/preview/marketplace');
    return redirect('/');
  }
};
