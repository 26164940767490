
import {
  convertMiliseconds,
  percentToHandleMeetingRequest,
  timeToHandleMeetingRequest,
} from '~/utils/functions';
import { timeToHandleRequest, percentToHandleRequest } from '~/utils/functions';
import { MESSAGE, NOTIFICATIONS_ACTIVITIES, ROOM_TYPE_ROOMS } from '~/vars/api';
export default {
  props: {
    dateStamp: {
      type: Number,
      default: undefined,
    },
    startStamp: {
      type: Number,
      default: null,
    },
    type: {
      type: String,
      default: undefined,
    },
    status: {
      type: String,
      default: '',
    },
    showBar: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    isNearToStart: false,
  }),
  computed: {
    requestRemainingTime() {
      if ([ROOM_TYPE_ROOMS].includes(this.type))
        return timeToHandleMeetingRequest(this.startStamp);
      return timeToHandleRequest(this.dateStamp);
    },
    requestRemainingPercent() {
      if ([ROOM_TYPE_ROOMS].includes(this.type)) {
        if (
          percentToHandleMeetingRequest(
            this.requestRemainingTime,
            this.startStamp,
            this.dateStamp,
          ) >= 100
        )
          this.$emit('expired');
        return percentToHandleMeetingRequest(
          this.requestRemainingTime,
          this.startStamp,
          this.dateStamp,
        );
      }
      if (percentToHandleRequest(this.requestRemainingTime) === 100) {
        this.$emit('expired');
        this.$emit('action', 'reject');
      } else if ([MESSAGE].includes(this.type)) {
        this.$emit('action', 'accept');
      }
      return percentToHandleRequest(this.requestRemainingTime);
    },
    remainingToHumanString() {
      const { d, h, m } = convertMiliseconds(this.requestRemainingTime);
      this.isNearToStart = d < 1;
      return this.$t('connections.time_to_accept', {
        days: d,
        hours: h,
        minutes: m,
      });
    },
  },
};
