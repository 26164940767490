
import UserMixinHandler from '~/components/user/UserMixinHandler.vue';
import {
  TOGGLE_SHOW_USER_MODAL,
  SET_SELECTED_USER_MODAL,
} from '~/vars/store/mutations';
export default {
  props: {
    userId: {
      type: String,
      required: false,
      default: null,
    },
    userNick: {
      type: String,
      required: false,
      default: null,
    },
    verifyIconSize: {
      type: String,
    },
    avatarSize: {
      type: Number,
      default: 30,
    },
    redirectOnClick: {
      type: Boolean,
      default: false,
    },
    showAvatar: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [UserMixinHandler],
  methods: {
    onClick() {
      this.redirectOnClick ? this.showPreviewModal() : this.$emit('clicked');
    },
    showPreviewModal() {
      this.$store.commit(`users/${SET_SELECTED_USER_MODAL}`, this.user);
      this.$store.commit(`users/${TOGGLE_SHOW_USER_MODAL}`, true);
    },
  },
};
