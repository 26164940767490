
import BaseNotificationBadge from '../base/BaseNotificationBadge.vue';
export default {
  components: { BaseNotificationBadge },
  props: {
    item: {
      type: Object,
      required: true,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    ringBellEnabled: {
      type: Boolean,
      default: false,
    },
    notification: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ringBell: false,
    };
  },
  watch: {
    'item.counter'(oldVal, newVal) {
      if (this.ringBellEnabled && newVal > oldVal) {
        this.ringBell = true;
        setTimeout(() => {
          this.ringBell = false;
        }, 10000);
      }
    },
  },
  computed: {
    isActive() {
      const key = this.item.key;
      const path =
        this.$route.path.split('/')[1] === ''
          ? 'home'
          : this.$route.path.split('/')[1];
      return path.includes(key);
    },
  },
};
