export default {
  page_not_found: 'This page is not here',
  other_error: 'Something went wrong...',
  error: 'An error occurred',
  info: "But the information you're looking for is somewhere out there ",
  problem: 'Do you have any problem? Please check out our',
  help_center: 'Help Center.',
  feedback_error_toast: 'Service not available, try again later.',
  second_feedback_toast: 'An error has occurred.',
  back: 'Back Home',
  no_funds: 'Not enough funds. Extra {diff} needed.',
  no_funds_base: 'Not enough funds.',
  min_balance: 'There must be a minimum of {min} in wallet after transaction.',
};
