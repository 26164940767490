export default {
  filters: {
    connections: 'My Connections',
    online: 'Online',
    default: 'People',
    company: 'Companies',
    by_relation: {
      no_followings: 'You don’t follow any',
      no_words: 'There’s no result with this search',
    },
  },
};
