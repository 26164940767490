/* G2C HANDLERS */
import G2cSession from '~/api/g2c/g2cSession';
import g2cMarket from '~/api/g2c/g2cMarket';
import g2cCommons from '~/api/g2c/g2cCommons';
import g2cPayments from '~/api/g2c/g2cPayments';
import g2cConnections from '~/api/g2c/g2cConnections';
import g2cMessages from '~/api/g2c/g2cMessages';
import g2cProvider from '~/api/g2clib/g2clibProvider';

/* APP HANDLERS */
import Session from '~/api/app/session';
import User from '~/api/app/user';
import Market from '~/api/app/market';
import Notifications from '~/api/app/notifications';
import Commons from '~/api/app/commons';
import Stripe from '~/api/app/stripe';
import Report from '~/api/app/report';
import Follow from '~/api/app/follow';
import Payments from '~/api/app/payments';
import Connections from '~/api/app/connections';
import Messages from '~/api/app/messages';
import Jumio from '~/api/app/jumio';
import ManualVerification from '~/api/app/manualVerification';
import KYC from '~/api/app/kyc';

/* TRACKING HANDLERS */

export default (ctx, inject) => {
  const factories = {
    g2cProvider: g2cProvider(ctx),
    g2cCommons: g2cCommons(ctx),
    g2cSession: G2cSession(ctx),
    g2cMarket: g2cMarket(ctx),
    session: Session(ctx),
    user: User(ctx),
    market: Market(ctx),
    notifications: Notifications(ctx),
    commons: Commons(ctx),
    kyc: KYC(ctx),
    report: Report(ctx),
    follow: Follow(ctx),
  };
  if (process.env.fiatEnabled && process.env.pGatewayProvider !== 'no') {
    if (process.env.pGatewayProvider === 'stripe')
      factories['pGatewayProvider'] = Stripe(ctx);
  }
  if (process.env.kycProvider !== 'no') {
    if (process.env.kycProvider === 'jumio')
      factories['kycProvider'] = Jumio(ctx);
    else if (process.env.kycProvider === 'manual')
      factories['kycProvider'] = ManualVerification(ctx);
  }
  if (process.env.satsEnabled === 'yes') {
    factories['payments'] = Payments(ctx);
    factories['g2cPayments'] = g2cPayments(ctx);
  }
  if (process.env.connectionsEnabled === 'yes') {
    factories['connections'] = Connections(ctx);
    factories['g2cConnections'] = g2cConnections(ctx);
  }
  if (process.env.messagesEnabled === 'yes') {
    factories['messages'] = Messages(ctx);
    factories['g2cMessages'] = g2cMessages(ctx);
  }
  inject('api', factories);
};
