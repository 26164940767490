import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _721a4a37 = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _57c64317 = () => interopDefault(import('../pages/account/activity.vue' /* webpackChunkName: "pages/account/activity" */))
const _00e589dc = () => interopDefault(import('../pages/account/activity/_slug.vue' /* webpackChunkName: "pages/account/activity/_slug" */))
const _b1bdb65e = () => interopDefault(import('../pages/account/profile.vue' /* webpackChunkName: "pages/account/profile" */))
const _168a622b = () => interopDefault(import('../pages/account/settings.vue' /* webpackChunkName: "pages/account/settings" */))
const _7c8ed084 = () => interopDefault(import('../pages/account/settings/general.vue' /* webpackChunkName: "pages/account/settings/general" */))
const _19d50383 = () => interopDefault(import('../pages/account/settings/paymentmethod.vue' /* webpackChunkName: "pages/account/settings/paymentmethod" */))
const _13f09b01 = () => interopDefault(import('../pages/account/wallet.vue' /* webpackChunkName: "pages/account/wallet" */))
const _edfe2bd2 = () => interopDefault(import('../pages/account/wallet/mymints.vue' /* webpackChunkName: "pages/account/wallet/mymints" */))
const _68bb7144 = () => interopDefault(import('../pages/account/wallet/mytokens.vue' /* webpackChunkName: "pages/account/wallet/mytokens" */))
const _565dd500 = () => interopDefault(import('../pages/account/wallet/mytokenshistory.vue' /* webpackChunkName: "pages/account/wallet/mytokenshistory" */))
const _3926fddc = () => interopDefault(import('../pages/error.vue' /* webpackChunkName: "pages/error" */))
const _2e3c8a44 = () => interopDefault(import('../pages/index_old.vue' /* webpackChunkName: "pages/index_old" */))
const _4724c032 = () => interopDefault(import('../pages/messages.vue' /* webpackChunkName: "pages/messages" */))
const _3c45a4dd = () => interopDefault(import('../pages/messages/_id.vue' /* webpackChunkName: "pages/messages/_id" */))
const _a4f63ce8 = () => interopDefault(import('../pages/musicians.vue' /* webpackChunkName: "pages/musicians" */))
const _cf83e7fc = () => interopDefault(import('../pages/redeem.vue' /* webpackChunkName: "pages/redeem" */))
const _363235e0 = () => interopDefault(import('../pages/redirecting.vue' /* webpackChunkName: "pages/redirecting" */))
const _5fbcc24e = () => interopDefault(import('../pages/support.vue' /* webpackChunkName: "pages/support" */))
const _1f8cd147 = () => interopDefault(import('../pages/kyc/pending.vue' /* webpackChunkName: "pages/kyc/pending" */))
const _ef892294 = () => interopDefault(import('../pages/kyc/redirecting.vue' /* webpackChunkName: "pages/kyc/redirecting" */))
const _1f6b508a = () => interopDefault(import('../pages/legal/terms.vue' /* webpackChunkName: "pages/legal/terms" */))
const _ac45ee4a = () => interopDefault(import('../pages/market/preview.vue' /* webpackChunkName: "pages/market/preview" */))
const _4b37cb61 = () => interopDefault(import('../pages/market/preview/collections.vue' /* webpackChunkName: "pages/market/preview/collections" */))
const _8ace87ba = () => interopDefault(import('../pages/market/preview/favorites.vue' /* webpackChunkName: "pages/market/preview/favorites" */))
const _2a59e517 = () => interopDefault(import('../pages/market/preview/marketplace.vue' /* webpackChunkName: "pages/market/preview/marketplace" */))
const _55c77626 = () => interopDefault(import('../pages/market/preview/mybids.vue' /* webpackChunkName: "pages/market/preview/mybids" */))
const _51e994cd = () => interopDefault(import('../pages/market/preview/myofferings.vue' /* webpackChunkName: "pages/market/preview/myofferings" */))
const _5e9d3acc = () => interopDefault(import('../pages/market/preview/assets/related/_involved.vue' /* webpackChunkName: "pages/market/preview/assets/related/_involved" */))
const _17574195 = () => interopDefault(import('../pages/market/preview/assets/_collection.vue' /* webpackChunkName: "pages/market/preview/assets/_collection" */))
const _4f23cd25 = () => interopDefault(import('../pages/my-tokens/mint-form.vue' /* webpackChunkName: "pages/my-tokens/mint-form" */))
const _9e8e2e0a = () => interopDefault(import('../pages/resources/image.vue' /* webpackChunkName: "pages/resources/image" */))
const _e6893dbc = () => interopDefault(import('../pages/session/forgot.vue' /* webpackChunkName: "pages/session/forgot" */))
const _a1cc108c = () => interopDefault(import('../pages/session/login.vue' /* webpackChunkName: "pages/session/login" */))
const _ff0226d2 = () => interopDefault(import('../pages/session/signup.vue' /* webpackChunkName: "pages/session/signup" */))
const _4e70b227 = () => interopDefault(import('../pages/stripe/onboarding/minter.vue' /* webpackChunkName: "pages/stripe/onboarding/minter" */))
const _1eba7d2f = () => interopDefault(import('../pages/stripe/payment/cancel.vue' /* webpackChunkName: "pages/stripe/payment/cancel" */))
const _739bcb14 = () => interopDefault(import('../pages/stripe/payment/refresh.vue' /* webpackChunkName: "pages/stripe/payment/refresh" */))
const _656c18c4 = () => interopDefault(import('../pages/stripe/payment/success.vue' /* webpackChunkName: "pages/stripe/payment/success" */))
const _1f875e06 = () => interopDefault(import('../pages/market/collection/_id.vue' /* webpackChunkName: "pages/market/collection/_id" */))
const _ceb21820 = () => interopDefault(import('../pages/market/product/_id/index.vue' /* webpackChunkName: "pages/market/product/_id/index" */))
const _53de3594 = () => interopDefault(import('../pages/my-tokens/list-form/_id.vue' /* webpackChunkName: "pages/my-tokens/list-form/_id" */))
const _3352a690 = () => interopDefault(import('../pages/password/reset/_token.vue' /* webpackChunkName: "pages/password/reset/_token" */))
const _da3934d0 = () => interopDefault(import('../pages/market/product/_id/checkout.vue' /* webpackChunkName: "pages/market/product/_id/checkout" */))
const _c0b33a48 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _1c98f297 = () => interopDefault(import('../pages/_usernick.vue' /* webpackChunkName: "pages/_usernick" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _721a4a37,
    name: "account___en",
    children: [{
      path: "activity",
      component: _57c64317,
      name: "account-activity___en",
      children: [{
        path: ":slug?",
        component: _00e589dc,
        name: "account-activity-slug___en"
      }]
    }, {
      path: "profile",
      component: _b1bdb65e,
      name: "account-profile___en"
    }, {
      path: "settings",
      component: _168a622b,
      name: "account-settings___en",
      children: [{
        path: "general",
        component: _7c8ed084,
        name: "account-settings-general___en"
      }, {
        path: "paymentmethod",
        component: _19d50383,
        name: "account-settings-paymentmethod___en"
      }]
    }, {
      path: "wallet",
      component: _13f09b01,
      name: "account-wallet___en",
      children: [{
        path: "mymints",
        component: _edfe2bd2,
        name: "account-wallet-mymints___en"
      }, {
        path: "mytokens",
        component: _68bb7144,
        name: "account-wallet-mytokens___en"
      }, {
        path: "mytokenshistory",
        component: _565dd500,
        name: "account-wallet-mytokenshistory___en"
      }]
    }]
  }, {
    path: "/error",
    component: _3926fddc,
    name: "error___en"
  }, {
    path: "/index_old",
    component: _2e3c8a44,
    name: "index_old___en"
  }, {
    path: "/messages",
    component: _4724c032,
    name: "messages___en",
    children: [{
      path: ":id?",
      component: _3c45a4dd,
      name: "messages-id___en"
    }]
  }, {
    path: "/musicians",
    component: _a4f63ce8,
    name: "musicians___en"
  }, {
    path: "/redeem",
    component: _cf83e7fc,
    name: "redeem___en"
  }, {
    path: "/redirecting",
    component: _363235e0,
    name: "redirecting___en"
  }, {
    path: "/support",
    component: _5fbcc24e,
    name: "support___en"
  }, {
    path: "/kyc/pending",
    component: _1f8cd147,
    name: "kyc-pending___en"
  }, {
    path: "/kyc/redirecting",
    component: _ef892294,
    name: "kyc-redirecting___en"
  }, {
    path: "/legal/terms",
    component: _1f6b508a,
    name: "legal-terms___en"
  }, {
    path: "/market/preview",
    component: _ac45ee4a,
    name: "market-preview___en",
    children: [{
      path: "collections",
      component: _4b37cb61,
      name: "market-preview-collections___en"
    }, {
      path: "favorites",
      component: _8ace87ba,
      name: "market-preview-favorites___en"
    }, {
      path: "marketplace",
      component: _2a59e517,
      name: "market-preview-marketplace___en"
    }, {
      path: "mybids",
      component: _55c77626,
      name: "market-preview-mybids___en"
    }, {
      path: "myofferings",
      component: _51e994cd,
      name: "market-preview-myofferings___en"
    }, {
      path: "assets/related/:involved?",
      component: _5e9d3acc,
      name: "market-preview-assets-related-involved___en"
    }, {
      path: "assets/:collection?",
      component: _17574195,
      name: "market-preview-assets-collection___en"
    }]
  }, {
    path: "/my-tokens/mint-form",
    component: _4f23cd25,
    name: "my-tokens-mint-form___en"
  }, {
    path: "/resources/image",
    component: _9e8e2e0a,
    name: "resources-image___en"
  }, {
    path: "/session/forgot",
    component: _e6893dbc,
    name: "session-forgot___en"
  }, {
    path: "/session/login",
    component: _a1cc108c,
    name: "session-login___en"
  }, {
    path: "/session/signup",
    component: _ff0226d2,
    name: "session-signup___en"
  }, {
    path: "/stripe/onboarding/minter",
    component: _4e70b227,
    name: "stripe-onboarding-minter___en"
  }, {
    path: "/stripe/payment/cancel",
    component: _1eba7d2f,
    name: "stripe-payment-cancel___en"
  }, {
    path: "/stripe/payment/refresh",
    component: _739bcb14,
    name: "stripe-payment-refresh___en"
  }, {
    path: "/stripe/payment/success",
    component: _656c18c4,
    name: "stripe-payment-success___en"
  }, {
    path: "/market/collection/:id?",
    component: _1f875e06,
    name: "market-collection-id___en"
  }, {
    path: "/market/product/:id",
    component: _ceb21820,
    name: "market-product-id___en"
  }, {
    path: "/my-tokens/list-form/:id?",
    component: _53de3594,
    name: "my-tokens-list-form-id___en"
  }, {
    path: "/password/reset/:token?",
    component: _3352a690,
    name: "password-reset-token___en"
  }, {
    path: "/market/product/:id?/checkout",
    component: _da3934d0,
    name: "market-product-id-checkout___en"
  }, {
    path: "/",
    component: _c0b33a48,
    name: "index___en"
  }, {
    path: "/:usernick",
    component: _1c98f297,
    name: "usernick___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
