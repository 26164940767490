import {
  setContetMailingModal,
  showMailingInvitationModal,
} from '~/vars/store/actions';
import {
  SET_EMAIL_LIST_MAILING_MODAL,
  SET_INFO_MAILING_MODAL,
  SET_MESSAGE_MAILING_MODAL,
  TOGGLE_SHOW_MAILING_MODAL,
} from '~/vars/store/mutations';

export const state = () => ({
  info: null,
  showMailingInvitationModal: false,
  emailList: [],
  message: '',
});

export const getters = {
  info: (state) => state.info,
  showMailingInvitationModal: (state) => state.showMailingInvitationModal,
  content: (state) => {
    return { emailList: state.emailList, message: state.message };
  },
};

export const actions = {
  [showMailingInvitationModal]({ commit }, { show, info }) {
    commit(SET_INFO_MAILING_MODAL, info);
    commit(TOGGLE_SHOW_MAILING_MODAL, show);
  },
  [setContetMailingModal]({ commit }, { message, emailList }) {
    commit(SET_EMAIL_LIST_MAILING_MODAL, emailList);
    commit(SET_MESSAGE_MAILING_MODAL, message);
  },
};

export const mutations = {
  [SET_INFO_MAILING_MODAL](state, info) {
    state.info = info;
  },
  [TOGGLE_SHOW_MAILING_MODAL](state, show) {
    state.showMailingInvitationModal = show;
  },
  [SET_EMAIL_LIST_MAILING_MODAL](state, emailList) {
    state.emailList = emailList;
  },
  [SET_MESSAGE_MAILING_MODAL](state, message) {
    state.message = message;
  },
};
