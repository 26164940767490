
import { handleErrors, sat2Usd } from '~/utils/functions';
import {
  CONNECTION,
  CONNECTION_STATUS_PENDING,
  CONNECTION_STATUS_PREPAID,
  EXCHANGE_RATE_MODE_BSV_TO_USD,
  EXTRA_MINER_MARGIN,
  SECOND,
  CUSTOM_ERROR,
  CONNECTION_STATUS_PRESHARED,
  CONNECTION_STATUS_PRECHARGED,
  USER_ACTION_CONNECT,
} from '~/vars/api';
import { mapActions, mapGetters } from 'vuex';
import UserMixinHandleLoggedUser from '~/components/user/UserMixinHandleLoggedUser.vue';
export default {
  mixins: [UserMixinHandleLoggedUser],
  props: {
    user: {
      type: Object,
      required: false,
    },
    type: {
      type: String,
      required: true,
    },
    parentNode: {
      type: String,
      default: '',
    },
    showInfoSpan: {
      type: Boolean,
      default: true,
    },
    dismissBtn: {
      type: Boolean,
      default: true,
    },
    fullBtn: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selectAcceptByType: {
        connection: this.acceptRequest,
      },
      selectRejectByType: {
        connection: this.rejectRequest,
      },
      selectContinueByType: {
        connection: this.continueRequest,
      },
      loading: {
        positive: false,
        negative: false,
      },
      isLoadingMessage: false,
      amount: 0,
    };
  },
  computed: {
    ...mapGetters({
      loadingConnection: 'connections/isLoading',
    }),
    satsEnabled() {
      return this.$api.commons.satsEnabled();
    },
    CONNECTION() {
      return CONNECTION;
    },
    isLoadingConnection() {
      return this.loadingConnection(this.user.id);
    },
    currentConnection() {
      return this.$store.getters['connections/getConnection'](this.user.id);
    },
    isReceived() {
      return this.$auth.user.data.id !== this.currentConnection.sourceid;
    },
    acceptLabel() {
      let label = this.$t('base.accept');
      if ([CONNECTION].includes(this.type) && this.satsEnabled) {
        if (this.amount !== 0)
          label += ` ${this.$currency_2.format(this.amount)}`;
      }
      return label;
    },
    action() {
      return USER_ACTION_CONNECT;
    },
  },
  watch: {
    forceReject(value) {
      if (value === true) {
        console.log('AUTOREJECT EXPIRED');
        this.selectRejectByType[this.type]();
      }
    },
  },
  mounted() {
    if (this.isReceived) this.calculateAmountToReceive();
  },
  methods: {
    ...mapActions({
      feedbackNotificationHandler: 'notifications/feedbackNotificationHandler',
      setLoadingConnection: 'connections/setLoadingConnection',
    }),
    isLoadingConnectionOption(option) {
      return this.isLoadingConnection
        ? this.isLoadingConnection.loading[option]
        : false;
    },
    toggleLoading(option) {
      const oldLoading = Object.assign({}, this.loading);
      oldLoading[option] = !oldLoading[option];
      this.loading = Object.assign({}, oldLoading);
      this.$emit('loading', this.loading);
      this.setLoadingConnection({
        userId: this.user.id,
        option,
        status: this.loading[option],
      });
    },
    refresh(option, time = 2) {
      setTimeout(() => {
        this.toggleLoading(option);
        this.feedbackNotificationHandler({
          userId: this.user.id,
          type: this.type,
          show: false,
        });
      }, SECOND * time);
    },
    async recalculateCost({ prefix, from, to }) {
      let amount = 0;
      if (this.currentConnection.status === CONNECTION_STATUS_PREPAID) {
        this.contentMessage = this.currentConnection.message;
        //no crypt on frontend
        //   this.contentMessage = await this.$api.messages.decryptMessage({
        //     prefix,
        //     from,
        //     to,
        //     message: this.currentConnection.message,
        //   });
      } else {
        amount = this.currentConnection.amount;
      }
      return await this.$api.connections.messageConnectRate(
        this.contentMessage,
        amount,
      );
    },
    async calculateAmountToReceive() {
      if (
        this.currentConnection &&
        this.currentConnection.satoshis !== undefined
      ) {
        if (this.fiatEnabled) {
          const exchange = await this.$api.commons.exchangeRates(
            1,
            EXCHANGE_RATE_MODE_BSV_TO_USD,
          );
          this.amount = sat2Usd(this.currentConnection.satoshis, exchange);
        } else this.amout = this.currentConnection.satoshis;
      }
    },
    async continueRequest() {
      this.toggleLoading('positive');
      try {
        const userNick = this.user.nick;
        const to = userNick;
        const from = this.$auth.user.data.nick;
        const prefix = this.$auth.user.data.id;
        let userHasEnoughBalance = this.$api.commons.userPaidFees()
          ? false
          : true;
        if (this.$api.commons.userPaidFees()) {
          const partialCost = await this.recalculateCost({
            prefix,
            from,
            to,
          });
          userHasEnoughBalance = await this.$api.commons.userHasEnoughBalance(
            partialCost,
          );
        }

        if (userHasEnoughBalance) {
          await this.$api.connections.continueConnectToUser({
            to,
            from,
            userId: this.user.id,
            connection: this.currentConnection,
            message: this.contentMessage,
          });
        } else {
          this.$toast.error(this.$t('connections.errors.notfunds'));
        }
        this.refresh('positive');
      } catch (error) {
        this.toggleLoading('positive');
        if (error !== null) {
          handleErrors(error.message.replace(/['"]+/g, ''));
          this.$toast.error(this.$t('connections.errors.create.generic'));
        }
      }
    },
    async acceptRequest() {
      try {
        this.toggleLoading('positive');
        this.feedbackNotificationHandler({
          userId: this.user.id,
          message: this.$t('connections.base.accept'),
          type: this.type,
          status: 'info',
          show: true,
        });
        const userId = this.user.id;
        const userNick = this.user.nick;
        const datestamp = this.currentConnection.historyversionsrc
          ? this.currentConnection.historydatestampsrc
          : this.currentConnection.created_at;
        // Accept payment
        if (this.currentConnection.status === CONNECTION_STATUS_PENDING) {
          await this.$api.connections.acceptConnection({
            userNick,
            userId,
            connection: this.currentConnection,
            message: this.currentConnection.message,
            datestamp,
          });
        } else if (
          [CONNECTION_STATUS_PRESHARED, CONNECTION_STATUS_PRECHARGED].includes(
            this.currentConnection.status,
          )
        ) {
          await this.$api.connections.continueAcceptConnection({
            userNick,
            userId,
            connection: this.currentConnection,
            message: this.currentConnection.message,
            datestamp,
          });
        }
        this.feedbackNotificationHandler({
          userId: this.user.id,
          message: this.$t('connections.base.accept_success'),
          type: this.type,
          status: 'success',
          show: true,
        });
        this.refresh('positive');
      } catch (error) {
        this.feedbackNotificationHandler({
          userId: this.user.id,
          message: this.$t('connections.errors.accept.feedback'),
          type: this.type,
          status: 'error',
          show: true,
        });
        let errorValidation;
        if (error.name === CUSTOM_ERROR) {
          errorValidation = error.message;
        } else {
          handleErrors(error);
          errorValidation = this.$t('connections.errors.accept.generic');
        }
        this.$toast.error(errorValidation);
        this.refresh('positive');
      }
    },
    async rejectRequest() {
      this.toggleLoading('negative');
      this.feedbackNotificationHandler({
        userId: this.user.id,
        message: this.$t('connections.base.reject'),
        type: this.type,
        status: 'info',
        show: true,
      });
      try {
        const response = await this.$api.connections.walletDismiss(
          this.currentConnection.paymentauth,
        );
        if (response.error) throw new Error(response.error);
        const connectionid = `${this.currentConnection.sourceid}-${this.currentConnection.destinationid}`;
        await this.$api.connections.rejectConnection({
          userId: this.user.id,
          sourceid: this.currentConnection.sourceid,
          destinationid: this.currentConnection.destinationid,
          connectionid,
        });
        this.feedbackNotificationHandler({
          userId: this.user.id,
          message: this.$t('connections.base.reject_success'),
          type: this.type,
          status: 'success',
          show: true,
        });
        this.refresh('negative');
      } catch (error) {
        this.feedbackNotificationHandler({
          userId: this.user.id,
          message: this.$t('connections.errors.reject.feedback'),
          type: this.type,
          status: 'error',
          show: true,
        });
        handleErrors(error);
        this.refresh('negative');
      }
    },
  },
};
