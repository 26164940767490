
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters({
      g2cInProcess: 'processing/g2cInProcess',
    }),
  },
  watch: {
    g2cInProcess(value) {
      if (value === true) {
        window.addEventListener('beforeunload', this.preventNavigation);
      } else {
        window.removeEventListener('beforeunload', this.preventNavigation);
      }
    },
  },
  mounted() {
    const rejectBtn = document.querySelector(
      "[data-cookiefirst-action='reject']",
    );
    if (rejectBtn) {
      rejectBtn.parentElement.style.display = 'none';
      const adjustBtn = document.querySelector(
        "[data-cookiefirst-action='adjust']",
      );
      adjustBtn.addEventListener('click', (e) => {
        const dialog = document.querySelector('dialog');
        if (
          dialog.attributes['aria-labelledby'].nodeValue ===
          'cookie-preference-panel-title'
        ) {
          const denyBtn = dialog.querySelector(
            "[data-cookiefirst-action='reject']",
          );
          denyBtn.parentElement.style.display = 'none';

          const saveBtn = dialog.querySelector(
            "[data-cookiefirst-action='save']",
          );
          saveBtn.parentElement.style.flex = '0 0 100%';
          saveBtn.parentElement.style.maxWidth = '100%';
        }
      });
      adjustBtn.parentElement.style.flex = '0 0 100%';
      adjustBtn.parentElement.style.maxWidth = '100%';
    }
    this.$socket.instance.setConnection();
  },
  methods: {
    preventNavigation(event) {
      // need user interaction with page to fire this event propertly
      event.preventDefault();
      event.returnValue = this.$t('base.g2c_in_process.warning');
      return event.returnValue;
    },
  },
};
