import { EventBus } from '~/components/eventBus/EventBus';
import { convertObjectContent2File, handleErrors } from '~/utils/functions';
import {
  G2C_MARKET_OBJECT_PATH,
  BULK_TOKEN,
  CUSTOM_ERROR,
  CustomError,
  SECOND,
  WEEK,
  MINUTE,
  G2C_PURCHASE_COMMISION_TYPE,
  G2C_BENEFICIARY_COMMISION_TYPE,
  G2C_ROYALTY_COMMISION_TYPE,
  EXCHANGE_BY_CURRENCY,
  G2C_BID_COMMISION_TYPE,
} from '~/vars/api';

import {
  setListingOffering,
  setListingStatus,
  setRedeemStatus,
  setTransferStatus,
  showSessionModal,
  confirmListingModal,
} from '~/vars/store/actions';

import {
  ADD_TOKEN_OBJECT,
  CREATE_G2C_ALERT,
  CREATE_OFFERING_CREATION_ALERT,
  DOWNLOAD_MARKET_OBJECT,
  ERROR_CREATING_MARKET_OBJECT,
  REMOVE_G2C_ALERT,
  REMOVE_OFFERING_CREATION_ALERT,
  SET_G2C_IN_PROCESS,
  SET_LOADING_G2C_OBJECT_MODAL,
  SET_MARKET_OBJECT,
  SET_SELECTED_FILE_MODAL,
  SET_SELECTED_G2C_OBJECT_MODAL,
  SET_TOKEN_MINTIN,
  SHOW_CONFIRM_LISTING_MODAL,
  TOGGLE_SHOW_FILE_PREVIEW_MODAL,
  TOGGLE_SHOW_HANDLE_G2C_OBJECT_MODAL,
} from '~/vars/store/mutations';

let checkStatusInterval = null;

export default ({ app, $axios }) => ({
  getCollectionList(nick) {
    return new Promise((resolve, reject) => {
      const id = app.$auth.$state.loggedIn ? app.$auth.user.data.id : '-';
      $axios
        .get(`users/${id}/token/findcollections${nick ? '/' + nick : ''}`)
        .then(({ data }) => {
          return resolve(data.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  createCollection(collection) {
    return new Promise((resolve, reject) => {
      $axios
        .post(
          `users/${app.$auth.user.data.id}/token/createcollection`,
          collection,
        )
        .then(({ data }) => {
          return resolve(data.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getCollectionInfo(collectionId) {
    const idUser = app.$auth.$state.loggedIn ? app.$auth.user.data.id : '-';
    return new Promise((resolve, reject) => {
      // return resolve(MOCKED_COLLECTIONS[symbol]);
      $axios
        .get(`users/${idUser}/token/getcollection/${collectionId}`)
        .then(({ data }) => {
          return resolve(data.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getOwnOwnerCollectionList() {
    return new Promise((resolve, reject) => {
      $axios
        .get(`users/${app.$auth.user.data.id}/offering/getmyownercollections`)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getOwnerCollectionList(ownernick) {
    const idUser = app.$auth.$state.loggedIn ? app.$auth.user.data.id : '-';
    return new Promise((resolve, reject) => {
      $axios
        .post(`users/${idUser}/offering/getownercollections`, {
          nick: ownernick,
        })
        .then(({ data }) => {
          return resolve(data.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  async createTokenFolder() {
    return app.$api.g2cMarket.createTokenFolder();
  },
  /**
   * Create user object to upload file to blockchain
   * @returns
   */
  async createMarketObject({ file, fileName, type }) {
    let objectSelected;
    try {
      const objectCreated = await app.$api.g2cMarket.createMarketObject({
        file,
        fileName,
        type,
      });
      setTimeout(async () => {
        const rawObjectList = await this.getMarketObjectList();
        const userMarketObjects = rawObjectList.map((object) => {
          const fullPath = object.fullPath;
          const fullName = object.fullPath.split('/')[2];
          const fileName = fullName
            .split('-')
            .slice(0, fullName.split('-').length - 1)
            .join('-');
          const mimeType = object.type.includes('/')
            ? object.type.split('/')[1]
            : object.type;
          const createdAt = app
            .$moment(object.created)
            .format('DD/MM/YYYY HH:mm');
          return Object.assign(
            { fullPath, fileName, mimeType, createdAt, fullName },
            object,
          );
        });
        objectSelected = userMarketObjects.find(
          (item) => item.fullName === objectCreated.name,
        );
        if (!app.$api.commons.userPaidFees())
          await app.$api.user.fileUploaded({ size: objectCreated.objectSize });
        app.store.commit(`market/${SET_SELECTED_G2C_OBJECT_MODAL}`, {
          size: objectCreated.objectSize,
          ...objectSelected,
        });
        app.store.commit(`processing/${SET_MARKET_OBJECT}`, {
          fullPath: `/${G2C_MARKET_OBJECT_PATH}/${fileName}`,
          creating: false,
        });
        app.store.commit(
          `market/${TOGGLE_SHOW_HANDLE_G2C_OBJECT_MODAL}`,
          false,
        );
        app.store.commit(`processing/${SET_G2C_IN_PROCESS}`, false);
        return objectCreated;
      }, 5 * SECOND);
    } catch (error) {
      let errorMessage;
      if (error.name.includes(CUSTOM_ERROR)) {
        errorMessage = error.message;
      } else {
        errorMessage = app.i18n.t(
          'market.create_product.errors.create.generic',
        );
      }
      app.store.commit(`processing/${ERROR_CREATING_MARKET_OBJECT}`, {
        fullPath: `/${G2C_MARKET_OBJECT_PATH}/${fileName}`,
        errorMessage: errorMessage,
        error: true,
        creating: false,
      });
      app.store.commit(`processing/${SET_G2C_IN_PROCESS}`, false);
      handleErrors(error);
    }
  },
  /**
   * get user uploaded object file to blockchain
   * @returns
   */
  async getMarketObject({ name, mode }) {
    return await app.$api.g2cMarket.getMarketObject({
      name,
      mode,
    });
  },
  /**
   * Get all loggedUser's market(tokens) objects
   * @returns
   */
  async getMarketObjectList() {
    return await app.$api.g2cMarket.getMarketObjectList({});
  },

  /**
   * Get one product or offering by type
   * @param {Object} {id, usernick}
   * @returns
   */
  async getOneMarketItem({ id, usernick }) {
    return new Promise(async (resolve, reject) => {
      const isOffering = id.slice(0, 2).includes('oF');
      const path = isOffering
        ? 'offering/getofferingbyid'
        : 'token/getproductbyid';
      const idUser = app.$auth.$state.loggedIn ? app.$auth.user.data.id : '-';
      $axios
        .post(`users/${idUser}/${path}/${id}`, {
          usernick,
        })
        .then(({ data }) => {
          if (!data || data.error) {
            reject(data.message ? data.message : 'Error get market item');
          } else {
            if (data.data.items[0]) return resolve(data.data.items[0]);

            resolve(false);
          }
        })
        .catch((e) => {
          handleErrors(e);
          reject(e);
        });
    });
  },

  /**
   * Get all tokenserieid by given nick
   * @param {Object} {nick, tokenSerieId}
   * @returns
   */
  async getLoggedUserProducts({ onlyMint, last, tokenType, productType }) {
    return new Promise(async (resolve, reject) => {
      $axios
        .post(`users/${app.$auth.user.data.id}/token/getmyproducts`, {
          last,
          onlyMint,
          tokenType,
          productType,
        })
        .then(({ data }) => {
          if (data.error) {
            reject(data.message);
          } else {
            resolve(data.data);
          }
        })
        .catch((e) => {
          handleErrors(e);
          reject(e);
        });
    });
  },
  /**
   * Get all public token by given id/nick
   * @param {Object} {sourceUserNick}
   * @returns
   */
  async getPublicProducts(sourceUserNick) {
    return new Promise(async (resolve, reject) => {
      $axios
        .post(
          `users/${
            app.$auth.loggedIn ? app.$auth.user.data.id : '-'
          }/token/getpublicproducts`,
          {
            sourceUserNick,
          },
        )
        .then(({ data }) => {
          if (data.error) {
            reject(data.message);
          } else {
            resolve(data.data.items);
          }
        })
        .catch((e) => {
          handleErrors(e);
          reject(e);
        });
    });
  },

  /**
   * Get tickets redeemed
   * @param {Object} {last}
   * @returns
   */
  async getMyTicketsRedeemed({ last }) {
    return new Promise(async (resolve, reject) => {
      $axios
        .post(`users/${app.$auth.user.data.id}/token/getmytickets-redeemed`, {
          last,
        })
        .then(({ data }) => {
          if (data.error) {
            reject(data.message);
          } else {
            resolve(data.data);
          }
        })
        .catch((e) => {
          handleErrors(e);
          reject(e);
        });
    });
  },

  /**
   * Get all offerings
   * @param {Object} {}
   * @returns
   */
  async getOfferings({
    issuernick,
    collectionid,
    ownernick,
    ownercollection,
    status,
    last,
  }) {
    const id = app.$auth.$state.loggedIn ? app.$auth.user.data.id : '-';
    return new Promise(async (resolve, reject) => {
      $axios
        .post(`users/${id}/offering/getofferings`, {
          issuernick,
          collectionid,
          ownernick,
          ownercollection,
          status,
          last,
        })
        .then(({ data }) => {
          if (!data || data.error) {
            reject(data.message ? data.message : 'Error get offerings');
          } else {
            resolve(data.data);
          }
        })
        .catch((e) => {
          handleErrors(e);
          reject(e);
        });
    });
  },
  /**
   * Get all my offerings (active and closed)
   * @param {Object} {}
   * @returns
   */
  async getMyOfferings({ status, last }) {
    if (!app.$auth.loggedIn) {
      app.store.dispatch(`session/${showSessionModal}`, true);
      return;
    }
    return new Promise(async (resolve, reject) => {
      $axios
        .post(`users/${app.$auth.user.data.id}/offering/getmyofferings`, {
          status,
          last,
        })
        .then(({ data }) => {
          if (data.error) {
            reject(data.message);
          } else {
            resolve(data.data);
          }
        })
        .catch((e) => {
          handleErrors(e);
          reject(e);
        });
    });
  },
  /**
   * Get all offerings where i bid
   * @param {Object} {}
   * @returns
   */
  async getMyBiddedOfferings({ status, last }) {
    if (!app.$auth.loggedIn) {
      app.store.dispatch(`session/${showSessionModal}`, true);
      return;
    }
    return new Promise(async (resolve, reject) => {
      $axios
        .post(`users/${app.$auth.user.data.id}/offering/getmybettedofferings`, {
          status,
          last,
        })
        .then(({ data }) => {
          if (data.error) {
            reject(data.message);
          } else {
            resolve(data.data);
          }
        })
        .catch((e) => {
          handleErrors(e);
          reject(e);
        });
    });
  },

  /**
   * Get all bids of a given nftuniqueid (token)
   * @param {Object} {}
   * @returns
   */
  async getHistoricByNFTId({ nftuniqueid, historic }) {
    const idUser = app.$auth.$state.loggedIn ? app.$auth.user.data.id : '-';
    return new Promise(async (resolve, reject) => {
      $axios
        .get(
          `users/${idUser}/token/gethistoric/${encodeURIComponent(
            nftuniqueid,
          )}`,
        )
        .then(({ data }) => {
          if (data.error) {
            reject(data.message);
          } else {
            resolve(data.data);
          }
        })
        .catch((e) => {
          handleErrors(e);
          reject(e);
        });
    });
  },

  /**
   * Get all bids of a given offeringid
   * @param {Object} {}
   * @returns
   */
  async getBidsByOfferingId({ offering }) {
    const id = app.$auth.$state.loggedIn ? app.$auth.user.data.id : '-';
    return new Promise(async (resolve, reject) => {
      $axios
        .get(`users/${id}/bid/getbyofferingid/${offering.offeringid}`)
        .then(({ data }) => {
          if (!data || data.error) {
            reject(data.message ? data.message : 'Error get bids');
          } else {
            resolve(data.data.bidlist);
          }
        })
        .catch((e) => {
          handleErrors(e);
          reject(e);
        });
    });
  },
  /**
   * Get all spent tickets of user
   * @param {Object} {}
   * @returns
   */
  async getSpentTickets() {
    return new Promise(async (resolve, reject) => {
      $axios
        .get(`/order/retrievespenttickets`)
        .then(({ data }) => {
          if (!data || data.error) {
            reject(data.message ? data.message : 'Error get spent tickets');
          } else {
            resolve(data.data);
          }
        })
        .catch((e) => {
          handleErrors(e);
          reject(e);
        });
    });
  },
  /**
   * Get all out order of user
   * @param {Object} {}
   * @returns
   */
  async getTokenHistory({ way, last }) {
    return new Promise(async (resolve, reject) => {
      $axios
        .post(`/order/retrieveby${way === 'in' ? 'customer' : 'seller'}user`, {
          last,
        })
        .then(({ data }) => {
          if (!data || data.error) {
            reject(
              data.message ? data.message : `Error get items ${way} history`,
            );
          } else {
            data.data.items.map((token) => {
              token.orders = token.orders.filter(
                (order) => !['beneficiary', 'royalty'].includes(order.subScope),
              );
              return token;
            });
            resolve(data.data);
          }
        })
        .catch((e) => {
          handleErrors(e);
          reject(e);
        });
    });
  },

  /**
   * check offering tokens' availability
   * @param {Object} {}
   * @returns
   */
  async checkOfferingTokensStatus(offeringid) {
    return new Promise(async (resolve, reject) => {
      const idUser = app.$auth.$state.loggedIn ? app.$auth.user.data.id : '-';
      $axios
        .post(`users/${idUser}/offering/getassignedtokens`, {
          offeringid,
        })
        .then(({ data }) => {
          if (!data || data.error) {
            reject(
              data.message ? data.message : 'Error get offering items status',
            );
          } else {
            resolve(data.data);
          }
        })
        .catch((e) => {
          handleErrors(e);
          reject(e);
        });
    });
  },

  async downloadMarketObject({ objectSelected, token }) {
    let tokenObject;
    let objectType;
    let download;
    let fullPath = `/${G2C_MARKET_OBJECT_PATH}/`;
    if (typeof objectSelected !== 'undefined') {
      const dataObject = await this.getMarketObject({
        name: objectSelected.fullName,
        mode: 'full',
      });
      tokenObject = dataObject.object;
      objectType = objectSelected.mimeType;
      download = objectSelected.fullName;
      fullPath = fullPath + download;
      if (objectSelected.type.includes('zip'))
        download = `${download}.${objectSelected.type.split('/')[1]}`;
    } else if (typeof token !== 'undefined') {
      const tokenSerie = await app.$api.g2cMarket.getNFTs({
        tokenSerieId: token.tokenSerieId,
        mode: 'full',
      });
      tokenObject = tokenSerie.tokenlist[0].tokenObject.object;
      objectType = token.contract.filetype;
      const nameArr = token.contract.filename.split('-');
      download = nameArr.reduce((total, item, index) => {
        if (index !== nameArr.length - 1)
          total = `${total}${index === 0 ? '' : '-'}${item}`;
        return total;
      }, '');
      fullPath = fullPath + download;
      if (token.contract.filetype.includes('zip'))
        download = `${download}.${token.contract.filetype.split('/')[1]}`;
    }
    const { blob, href } = convertObjectContent2File({
      tokenObject: tokenObject,
      objectType: objectType,
    });

    const link = document.createElement('a');
    link.href = href;
    link.download = download;
    link.click();
    URL.revokeObjectURL(link.href);
    app.store.commit(`processing/${SET_MARKET_OBJECT}`, {
      fullPath,
      downloading: false,
    });
  },

  /**
   * set tokenobject
   * @param {Object} {product, tokensGroup}
   * @returns
   */
  async previewTokenObject(product) {
    return new Promise(async (resolve, reject) => {
      try {
        // app.store.commit(`market/${TOGGLE_SHOW_FILE_PREVIEW_MODAL}`, true);

        const tokenSerie = await app.$api.g2cMarket.getNFTs({
          tokenSerieId: product.tokenSerieId,
          mode: 'full',
        });
        const { blob, href } = convertObjectContent2File({
          tokenObject: tokenSerie.tokenlist[0].tokenObject.object,
          objectType: product.contract.filetype,
        });

        resolve({ blob, href });
      } catch (error) {
        reject(error);
      }
    });
  },
  /**
   * Get all tokens of a given nick by tokenserieId
   * @param {Object} {nick, tokenSerieId}
   * @returns
   */
  async getNFTs({ tokenSerieId, mode = 'fullnoobject' }) {
    return new Promise(async (resolve, reject) => {
      try {
        const tokenSerie = await app.$api.g2cMarket.getNFTs({
          tokenSerieId,
          mode,
        });

        resolve(tokenSerie);
      } catch (error) {
        reject(error);
      }
    });
  },

  async uploadImage({ imageFile }) {
    const formData = new FormData();
    formData.append('image', imageFile);

    return await $axios.post(
      `users/${app.$auth.user.data.id}/store-token-images`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  },
  async uploadImageMultiple({ images }) {
    const formData = new FormData();
    for (const index in images) {
      formData.append(`${index}`, images[index].imageFile);
    }

    return await $axios.post(
      `users/${app.$auth.user.data.id}/store-token-images`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  },

  /**
   * Create NFT token with a given info
   * @param {Object} {description, nftname, symbol, imageurl, transferrable, commissionschema, legalterms, amountEmission, name, path}
   * @returns
   */
  async createNFT({
    description,
    nftname,
    symbol,
    imageurl,
    transferrable,
    legalterms,
    amountEmission,
    name,
    path,
    commissionschema,
    selectedRedeemer,
    productType,
  }) {
    try {
      const issuerEmail = app.$auth.user.data.email;
      const productCreated = await app.$api.g2cMarket.createNFT({
        description,
        nftname,
        symbol,
        imageurl,
        transferrable,
        issuerEmail,
        legalterms,
        amountEmission,
        name,
        path,
        commissionschema,
      });
      if (productCreated.error) throw productCreated.error;
      await app.store.commit(`processing/${SET_TOKEN_MINTIN}`, {
        symbol: symbol,
        status: 'checking',
      });
      const token = await this.getNFTs({
        nick: app.$auth.user.data.nick,
        tokenSerieId: productCreated.tokenSerieId,
      });
      if (Object.keys(token.contract).length === 0 || token.error)
        throw new CustomError(
          app.i18n.t('market.create_product.errors.mint.create'),
        );
      await app.store.commit(`processing/${SET_TOKEN_MINTIN}`, {
        symbol: symbol,
        status: 'uploading-token',
      });
      const productInfo = this.processProductInfo(
        productCreated,
        token,
        name && path ? { name, path } : null,
        selectedRedeemer,
        productType,
      );
      const productUploaded = await this.createProduct({
        productInfo,
      });

      if (productUploaded.error)
        throw new CustomError(
          app.i18n.t('market.create_product.errors.mint.register'),
        );
      // *** mostrar modal success mintin
      await app.store.commit(`processing/${SET_TOKEN_MINTIN}`, {
        symbol: symbol,
        status: 'done',
      });
      setTimeout(() => {
        EventBus.$emit('update-product', { id: productCreated.tokenSerieId });
        app.store.commit(`processing/${SET_TOKEN_MINTIN}`, {
          symbol: symbol,
          remove: true,
        });
      }, 5 * SECOND);
    } catch (error) {
      let errorText = app.i18n.t('market.create_product.errors.mint.generic');
      if (error.name.includes(CUSTOM_ERROR)) errorText = error.message;
      // *** mostrar modal error mintin
      await app.store.commit(`processing/${SET_TOKEN_MINTIN}`, {
        symbol: symbol,
        status: 'error',
        error: errorText,
      });
      setTimeout(() => {
        app.store.commit(`processing/${SET_TOKEN_MINTIN}`, {
          symbol: symbol,
          remove: true,
        });
      }, 5 * SECOND);
    }
  },

  processProductInfo(
    productCreated,
    token,
    selectedG2cObject,
    selectedRedeemer,
    productType,
  ) {
    const { contracttxid, issuetxid, tokenSerieId, transactionGroupId } =
      productCreated;
    const { tokenlist } = token;
    const commissionschema = Object.assign(
      {},
      token.contract.properties.meta.commissionschema,
    );
    let filename;
    let filetype;
    if (selectedG2cObject) {
      filename = selectedG2cObject.name;
      filetype = token.contract.properties.meta.nfttype;
    }
    let redeemernick;
    let redeemerid;
    let ticketExpirationDatestamp;
    if (selectedRedeemer) {
      redeemernick = selectedRedeemer.user.nick;
      redeemerid = selectedRedeemer.user.id;
      ticketExpirationDatestamp = selectedRedeemer.ticketExpirationDatestamp;
    }
    const contract = {
      tokenSerieId,
      name: token.contract.name,
      description: token.contract.description,
      totalSupply: token.contract.totalSupply,
      image: token.contract.image,
      issuercollectionid: null,
      // issuercollectionid: this.selectedCollection
      //   ? this.selectedCollection.collectionid
      //   : null,
      symbol: token.contract.symbol,
      filename,
      filetype,
      commissionschema:
        Object.keys(commissionschema).length === 0 ? null : commissionschema,
      legalterms: token.contract.properties.meta.legalterms,
      transferrable: token.contract.properties.meta.transferrable,
      issuernick: app.$auth.user.data.nick,
      issuerid: app.$auth.user.data.id,
      datestamp: new Date().getTime(),
      transactionGroupId,
      contracttxid,
      is_ticket: productType.is_ticket,
      redeemernick,
      redeemerid,
      ticketExpirationDatestamp,
      token_type: productType.token_type,
      product_type: productType.product_type,
    };
    const nftlist = tokenlist.reduce((objectList, uniqueToken) => {
      const nftuniqueid = uniqueToken._id;
      objectList[nftuniqueid] = {
        tokenSerieId,
        nftuniqueid,
        index: uniqueToken.serialNumber,
        ownerid: contract.issuerid,
        ownernick: contract.issuernick,
        ownerdatestamp: contract.datestamp,
        offeringid: null,
        conditionaltransferpropose: null,
        conditionaltransferproposeexpiration: null,
        expirationdatestamp: null,
        transactionGroupId,
        issuetxid: uniqueToken.issuetxid,
      };
      return objectList;
    }, {});
    return Object.assign({}, { contract, nftlist });
  },

  /**
   * Add NFT token info to DB
   * @param {Object} {productInfo}
   * @returns
   */
  async createProduct({ productInfo }) {
    return await $axios
      .post(`users/${app.$auth.user.data.id}/token/create`, productInfo)
      .then(({ data }) => data);
  },

  /**
   * Update Visibility of a token
   * @param {Object} {productInfo}
   * @returns
   */
  async switchVisibility(productInfo) {
    return await $axios
      .post(
        `users/${app.$auth.user.data.id}/token/switch-visibility`,
        productInfo,
      )
      .then(({ data }) => data);
  },

  async listOffering({ listingInfo, product }) {
    try {
      const stampId = listingInfo.data.startdatestamp;
      app.store.dispatch(`market/${setListingOffering}`, {
        stampId,
        listingInfo,
        product,
      });
      const conditionalProposes = await this.prepareOffering({
        stampId,
        listingInfo,
        name: product.contract.name,
      });
      if (conditionalProposes.length === 0)
        throw new CustomError(
          app.i18n.t('market.list_product.errors.conditionals'),
        );
      await app.store.dispatch(`market/${setListingOffering}`, {
        stampId,
        conditionalProposes,
      });
      if (conditionalProposes.length < listingInfo.copies) {
        await app.store.commit(`market/${SHOW_CONFIRM_LISTING_MODAL}`, {
          stampId,
          show: true,
        });
      } else {
        this.handleCreateOffering({ stampId });
      }
    } catch (error) {
      console.log('listOffering error', error);
      app.store.commit(`processing/${SET_G2C_IN_PROCESS}`, false);
    }
  },

  async handleCreateOffering({ stampId }) {
    try {
      app.store.dispatch(`market/${setListingStatus}`, {
        stampId,
        status: 'uploading',
      });
      const offeringCreated = await this.createOfferingDB({ stampId });
      if (offeringCreated.error) throw new Error(offeringCreated.message);
      if (
        offeringCreated.data.status &&
        offeringCreated.data.id &&
        offeringCreated.data.status === 'listing'
      ) {
        app.store.dispatch(`market/${setListingOffering}`, {
          stampId,
          offeringId: offeringCreated.data.id,
        });
        this.creatingOfferingHandler(stampId, offeringCreated.data);
        checkStatusInterval = setInterval(() => {
          this.checkOfferingCreationStatus(offeringCreated.data.id)
            .then((result) => {
              this.creatingOfferingHandler(stampId, result.data);
            })
            .catch((error) => {
              const offeringStatus =
                app.store.getters['market/listingStatus'](stampId);
              let notFound = offeringStatus.notFound || 0;
              if (
                ((error.message.includes('Not found') ||
                  error.message.includes('404')) &&
                  notFound > 10) ||
                !(
                  error.message.includes('Not found') ||
                  error.message.includes('404')
                )
              ) {
                this.creatingOfferingHandler(stampId, {
                  id: offeringCreated.data.id,
                  status: 'error',
                });
              } else if (
                error.message.includes('Not found') ||
                error.message.includes('404')
              ) {
                notFound++;
                offeringStatus['notFound'] = notFound;

                app.store.dispatch(`market/${setListingStatus}`, {
                  stampId,
                  ...offeringStatus,
                });
              }
            });
        }, 50 * SECOND);

        EventBus.$on('creating-offering', (data) =>
          this.creatingOfferingHandler(stampId, data),
        );
      }
    } catch (error) {
      console.log('handleCreateOffering error', error);
      await this.handleErrorCreatingOffering(stampId, error);
    }
  },

  clearCheckStatusInterval() {
    if (checkStatusInterval !== null) {
      clearInterval(checkStatusInterval);
      checkStatusInterval = null;
    }
  },

  creatingOfferingHandler(stampId, data) {
    const offeringToList = app.store.getters['market/offeringToList'](stampId);
    const offeringId = offeringToList.offeringId;
    if (data.id && data.id === offeringId) {
      if (data.status === 'error') {
        this.handleErrorCreatingOffering(
          stampId,
          new Error(data.error),
          offeringToList,
        );
        return;
      }
      app.store.dispatch(`market/${setListingStatus}`, { stampId, ...data });
      if (data.status === 'done') {
        app.store.commit(`processing/${SET_G2C_IN_PROCESS}`, false);
        this.clearCheckStatusInterval();
        app.store.dispatch(`market/${setListingStatus}`, {
          stampId,
          status: 'loading',
          counter: 0,
        });
        EventBus.$off('creating-offering', (data) =>
          this.creatingOfferingHandler(stampId, data),
        );

        EventBus.$emit('update-product', {
          id: offeringToList.product.contract.tokenSerieId,
        });
        // *** MOSTRAR AVISO DE LISTING CREADO Y PENDIENTE DE VALIDACIÓN ***
        app.store.commit(`processing/${CREATE_G2C_ALERT}`, {
          type: 'list_product',
          item: offeringToList.product.contract.token_type,
          name: offeringToList.product.contract.name,
          action: 'success',
          message: '',
        });
      }
    }
  },

  async handleErrorCreatingOffering(stampId, error) {
    console.log('handleErrorCreatingOffering', error);
    EventBus.$off('creating-offering', (data) =>
      this.creatingOfferingHandler(data),
    );
    this.clearCheckStatusInterval();
    app.store.dispatch(`market/${setListingStatus}`, {
      stampId,
      status: 'error',
    });
    await this.cancelCreateOffering({ stampId });
    app.store.commit(`processing/${SET_G2C_IN_PROCESS}`, false);
    let errorText = app.i18n.t('market.list_product.errors.cancelled');
    if (error && error.name.includes(CUSTOM_ERROR)) errorText = error.message;
    const offeringToList = app.store.getters['market/offeringToList'](stampId);
    // *** MOSTRAR AVISO DE ERROR EN LA CREACIÓN ***
    app.store.commit(`processing/${CREATE_G2C_ALERT}`, {
      type: 'list_product',
      item: offeringToList.product.contract.token_type,
      name: offeringToList.product.contract.name,
      action: 'error',
      message: errorText,
    });

    handleErrors(error);
    app.$sentry.captureException(error);
    return;
  },

  /**
   * prepare conditional transfers to create offering
   * @param {Object} {productInfo}
   * @returns
   */
  async prepareOffering({ stampId, listingInfo, name }) {
    try {
      const destinationnick = app.context.env.g2clibwarehousenick;
      const expirationDatestamp =
        listingInfo.data.enddatestamp + (48 * 60 + 6) * 60 * 1000; //2 days and 6 minutes
      const type = listingInfo.data.allowbuy ? 'Buy Now' : 'Auction';
      const description = `${name} listed for ${type}`;
      const conditionalProposes = await this.transferNFTPropose({
        destinationnick,
        nftUniqueIdList: listingInfo.nftUniqueIdList,
        copies: listingInfo.copies,
        tokenSerieId: listingInfo.contractid,
        expirationDatestamp,
        description,
      });
      return conditionalProposes;
    } catch (error) {
      console.log('Preparing offering error', error, error.error);
      await this.handleErrorCreatingOffering(stampId, error);
    }
  },
  /**
   * Add Offering of product to DB
   * @param {Object} {productInfo}
   * @returns
   */

  async createOfferingDB({ stampId }) {
    return new Promise(async (resolve, reject) => {
      try {
        const offeringToList =
          app.store.getters['market/offeringToList'](stampId);
        const offering = Object.assign({}, offeringToList);
        const listingInfo = Object.assign({}, offering.listingInfo);
        const listingInfoData = Object.assign({}, listingInfo.data);
        // const conditionalProposes = [];
        const conditionalProposes = offering.conditionalProposes.map(
          (item) => item,
        );
        const expirationDatestamp = listingInfo.data.enddatestamp + WEEK;
        listingInfo.nftlist = conditionalProposes.map((item) => {
          item.conditionaltransferproposeexpiration = expirationDatestamp;
          return item;
        });
        if (!app.$api.commons.satsEnabled()) {
          listingInfoData.allowwalletpayment = null;
          listingInfoData.deposit = null;
          listingInfo.data = listingInfoData;
        }

        const offeringCreated = await $axios.post(
          `users/${app.$auth.user.data.id}/offering/create`,
          listingInfo,
        );
        return resolve(offeringCreated.data);
      } catch (error) {
        console.log('createOfferingDB error', error);
        reject(error);
      }
    });
  },

  /**
   * CHeck status of creation of offering offeringId
   * @param {Object} {offeringId}
   * @returns
   */
  async checkOfferingCreationStatus(offeringId) {
    return new Promise(async (resolve, reject) => {
      try {
        const offeringCreationStatus = await $axios.post(
          `users/${app.$auth.user.data.id}/offering/create/status`,
          { offeringId },
        );
        return resolve(offeringCreationStatus.data);
      } catch (error) {
        reject(error);
      }
    });
  },

  /**
   * reject conditional transfer propose cancel create offering
   * @param {Object} {productInfo}
   * @returns
   */
  async cancelCreateOffering({ stampId }) {
    return new Promise(async (resolve, reject) => {
      try {
        const offeringToList =
          app.store.getters['market/offeringToList'](stampId);
        const failedRejected = [];
        if (
          offeringToList.conditionalProposes &&
          offeringToList.conditionalProposes.length > 0
        ) {
          const conditionalProposes = [...offeringToList.conditionalProposes];

          const packs = this.preparePacks(
            conditionalProposes.length,
            conditionalProposes,
          );
          do {
            const promises = packs[0].map((item) => {
              return this.transferNFTReject({
                tokenSerieId: offeringToList.product.contract.tokenSerieId,
                conditionalTransferAuth: item.conditionalTransferAuth,
              });
            });
            await Promise.allSettled(promises).then((results) => {
              results.map((result, index) => {
                if (result.status !== 'fulfilled' || result.value.error) {
                  failedRejected.push(packs[0][index].nftuniqueid);
                }
              });
            });
            packs.shift();
          } while (packs.length > 0);
        }

        return resolve(failedRejected);
      } catch (error) {
        reject(error);
      }
    });
  },

  /**
   * add users to private offering (send invitations)
   * @param {Object} {offeringid}
   * @returns
   */
  async sendInvitationsOffering(offeringid, invitedIds) {
    return new Promise(async (resolve, reject) => {
      try {
        const invitationsSent = await $axios.post(
          `users/${app.$auth.user.data.id}/offering/add-invitations/${offeringid}`,
          { invitedIds },
        );
        return resolve(invitationsSent.data);
      } catch (error) {
        reject(error);
      }
    });
  },

  /**
   * delist offering from DB and free tokens conditional transfer
   * @param {Object} {offeringid}
   * @returns
   */
  async delistOffering(offeringid) {
    return new Promise(async (resolve, reject) => {
      try {
        const offeringDelisted = await $axios.get(
          `users/${app.$auth.user.data.id}/offering/delist/${offeringid}`,
        );
        return resolve(offeringDelisted.data);
      } catch (error) {
        reject(error);
      }
    });
  },

  /**
   * Add bid to offering
   * @param {Object} {productInfo}
   * @returns
   */
  async createBid({
    product,
    userBids,
    offering,
    offeringid,
    bidprice,
    quantity,
  }) {
    let paymentauths;
    const expirationdatestamp = offering.enddatestamp + 48 * 60 * MINUTE; // 48 hours from offerings end

    if (app.$api.commons.userPaidFees() && offering.deposit)
      paymentauths = await this.walletProposeBids({
        quantity,
        offering,
        product,
        userBids,
        expirationdatestamp,
      });
    return await $axios
      .post(`users/${app.$auth.user.data.id}/bid/create`, {
        offeringid,
        bidprice,
        destinationusernick: offering.ownernick,
        destinationuserid: offering.ownerid,
        currency: offering.currency,
        quantity,
        expirationdatestamp,
        paymentauths,
      })
      .then(({ data }) => data);
  },
  async walletProposeBids({
    quantity,
    offering,
    product,
    userBids,
    expirationdatestamp,
  }) {
    if (userBids.length > 0) return userBids.map((bid) => bid.paymentauth);
    const paymentAuths = [];
    let amount = offering.deposit;
    if (offering.currency !== 'Satoshi')
      amount = await app.$api.commons.exchangeRates(
        offering.deposit,
        EXCHANGE_BY_CURRENCY[offering.currency],
      );
    const params = {
      destinationnick: offering.ownernick,
      commissiontype: G2C_BID_COMMISION_TYPE,
      description: `${product.name} deposit bid by @${app.$auth.user.data.nick} to @${offering.ownernick}`,
      expirationdatestamp,
      amount,
    };

    try {
      for (let i = 0; i < quantity; i++) {
        const walletPropose = await app.$api.payments.walletProposePayment(
          params,
        );
        if (walletPropose.error) {
          throw walletPropose.error;
        }
        paymentAuths.push(walletPropose.paymentauth);
      }
      return paymentAuths;
    } catch (error) {
      console.log('Wallet payment propose for bids error', error);
      if (paymentAuths.length > 0) {
        await Promise.all(
          paymentAuths.map(async (paymentauth) =>
            app.$api.payment.walletRejectPayment({ paymentauth }),
          ),
        );
      }
      throw new CustomError(
        app.i18n.t('market.buy_product.errors.create_bids'),
      );
    }
  },
  /**
   * Transfer a NFT token from loggedUser to destinationnick user
   * @param {Object} {destinationnick, nftuniqueid, tokenSerieId}
   * @returns
   */
  async transferNFT({
    destinationnick,
    destinationid,
    nftUniqueIdList,
    copies,
    tokenSerieId,
    name,
    image,
    tokenType,
  }) {
    try {
      const sourcenick = app.$auth.user.data.nick;
      let failedTransfer = 0;
      let remainsTransfer = [...nftUniqueIdList];
      const successTransfer = [];
      const errorData = {
        errors: [],
        sourcenick,
        destinationnick: '',
        tokenSerieId,
        nftuniqueidlist: [],
      };
      const packs = this.preparePacks(copies, nftUniqueIdList);
      const description = `Transfer ${name} from @${sourcenick} to @${destinationnick}`;
      do {
        const transferResponse = await app.$api.g2cMarket
          .transferNFT({
            destinationnick,
            nftuniqueidlist: packs[0],
            tokenSerieId,
            description,
          })
          .catch((error) => {
            console.log({ error });
            if (
              typeof error.errordata === 'object' &&
              error.errordata.length > 0
            )
              return error;
            else throw error;
          });
        if (
          app.$api.commons.userPaidFees() &&
          transferResponse.error &&
          transferResponse.error.includes('Not enought funds')
        ) {
          throw new CustomError(app.i18n.t('g2clib.errors.no_funds'));
        }
        if (transferResponse.error) {
          failedTransfer += packs[0].length;
          remainsTransfer = remainsTransfer.filter((nftuniqueid) => {
            return (
              (transferResponse.errordata &&
                !transferResponse.errordata.includes(nftuniqueid)) ||
              (!transferResponse.errordata &&
                !transferResponse.error.includes(nftuniqueid))
            );
          });
          errorData.errors.push(transferResponse.error);
          errorData.nftuniqueidlist.push(
            transferResponse.errordata
              ? transferResponse.errordata
              : transferResponse.error,
          );
        } else {
          successTransfer.push(
            ...transferResponse.results.reduce((total, token) => {
              if (token.transfertxid && token.transactionGroupId)
                total.push({
                  sourcenick,
                  destinationnick,
                  destinationid,
                  nftuniqueid: token.nftuniqueid,
                  tokenSerieId,
                  transfertxid: token.transfertxid,
                  tokenName: name,
                  tokenImage: image,
                });
              return total;
            }, []),
          );
          app.store.dispatch(`market/${setTransferStatus}`, {
            status: 'transferred',
            done: successTransfer.length,
            total: copies,
          });
        }

        packs.shift();
        console.log({
          transferResponse,
          errorData,
          failedTransfer,
          successTransfer,
          remainsTransfer,
        });
        if (
          packs.length === 0 &&
          failedTransfer > 0 &&
          successTransfer.length < copies
        ) {
          const nftUniqueIdListToAdd = [
            ...remainsTransfer.filter(
              (nftuniqueid) =>
                !successTransfer.some(
                  (token) => token.nftuniqueid === nftuniqueid,
                ),
            ),
          ];
          if (nftUniqueIdListToAdd.length > 0) {
            packs.push(
              ...this.preparePacks(failedTransfer, nftUniqueIdListToAdd),
            );
            failedTransfer = 0;
          }
        }
      } while (packs.length > 0);

      // Endpoint call to send successTransfer array with the info of each token transferred to set it in database
      if (successTransfer.length > 0) {
        app.store.dispatch(`market/${setTransferStatus}`, {
          status: 'uploading',
        });
        //MUST FIX WITH ERROR DETECTION TODO!!!
        await $axios.post(`/order/directtransfer/${app.$auth.user.data.id}`, {
          transfers: successTransfer,
        });
      }

      if (errorData.nftuniqueidlist.length > 0) {
        const error = new Error(
          `Transfer token Failed, payload: ${JSON.stringify(errorData)}`,
        );
        error.name = 'Transfer token Failed';
        app.$sentry.captureException(error);
      }
      //   reject(
      //     new CustomError(
      //       app.i18n.t('market.transfer_product.errors.not_transferred', {
      //         copies: failedTransfer.length,
      //       }),
      //     ),
      //   );
      if (successTransfer.length === 0)
        throw new CustomError(
          app.i18n.t('market.transfer_product.errors.not_transferred', {
            copies,
          }),
        );
      app.store.dispatch(`market/${setTransferStatus}`, {
        status: 'done',
      });
      EventBus.$emit('update-product', { id: tokenSerieId });
      app.store.commit(`processing/${CREATE_G2C_ALERT}`, {
        type: 'transfer_product',
        item: tokenType,
        copies: successTransfer.length,
        total: copies,
        name,
        action: 'success',
        message: '',
      });
      app.store.commit(`processing/${SET_G2C_IN_PROCESS}`, false);
      setTimeout(() => {
        app.store.dispatch(`market/${setTransferStatus}`, {
          status: 'loading',
          counter: 0,
        });
      }, SECOND * 0.5);
    } catch (error) {
      handleErrors(error);
      app.store.dispatch(`market/${setTransferStatus}`, {
        status: 'loading',
        counter: 0,
      });
      let errorTransferring = app.i18n.t(
        'market.transfer_product.errors.generic',
      );
      if (error.name === CUSTOM_ERROR) errorTransferring = error.message;
      // *** MOSTRAR AVISO DE ERROR EN LA transferencia ***
      app.store.commit(`processing/${CREATE_G2C_ALERT}`, {
        type: 'transfer_product',
        item: tokenType,
        copies,
        name,
        action: 'error',
        message: errorTransferring,
      });
      app.store.commit(`processing/${SET_G2C_IN_PROCESS}`, false);
    }
  },

  preparePacks(copies, list, bulkCopies = undefined) {
    const maxBulkCopies =
      typeof bulkCopies === 'undefined' ? BULK_TOKEN : bulkCopies;
    const moreThanOnePack = copies > maxBulkCopies;
    const remains = moreThanOnePack ? copies % maxBulkCopies : copies;
    const numberOfPacks = moreThanOnePack
      ? Math.floor(copies / maxBulkCopies) + (remains > 0 ? 1 : 0)
      : 1;
    const packs = [];
    for (let index = 0; index < numberOfPacks; index++) {
      packs.push(
        list.slice(
          index * maxBulkCopies,
          moreThanOnePack && (index < numberOfPacks || remains === 0)
            ? (index + 1) * maxBulkCopies
            : moreThanOnePack
            ? remains
            : copies,
        ),
      );
    }
    return packs;
  },

  /**
   * Conditional propose a Transfer  NFT token from loggedUser to destinationnick user
   * @param {Object} {destinationnick, nftuniqueid, tokenSerieId}
   * @returns
   */
  transferNFTPropose({
    destinationnick,
    nftUniqueIdList,
    copies,
    tokenSerieId,
    expirationDatestamp,
    description,
  }) {
    return new Promise(async (resolve, reject) => {
      try {
        const sourcenick = app.$auth.user.data.nick;
        const expirationdatestamp = expirationDatestamp
          ? expirationDatestamp
          : new Date().getTime() + 48 * 60 * 60 * SECOND;

        let failedTransfer = 0;
        let remainsTransfer = [...nftUniqueIdList];
        const successTransfer = [];
        const errorData = {
          errors: [],
          sourcenick,
          destinationnick: '',
          tokenSerieId,
          nftuniqueidlist: [],
        };

        const packs = this.preparePacks(copies, nftUniqueIdList);

        do {
          const transferPropose =
            await app.$api.g2cMarket.transferNFTConditionalPropose({
              destinationnick,
              nftuniqueidlist: packs[0],
              tokenSerieId,
              expirationdatestamp,
              description,
            });
          if (
            app.$api.commons.userPaidFees() &&
            transferPropose.error &&
            transferPropose.error.includes('Not enought funds')
          ) {
            throw new CustomError(app.i18n.t('g2clib.errors.no_funds'));
          }
          if (transferPropose.error) {
            failedTransfer += packs[0].length;
            remainsTransfer = remainsTransfer.filter(
              (nftuniqueid) =>
                (transferPropose.errordata &&
                  !transferPropose.errordata.includes(nftuniqueid)) ||
                (!transferPropose.errordata &&
                  !transferPropose.error.includes(nftuniqueid)),
            );
            errorData.errors.push(transferPropose.error);
            errorData.nftuniqueidlist.push(
              transferPropose.errordata
                ? transferPropose.errordata
                : transferPropose.error,
            );
          } else {
            successTransfer.push(
              ...transferPropose.results.reduce((total, token) => {
                if (token.conditionalTransferAuth && token.transactionGroupId)
                  total.push({
                    nftuniqueid: token.nftuniqueid,
                    conditionalTransferAuth: token.conditionalTransferAuth,
                  });
                return total;
              }, []),
            );
            app.store.dispatch(`market/${setListingStatus}`, {
              status: 'preparing',
              done: successTransfer.length,
              total: copies,
            });
          }

          packs.shift();
          if (
            packs.length === 0 &&
            failedTransfer > 0 &&
            successTransfer.length < copies
          ) {
            const nftUniqueIdListToAdd = [
              ...remainsTransfer.filter(
                (nftuniqueid) =>
                  !successTransfer.some(
                    (token) => token.nftuniqueid === nftuniqueid,
                  ),
              ),
            ];

            if (nftUniqueIdListToAdd.length > 0) {
              packs.push(
                ...this.preparePacks(failedTransfer, nftUniqueIdListToAdd),
              );
              failedTransfer = 0;
            }
          }
        } while (packs.length > 0);

        if (errorData.nftuniqueidlist.length > 0) {
          const error = new Error(
            `Conditional Transfer Propose Failed, payload: ${JSON.stringify(
              errorData,
            )}`,
          );
          error.name = 'Conditional Transfer Propose Failed';

          app.$sentry.captureException(error);
        }
        resolve(successTransfer);
      } catch (error) {
        reject(error);
      }
    });
  },
  /**
   * Conditional accept propose to transfer a NFT token
   * @param {Object} { tokenSerieId}
   * @returns
   */
  async transferNFTAccept({ tokenSerieId, conditionalTransferAuth }) {
    return await app.$api.g2cMarket.transferNFTConditionalAccept({
      tokenSerieId,
      conditionalTransferAuth,
    });
  },
  /**
   * Conditional reject propose to transfer a token
   * @param {Object} {  tokenSerieId}
   * @returns
   */
  async transferNFTReject({ tokenSerieId, conditionalTransferAuth }) {
    return await app.$api.g2cMarket.transferNFTConditionalReject({
      tokenSerieId,
      conditionalTransferAuth,
    });
  },
  /**
   * Redeem a NFT token
   * @param {Object} {nftUniqueIdList, tokenSerieId}
   * @returns
   */
  async redeemNFT({ nftUniqueIdList, tokenSerieId, name, tokenType }) {
    try {
      const nick = app.$auth.user.data.nick;
      let failedRedeem = 0;
      let remainsRedeem = [...nftUniqueIdList];
      const successRedeem = [];
      const copies = nftUniqueIdList.length;
      // packs of 40 copies to do in parallel, unset to bulk by 50
      const packs = this.preparePacks(copies, nftUniqueIdList);

      const errorData = {
        errors: [],
        sourcenick: nick,
        destinationnick: '',
        tokenSerieId,
        nftuniqueidlist: [],
      };
      do {
        // *** REDEEM BULK code <- bulk fixed !!!!
        const redeemed = await app.$api.g2cMarket.redeemNFT({
          nftuniqueidlist: packs[0],
          tokenSerieId,
        });
        console.log({ redeemed });
        if (
          app.$api.commons.userPaidFees() &&
          redeemed.error &&
          redeemed.error.includes('Not enought funds')
        ) {
          throw new CustomError(app.i18n.t('g2clib.errors.no_funds'));
        }
        if (redeemed.error) {
          failedRedeem += packs[0].length;
          remainsRedeem = remainsRedeem.filter((nftuniqueid) => {
            return (
              (redeemed.errordata &&
                !redeemed.errordata.includes(nftuniqueid)) ||
              (!redeemed.errordata && !redeemed.error.includes(nftuniqueid))
            );
          });
          errorData.errors.push(redeemed.error);
          errorData.nftuniqueidlist.push(
            redeemed.errordata ? redeemed.errordata : redeemed.error,
          );
          const data = {
            error: redeemed.error,
            sourcenick: nick,
            destinationnick: '',
            nftuniqueidlist: redeemed.errordata
              ? redeemed.errordata
              : redeemed.error,
            tokenSerieId,
          };

          const error = new Error(
            `Redeem token Failed, payload: ${JSON.stringify(data)}`,
          );
          error.name = 'Redeem token Failed';
          app.$sentry.captureException(error);
        } else {
          successRedeem.push(
            ...redeemed.results.reduce((total, token) => {
              if (token.redeemtxid && token.transactionGroupId)
                total.push({
                  sourcenick: nick,
                  destinationnick: '',
                  nftuniqueid: token.nftuniqueid,
                  tokenSerieId,
                  redeemtxid: token.redeemtxid,
                });
              return total;
            }, []),
          );
          app.store.dispatch(`market/${setRedeemStatus}`, {
            status: 'redeemed',
            done: successRedeem.length,
            total: copies,
          });
        }

        // *** parallel if bulk redeem is broken !!!!!
        // const promises = packs[0].map((item) => {
        //   return app.$api.g2cMarket.redeemNFT({
        //     g2ctoken
        //     nftuniqueid: item,
        //     tokenSerieId,
        //   });
        // });
        // await Promise.allSettled(promises).then((results) => {
        //   results.map((result, index) => {
        //     if (result.status !== 'fulfilled' || result.value.error) {
        //       failedRedeem++;
        //       remainsRedeem = remainsRedeem.filter(
        //         (nftuniqueid) =>
        //           (result.value.errordata &&
        //             !result.value.errordata.some(
        //               (token) => token === nftuniqueid,
        //             )) ||
        //           (!result.value.errordata &&
        //             !result.value.error.includes(nftuniqueid)),
        //       );

        //       errorData.errors.push(result.value.error);
        //       errorData.nftuniqueidlist.push(
        //         result.value.errordata
        //           ? result.value.errordata
        //           : result.value.error,
        //       );
        //     } else {
        //       successRedeem.push({
        //         sourcenick: nick,
        //         destinationnick: '',
        //         nftuniqueid: result.value.nftuniqueid,
        //         tokenSerieId,
        //         redeemtxid: result.value.redeemtxid,
        //       });
        //       app.store.dispatch(`market/${setRedeemStatus}`, {
        //         status: 'redeemed',
        //         done: successRedeem.length,
        //         total: copies,
        //       });
        //     }
        //   });
        // });

        packs.shift();
        if (
          packs.length === 0 &&
          failedRedeem > 0 &&
          successRedeem.length < copies
        ) {
          const nftUniqueIdListToAdd = [
            ...remainsRedeem.filter(
              (nftuniqueid) =>
                !successRedeem.some(
                  (token) => token.nftuniqueid === nftuniqueid,
                ),
            ),
          ];
          if (nftUniqueIdListToAdd.length > 0) {
            packs.push(
              ...this.preparePacks(failedRedeem, nftUniqueIdListToAdd),
            );
            failedRedeem = 0;
          }
        }
      } while (packs.length > 0);

      if (successRedeem.length > 0) {
        app.store.dispatch(`market/${setRedeemStatus}`, {
          status: 'uploading',
        });
        await $axios.post(`/order/redeemtoken/${app.$auth.user.data.id}`, {
          redeems: successRedeem,
        });
      }
      if (errorData.nftuniqueidlist.length > 0) {
        const error = new Error(
          `Redeem token Failed, payload: ${JSON.stringify(errorData)}`,
        );
        error.name = 'Redeem token Failed';
        app.$sentry.captureException(error);
        throw new CustomError(
          app.i18n.t('market.redeem_product.errors.not_redeemed', {
            copies: failedRedeem.length,
          }),
        );
      }
      app.store.dispatch(`market/${setRedeemStatus}`, {
        status: 'done',
      });
      app.store.commit(`processing/${CREATE_G2C_ALERT}`, {
        type: 'redeem_product',
        item: tokenType,
        copies: successRedeem.length,
        total: copies,
        name,
        action: 'success',
        message: '',
      });
      app.store.commit(`processing/${SET_G2C_IN_PROCESS}`, false);
      setTimeout(() => {
        app.store.dispatch(`market/${setRedeemStatus}`, {
          status: 'loading',
          counter: 0,
        });
        EventBus.$emit('update-product', {
          id: tokenSerieId,
        });
      }, SECOND * 1.5);
    } catch (error) {
      handleErrors(error);
      app.store.dispatch(`market/${setRedeemStatus}`, {
        status: 'loading',
        counter: 0,
      });
      let errorRedeeming = app.i18n.t('market.redeem_product.errors.generic');
      if (error.name === CUSTOM_ERROR) errorRedeeming = error.message;
      // *** MOSTRAR AVISO DE ERROR EN LA transferencia ***
      app.store.commit(`processing/${CREATE_G2C_ALERT}`, {
        type: 'redeem_product',
        item: tokenType,
        copies,
        name,
        action: 'error',
        message: errorRedeeming,
      });
      app.store.commit(`processing/${SET_G2C_IN_PROCESS}`, false);
    }
  },

  /**
   * CHECK AND PREPARE BID FOR PAYMENTS
   */
  /**
   * Check if transferAuth exist and belongs to user/token,
   * if buynow: creates bid and returns bidid as ok
   * if auction: check bid and returns bidid as ok
   * @param {Object} {buyInfo: info to create bid object for buy now type}
   * @returns {data: {bidid}, error}
   */
  async assignToken2Preorder({ offeringId, quantity, postalAddress }) {
    return new Promise(async (resolve, reject) => {
      try {
        const preorderCreated = await $axios
          .post(
            `users/${app.$auth.user.data.id}/offering/assigntoken2preorder`,
            {
              order: {
                offeringid: offeringId,
                quantity,
                postaladdress: postalAddress,
              },
            },
          )
          .then(({ data }) => data);
        return resolve(preorderCreated);
      } catch (error) {
        reject(error);
      }
    });
  },

  /**
   *
   * @param  offeringId offeringid to search for preorder
   * @param  assignround : actual offering assign round
   * @returns
   */
  async retrieveAuctionPreorder({ offeringId, assignround }) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await $axios
          .post(`order/retrievebyofferinguser`, {
            offeringid: offeringId,
            customerNick: app.$auth.user.data.nick,
          })
          .then(({ data }) => data);

        const hasOrderHonor =
          typeof response.data[0].ordergroupid !== 'undefined' &&
          response.data[0].ordergroupid !== null;
        let assignedOrder;
        let assignedOrderHonor;
        let error;
        let errors;
        let message;
        if (
          response.data[0] !== undefined &&
          response.data[0].assignround === assignround
        ) {
          // has winner bids and might have substitute bids on future
          assignedOrder = response.data[0];
          assignedOrderHonor =
            hasOrderHonor === true ? response.data[1] : undefined;
        } else if (
          (hasOrderHOnor === true &&
            response.data[2] !== undefined &&
            response.data[2].assignround === assignround) ||
          (hasOrderHOnor === false &&
            response.data[1] !== undefined &&
            response.data[1].assignround === assignround)
        ) {
          // has a splitted bids (some on winner osme on substitute)
          assignedOrder =
            hasOrderHonor === true ? response.data[2] : response.data[1];
          assignedOrderHonor =
            hasOrderHonor === true ? response.data[3] : undefined;
        } else {
          error = true;
          errors = ['No valid preorder found'];
          message = 'No valid preorder found';
        }
        return resolve({
          assignedOrder,
          assignedOrderHonor,
          error,
          errors,
          message,
        });
      } catch (error) {
        reject(error);
      }
    });
  },
  /**
   * update preorder
   * @param {Object} {buyInfo: info to create bid object for buy now type}
   * @returns {data: {bidid}, error}
   */
  async updateOrder({ paymentauths, walletPayment, orderId, postalAddress }) {
    return new Promise(async (resolve, reject) => {
      try {
        const orderUpdated = await $axios
          .post(
            `users/${app.$auth.user.data.id}/offering/updateorder/${orderId}`,
            {
              paymentauths,
              walletpayment: walletPayment,
              postaladdress: postalAddress,
            },
          )
          .then(({ data }) => data);
        return resolve(orderUpdated);
      } catch (error) {
        reject(error);
      }
    });
  },
  async leaveOffering(offeringid) {
    return new Promise(async (resolve, reject) => {
      try {
        const leaveOffering = await $axios
          .get(`users/${app.$auth.user.data.id}/bid/cancel/${offeringid}`)
          .then(({ data }) => data);
        return resolve(leaveOffering);
      } catch (error) {
        reject(error);
      }
    });
  },
  /**
   * if buynow: creates bid and returns bidid as ok
   * @param {Object} {buyInfo: info to create bid object for buy now type}
   * @returns {data: {bidid}, error}
   */
  async handleOrderPayment({ offeringId, quantity, postalAddress }) {
    return new Promise(async (resolve, reject) => {
      try {
        const preorder = await this.assignToken2Preorder({
          offeringId,
          quantity,
          postalAddress,
        });

        if (preorder.error) throw new CustomError(preorder.errors[0]);
        const response = {
          data: {
            preorderid: preorder.order.data.id,
            preorder: preorder.order.data,
          },
          error: false,
        };
        if (
          typeof preorder.commissionSchemaOrder !== 'undefined' &&
          preorder.commissionSchemaOrder !== null
        )
          response.data.preorderCommSchema =
            preorder.commissionSchemaOrder.data;
        return resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },

  async checkOrderStatus(preorderId) {
    try {
      const { assignedOrderHonor, assignedOrder } =
        await this.getAssignedOrdersById(preorderId);
      const order =
        assignedOrderHonor && assignedOrderHonor.id === preorderId
          ? assignedOrderHonor
          : assignedOrder && assignedOrder.id === preorderId
          ? assignedOrder
          : { status: 'order_expired' };
      // if (['draft', 'payment_intent_created'].includes(order.status) )
      if (!['order_expired'].includes(order.status)) return order;
      return null;
    } catch (error) {
      handleErrors(error);
    }
  },

  /**
   * if auction: check bid and returns bidid as ok
   * @param {Object} {buyInfo: info to create bid object for buy now type}
   * @returns {data: {bidid}, error}
   */
  async handleOrderPaymentAuction({
    preorderId,
    postalAddress,
    walletPayment,
  }) {
    return new Promise(async (resolve, reject) => {
      try {
        const preorder = await this.checkOrderStatus(preorderId);
        if (preorder === null)
          throw new CustomError(
            app.i18n.t('market.checkout_session.errors.order_expired'),
          );
        const paymentauths = [];
        if (
          preorder.status === 'payment_intent_created' ||
          !preorder.lineItems.some(
            (lineitem) =>
              !lineitem.paymentauth ||
              typeof lineitem.paymentauth === 'undefined' ||
              lineitem.paymentauth === '',
          )
        )
          paymentauths.push(
            ...preorder.lineItems.map(({ paymentauth }) => paymentauth),
          );
        else {
          for (let index = 0; index < preorder.lineItems.length; index++) {
            const walletProposeSchema = await this.handlePaymentAuthsToPay({
              owner: preorder.sellerNick,
              listItem: preorder.lineItems[index],
              walletPayment,
            });
            paymentauths.push(walletProposeSchema);
          }
        }
        const orderUpdated = await this.updateOrder({
          paymentauths,
          orderId: preorder.id,
          walletPayment,
          postalAddress: ['auction'].includes(preorder.subScope)
            ? postalAddress
            : undefined,
        });

        if (orderUpdated.error) {
          for (const pauth of paymentauths) {
            await app.$api.payments.walletRejectPayment({
              paymentauth: pauth.paymentauth,
            });
          }
          throw new CustomError(app.i18n.t('base.payments'));
        }
        return resolve({
          data: {
            preorderid: preorder.id,
          },
          error: false,
        });
      } catch (error) {
        const customErrors = error;
        if (error.message.includes('not enought free tokens')) {
          app.$toast.error(app.i18n.t('market.buy_product.errors.booked'));
        }
        reject(error);
      }
    });
  },

  // Order wallet payment
  async walletPaymentOrder({ preorder, walletPayment, postalAddress }) {
    return new Promise(async (resolve, reject) => {
      try {
        const paymentauths = [];
        if (
          preorder.status === 'payment_intent_created' &&
          !preorder.lineItems.some(
            (lineitem) =>
              !lineitem.paymentauth ||
              typeof lineitem.paymentauth === 'undefined' ||
              lineitem.paymentauth === '',
          )
        )
          paymentauths.push(
            ...preorder.lineItems.map(({ paymentauth }) => paymentauth),
          );
        else {
          for (let index = 0; index < preorder.lineItems.length; index++) {
            const walletProposeSchema = await this.handlePaymentAuthsToPay({
              owner: preorder.sellerNick,
              listItem: preorder.lineItems[index],
              walletPayment,
              subScope: preorder.subScope,
            });
            paymentauths.push(walletProposeSchema);
          }
        }
        // TO DO: back need to execute pyaments and conditional transfer propose with this call or make another endpoint to call after orderUpdate success
        const orderUpdated = await this.updateOrder({
          paymentauths, // [{paymentauth: 'asñdjfnasñdf', royaltiesauth: 'asdñlfnas', beneficiaryauth: 'asdf'}]
          orderId: preorder.id,
          walletPayment,
          postalAddress,
        });
        if (orderUpdated.error) {
          if (
            orderUpdated.message === 'invalid_honoring' ||
            orderUpdated.message === 'order_expired'
          )
            throw new CustomError(
              app.i18n.t(
                `market.checkout_session.errors.${orderUpdated.message}`,
              ),
            );
          else throw new CustomError(app.i18n.t('base.payments'));
        }
        return resolve(orderUpdated);
      } catch (error) {
        reject(error);
      }
    });
  },
  async handlePaymentAuthsToPay({ owner, listItem, walletPayment, subScope }) {
    return new Promise(async (resolve, reject) => {
      try {
        const from = app.$auth.user.data.nick;
        const name = `${listItem.name} ${listItem.id.slice(-2)}`;
        const paymentauths = {};
        let deposit = 0;
        if (
          typeof listItem.deposit !== 'undefined' &&
          listItem.deposit !== null
        ) {
          if (
            listItem.currency === 'satoshi' &&
            app.$api.commons.fiatEnabled()
          ) {
            deposit = await app.$api.commons.exchangeRates(
              listItem.deposit,
              EXCHANGE_BY_CURRENCY['usd'],
            );
          } else deposit = listItem.deposit;
        }
        const amount =
          listItem.currency === 'satoshi'
            ? listItem.amount - deposit
            : await app.$api.commons.exchangeRates(
                listItem.amount - deposit,
                EXCHANGE_BY_CURRENCY[listItem.currency],
              );
        const expirationdatestamp = new Date().getTime() + 6 * MINUTE; // 6 minutes from now for stripe
        let netAmount = amount;
        if (walletPayment) {
          const description = `${name} bought by @${from} ${
            ['beneficiary', 'royalty'].includes(subScope) ? subScope : 'to'
          } @${owner}`;
          let commissiontype = G2C_PURCHASE_COMMISION_TYPE;
          if (subScope === 'royalty')
            commissiontype = G2C_ROYALTY_COMMISION_TYPE;
          if (subScope === 'beneficiary')
            commissiontype = G2C_BENEFICIARY_COMMISION_TYPE;
          const walletPropose = await app.$api.payments.walletProposePayment({
            amount: netAmount,
            destinationnick: owner,
            description,
            commissiontype,
            expirationdatestamp,
          });
          // const walletPropose = { data: { paymentauth: 'fakepaymentauth' } };
          if (walletPropose.error) {
            await Promise.all(
              paymentauths.map(async (paymentauth) =>
                app.$api.payment.walletRejectPayment({ paymentauth }),
              ),
            );
            throw new CustomError(
              app.i18n.t('market.buy_product.errors.generic'),
            );
          }
          paymentauths.paymentauth = walletPropose.paymentauth;
          paymentauths.amountSats = netAmount;
        }
        resolve(paymentauths);
      } catch (error) {
        reject(error);
      }
    });
  },
  /**
   * HANDLE ORDERS
   */
  getAssignedOrder({ userNick, offeringId, assignround }) {
    return new Promise((resolve, reject) => {
      $axios
        .post(`order/retrievebyofferinguser`, {
          customerNick: userNick,
          offeringid: offeringId,
        })
        .then(({ data }) => {
          if (data.error) throw new Error(data.error);
          if (data.data.length > 0) {
            const hasOrderHonor =
              typeof data.data[0].ordergroupid !== 'undefined' &&
              data.data[0].ordergroupid !== null;
            let assignedOrder;
            let assignedOrderHonor;
            if (assignround === 'winner') {
              // has winner bids and might have substitute bids on future
              assignedOrder =
                hasOrderHonor === true ? data.data[1] : data.data[0];
              assignedOrderHonor =
                hasOrderHonor === true ? data.data[0] : undefined;
            } else if (
              assignround === 'substitute' &&
              ((hasOrderHonor === true && data.data[3] !== undefined) ||
                (hasOrderHonor === false && data.data[1] !== undefined))
            ) {
              // has a splitted bids (some on winner osme on substitute)
              assignedOrder =
                hasOrderHonor === true ? data.data[3] : data.data[1];
              assignedOrderHonor =
                hasOrderHonor === true ? data.data[2] : undefined;
            } else {
              // has only substitute bids
              assignedOrder =
                hasOrderHonor === true ? data.data[1] : data.data[0];
              assignedOrderHonor =
                hasOrderHonor === true ? data.data[0] : undefined;
            }
            return resolve({ assignedOrder, assignedOrderHonor });
          } else return resolve({});
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getAssignedOrdersById(orderId) {
    return new Promise((resolve, reject) => {
      $axios
        .post(`order/retrievebyid`, {
          order_id: orderId,
        })
        .then(({ data }) => {
          if (data.error) throw new Error(data.error);
          return resolve({
            assignedOrderHonor: data.data.assignedOrderHonor,
            assignedOrder: data.data.assignedOrder,
          });
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * TICKETS QR CODE
   */
  /**
   * CLIENT getQRCode from nftuniqueid (ticket)
   * @param {nftuniqueid} ticket id
   * @returns String QRCode or ''
   */
  getQRCode({ nftuniqueid }) {
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `users/${app.$auth.user.data.id}/ticket/getqr/${encodeURIComponent(
            nftuniqueid,
          )}`,
        )
        .then(({ data }) => {
          if (data.error) {
            if (
              data.message.includes('has not been created yet') ||
              data.message.includes('no order associated')
            )
              resolve(false);
            reject(data.message);
          } else {
            resolve(data.data);
          }
        })
        .catch((e) => {
          if (
            e.response &&
            e.response.data &&
            (e.response.data.message.includes('has not been created yet') ||
              e.response.data.message.includes('no order associated'))
          )
            resolve(false);
          reject(e);
        });
    });
  },
  /**
   * CLIENT createQRCode for a nftuniqueid (ticket)
   * @param { nftuniqueid, ticketTransferAuth, docType, docId }
   * @returns String QRCode or ''
   */
  createQRCode({
    tokenSerieId,
    nftuniqueid,
    ticketTransferAuth,
    docType,
    docId,
  }) {
    return new Promise((resolve, reject) => {
      $axios
        .post(`users/${app.$auth.user.data.id}/ticket/createqr`, {
          tokenSerieId,
          nftuniqueid,
          ticketTransferAuth,
          docType,
          docId,
        })
        .then(({ data }) => {
          if (data.error) {
            reject(data.message);
          } else {
            resolve(data.data);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * REDEEMER verifyQRCode verify ticket QR Code exits and retrieve it
   * @param {orderid, lineitemidx}
   * @returns Object ticket's order
   */
  verifyQRCode({ orderid, lineitemidx }) {
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `users/${app.$auth.user.data.id}/ticket/verify/${encodeURIComponent(
            orderid,
          )}/${encodeURIComponent(lineitemidx)}`,
        )
        .then(({ data }) => {
          if (data.error) {
            reject(data.message);
          } else {
            resolve(data.data);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * REDEEMER executeQRCode execute action for a verified ticket QR Code
   * @param {orderid, lineItemIndex}
   * @returns Object ticket's order
   */
  validateAndExecuteQRCode({ orderid, lineitemidx }) {
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `users/${app.$auth.user.data.id}/ticket/execute/${encodeURIComponent(
            orderid,
          )}/${encodeURIComponent(lineitemidx)}`,
        )
        .then(({ data }) => {
          if (data.error) {
            reject(data.message);
          } else {
            resolve(data.data);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
});
