
import CompanyLogo from '~/components/company/CompanyLogo.vue';
export default {
  components: { CompanyLogo },
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  head() {
    const title =
      this.error.statusCode === 404 ? this.pageNotFound : this.otherError;
    return {
      title,
    };
  },
  data() {
    return {
      pageNotFound: this.$t('error.page_not_found'),
      otherError: this.$t('error.other_error'),
    };
  },
};
