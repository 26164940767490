import { MARKETPLACE_NAME } from '~/vars/api';

export default {
  steps: {
    nick: 'Username',
    form: {
      handle: 'Username',
      username: 'Enter your username',
      code_company: 'Enter signup code',
      email: 'Enter your email',
      password: 'Create a password',
      password_2: 'Repeat password',
    },
    email: {
      title: 'Email Register',
      info: "Text explaining why do we need your email. We won't spam.",
      validation: {
        title: 'Email Validation Code',
        info: "We've send you an email with your validation code. Please, check your inbox and write down the code you've received to verify your email address.",
      },
    },
    security_key: {
      label: 'Security 12 words',
      title: 'Own your data and money',
      info: 'These words are your Security 12 words. They will be required to access your account and wallet',
      info_2:
        'Without them all your data and funds will become inaccessible forever.',
      warning: {
        restore:
          'W I V A does not have access to your Security 12 words and cannot restore it',
        keep: 'Write down these 12 words in that order in a piece of paper and keep them in a safe place.',
        disclaimer: 'Responsability Disclaimer',
        confirmation:
          'I have writen down these 12 words and I am resposible to keeping them in a safe place.',
        field: {
          info: 'To confirm that you understand that without this 12 words you cant acces your account and wallet, please type:',
          phrase: 'I agree',
          label: 'Type here',
        },
      },
    },
    phone: {
      label: 'Phone Verification',
      title: 'Trusted Accounts',
      info: 'We need to verify your phone number to create your wallet.',
      input: 'Enter a phone number',
      inputs: {
        country: 'Country',
        phone: 'Phone Number',
      },
      uk: {
        confirmation: "I'm a UK resident",
        tip: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla id rutrum urna, et sodales arcu.',
        taxes: "I'll let W I V A (a UK Company) charge me VAT.",
      },
      validation: {
        title: 'Phone Validation Code',
        info: 'Please enter the code you’ve receive on your phone ',
        conditions: {
          pre_condition: 'Doing click on Sign In, you accept ours',
          condition: 'Conditions',
          pre_data:
            'Get more information about how we recollect, use and share your information in',
          data: 'Data Policy',
          pre_cookie: 'and how we use and manage cookies in our',
          cookie: 'Cookie Policy',
        },
      },
    },
    code: {
      valid: 'Please enter a valid Code',
      resent: 'Código reenviado',
    },
    actions: {
      resend: 'Resend Code',
    },
  },
  account: {
    creating: 'Now we are creating your user, please wait a moment',
    created: {
      created: 'User created correctly ',
      redirecting: `Checking your account in ${MARKETPLACE_NAME} Marketplace, you will be redirected to home page`,
    },
  },
  register_steps: {
    first_step: {
      title: `Do you want to belong to the ${MARKETPLACE_NAME} Marketplace community?`,
      create: 'Create your Account',
      marketplace: `Mint and enjoy all the benefits of ${MARKETPLACE_NAME} Marketplace`,
      marketplace_strong: `beneficts of ${MARKETPLACE_NAME} Marketplace.`,
      info: 'It’s easy, select your ',
      info_strong: 'name, password, email and accept the terms and conditions.',
      company: ' Don’t forget to check the option if you’re a',
      company_strong: 'company, institution or brand.',
    },
    second_step: {
      title: 'Verify your email',
      subtitle:
        'We’ve just sent you an email to {email} with a code that you must paste below to confirm that the email is correct. Check your Spam, Promotions, folders in your mail',
    },
    thirst_step: {
      title: `Bots are not allowed in ${MARKETPLACE_NAME} Marketplace.`,
      phone: 'Phone Number',
      phone_subtitle:
        'For security to create your Wallet we need your phone number.',
      marketplace: `At ${MARKETPLACE_NAME} Marketplace we take the `,
      marketplace_strong: 'safety and honesty of our community very seriously.',
      security: 'Therefore, for a better experience,',
      security_strong: 'we want to authenticate your identity.',
      bot: 'Thanks to this step',
      bot_strong: 'you’ll be a bit safer from trolls and bots.',
    },
    four_step: {
      title: 'Phone Validation Code',
      subtitle:
        'We have just sent you a code to {prefix} {phone} with a code, enter it here.',
    },
    five_step: {
      title: 'We come to the most important part.',
      subtitle: 'Your safety words',
      body: 'These are your 12 randomly generated safe words:',
      words: 'Write the 12 words',
      words_strong:
        'on a piece of paper and keep them in a place where you will never lose them,',
      words_second_strong:
        'without them you will not be able to access your account.',
      recovery: 'which you will need to download and save.',
      recovery_strong: `For the recovery of your 12 words, we divide the information into two. We'll send half to a safe bank and the other half
      will generate to a file`,
      file: 'Without this file you will not be able to recover your 12 words and you will lose your bill.',
      on_save:
        'When you have saved the words and the security file, check the box and you will be able to',
      on_save_strong: `enjoy your account in ${MARKETPLACE_NAME} Marketplace.`,
      download:
        'Download the security file for the recovery of my security words.',
      account:
        'I understand that without the 12 words I will not be able to access my account.',
      button: "I've secured the 12 words",
    },
    six_step: {
      title: `Writing. Don't close this window.`,
    },
  },
};
