
import { handleErrors } from '~/utils/functions';
import BaseBtn from '../base/BaseBtn.vue';
export default {
  components: { BaseBtn },
  data() {
    return {
      switcher: false,
      loading: false,
      userBalance: {
        userBalanceSAT: null,
        userBalanceUSD: null,
      },
      interval: null,
      routePath: '/account/wallet',
      routeName: 'account-wallet',
    };
  },
  computed: {
    isRoute() {
      return this.$route.path.includes(this.routePath);
    },
    userPaidFees() {
      return this.$api.commons.userPaidFees();
    },
    satsEnabled() {
      return this.$api.commons.satsEnabled();
    },
    fiatEnabled() {
      return this.$api.commons.fiatEnabled();
    },
    getBalanceSats() {
      return this.$currency_sat.format(this.userBalance.userBalanceSAT);
    },
    getBalanceUsd() {
      return this.$currency.format(this.userBalance.userBalanceUSD);
    },
  },
  methods: {
    async getBalance() {
      if (this.satsEnabled && this.userPaidFees) {
        try {
          this.loading = true;
          const balance = await this.$api.commons.getUserBalance();
          if (balance && Object.keys(balance).length) {
            this.userBalance.userBalanceUSD = parseFloat(balance.balanceUSD);
            this.userBalance.userBalanceSAT = +balance.balanceSats;
          }
          this.loading = false;
        } catch (error) {
          handleErrors(error);
        }
      }
    },
    redirectTo() {
      this.$router.push(
        this.localePath({
          name: this.routeName,
        }),
      );
    },
  },
  mounted() {},
};
