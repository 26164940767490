import { render, staticRenderFns } from "./UserPendingRequestBtns.vue?vue&type=template&id=73553466&"
import script from "./UserPendingRequestBtns.vue?vue&type=script&lang=js&"
export * from "./UserPendingRequestBtns.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseBtn: require('/tmp/build_fc5c3f7c/components/base/BaseBtn.vue').default,UserHandleConnectionBtn: require('/tmp/build_fc5c3f7c/components/user/cards/btns/UserHandleConnectionBtn.vue').default,UserHandleExpiredRequestBtns: require('/tmp/build_fc5c3f7c/components/user/cards/btns/UserHandleExpiredRequestBtns.vue').default})
