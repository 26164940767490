import { SET_G2C_IN_PROCESS } from '~/vars/store/mutations';

export default function ({ app, store, from, route, next }) {
  if (
    typeof Worker === 'undefined' &&
    typeof from !== 'undefined' &&
    from.fullPath !== route.fullPath &&
    store.getters['processing/g2cInProcess'] === true &&
    !['list-form', 'market/product', 'wallet/mytokens', 'mint-form'].some(
      (path) => from.path.includes(path),
    ) &&
    !from.path.includes('/messages/')
  )
    if (confirm(app.i18n.t('base.g2c_in_process.warning'))) {
      store.commit(`processing/${SET_G2C_IN_PROCESS}`, false);
      next();
    } else {
      next(false);
    }
}
