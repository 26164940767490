import {
  RESET_STATE_APPNOTIFICATIONS,
  SET_MESSAGE_NOTIFICATIONS,
  SET_MESSAGE_NOTIFICATIONS_COUNTER,
  SET_NOTIFICATIONS,
  SET_NOTIFICATIONS_COUNTER,
  SET_UNREAD_MSGS_WITH_USER,
} from '../vars/store/mutations';

import {
  setMessageNotifications,
  setNotifications,
} from '../vars/store/actions';

const APPLICACTION_NOTIFICATIONS_INITIAL_STATE = {
  connections: {
    messages: 0,
    requests: 0,
    total: 0,
  },
  rooms: {
    invitations: 0,
    myRooms: 0,
    total: 0,
  },
};

const getDefaultState = () => ({
  appNotifications: [],
  messageNotifications: [],
  appNotificationsCounter: APPLICACTION_NOTIFICATIONS_INITIAL_STATE,
  unReadMsgsOnChatWithUserId: {},
});

export const state = getDefaultState();

export const getters = {
  getNotificationsCounter: (state) => state.appNotificationsCounter,
  getRoomsNotificationsCounter: (state) => state.appNotificationsCounter.rooms,
  getConnectionsNotificationsCounter: (state) =>
    state.appNotificationsCounter.connections,
  getUnReadConnectionCounterWithUserById: (state) => (userId) => {
    return state.appNotifications.filter(
      ({ activity, sourceid, read }) =>
        activity === 'connection_request' && sourceid === userId && !read,
    );
  },
  getUnReadMsgsCounterWithUserById: (state) => (userId) => {
    return state.unReadMsgsOnChatWithUserId[userId] || 0;
  },
  getNotificationsWithRooms: (state) => {
    const unReadNotificationsWithRooms = state.appNotifications.filter(
      (notification) => !notification.read && notification.roomid,
    );
    const roomsWithNotifications = {};
    unReadNotificationsWithRooms.forEach(({ roomid }) => {
      roomsWithNotifications[roomid]
        ? roomsWithNotifications[roomid]++
        : (roomsWithNotifications[roomid] = 1);
    });
    return roomsWithNotifications;
  },
};

export const actions = {
  [setNotifications]({ commit }, notifications) {
    const notifications_new_reference = [...notifications];
    commit('SET_NOTIFICATIONS', notifications_new_reference);
    commit('SET_NOTIFICATIONS_COUNTER', notifications_new_reference);
    commit('SET_UNREAD_MSGS_WITH_USER', notifications_new_reference);
  },
  [setMessageNotifications]({ commit }, notifications) {
    const notifications_new_reference = [...notifications];
    commit('SET_MESSAGE_NOTIFICATIONS', notifications_new_reference);
    commit('SET_MESSAGE_NOTIFICATIONS_COUNTER', notifications_new_reference);
    commit('SET_UNREAD_MSGS_WITH_USER', notifications_new_reference);
  },
};

export const mutations = {
  [RESET_STATE_APPNOTIFICATIONS](state) {
    Object.assign(state, getDefaultState());
  },
  [SET_NOTIFICATIONS](state, notifications) {
    state.appNotifications = notifications;
  },
  [SET_MESSAGE_NOTIFICATIONS](state, notifications) {
    state.messageNotifications = notifications;
  },
  [SET_NOTIFICATIONS_COUNTER](state, notifications) {
    const notificationsCounter = {
      connections: Object.assign(
        {},
        APPLICACTION_NOTIFICATIONS_INITIAL_STATE.connections,
      ),
      rooms: Object.assign({}, APPLICACTION_NOTIFICATIONS_INITIAL_STATE.rooms),
    };
    /*
    if (
      state.appNotificationsCounter.connections &&
      state.appNotificationsCounter.connections.messages > 0
      ) {
        notificationsCounter.connections.messages =
        state.appNotificationsCounter.connections.messages;
        notificationsCounter.connections.total =
        state.appNotificationsCounter.connections.messages;
    }
    */
    notifications.forEach(({ activity, sourceid, read }) => {
      if (!read) {
        const loggedUserIsSource = sourceid === this.$auth.user.data.id;
        if (activity.includes('room')) {
          const tab = loggedUserIsSource ? 'myRooms' : 'invitations';
          notificationsCounter.rooms[tab]++;
          notificationsCounter.rooms.total++;
        } else if (activity.includes('connection')) {
          notificationsCounter.connections.requests++;
          notificationsCounter.connections.total++;
        } else if (activity.includes('message')) {
          notificationsCounter.connections.messages++;
          notificationsCounter.connections.total++;
        }
      }
    });
    state.appNotificationsCounter = notificationsCounter;
  },
  [SET_MESSAGE_NOTIFICATIONS_COUNTER](state, notifications) {
    const notificationsCounter = {
      connections: Object.assign(
        {},
        APPLICACTION_NOTIFICATIONS_INITIAL_STATE.connections,
      ),
      rooms: Object.assign({}, state.appNotificationsCounter.rooms),
    };
    if (
      state.appNotificationsCounter.connections &&
      state.appNotificationsCounter.connections.connections > 0
    ) {
      notificationsCounter.connections.connections =
        state.appNotificationsCounter.connections.connections;
      notificationsCounter.connections.total =
        state.appNotificationsCounter.connections.connections;
    }
    notifications.forEach(({ read }) => {
      if (!read) {
        notificationsCounter.connections.messages++;
        notificationsCounter.connections.total++;
      }
    });

    state.appNotificationsCounter = notificationsCounter;
  },
  [SET_UNREAD_MSGS_WITH_USER](state, notifications) {
    const unReadNotifications = notifications.filter(
      (notification) => !notification.read,
    );
    const conversationsMsgsUnread = {};
    unReadNotifications.forEach((notification) => {
      const userTo =
        notification.destinationid === this.$auth.user.data.id
          ? notification.sourceid
          : notification.destinationid;
      if (notification.activity.includes('message')) {
        if (conversationsMsgsUnread[userTo]) {
          conversationsMsgsUnread[userTo] = conversationsMsgsUnread[userTo] + 1;
        } else {
          conversationsMsgsUnread[userTo] = 1;
        }
      }
    });
    state.unReadMsgsOnChatWithUserId = conversationsMsgsUnread;
  },
};
