
import 'viewerjs/dist/viewer.css';
import VueViewer from 'v-viewer';
import Vue from 'vue';
Vue.use(VueViewer);
export default {
  props: {
    previewImgs: {
      type: Array,
      required: true,
    },
    previewOptions: {
      type: Object,
    },
  },
  computed: {
    options() {
      return {
        inline: true,
        button: true,
        navbar: false,
        title: false,
        toolbar: true,
        tooltip: false,
        movable: false,
        zoomable: false,
        rotatable: false,
        scalable: false,
        transition: false,
        fullscreen: false,
        keyboard: true,
      };
    },
  },
  mounted() {
    const options = Object.assign(this.options, this.previewOptions);
    options['hidden'] = (e) => {
      this.$store.dispatch('ui/hidePreviewModal');
    };
    this.$viewerApi({
      images: this.previewImgs,
      options: options,
    });
  },
  methods: {},
};
