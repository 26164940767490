import Vue from 'vue';
import { UPDATE_USER_INFO } from '../vars/store/mutations';
import { updateUserInfo } from '../vars/store/actions';

export const state = () => ({});

export const getters = {};

export const actions = {
  [updateUserInfo]({ commit }, data) {
    commit(UPDATE_USER_INFO, data);
  },
};
export const mutations = {
  [UPDATE_USER_INFO](state, data) {
    const userData = Object.assign({}, state.auth.user.data);
    const updatedUserData = Object.assign(userData, data);
    Vue.set(state.auth.user, 'data', updatedUserData);
  },
};
