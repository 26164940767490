import { MARKETPLACE_NAME } from '~/vars/api';

export default {
  button: {
    user: 'Verify your identity',
    entity: 'Request {userOption} verification',
  },
  base: {
    validated: `User verified by ${MARKETPLACE_NAME} Marketplace`,
    validated_company: `${MARKETPLACE_NAME} Marketplace Company verified by ${MARKETPLACE_NAME} Marketplace`,
    verified: 'User with no listings',
    verified_company: `${MARKETPLACE_NAME} Marketplace Company with no listings`,
    no_verified: 'Unverified {entity_option}',
    all: 'All',
    entity_options: {
      company: 'Store',
      individual: 'User',
    },
  },
  filters: {
    all: 'All profiles',
    by_account: {
      company: 'Store',
      individual: 'Users',
    },
  },
  entity_modal: {
    title: 'Verify your {userOption} account',
    subtitle: `As mentioned in sign up process, we need you to fill this simple form to help us checking that you are the rightfull owner of this {userOption} account. Once you have submitted the form, a ${MARKETPLACE_NAME} Marketplace Team Member(*) will contact you to verify all the info.`,
    tip: `Please, remember that no ${MARKETPLACE_NAME} Marketplace Team Member will never request you any information about your password or 12 security words.`,
    fields: {
      contact_name: 'Contact Name',
      business_name: 'Business Name',
      observations: 'Observations',
      phone_number: 'Phone Number to contact',
      email: 'Email to contact',
    },
    send: 'Submiting verification form.',
    success: 'Verification form submited correctly ',
    error: 'Error submiting verification form.',
  },
};
