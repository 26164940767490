import logout from './session/logout';
import login from './session/login';
import register from './session/register';
import signup from './session/signup';
import session from './unused/session';
import routes from './basic/routes';
import base from './basic/base';
import network from './basic/network';
import sidebar from './basic/sidebar';
import account from './account/account';
import market from './market/market';
import report from './basic/report';
import mailing_invitation from './market/mailing_invitation';
import verification from './session/verification';
import one_to_one from './rooms/one_to_one';
import banner from './basic/banner';
import contribute from './market/contribute';
import notification from './unused/notification';
import user from './user/user';
import error from './basic/error';
import connect from './basic/connect';
import home from './home/HomePage';
// import meetings from './rooms/meetings';
import page from './unused/page';
import profile from './unused/profile';
import forms from './basic/forms';
import wallet from './account/wallet';
import forgot from './session/forgot';
import support from './user/support';
import stripe from './stripe/stripe';
import create_account_transitions from './transitions/create_account';
import loadings from './basic/loadings';
import g2clib from './g2clib/g2clib';
import connections from './user/connections';
import verify_identity from './verification/verification';

export default {
  home,
  routes,
  logout,
  network,
  login,
  register,
  base,
  sidebar,
  signup,
  account,
  market,
  report,
  mailing_invitation,
  verification,
  one_to_one,
  banner,
  contribute,
  notification,
  user,
  error,
  connect,
  session,
  page,
  profile,
  // meetings,
  forms,
  wallet,
  forgot,
  support,
  stripe,
  create_account_transitions,
  loadings,
  g2clib,
  connections,
  verify_identity,
};
