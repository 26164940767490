// appNotifications.js
export const setNotifications = 'setNotifications';
export const setMessageNotifications = 'setMessageNotifications';

// connections.js
export const setConnections = 'setConnections';
export const addConnection = 'addConnection';
export const updateConnection = 'updateConnection';
export const setLoadingConnection = 'setLoadingConnection';

// devices.js
export const setAudioInput = 'setAudioInput';
export const setVideoInput = 'setVideoInput';

// index.js
export const nuxtServerInit = 'nuxtServerInit';
export const updateUserInfo = 'updateUserInfo';

// mailing.js
export const showMailingInvitationModal = 'showMailingInvitationModal';
export const setContetMailingModal = 'setContetMailingModal';

// market.js
export const showListProductModal = 'showListingModal';
export const showTransferProductModal = 'showTransferModal';
export const showHandleInvitationOfferingModal =
  'showHandleInvitationOfferingModal';
export const showDelistOfferingModal = 'showDelistOfferingModal';
export const showCheckoutSession = 'showCheckoutSession';
export const showRedeemProductModal = 'showRedeemModal';
export const showCreateQrModal = 'showCreateQrModal';
export const showFilePreviewModal = 'showFilePreviewModal';
export const removeFileG2CObject = 'removeFileG2CObject';
export const removeCollection = 'removeCollection';
export const removeRoyalty = 'removeRoyalty';
export const removeBeneficiary = 'removeBeneficiary';
export const removeRedeemer = 'removeRedeemer';
export const setOfferingProcess = 'setOfferingProcess';
export const setListingStatus = 'setListingStatus';
export const setTransferStatus = 'setTransferStatus';
export const setRedeemStatus = 'setRedeemStatus';
export const setSelectedAccessOption = 'setSelectedAccessOption';
export const setSelectedAccessInvitations = 'setSelectedAccessInvitations';
export const setListingOffering = 'setListingOffering';

// messages.js
export const setLoadingMessage = 'setLoadingMessage';
export const setConnectionMessage = 'setConnectionMessage';
export const setLastMessage = 'setLastMessage';
export const setChats = 'setChats';
export const prepareChats = 'prepareChats';
export const addMsgsToChat = 'addMsgsToChat';
export const updateMessagesFromChat = 'updateMessagesFromChat';
export const updateMessageFromChat = 'updateMessageFromChat';
export const updateConversation = 'updateConversation';
export const setConnectionMessages = 'setConnectionMessages';
export const addMessagePendingToUnblock = 'addMessagePendingToUnblock';

// notifications.js
export const feedbackNotificationHandler = 'feedbackNotificationHandler';
export const addNotification = 'addNotification';
export const addNotificationWithUser = 'addNotificationWithUser';
export const addNotificationWithRoom = 'addNotificationWithRoom';
export const removeNotifications = 'removeNotifications';
export const removeNotificationsWithUser = 'removeNotificationsWithUser';
export const removeNotificationsWithRoom = 'removeNotificationsWithRoom';

// report.js
export const showReportModal = 'showReportModal';
// publicProfile.js
export const setPublicProfile = 'setPublicProfile';

// rooms.js
export const initRoomsFavoritesIds = 'initRoomsFavoritesIds';
export const toggleFavorite = 'toggleFavorite';
export const setRooms = 'setRooms';
export const setPrivateRooms = 'setPrivateRooms';
export const add_room = 'add_room';
export const updateRoom = 'updateRoom';
export const updateRoomStatus = 'updateRoomStatus';
export const updateParticipantStatus = 'updateParticipantStatus';
export const updateInvitation = 'updateInvitation';
export const hiddeRoom = 'hiddeRoom';
export const addNowRoom = 'addNowRoom';
export const removeNowRoom = 'removeNowRoom';
export const setWaitingRoom = 'setWaitingRoom';
export const showRoomModal = 'showRoomModal';
export const showPreviewRoomModal = 'showPreviewRoomModal';

// session.js
export const showSessionModal = 'showSessionModal';
export const showVerifyEntityModal = 'showVerifyEntityModal';
export const setLoadingProducts = 'setLoadingProducts';

// tokens.js
export const addProduct = 'addProduct';
export const addListedProduct = 'addListedProduct';
export const setTokenSerie = 'setTokenSerie';
export const setToken = 'setToken';
export const setOfferingBids = 'setOfferingBids';
export const setLoadingBids = 'setLoadingBids';

// users.js
export const hiddeUserModal = 'hiddeUserModal';
export const addUsers = 'addUsers';
export const addCompanies = 'addCompanies';
export const addUser = 'addUser';
export const updateUser = 'updateUser';
export const showUserModal = 'showUserModal';
export const showUserConnectModal = 'showUserConnectModal';
export const setUserToken = 'setUserToken';
export const setSellers = 'setSellers';
