
export default {
  components: {},
  mixins: [],
  props: {},
  data() {
    return {};
  },
  computed: {
    isActive() {
      const key = 'market';
      const path =
        this.$route.path.split('/')[1] === '' ||
        this.$route.path.split('/')[1] === 'support'
          ? 'home'
          : this.$route.path.split('/')[1];
      return path.includes(key);
    },
    icon() {
      return `store_thin${this.isActive ? '_primary' : ''}`;
    },
  },
  watch: {},
  methods: {
    onBtnClicked() {
      this.$router.push(
        this.localePath({ name: 'market-preview-marketplace' }),
      );
    },
  },
  /*
   ** Lyfecycle hooks
   */
  created() {},
  mounted() {},
  beforeDestroy() {},
  updated() {},
};
