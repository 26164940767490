
export default {
  props: {
    error: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    imageSrc() {
      return `/svg/icons/edit_pencil_${
        this.error === true ? 'error' : 'primary'
      }.svg`;
    },
  },
};
