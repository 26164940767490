
import { EventBus } from '~/components/eventBus/EventBus';
import { handleErrors } from '~/utils/functions';
import { SECOND, USER_PLATFORM } from '~/vars/api';
import BaseBtn from '../base/BaseBtn.vue';
export default {
  components: { BaseBtn },
  data() {
    return {
      showModal: false,
      notifications: [],
      pendingNotifications: 0,
      users: [],
      loading: false,
      ringBell: false,
      draggablePos1: 0,
      draggablePos3: 0,
      draggableNotification: null,
      titleInterval: null,
      previousTitle: '',
    };
  },
  computed: {
    container() {
      return this.$refs.container;
    },
    unreadNotifications() {
      this.getPendingNotifications();
      return this.pendingNotifications;
    },
    testNotifications() {
      return this.notifications;
    },
  },
  watch: {
    ringBell(newVal) {
      if (newVal) {
        setTimeout(() => {
          this.ringBell = false;
        }, 10000);
      }
    },
    /*
    pendingNotifications() {
      if (!this.previousTitle) {
        this.previousTitle = document.title;
      }
      clearInterval(this.titleInterval);
      if (this.unreadNotifications > 0) {
        let toggleTitle = false;
        this.titleInterval = setInterval(() => {
          if (
            this.unreadNotifications === 0 ||
            !this.unreadNotifications ||
            !this.$auth.loggedIn
          ) {
            clearInterval(this.titleInterval);
          } else {
            const title = `(${this.unreadNotifications}) New notifications`;
            document.title = toggleTitle ? title : this.previousTitle;
            toggleTitle = !toggleTitle;
          }
        }, 3000);
      } else {
        document.title = this.previousTitle;
      }
    },
    */
  },
  fetchOnServer: false,
  async fetch() {
    // fetch last sidebar notifications
    await this.getNotifications(null);
  },
  mounted() {
    EventBus.$on('update-notifications', async () => {
      this.ringBell = true;
      await this.getNotifications(null);
      await this.getPendingNotifications();
    });
    EventBus.$on('read-notifications', this.onReadNotifications);
    if (!this.previousTitle) {
      this.previousTitle = document.title;
    }
  },
  methods: {
    async getPendingNotifications() {
      const result = await this.$api.notifications.getNewSidebarNotifications();
      this.pendingNotifications = result;
    },
    async onReadNotifications({ type, tab, roomId, userId }) {
      const loggedUserId = this.$auth.user.data.id;
      const readedNotifications = this.notifications.filter(
        ({ activity, destinationid, sourceid, read, roomid }) => {
          if (!read) {
            if (activity.includes(type) && type === 'room') {
              if (tab === 'invitations') {
                return destinationid === loggedUserId && roomId === roomid;
              } else {
                return sourceid === loggedUserId && roomId === roomid;
              }
            } else if (activity.includes(type) && type === 'connection') {
              return destinationid === loggedUserId && sourceid === userId;
            } else if (activity.includes(type) && type === 'message') {
              return sourceid === tab || destinationid === tab;
            }
          }
        },
      );
      if (Boolean(readedNotifications.length)) {
        await this.$api.notifications
          .updateReadSidebarNotifications(readedNotifications, true, 'sidebar')
          .then(async () => {
            await this.getNotifications(
              readedNotifications[0].createdat + SECOND,
            );
            await this.getPendingNotifications();
          });
      }
    },
    resetSwipe(value, notification) {
      if (
        this.draggableNotification === null ||
        typeof this.draggableNotification === 'undefined'
      )
        return;
      this.draggableNotification.style.left = '16px';
      this.draggablePos1 = 0;
      this.draggablePos3 = 0;
      // if (value === 'left') this.unReadNotification(notification);
      if (['right', 'left'].includes(value))
        this.readNotification(notification);
    },
    swipe(event) {
      this.draggableNotification = event.target.closest(
        'div.notification-item__content',
      );
      if (
        this.draggableNotification === null ||
        typeof this.draggableNotification === 'undefined'
      )
        return;

      this.draggablePos3 = this.draggablePos1;
      this.draggablePos1 = event.touchmoveX - event.touchstartX;

      this.draggableNotification.style.left =
        this.draggableNotification.offsetLeft +
        (this.draggablePos1 - this.draggablePos3) +
        'px';
    },
    /**
     * show hide sidebar notifications dialog
     */
    switchModal(name) {
      this.showModal = !this.showModal;
      if (name) {
        // if Uri passed check if it is in the same page and refresh nuxt
        if (this.samePage(name)) this.$nuxt.refresh();
        // if Uri passed and mobile hide drawer
        if (this.$vuetify.breakpoint.mobile) this.$emit('hide-drawer');
      }
      this.getPendingNotifications();
    },
    samePage(uri) {
      return this.$route.name.includes(uri);
    },
    /**
     * for fetching
     */
    switchLoading() {
      this.loading = !this.loading;
    },
    /**
     * get user's info object by notifications's source nick
     */
    getUser(notification) {
      const sourceid =
        notification.sourceid &&
        notification.sourceid !== notification.destinationid
          ? notification.sourceid
          : USER_PLATFORM.id;
      return sourceid === this.$auth.user.data.id
        ? notification.destinationid
        : sourceid;
    },
    /**
     * update notifications to read
     */
    async readNotification(notification) {
      if (!notification.read) {
        await this.$api.notifications.updateReadSidebarNotification({
          id: notification.id,
          read: true,
          activity: notification.activity,
          mode: 'sidebar',
        });
        this.getNotifications(notification.createdat + SECOND);
      }
    },
    /**
     * update notifications to no read
     */
    async unReadNotification(notification) {
      if (notification.read) {
        await this.$api.notifications.updateReadSidebarNotification({
          id: notification.id,
          read: false,
          activity: notification.activity,
          mode: 'sidebar',
        });
        this.getNotifications(notification.createdat + SECOND);
      }
    },
    async readAllNotifications() {
      try {
        if (this.unreadNotifications > 0)
          await this.$api.notifications
            .markAllNotificationsAsRead()
            .then(async () => {
              await this.getNotifications(
                this.notifications[0].createdat + SECOND,
              );

              await this.getPendingNotifications();
            });
      } catch (error) {
        handleErrors(error);
      }
    },
    /**
     * fetch and filter notifications
     * if startFrom === null take last notifications
     */
    async getNotifications(startFrom) {
      this.switchLoading();
      const inNotifications = Object.entries(
        await this.$api.notifications.getSidebarNotifications(startFrom),
      ).map((el) => {
        el[1].id = el[0];
        return el[1];
      });
      let newNotifications = 0;
      const notifications = [...inNotifications];
      notifications.forEach((notification) => {
        const index = this.notifications.findIndex(
          (oldNotification) => oldNotification.id === notification.id,
        );
        if (index === -1) {
          this.notifications.push(notification);
          this.notifications = this.notifications.sort(
            (a, b) => b.createdat - a.createdat,
          );
          newNotifications++;
          if (
            notification.activity.includes('message') &&
            this.$route.params.id
          ) {
            this.onReadNotifications({
              type: 'message',
              tab: this.$route.params.id,
            });
          }
        } else {
          this.$set(this.notifications, index, notification);
        }
      });
      this.switchLoading();
      this.setStoreWithNotifications();
      // It has to return number of new notifications for infiniteHandler to know if has to fetch more or hasn't
      return newNotifications;
    },
    setStoreWithNotifications() {
      this.$store.dispatch(
        'appNotifications/setNotifications',
        this.notifications,
      );
    },
    /**
     * vue-infinite-loading handler
     * @param $state from infinite-loading
     */
    async infiniteHandler($state) {
      let startFrom = null;
      // if notifications in previous getnotifications, need oldest timestamp
      if (this.notifications.length > 0) {
        startFrom = this.notifications.reduce((final, el) => {
          if (el.createdat < final) {
            final = el.createdat + 1;
          }
          return final;
        }, Date.now());
      }
      // filter new notifications from prev obtained if timestamp includes again
      const newNotifications = await this.getNotifications(startFrom);
      // finish infinite loading if no more notifications obtained
      if (newNotifications > 0) {
        $state.loaded();
      } else {
        $state.complete();
      }
    },
  },
};
