
import { handleErrors } from '~/utils/functions';
import AccountAddFundsModal from './AccountAddFundsModal.vue';
import AccountSendFundsModal from './AccountSendFundsModal.vue';
export default {
  components: { AccountAddFundsModal, AccountSendFundsModal },
  props: {
    header: {
      type: Boolean,
      default: false,
    },
    showInfo: {
      type: Boolean,
      default: false,
    },
    alignmentRow: {
      type: Boolean,
      default: false,
    },
    lite: {
      type: Boolean,
      default: false,
    },
    balance: {
      type: Object,
      default: () => ({}),
      required: false,
    },
  },
  data() {
    return {
      userBalance: {
        userBalanceSAT: null,
        userBalanceUSD: null,
      },
      interval: null,
      timeOutToSwitch: null,
      intervalToHide: null,
      loading: true,
      timeToHide: null,
      showFreeSats: true,
    };
  },
  mounted() {
    if (
      !Object.keys(this.balance).length ||
      Object.values(this.balance).some(
        (balance) => typeof balance === 'undefined' || balance === null,
      )
    ) {
      this.getBalance();
      if (!this.interval) {
        this.interval = setInterval(() => {
          this.getBalance();
        }, 30000);
      }
    } else {
      this.loading = false;
    }
    this.$auth.user.data.offer
      ? (this.showFreeSats = false)
      : (this.showFreeSats = true);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  computed: {
    firstTime() {
      return (
        typeof this.getUserBalance.userBalanceSAT === 'undefined' ||
        this.getUserBalance.userBalanceSAT === null
      );
    },
    getUserBalance() {
      return Object.keys(this.balance).length &&
        !Object.values(this.balance).some(
          (balance) => typeof balance === 'undefined' || balance === null,
        )
        ? this.balance
        : this.userBalance;
    },
    getBalanceSats() {
      return this.$currency_sat.format(this.getUserBalance.userBalanceSAT);
    },
    getBalanceUsd() {
      return this.$currency.format(this.getUserBalance.userBalanceUSD);
    },
    getStyleHeader() {
      return this.header ? 'cursor: pointer' : '';
    },
    getTitleClass() {
      let textSize = 'h4';
      let textWeight = 'medium';
      let spacing = '';
      if (this.header) {
        textSize = 'caption';
        textWeight = 'regular';
        spacing = 'py-0 px-3';
      }
      return `justify-end text-${textSize} font-weight-${textWeight} ${spacing}`;
    },
    getSubtitleClass() {
      let textSize = 'h5';
      let textWeight = 'light';
      let spacing = '';
      if (this.header) {
        textSize = 'caption';
        textWeight = 'regular';
        spacing = 'py-0 px-3 mt-0';
      }
      return `text-right white--text text-${textSize} font-weight-${textWeight} ${spacing}`;
    },
    changeStateFreeSats() {
      return this.$auth.user.data.offer && this.$auth.loggedIn
        ? (this.showFreeSats = false)
        : (this.showFreeSats = true);
    },
  },
  methods: {
    redirectTo() {
      this.$router.push(
        this.localePath({
          name: 'account-wallet',
        }),
      );
    },
    async getBalance() {
      try {
        this.loading = true;
        const balance = await this.$api.commons.getUserBalance();
        if (balance && Object.keys(balance).length) {
          this.userBalance.userBalanceUSD = parseFloat(balance.balanceUSD);
          this.userBalance.userBalanceSAT = +balance.balanceSats;
        }
        this.loading = false;
      } catch (error) {
        handleErrors(error);
      }
    },
    switchShowInfo() {
      this.$emit('show-info');
      if (this.header) {
        if (this.intervalToHide === null) {
          this.getBalance();
          this.timeToHide = 0;
          this.intervalToHide = setInterval(() => {
            this.timeToHide += 6;
          }, 100);
          this.timeOutToSwitch = setTimeout(() => {
            this.timeToHide = null;
            clearInterval(this.intervalToHide);
            this.intervalToHide = null;
            this.$emit('show-info');
          }, 2000);
        } else {
          this.timeToHide = null;
          clearTimeout(this.timeOutToSwitch);
          this.timeOutToSwitch = null;
          clearInterval(this.intervalToHide);
          this.intervalToHide = null;
        }
      }
    },
  },
};
