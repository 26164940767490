
import ProgressBar from '~/components/user/cards/btns/ProgressBar.vue';
import G2cProcessMixinHandler from '~/components/commons/G2cProcessMixinHandler.vue';

import {
  CONNECTION,
  CONNECTION_STATUS_PENDING,
  CONNECTION_STATUS_PRECHARGED,
  CONNECTION_STATUS_PRECREATED,
  CONNECTION_STATUS_PREPAID,
  CONNECTION_STATUS_PRESHARED,
} from '~/vars/api';
import { mapGetters } from 'vuex';
import { timeToHandleRequest, percentToHandleRequest } from '~/utils/functions';
import { SET_G2C_IN_PROCESS } from '~/vars/store/mutations';
export default {
  mixins: [G2cProcessMixinHandler],
  props: {
    user: {
      type: Object,
      required: true,
    },
    btnsClasses: {
      type: String,
      default: '',
    },
    showProgressBar: {
      type: Boolean,
      default: true,
    },
    parentNode: {
      type: String,
      default: '',
    },
  },
  components: {
    ProgressBar,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      getConnection: 'connections/getConnection',
    }),
    connectionRemainingTime() {
      return timeToHandleRequest(this.currentConnection.updated_at);
    },
    connectionRemainingPercent() {
      return percentToHandleRequest(this.connectionRemainingTime);
    },
    currentConnection() {
      return this.getConnection(this.user.id);
    },
    CONNECTION() {
      return CONNECTION;
    },
    isSentConnection() {
      return this.currentConnection.sourceid === this.$auth.user.data.id;
    },
    isPendingRequest() {
      return (
        ([
          CONNECTION_STATUS_PENDING,
          CONNECTION_STATUS_PRESHARED,
          CONNECTION_STATUS_PRECHARGED,
        ].includes(this.currentConnection.status) &&
          this.currentConnection.sourceid === this.$auth.user.data.id) ||
        ([CONNECTION_STATUS_PREPAID, CONNECTION_STATUS_PRECREATED].includes(
          this.currentConnection.status,
        ) &&
          this.currentConnection.sourceid !== this.$auth.user.data.id)
      );
    },
  },
  beforeDestroy() {
    this.$store.commit(`processing/${SET_G2C_IN_PROCESS}`, false);
  },
  methods: {},
};
