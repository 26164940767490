import { APP_AUTH_STRATEGY_G2C } from '~/vars/api';

export default (ctx) => g2c_middleware_prod(ctx);

const g2c_middleware_prod = ({ app, redirect, route, from, req }) => {
  if (route.fullPath.includes('rooms')) {
    return redirect('/');
  }
  if (app.$auth.strategy.name === APP_AUTH_STRATEGY_G2C) {
    if (process.client) {
      let isSafari = navigator.userAgent.indexOf('Safari') > 0;
      const g2ctoken = app.$api.g2cSession.getToken();
      const hasToken = g2ctoken !== null && typeof g2ctoken !== 'undefined';
      if (app.$auth.loggedIn) {
        if (!hasToken) {
          const error = new Error(
            `${app.$auth.user.data.nick} no tokens found, session closed, payload: ${g2ctoken}`,
          );
          error.name = 'Session Expired';

          app.$sentry.captureException(error);
          let message = 'Your session has expired';
          if (isSafari) {
            message = `${message}: Safari clear sessions in 7 days from last entry and extend it 7 days more with each visit.`;
          }
          app.$api.session.logout();
          app.$toast.show(message, {
            icon: 'alert-circle',
          });
          app.$api.session.logout();
        } else if (hasToken) {
          const hasConnectionToInternet = navigator.onLine;
          if (hasConnectionToInternet) {
            app.$api.session.checkSession(isSafari);
          }
        }
      }
    }
  }
};
