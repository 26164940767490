import { MARKETPLACE_NAME } from '~/vars/api';

export default {
  copied_paymail: 'Paymail copied correctly',
  error_copied_paymail: 'Error on copied paymail',
  set_price: 'Set price',
  status: {
    label: `Your status in ${MARKETPLACE_NAME} Marketplace`,
    available: {
      label: 'Online',
      info: 'All users see you as you were active',
    },
    invisible: {
      label: 'Offline',
      info: "All users see you as you were not logged in and users can't request you Rooms Now",
    },
    bussy: {
      label: 'No disturb',
      info: "Online but users can't request you Rooms Now",
    },
    error: 'Imposible to apply status',
  },
  profile: {
    list_token: 'List your Items',
  },
  user_card: {
    user_tokens: '{user} Items',
  },
};
