
import G2cProcessMixinHandler from '~/components/commons/G2cProcessMixinHandler.vue';
export default {
  mixins: [G2cProcessMixinHandler],
  props: {
    button: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    text: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: 'primary',
      validator: (val) =>
        ['primary', 'secondary', 'tertiary', 'cuartiary', 'success'].indexOf(
          val,
        ) !== -1,
    },
  },
  components: {
    SendFundsInternal: () =>
      import('~/components/account/wallet/AccountSendFundsInternal'),
    SendFundsExternal: () =>
      import('~/components/account/wallet/AccountSendFundsExternal'),
  },
  data() {
    return {
      dialog: false,
      tab: 0,
      loading: false,
    };
  },
  watch: {
    sendFundsModal(value) {
      if (value === false) {
        this.tab = null;
      }
    },
  },
  computed: {
    navbarSendFundsItems() {
      return [
        { title: 'MarketPlace User', component: 'internal' },
        {
          title: 'Paymail',
          component: 'external',
        },
      ];
    },
  },
};
