import G2CLib from './g2clib';
export default ({ app }) => ({
  g2clib: new G2CLib({
    libsURL: {
      g2clibURL: app.context.env.g2clibURL,
      stasjsURL: app.context.env.stasjsURL,
      bsvsdkURL: app.context.env.bsvsdkURL,
    },
  }),
  handleError(response, method) {
    const errorCode =
      typeof response !== 'undefined' ? response.errorCode : 500;
    const errorMessage =
      typeof response !== 'undefined' ? response.error : 'Undefined Response';
    const error = new Error(
      `${method} -- ERROR CODE -- ${errorCode} -- ERROR -- ${errorMessage}`,
    );
    app.$sentry.captureException(error);
    if (typeof response === 'undefined') {
      throw new Error(app.i18n.t('g2clib.errors.undefined'));
    } else {
      if (
        response.errorCode === 500 ||
        response.error.includes('Mempool rejected transaction') ||
        response.error.includes('token is undefined')
      )
        throw new Error(app.i18n.t('g2clib.errors.connections'));
      if (
        response.error.includes('Not enought balance') ||
        response.error.includes('Not enought funds')
      )
        throw new Error(app.i18n.t('g2clib.errors.no_funds'));
      if (
        ['walletConditionalReject', 'walletConditionalAccept'].includes(
          method,
        ) &&
        (response.error.includes('Failed to fetch') ||
          response.error.includes('Trying to spend already spent funds') ||
          response.error.includes('Transaction already in the mempool') ||
          response.error.includes('Missing inputs') ||
          response.error.includes('txn-already-known') ||
          response.error.includes('Transaction already known'))
      )
        throw new Error(app.i18n.t('g2clib.errors.already_done'));
      throw new Error(response.error);
    }
  },

  async getRandomWords() {
    const response = await this.g2clib.g2c_getRandomWords();
    if (response.hasOwnProperty('error') || typeof response === 'undefined') {
      this.handleError(response, 'getRandomWords');
    } else return response.data;
  },

  /**
   * Tree object
   */

  async createUserObjectTree({ g2ctoken, treePath, treeName }) {
    const response = await this.g2clib.g2cclient_createUserObjectTree({
      g2ctoken,
      treePath,
      treeName,
    });
    console.log({ response });
    if (response.hasOwnProperty('error') || typeof response === 'undefined') {
      this.handleError(response, 'createUserObjectTree');
    } else return response.data;
  },

  /**
   * Getters User object
   */

  async getUserObject({ g2ctoken, path, name, mode }) {
    const response = await this.g2clib.g2c_getUserObject({
      g2ctoken,
      path,
      name,
      mode,
    });
    if (response.hasOwnProperty('error') || typeof response === 'undefined') {
      this.handleError(response, 'getUserObject');
    } else return response.data;
  },
  async getUserObjectList({ g2ctoken, path, type = '', mode = 'noobject' }) {
    const response = await this.g2clib.g2c_getUserObjectList({
      g2ctoken,
      path,
      type,
      mode,
    });
    console.log({ response });
    if (response.hasOwnProperty('error') || typeof response === 'undefined') {
      this.handleError(response, 'getUserObjectList');
    } else return response.data.objects;
  },
  async getUserObjectHistoric({
    g2ctoken,
    path,
    name,
    versions,
    datefrom,
    dateto,
    type,
  }) {
    if (
      (typeof versions !== 'undefined' &&
        (typeof datefrom !== 'undefined' ||
          typeof dateto !== 'undefined' ||
          typeof type !== 'undefined')) ||
      ((typeof datefrom !== 'undefined' || typeof dateto !== 'undefined') &&
        typeof type !== 'undefined')
    ) {
      const response = {
        error: 'Only one filter at time',
        errorCode: 400,
      };
      this.handleError(response);
    }
    let formatedVersions;
    if (typeof versions !== 'undefined' && versions.length > 0)
      formatedVersions = versions.map((version) => +version);
    const response = await this.g2clib.g2c_getUserObjectHistoric({
      g2ctoken,
      path,
      name,
      versions: formatedVersions,
      datefrom,
      dateto,
      type,
    });
    if (response.hasOwnProperty('error') || typeof response === 'undefined') {
      this.handleError(response, 'getUserObjectHistoric');
    } else return response.data.versions;
  },

  /**
   * Create update User object
   */

  async createUserObject({
    g2ctoken,
    path,
    type,
    name,
    data,
    encoding,
    permissions,
  }) {
    const g2coverlay = app.$api.commons.userPaidFees ? false : true;
    const response = await this.g2clib.g2cclient_createUserObject({
      g2ctoken,
      path,
      name,
      type,
      data,
      encoding,
      objectPermissions: permissions,
      g2coverlay,
    });

    if (
      (response.hasOwnProperty('error') &&
        response.error.includes('Trying to create an existing object') !==
          true) ||
      typeof response === 'undefined'
    ) {
      this.handleError(response, 'getUserObjectList');
    } else return response.data;
  },
  async updateUserObject({
    g2ctoken,
    path,
    type,
    name,
    data,
    encoding,
    permissions,
  }) {
    const g2coverlay = app.$api.commons.userPaidFees ? false : true;
    const response = await this.g2clib.g2cclient_updateUserObject({
      g2ctoken,
      path,
      name,
      type,
      data,
      encoding,
      objectPermissions: permissions,
      g2coverlay,
      upsert: true,
    });

    if (response.hasOwnProperty('error') || typeof response === 'undefined') {
      this.handleError(response, 'getUserObjectList');
    } else return response.data;
  },

  /**
   * sats
   */
  async exchangeRates({ amount, mode }) {
    const response = await this.g2clib.g2c_exchangeRates(amount, mode);
    if (response.hasOwnProperty('error') || typeof response === 'undefined') {
      this.handleError(response, 'exchangeRates');
    } else return response;
  },
  async getUserBalance({ g2ctoken }) {
    const response = await this.g2clib.g2c_getBalance({ g2ctoken });
    if (response.hasOwnProperty('error') || typeof response === 'undefined') {
      this.handleError(response, 'getUserBalance');
    } else return response.data;
  },

  async walletDirectSend({
    g2ctoken,
    destinationnick,
    destinationpaymail,
    amount,
    lockuntil,
    description,
    commissiontype,
  }) {
    const response = await this.g2clib.g2c_walletDirectSend({
      g2ctoken,
      destinationnick,
      destinationpaymail,
      amount,
      lockuntil,
      description,
      commissiontype,
    });

    if (response.hasOwnProperty('error') || typeof response === 'undefined') {
      this.handleError(response, 'walletDirectSend');
    } else return response.data;
  },
  async walletConditionalPropose({
    g2ctoken,
    destinationnick,
    amount,
    lockuntil,
    description,
    commissiontype,
    expirationdatestamp,
  }) {
    const response = await this.g2clib.g2cclient_walletConditionalPropose({
      g2ctoken,
      destinationnick,
      amount,
      lockuntil,
      description,
      commissiontype,
      expirationdatestamp,
    });

    if (response.hasOwnProperty('error') || typeof response === 'undefined') {
      this.handleError(response, 'walletConditionalPropose');
    } else return response.data;
  },
  async walletConditionalReject({ g2ctoken, paymentauth }) {
    const response = await this.g2clib.g2c_walletConditionalReject({
      g2ctoken,
      paymentauth,
    });

    if (response.hasOwnProperty('error') || typeof response === 'undefined') {
      this.handleError(response, 'walletConditionalReject');
    } else return response.data;
  },
  async walletConditionalAccept({ g2ctoken, paymentauth }) {
    const response = await this.g2clib.g2c_walletConditionalAccept({
      g2ctoken,
      paymentauth,
    });

    if (response.hasOwnProperty('error') || typeof response === 'undefined') {
      this.handleError(response, 'walletConditionalAccept');
    } else return response.data;
  },

  /**
   * Getters token
   */

  async getToken({ g2ctoken, mode, tokenSerieId }) {
    const response = await this.g2clib.g2cclient_tokenGet({
      g2ctoken,
      mode,
      tokenSerieId,
    });
    if (response.hasOwnProperty('error') || typeof response === 'undefined') {
      this.handleError(response, 'getToken');
    } else return response.data;
  },

  /**
   * Create token
   */

  async createToken({
    g2ctoken,
    description,
    nftname,
    symbol,
    imageurl,
    transferrable,
    commissionschema,
    issuerEmail,
    legalterms,
    amountEmission,
    name,
    path,
  }) {
    const response = await this.g2clib.g2cclient_tokenCreate({
      g2ctoken,
      description,
      nftname,
      symbol,
      imageurl,
      transferrable,
      commissionschema,
      issuerEmail,
      legalterms,
      amountEmission,
      name, //Object to share name or undefined if no object related
      path, //Path of object to share or undefined if no object related
    });
    if (response.hasOwnProperty('error') || typeof response === 'undefined') {
      this.handleError(response, 'createToken');
    } else return response.data;
  },

  /**
   * Transfers token
   */

  async transferToken({
    g2ctoken,
    destinationnick,
    nftuniqueidlist,
    tokenSerieId,
    description,
  }) {
    const response = await this.g2clib.g2cclient_tokenTransfer({
      g2ctoken,
      destinationnick,
      nftuniqueidlist,
      tokenSerieId,
      description,
    });
    //   results: [{
    //     transactionGroipId,
    //     transfertxid,
    //     nftuniqueid,
    //     tokenSerieId,
    //   }]
    if (typeof response === 'undefined') {
      this.handleError(response, `transferToken`);
    } else if (response.hasOwnProperty('error')) return response;
    else return response.data;
  },
  async transferTokenPropose({
    g2ctoken,
    destinationnick,
    nftuniqueidlist,
    tokenSerieId,
    permissions,
    expirationdatestamp,
    description,
  }) {
    const response = await this.g2clib.g2cclient_tokenConditionalPropose({
      g2ctoken,
      destinationnick,
      nftuniqueidlist,
      tokenSerieId,
      permissions,
      expirationdatestamp,
      description,
    });
    //   results: [{
    //     transactionGroipId,
    //     conditionalTransferAuth,
    //     nftuniqueid,
    //     tokenSerieId,
    //   }]
    if (typeof response === 'undefined') {
      this.handleError(response, `transferTokenPropose`);
    } else if (response.hasOwnProperty('error')) return response;
    else return response.data;
  },
  async transferTokenAccept({
    g2ctoken,
    tokenSerieId,
    conditionalTransferAuth,
  }) {
    const response = await this.g2clib.g2c_tokenConditionalAccept({
      g2ctoken,
      tokenSerieId,
      conditionalTransferAuth,
    });
    //   data: {
    //     transactionGroipId,
    //     transfertxid,
    //     nftuniqueid,
    //     tokenSerieId,
    //   }
    if (response.hasOwnProperty('error') || typeof response === 'undefined') {
      this.handleError(response, 'transferTokenAccept');
    } else return response.data;
  },
  async transferTokenReject({
    g2ctoken,
    tokenSerieId,
    conditionalTransferAuth,
  }) {
    const response = await this.g2clib.g2c_tokenConditionalReject({
      g2ctoken,
      tokenSerieId,
      conditionalTransferAuth,
    });
    //   data: {
    //     transactionGroipId,
    //     transfertxid,
    //     nftuniqueid,
    //     tokenSerieId,
    //   }
    if (response.hasOwnProperty('error') || typeof response === 'undefined') {
      this.handleError(response, 'transferTokenAccept');
    } else return response.data;
  },

  /**
   * Delete token
   */

  async deleteToken({ g2ctoken, nftuniqueid, nftuniqueidlist, tokenSerieId }) {
    const response = await this.g2clib.g2cclient_tokenRedeem({
      g2ctoken,
      nftuniqueid,
      nftuniqueidlist,
      tokenSerieId,
    });
    console.log({ response });
    //   data: {
    //     transactionGroipId,
    //     transfertxid,
    //     nftuniqueid,
    //     tokenSerieId,
    //   }
    if (typeof response === 'undefined') {
      this.handleError(response, `deleteToken`);
    } else if (response.hasOwnProperty('error')) return response;
    else return response.data;
  },
});
