
import { SECOND, USER_OPTION_COMPANY, USER_OPTION_DEFAULT } from '~/vars/api';
export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      default: 'medium',
    },
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    showVerifiedUser() {
      return this.$api.commons.showVerifiedUser();
    },
    userFullName() {
      return this.user
        ? this.user.name
          ? `${this.user.name} ${this.user.surname}`
          : `@${this.user.nick}`
        : '';
    },
    icon() {
      return `mdi-${
        this.isEntity
          ? `${this.verified ? 'check' : 'alert'}-decagram-outline`
          : 'account-check-outline'
      }`;
    },
    iconSize() {
      const sizes = {
        large: '20px',
        medium: '20px',
        small: '16px',
      };
      return sizes[this.size];
    },
    verified() {
      return (
        this.user.is_verified && this.user.verification_status === 'complete'
      );
    },
    validated() {
      return this.user.is_validated;
    },
    showIcon() {
      return this.verified;
    },
    getTipContent() {
      return this.$t(
        `verification.base.${
          this.verified
            ? this.validated
              ? this.user.user_option === USER_OPTION_COMPANY
                ? 'validated_company'
                : 'validated'
              : this.user.user_option === USER_OPTION_COMPANY
              ? 'verified_company'
              : 'verified'
            : 'no_verified'
        }`,
      );
    },
    userOption() {
      return this.isEntity
        ? this.$t(
            `verification.base.entity_options.${this.user.user_option.toLowerCase()}`,
          )
        : this.$t(`base.user`);
    },
    isEntity() {
      return (
        this.user.user_option &&
        this.user.user_option.toLowerCase() !== USER_OPTION_DEFAULT
      );
    },
  },
  methods: {
    showTooltip() {
      if (this.$vuetify.breakpoint.mobile) {
        this.show = true;
        setTimeout(() => {
          this.show = false;
        }, 3 * SECOND);
      }
    },
  },
};
