import { SET_AUDIO_INPUT, SET_VIDEO_INPUT } from '../vars/store/mutations';

import { setAudioInput, setVideoInput } from '../vars/store/actions';

export const state = () => ({
  audioinput: '',
  videoinput: '',
});

export const getters = {
  getAudioInput: (state) => {
    return state.audioinput;
  },
  getVideoInput: (state) => {
    return state.videoinput;
  },
};

export const actions = {
  [setAudioInput]({ commit }, deviceId) {
    commit('SET_AUDIO_INPUT', deviceId);
  },
  [setVideoInput]({ commit }, deviceId) {
    commit('SET_VIDEO_INPUT', deviceId);
  },
};

export const mutations = {
  [SET_AUDIO_INPUT](state, deviceId) {
    state.audioinput = deviceId;
  },
  [SET_VIDEO_INPUT](state, deviceId) {
    state.videoinput = deviceId;
  },
};
