/* MARKETPLACE */
export const MARKETPLACE_NAME = 'Minta';
/* EXCHANGE RATES MODES */
export const EXCHANGE_RATE_MODE_SAT_TO_USD = 'SAT2USD';
export const EXCHANGE_RATE_MODE_USD_TO_SAT = 'USD2SAT';
export const EXCHANGE_RATE_MODE_BSV_TO_USD = 'BSV2USD';
export const EXCHANGE_RATE_MODE_USD_TO_BSV = 'USD2BSV';
export const EXCHANGE_RATE_MODE_SAT_TO_EUR = 'SAT2EUR';
export const EXCHANGE_RATE_MODE_EUR_TO_SAT = 'EUR2SAT';
export const EXCHANGE_RATE_MODE_BSV_TO_EUR = 'BSV2EUR';
export const EXCHANGE_RATE_MODE_EUR_TO_BSV = 'EUR2BSV';
export const EXCHANGE_RATE_MODE_GBP_TO_SAT = 'GBP2SAT';
export const EXCHANGE_RATE_MODE_BSV_TO_GBP = 'BSV2GBP';
export const EXCHANGE_RATE_MODE_GBP_TO_BSV = 'GBP2BSV';
export const EXCHANGE_BY_CURRENCY = {
  ['USD']: EXCHANGE_RATE_MODE_USD_TO_SAT,
  ['EUR']: EXCHANGE_RATE_MODE_EUR_TO_SAT,
};
export const EXCHANGE_TO_CURRENCY = {
  ['USD']: EXCHANGE_RATE_MODE_SAT_TO_USD,
  ['EUR']: EXCHANGE_RATE_MODE_SAT_TO_EUR,
};
/* COMMONS */
export const USER_PLATFORM = {
  nick: 'platformapp',
  name: `${MARKETPLACE_NAME} Marketplace App`,
  id: '0000',
  profile_image: {
    fullpath: '/svg/icons/crown_white.svg',
  },
};
export const LIMIT_USD_TRANSACTION = 1000;
export const LIMIT_UNVERIFIED_USD_TRANSACTION = 1000;
// export const WIVA_COMMISSION = 2; // 5% // *** pre-checkout-fees
export const MARKETPLACE_COMMISSION = 10; // 10% // *** pre-checkout-fees
export const EXCHANGE_COMMISSION = 5;
export const ROOM_APP_COMMISSION = 0.02;
export const TRANSACTION_FEE = 0.003;
export const BYTES_MINER_COMMISION = 0.6;
export const MINER_COMISSION = 1.001;
export const TRANSACTIONS_DATE_LAST_VERSION = 1622505600000; // Date and time (GMT): Tuesday, 1 June 2021 0:00:00
export const COMMISSION_TYPE_PAYMENT_EXTERNAL = 'directPaymentExternal';
export const COMMISSION_TYPE_PAYMENT_INTERNAL = 'directPayment';
export const MIN_AMOUNT_TO_CONNECT = 0.1;
export const EXTRA_MINER_MARGIN = 0.1;
export const MIN_BALANCE_MARGIN = 0.1;
export const MIN_BALANCE_SATS_MARGIN = 10000;
export const MIN_VALUE_TO_CALCULATE_RATE = 547;
export const BSV_TO_SAT_CONST = 100000000;
export const BOOKING_FEE_MINUTES = 5;
export const STREAMING_IN = 'ins';
export const STREAMING_OUT = 'outs';
export const SECOND = 1000;
export const MINUTE = 60 * SECOND;
export const WEEK = 7 * 24 * 60 * MINUTE;
export const NOT_ENOUGHT_BALANCE_ERROR = 'Not enought balance';
export const SESSION_WELCOME = 'welcome';
export const SESSION_LOGIN = 'login';
export const SESSION_SIGN_UP = 'signup';
export const SESSION_CREATE_ACCOUNT = 'create-account';
export const SESSION_RECOVER_PASSWORD = 'recover-password';
export const USER_LOGGED = 'logged';
export const USER_VERIFIED = 'verified';
export const USER_ONBOARDED = 'onboarded';
export const USER_ENTITY_ONBOARDED = 'entity_onboarded';
export const USER_ENTITY_VERIFIED = 'entity_verified';
export const USER_ACTION_LOGGED = 'logged';
export const USER_ACTION_VERIFY = 'verify';
export const USER_ACTION_NO_REQUIRED = 'default';
export const USER_ACTION_CONTRIBUTE = 'contribute';
export const USER_ACTION_CONNECT = 'connect';
export const USER_ACTION_ROOM = 'room';
export const USER_ACTION_PRODUCT_CREATE = 'product_create';
export const USER_ACTION_PRODUCT_LIST = 'product_list';
export const USER_ACTION_PRODUCT_DELIST = 'product_delist';
export const USER_ACTION_PRODUCT_TRANSFER = 'product_transfer';
export const USER_ACTION_PRODUCT_REDEEM = 'product_redeem';
export const USER_ACTION_PRODUCT_ACCEPT_CONDITIONAL = 'product_accept';
export const USER_ACTION_PRODUCT_REJECT_CONDITIONAL = 'product_reject';
export const USER_ACTION_PRODUCT_DOWNLOAD = 'product_preview';
export const USER_ACTION_REPORT = 'report';
export const USER_ACTION_PRODUCT_BUY = 'product_buy';
export const USER_ACTION_PRODUCT_BID = 'product_bid';
export const USER_ACTION_PRODUCT_FAV = 'product_fav';
export const USER_ACTION_FOLLOW = 'follow';
export const USER_ACTION_READ_TICKET = 'read_ticket';
export const USER_ACTION_CREATE_TICKET = 'create_ticket';
export const USER_ACTION_REQUIRED = {
  [USER_ACTION_NO_REQUIRED]: {
    [USER_LOGGED]: false,
    [USER_VERIFIED]: false,
    [USER_ENTITY_VERIFIED]: false,
    [USER_ONBOARDED]: false,
    [USER_ENTITY_ONBOARDED]: false,
  },
  [USER_ACTION_LOGGED]: {
    [USER_LOGGED]: true,
    [USER_VERIFIED]: false,
    [USER_ENTITY_VERIFIED]: false,
    [USER_ONBOARDED]: false,
    [USER_ENTITY_ONBOARDED]: false,
  },
  [USER_ACTION_VERIFY]: {
    [USER_LOGGED]: true,
    [USER_VERIFIED]: true,
    [USER_ENTITY_VERIFIED]: true,
    [USER_ONBOARDED]: false,
    [USER_ENTITY_ONBOARDED]: false,
  },
  [USER_ACTION_CONTRIBUTE]: {
    [USER_LOGGED]: true,
    [USER_VERIFIED]: false,
    [USER_ENTITY_VERIFIED]: false,
    [USER_ONBOARDED]: false,
    [USER_ENTITY_ONBOARDED]: false,
  },
  [USER_ACTION_CONNECT]: {
    [USER_LOGGED]: true,
    [USER_VERIFIED]: false,
    [USER_ENTITY_VERIFIED]: true,
    [USER_ONBOARDED]: false,
    [USER_ENTITY_ONBOARDED]: false,
  },
  [USER_ACTION_ROOM]: {
    [USER_LOGGED]: true,
    [USER_VERIFIED]: false,
    [USER_ENTITY_VERIFIED]: true,
  },
  [USER_ACTION_PRODUCT_CREATE]: {
    [USER_LOGGED]: true,
    [USER_VERIFIED]: true,
    [USER_ENTITY_VERIFIED]: true,
    [USER_ONBOARDED]: false,
    [USER_ENTITY_ONBOARDED]: false,
  },
  [USER_ACTION_PRODUCT_LIST]: {
    [USER_LOGGED]: true,
    [USER_VERIFIED]: false,
    [USER_ENTITY_VERIFIED]: false,
    [USER_ONBOARDED]: true,
    [USER_ENTITY_ONBOARDED]: true,
  },
  [USER_ACTION_PRODUCT_TRANSFER]: {
    [USER_LOGGED]: true,
    [USER_VERIFIED]: false,
    [USER_ENTITY_VERIFIED]: false,
    [USER_ONBOARDED]: false,
    [USER_ENTITY_ONBOARDED]: false,
  },
  [USER_ACTION_PRODUCT_REDEEM]: {
    [USER_LOGGED]: true,
    [USER_VERIFIED]: true,
    [USER_ENTITY_VERIFIED]: true,
    [USER_ONBOARDED]: false,
    [USER_ENTITY_ONBOARDED]: false,
  },
  [USER_ACTION_PRODUCT_ACCEPT_CONDITIONAL]: {
    [USER_LOGGED]: true,
    [USER_VERIFIED]: true,
    [USER_ENTITY_VERIFIED]: true,
  },
  [USER_ACTION_PRODUCT_REJECT_CONDITIONAL]: {
    [USER_LOGGED]: true,
    [USER_VERIFIED]: true,
    [USER_ENTITY_VERIFIED]: true,
  },
  [USER_ACTION_PRODUCT_DELIST]: {
    [USER_LOGGED]: true,
    [USER_VERIFIED]: true,
    [USER_ENTITY_VERIFIED]: true,
    [USER_ONBOARDED]: true,
    [USER_ENTITY_ONBOARDED]: true,
  },
  [USER_ACTION_PRODUCT_DOWNLOAD]: {
    [USER_LOGGED]: true,
    [USER_VERIFIED]: false,
    [USER_ENTITY_VERIFIED]: false,
    [USER_ONBOARDED]: false,
    [USER_ENTITY_ONBOARDED]: false,
  },
  [USER_ACTION_REPORT]: {
    [USER_LOGGED]: true,
    [USER_VERIFIED]: true,
    [USER_ENTITY_VERIFIED]: true,
    [USER_ONBOARDED]: false,
    [USER_ENTITY_ONBOARDED]: false,
  },
  [USER_ACTION_PRODUCT_BUY]: {
    [USER_LOGGED]: true,
    [USER_VERIFIED]: false,
    [USER_ENTITY_VERIFIED]: false,
    [USER_ONBOARDED]: false,
    [USER_ENTITY_ONBOARDED]: false,
  },
  [USER_ACTION_PRODUCT_BID]: {
    [USER_LOGGED]: true,
    [USER_VERIFIED]: false,
    [USER_ENTITY_VERIFIED]: false,
    [USER_ONBOARDED]: false,
    [USER_ENTITY_ONBOARDED]: false,
  },
  [USER_ACTION_PRODUCT_FAV]: {
    [USER_LOGGED]: true,
    [USER_VERIFIED]: false,
    [USER_ENTITY_VERIFIED]: true,
    [USER_ONBOARDED]: false,
    [USER_ENTITY_ONBOARDED]: false,
  },
  [USER_ACTION_FOLLOW]: {
    [USER_LOGGED]: true,
    [USER_VERIFIED]: false,
    [USER_ENTITY_VERIFIED]: true,
    [USER_ONBOARDED]: false,
    [USER_ENTITY_ONBOARDED]: false,
  },
  [USER_ACTION_READ_TICKET]: {
    [USER_LOGGED]: true,
    [USER_VERIFIED]: true,
    [USER_ENTITY_VERIFIED]: true,
    [USER_ONBOARDED]: false,
    [USER_ENTITY_ONBOARDED]: false,
  },
  [USER_ACTION_CREATE_TICKET]: {
    [USER_LOGGED]: true,
    [USER_VERIFIED]: true,
    [USER_ENTITY_VERIFIED]: true,
    [USER_ONBOARDED]: false,
    [USER_ENTITY_ONBOARDED]: false,
  },
};
export const DATE_MASK = 'DD-MM-YYYY';
export const DATE_MASK_DATE_PICKER = 'YYYY-MM-DD';
export const BULK_TOKEN = 50;
/* ACCOUNT */
export const USER_OPTION_DEFAULT = 'individual';
export const USER_OPTION_COMPANY = 'company';
export const USER_OPTIONS = [USER_OPTION_DEFAULT, USER_OPTION_COMPANY];
export const STATUS_AVAILABLE = 'available';
export const STATUS_INVISIBLE = 'invisible';
export const USER_WIVA_STATUS = [STATUS_AVAILABLE, STATUS_INVISIBLE];
export const INVESTMENT_AMOUNTS = [
  '1€ - 10.000€',
  '10.000€ - 50.000€',
  '50.000€ - 100.000€',
  '100.000€ - 500.000€',
  '+ 500.000€',
];
export const INVESTORS_TYPE = ['Individual', 'Company'];
export const TRANSACTION_TRANSFER_TO_MAKE = 'tomake';
export const TRANSACTION_PENDING = 'pending';
export const TRANSACTION_COMPLETED = 'completed';
export const TRANSACTION_WITHDRAWALS = 'outs';
export const TRANSACTION_DEPOSITS = 'ins';
export const PUBLIC_PROFILE_PURCHASE_CODES = {
  ['Video']: 'video',
  ['Visits']: 'visits_counter',
};
/* CONNECTIONS */
export const G2C_CONNECTIONS_TYPE = 'userConnection';
export const G2C_CONNECTIONS_ENCODING = 5;
export const G2C_CONNECTIONS_PERMISSIONS = 2;
export const G2C_CONNECTIONS_COMMISION_TYPE = 'userConnectionCommission';
export const CONNECTION_STATUS_PRECREATED = 'precreated';
export const CONNECTION_STATUS_PRECHARGED = 'precharged';
export const CONNECTION_STATUS_PREPAID = 'prepaid';
export const CONNECTION_STATUS_PRESHARED = 'preshared';
export const CONNECTION_STATUS_ACCEPTED = 'accepted';
export const CONNECTION_STATUS_PENDING = 'pending';
export const CONNECTION_STATUS_REJECTED = 'rejected';
export const CONNECTION_STATUS_NO_CONNECTION = 'no_connection';
export const CONNECTION_STATUS_DISCONNECTED = 'disconnected';
export const TIME_TO_HANDLE_REQUEST = 1000 * 60 * 60 * 24 * 14;
/* MESSAGES */
export const MESSAGES_PER_PAGE = 10;
export const G2C_MESSAGES_TYPE = 'userMessage';
export const G2C_MESSAGES_ENCODING = 5;
export const G2C_MESSAGES_PERMISSIONS = 2;
export const G2C_MESSAGES_COMMISION_TYPE = 'userMessagesCommission';
export const MESSAGES_RESPONSE = 'response';
export const MESSAGES_REQUEST = 'request';
export const MESSAGES_STREAM_IN = 'msgin';
export const MESSAGES_STREAM_OUT = 'msgout';
export const MESSAGES_TYPE_FREE = 'volatile';
export const MESSAGES_TYPE_PAID = 'paid';
export const MESSAGES_TYPE_PRIVACY = 'privacy';
export const MESSAGES_STATUS_CREATING = 'creating';
export const MESSAGES_STATUS_ACCEPTED = 'accepted';
export const MESSAGES_STATUS_PENDING = 'pending';
export const MESSAGES_STATUS_REJECTED = 'rejected';
export const MESSAGES_STATUS_PREPAID = 'prepaid';
export const MESSAGES_STATUS_PRECREATED = 'precreated';
export const MESSAGES_STATUS_PRECHARGED = 'precharged';
export const MESSAGES_STATUS_PRESHARED = 'preshared';
export const MESSAGES_STATUS_CREATED = 'created';
export const MESSAGES_EXPIRATION_VALUE = 10;
export const MESSAGES_EXPIRATION_MEASURE = 'days';
/* MY ASSETS */
export const DOC_TYPE_NIF = { key: 'nif', value: 0 };
export const DOC_TYPE_PASSPORT = { key: 'passport', value: 1 };
export const DOC_TYPE_DRIVE = { key: 'drive', value: 2 };

/* NOTIFICATIONS */
export const NOTIFICATIONS_ICON_BY_ACTIVITY = {
  direct_payment: 'cash-multiple',
  connection_request: 'forum',
  connection_accepted: 'forum',
  connection_rejected: 'forum',
  connection_canceled: 'forum',
  message_accepted: 'forum',
  message_rejected: 'forum',
  message_request: 'forum',
  purchase_wiva: 'shape-outline',
  contribution_wiva: 'diamond-stone',
  room_accepted: 'account-group',
  room_rejected: 'account-group',
  room_canceled: 'account-group',
  room_request: 'account-group',
  room_pending: 'account-group',
  room_started: 'account-group',
  market_bidcreated: 'shape-outline',
  market_ordercreated: 'diamond-stone',
  market_orderpaid: 'cash-multiple',
  market_transfer: 'geometric_primary',
  market_invitation: 'geometric_primary',
};
export const NOTIFICATIONS_ACTIVITIES = {
  direct: 'Wallet',
  contribution: 'Contribution',
  connection: 'Connections',
  message: 'Messages',
  room: 'Rooms',
  market: 'Market',
  asset: 'My Tokens',
};
export const ASSET = 'asset';
export const MINTER = 'minter';
export const MY_ROOMS = 'myRooms';
export const PUBLIC_ROOMS = 'publicRooms';
export const INVITATIONS_ROOMS = 'invitations';
export const URI_WALLET = 'account-activity';
export const URI_CONNECTIONS = 'connections-requests';
export const URI_MESSAGES = 'messages-id';
export const ROOMS_HOSTED = 'hosted';
export const URI_ROOMS_HOSTED = 'rooms-myrooms';
export const URI_ROOMS_PRIVATE = 'rooms-rooms';
export const URI_ROOMS_PUBLIC = 'rooms-rooms';
export const URI_WALLET_DATA = 'account-activity-market';
export const URI_MARKET_MYBIDS = 'market-preview-mybids';
export const URI_MARKET_ITEM = 'market-product-id';
export const URI_ASSET_ITEM = 'market-product-id';
export const URI_MINTER_STATUS = 'stripe-onboarding-minter';
export const NOTIFICATIONS_PATH_BY_ACTIVITY = {
  direct_payment: { name: URI_WALLET },
  connection_request: {
    name: URI_CONNECTIONS,
    params: {},
  },
  connection_accepted: {
    name: URI_CONNECTIONS,
    params: {},
  },
  connection_rejected: {
    name: URI_CONNECTIONS,
    params: {},
  },
  message: { name: URI_MESSAGES, params: { dynamic: 'id' } },
  contribution_wiva: { name: URI_WALLET },
  purchase_wiva: { name: URI_WALLET },
  room_hosted: {
    name: URI_ROOMS_HOSTED,
    params: {},
  },
  room_private: {
    name: URI_ROOMS_PRIVATE,
    params: { tag: INVITATIONS_ROOMS },
  },
  room_public: {
    name: URI_ROOMS_PUBLIC,
    params: {},
  },
  market_bidcreated: {
    name: URI_MARKET_ITEM,
    params: { dynamic: 'id' },
  },
  market_bidcreated_client: {
    name: URI_MARKET_ITEM,
    params: { dynamic: 'id' },
  },
  market_bidcreated_owner: {
    name: URI_MARKET_ITEM,
    params: { dynamic: 'id' },
  },
  market_ordercreated: {
    name: URI_MARKET_MYBIDS,
    params: {},
  },
  market_ordercancelled: {
    name: URI_MARKET_ITEM,
    params: { dynamic: 'id' },
  },
  market_orderpaid: {
    name: URI_WALLET_DATA,
    params: {},
  },
  market_royaltypaid: {
    name: URI_WALLET_DATA,
    params: {},
  },
  minter_pending: {
    name: URI_MINTER_STATUS,
    params: {},
  },
  asset_transfer: {
    name: URI_ASSET_ITEM,
    params: { dynamic: 'id' },
  },
  market_auction_win: {
    name: URI_MARKET_ITEM,
    params: { dynamic: 'id' },
  },
  market_auction_notwin: {
    name: URI_MARKET_ITEM,
    params: { dynamic: 'id' },
  },
  market_auction_substitute: {
    name: URI_MARKET_ITEM,
    params: { dynamic: 'id' },
  },
  market_invitation: {
    name: URI_MARKET_ITEM,
    params: { dynamic: 'id' },
  },
  market_newoffering: {
    name: URI_MARKET_ITEM,
    params: { dynamic: 'id' },
  },
  update_order: {
    name: URI_MARKET_MYBIDS,
    params: {},
  },
};
/* ROOM COMMONS */
export const G2C_ROOM_COMMISION_TYPE = 'userEmeetingCommission';
export const ROOM_TYPE_RECEIVED = 'received';
export const ROOM_TYPE_SENT = 'sent';
export const ROOM_TYPE_ROOMS = 'room';
export const ROOM_STATUS_ACCEPTED = 'accepted';
export const ROOM_STATUS_WAITING_ROOM = 'waitingroom';
export const ROOM_STATUS_REQUESTS = 'requests';
export const ROOM_STATUS_REQUEST = 'request'; // FOR PARTICIPANT
export const ROOM_STATUS_PENDING = 'pending';
export const ROOM_STATUS_REJECTED = 'rejected';
export const ROOM_STATUS_IN_PROGRESS = 'in-progress';
export const ROOM_STATUS_COMPLETED = 'completed';
export const ROOM_STATUS_CANCELED = 'canceled';
export const ROOM_MODE_FULL = 'full';
export const ROOM_MODE_ROOM_DETAILS = 'room';
export const ROOM_MODE_PARTICIPANTS = 'participants';
export const ROOM_PAYMENT_CHANNEL_PRE_PAID_MINUTES = BOOKING_FEE_MINUTES - 1;
export const ROOM_GUARANTEE_TIME = BOOKING_FEE_MINUTES * MINUTE;
export const ROOM_HOST = 'host';
export const ROOM_PARTICIPANT = 'participant';
export const CHANNEL_STATUS_OPEN = 'open';
export const CHANNEL_STATUS_CLOSE = 'close';
export const TWILIO_TRACK_AUDIO = 'audio';
export const TWILIO_TRACK_VIDEO = 'video';
export const TWILIO_TRACK_SCREEN_SHARE = 'screenShare';
export const ROOM_MODAL_CLOSE = 'endroom';
export const ROOM_MODAL_SETTINGS = 'roomSettings';
export const PAYMENT_DONE_EARNED = 'earned';
export const PAYMENT_DONE_PAID = 'paid';
export const ROOM_CHECK_HOST_INTERVAL = 10000; //10 SECS
export const PAYMENT_TYPE_PAY = 'pay';
export const PAYMENT_TYPE_PAYED = 'payed';
export const PAYMENT_TYPE_EARNED = 'earned';
/* ROOM */
export const ROOM_MIN_PRICE_PER_MINNUTE = 0.1;
export const ROOM_MIN_DURATION = 10;
export const ROOM_MAX_DURATION = 120;
export const ROOM_ACCESS_TYPE_PRIVATE = 'private';
export const ROOM_ACCESS_TYPE_PUBLIC = 'public';
export const ROOM_PAYMENT_EARN = 'earn';
export const ROOM_PAYMENT_PAY = 'pay';
export const ROOM_COST_RECORD = 0.05;
export const IOS_AGENT_WARNING = 'iPhone OS 15_1';
/* MARKET */
export const PAGINATION_LIMIT = 10;
export const TOKENS_V2_DATESTAMP = 1654185600000; // 2022-06-02 16.00 GMT
export const MIN_AMOUNT_TRANSACTION = 0.1;
export const G2C_MARKET_OBJECT_PATH = 'tokens';
export const G2C_MARKET_TYPE = 'userMarket';
export const G2C_MARKET_ENCODING = 1;
export const G2C_MARKET_PERMISSIONS = 2;
export const G2C_BID_COMMISION_TYPE = 'userBidCommission';
export const G2C_MARKET_COMMISION_TYPE = 'userMarketCommission';
export const G2C_ROYALTY_COMMISION_TYPE = 'userRoyaltyCommission';
export const G2C_BENEFICIARY_COMMISION_TYPE = 'userBeneficiaryCommission';
export const G2C_PURCHASE_COMMISION_TYPE = 'userPurchaseCommission';
export const MARKET_PURCHASE_MIN_NO_ROYALTY = 1;
export const MARKET_PURCHASE_MIN_ROYALTY = 5;
//
// creator = 4.500 || fee-creator = 500 || seller = 40500 || fee-seller = 4500
export const MARKET_PURCHASE_SATS_MIN_NO_ROYALTY = 5000;
// seller = 4.500 || fee-seller = 500
export const MARKET_PURCHASE_SATS_MIN_ROYALTY = 50000;
//
export const MARKET_PURCHASE_MIN_ROYALTY_FIAT = 10;
export const OFFERING_MIN_DEPOSIT_SATS = 1000;
export const OFFERING_MIN_DEPOSIT_FIAT = 0.1;
export const ROYALTY_TYPE = 'percent';
export const ROYALTY_UNIT = '%';
export const ROYALTY_MAX = 50;
export const ROYALTY_MIN = 10;
export const BENEFICIARY_TYPE = 'percent';
export const BENEFICIARY_UNIT = '%';
export const BENEFICIARY_MAX = 100;
export const PRODUCT_OWNER = 'owner';
export const PRODUCT_SELLER = 'seller';
export const PRODUCT_CREATOR = 'minter';
export const OFFERING_TYPE_FULL = 'fullprice';
export const OFFERING_TYPE_DEPOSIT = 'deposit';
export const OFFERING_ACCESS_TYPE_PRIVATE = 'private';
export const OFFERING_ACCESS_TYPE_PUBLIC = 'public';
export const GROUP_TYPE_AVAILABLE = 'available';
export const GROUP_TYPE_OFFERING = 'offering';
export const GROUP_TYPE_INVOLVED = 'involved';
export const GROUP_TYPE_REDEEMED = 'redeemed';
export const MARKET_ITEM_PRODUCT = 'myProducts';
export const MARKET_ITEM_OFFERING = 'offerings';
export const MARKET_LIST_MY_OFFERING = 'myOfferings';
export const MARKET_LIST_MY_BID = 'myBids';
export const BID_TYPE_OFFERING = 'bid';
export const BID_TYPE_BUY = 'buynow';
export const BID_TYPE_TRANSFER = 'transfer';
export const BID_TYPE_ISSUING = 'mint';
export const CONDITIONAL_ACCEPT_ANY = 0x0000; // Any user can accept the conditional transfer
export const CONDITIONAL_ACCEPT_SRC = 0x0001; // Only sourcenick can accept the conditional transfer
export const CONDITIONAL_ACCEPT_DST = 0x0002; // Only destinationnick can accept the conditional transfer
export const CONDITIONAL_ACCEPT_APP = 0x0004; // Only application owner can accept the conditional transfer
export const CONDITIONAL_REJECT_ANY = 0x0000; // Any user can reject the conditional transfer
export const CONDITIONAL_REJECT_SRC = 0x0100; // Only sourcenick can reject the conditional transfer
export const CONDITIONAL_REJECT_DST = 0x0200; // Only destinationnick can reject the conditional transfer
export const CONDITIONAL_REJECT_APP = 0x0400; // Only application owner can reject the conditional transfer
export const PERMISSIONS_CONDITIONAL =
  CONDITIONAL_ACCEPT_APP + CONDITIONAL_REJECT_APP;
export const PERMISSIONS_TRANSFER =
  CONDITIONAL_REJECT_SRC + CONDITIONAL_ACCEPT_APP + CONDITIONAL_REJECT_APP;
export const CURRENCY_FORMAT_CONFIGURATION = {
  thousandsSeparator: '.',
  fractionCount: 2,
  fractionSeparator: ',',
  symbolPosition: 'front',
  symbolSpacing: false,
  avoidEmptyDecimals: '00',
};
export const CURRENCIES = {
  ['usd']: {
    iso: 'USD',
    symbol: '$',
    country: 'us',
    currency: 'United States dollar',
  },
  ['USD']: {
    iso: 'USD',
    symbol: '$',
    country: 'us',
    currency: 'United States dollar',
  },
  ['EUR']: {
    iso: 'EUR',
    symbol: '€',
    country: 'eu',
    currency: 'Euro',
  },
  ['eur']: {
    iso: 'EUR',
    symbol: '€',
    country: 'eu',
    currency: 'Euro',
  },
  ['GBP']: {
    iso: 'GBP',
    symbol: '£',
    country: 'uk',
    currency: 'Pound',
  },
  ['gbp']: {
    iso: 'GBP',
    symbol: '£',
    country: 'uk',
    currency: 'Pound',
  },
  ['Satoshi']: {
    iso: 'Satoshi',
    symbol: 'Sats',
    country: 'eu',
    currency: 'BSV Satoshi',
    src: 'https://firebasestorage.googleapis.com/v0/b/networksv-prod.appspot.com/o/uploads%2Fprofile-images%2F052b7da9-6b3b-4b7f-85e7-07ab191fc02c.png',
  },
  ['satoshi']: {
    iso: 'Satoshi',
    symbol: 'Sats',
    country: 'eu',
    currency: 'BSV Satoshi',
    src: 'https://firebasestorage.googleapis.com/v0/b/networksv-prod.appspot.com/o/uploads%2Fprofile-images%2F052b7da9-6b3b-4b7f-85e7-07ab191fc02c.png',
  },
};
/* AUTH STRATEGIES */
export const APP_AUTH_STRATEGY_G2C = 'g2c_user';
/* OPERATION TYPES */
export const CONNECTION = 'connection';
export const ROOMS = 'rooms';
export const ROOM = 'room';
export const MESSAGE = 'message';
export const MARKET = 'market';
export const CARD = 'card';
/* FUNCTIONS */
export const RANDOM_STRING_LENGTH = {
  2: 10,
  3: 9,
  4: 8,
  5: 7,
  6: 6,
  7: 5,
  8: 4,
  9: 3,
  10: 2,
  11: 1,
};

/* EXTENDED CLASSES */
export const CUSTOM_ERROR = 'CustomError';
export class CustomError extends Error {
  constructor(...params) {
    // For the rest of params (even provider's specific) to constructor
    super(...params);
    // Keep stack trace from where throw our error (only available in V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, CustomError);
    }

    this.name = CUSTOM_ERROR;
    this.date = new Date();
  }
}
