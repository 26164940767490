import { render, staticRenderFns } from "./LayoutTopBar.vue?vue&type=template&id=0a1acaf1&"
import script from "./LayoutTopBar.vue?vue&type=script&lang=js&"
export * from "./LayoutTopBar.vue?vue&type=script&lang=js&"
import style0 from "./LayoutTopBar.vue?vue&type=style&index=0&id=0a1acaf1&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseIconNew: require('/tmp/build_fc5c3f7c/components/base/BaseIconNew.vue').default,CompanyLogo: require('/tmp/build_fc5c3f7c/components/company/CompanyLogo.vue').default,LayoutVerifyBtn: require('/tmp/build_fc5c3f7c/components/layout/LayoutVerifyBtn.vue').default,LayoutTopBarWalletBtn: require('/tmp/build_fc5c3f7c/components/layout/LayoutTopBarWalletBtn.vue').default,LayoutNotificationsBtn: require('/tmp/build_fc5c3f7c/components/layout/LayoutNotificationsBtn.vue').default,LayoutTopBarAccountBtn: require('/tmp/build_fc5c3f7c/components/layout/LayoutTopBarAccountBtn.vue').default,BaseBtn: require('/tmp/build_fc5c3f7c/components/base/BaseBtn.vue').default})
