export default {
  info: {},
  direct_payment: '',
  connection: {
    request: 'Request connection received',
    accepted: 'Sent connection accepted',
    rejected: 'Connection rejected',
  },
  message: {
    request: 'Message request pending',
    accepted: 'Message sent accepted',
    rejected: 'Message sent rejected',
  },
  one2one: {
    request: 'One to one request pending',
    accepted: 'Request One to One accepted',
    rejected: 'Request One to One canceled',
    canceled: 'Request One to One canceled',
  },
};
