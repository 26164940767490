
export default {
  props: {
    counter: {
      type: Number,
    },
    isSidebar: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    controlCounter() {
      return this.counter < 100
        ? this.counter
        : this.$t('base.number_of_notifications');
    },
  },
};
