const CHAT_STORE = 'chats';
const CONNECTIONS_MESSAGES_STORE = 'connectionsMessages';
const DUMMY_STORE = 'dummyStore';
class MpDB {
  constructor() {
    this.db = null;
  }

  static get STORES() {
    return [CHAT_STORE, CONNECTIONS_MESSAGES_STORE, DUMMY_STORE];
  }

  /**
   * COMMONS
   */

  async waitingUntilConnectToDB() {
    return new Promise((resolve, reject) => {
      const newInterval = setInterval(() => {
        if (this.db !== null) {
          clearInterval(newInterval);
          resolve();
        }
      }, 500);
    });
  }

  async connect(loggedUserId) {
    return new Promise((resolve, reject) => {
      if (this.db && this.db.name === loggedUserId) {
        resolve(this);
        return;
      }
      const req = indexedDB.open(loggedUserId);
      console.log({ req });
      req.onupgradeneeded = (event) => {
        const db = event.target.result;
        MpDB.STORES.forEach((store) =>
          db.createObjectStore(store, {
            keyPath: 'id',
            autoIncrement: true,
          }),
        );
      };

      req.onsuccess = (event) => {
        this.db = event.target.result;
        //this.deletePreviousDB(loggedUserId);
        resolve(this);
      };
    });
  }

  async deletePreviousDB(loggedUserId) {
    if (indexedDB) {
      const dbs = await window.indexedDB.databases();
      dbs.forEach(({ name }) => {
        if (name !== loggedUserId) {
          window.indexedDB.deleteDatabase(name);
        }
      });
    }
  }

  /**
   * CRUD
   */

  async add(store, register) {
    return new Promise((resolve, reject) => {
      const req = this.getStore(store).add(register);
      req.onsuccess = () => {
        register.id = req.result;
        resolve(register);
      };
      req.onerror = () => {
        reject(this);
      };
    });
  }

  async addRegisters(toStore, registers) {
    await this.waitingUntilConnectToDB();
    return new Promise((resolve, reject) => {
      const store = this.getStore(toStore);
      registers.forEach((register) => {
        const req = store.add(register);
        req.onerror = () => {
          reject(this);
        };
      });
      resolve();
    });
  }

  getTransaction(stores, readWrite = 'readwrite') {
    return this.db.transaction(stores, readWrite);
  }

  getStore(store) {
    return this.getTransaction([store]).objectStore(store);
  }

  async getAll(fromStore) {
    await this.waitingUntilConnectToDB();
    return new Promise((resolve, reject) => {
      let values = [];
      const store = this.getStore(fromStore);
      store.openCursor().onsuccess = (event) => {
        let cursor = event.target.result;
        if (cursor) {
          values.push(cursor.value);
          return cursor.continue();
        }
        resolve(values);
      };
      store.openCursor().onerror = () => {
        reject(this);
      };
    });
  }

  async put(toStore, register) {
    return new Promise((resolve, reject) => {
      const req = this.getStore(toStore).put(register);
      req.onsuccess = (data) => {
        resolve();
      };
      req.onerror = (data) => {
        reject(this);
      };
    });
  }

  remove(store, registerId) {
    this.getStore(store).delete(registerId);
  }
  /*
  addDummySentence(userId) {
    return this.addRegisters(DUMMY_STORE, [
      { id: 1, value: `user with id: ${userId} was log in` },
    ]);
  }
  getAllDummySentences() {
    return this.getAll(DUMMY_STORE);
  }
  */

  /**
   * MESSAGES
   */
  getAllChats() {
    return this.getAll(CHAT_STORE);
  }
  addChats(chats) {
    return this.addRegisters(CHAT_STORE, chats);
  }
  putChat(chat) {
    return this.put(CHAT_STORE, chat);
  }
  /**
   * CONNECTIONS
   */
  getAllConnectionMsgs() {
    return this.getAll(CONNECTIONS_MESSAGES_STORE);
  }
  updateConnectionMsg(msg) {
    return this.put(CONNECTIONS_MESSAGES_STORE, msg);
  }
  addConnectionMsgs(msgs) {
    return this.addRegisters(CONNECTIONS_MESSAGES_STORE, msgs);
  }
}

const mpDB = new MpDB();

export default mpDB;
