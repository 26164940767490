
// import { showBuySatsByBankModal } from '~/vars/store/actions';
// import BuyByBankModal from './BuyByBankModal.vue';
export default {
  // components: { BuyByBankModal },
  props: {
    button: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    text: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: 'mdi-plus',
    },
    color: {
      type: String,
      default: 'primary',
      validator: (val) =>
        ['primary', 'secondary', 'tertiary', 'cuartiary', 'success'].indexOf(
          val,
        ) !== -1,
    },
    newTab: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      optionSendHover: false,
      optionBuyHover: false,
      optionActive: null,
      optionText: '',
      paymailCopied: false,
    };
  },
  computed: {
    getPaymail() {
      return `${this.$auth.user.data.nick}@${this.$nuxt.context.env.g2clibApplication}`;
    },
    boxHeight() {
      return this.$vuetify.breakpoint.mdAndUp ? '200px' : '150px';
    },
    copiedBottom() {
      return this.$vuetify.breakpoint.mdAndUp ? '12px' : '0px';
    },
    paymailHover() {
      if (this.paymailCopied) {
        return {
          icon: 'tick',
          title: this.$t('wallet.add_funds.box.send.copied'),
        };
      }
      return {
        icon: 'copy_add_sats',
        title: this.$t('wallet.add_funds.box.send.copy'),
      };
    },
  },
  watch: {
    dialog(show) {
      if (!show) {
        this.paymailCopied = false;
      }
    },
  },
  methods: {
    onCopy(e) {
      this.paymailCopied = true;
    },
    onError(e) {
      alert('Failed to copy texts');
    },
    // redirect() {
    //   this.$toast.show('Buy sats with credit card will be able soon!');
    // *** pre-checkout-fees
    // if (this.newTab === true)
    //   window
    //     .open(this.localePath({ name: 'exchange-buy' }), '_blank')
    //     .focus();
    // else this.$router.push(this.localePath({ name: 'exchange-buy' }));
    // this.dialog = false;
    // },
  },
};
