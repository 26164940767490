
import { USER_OPTION_COMPANY } from '~/vars/api';
export default {
  computed: {
    isCompany() {
      return this.$auth.user.data.user_option === USER_OPTION_COMPANY;
    },
    onlyCompanyMinter() {
      return this.$api.commons.onlyCompanyMinter();
    },
    showVerifyBtn() {
      return (
        (!this.onlyCompanyMinter ||
          (this.onlyCompanyMinter && this.isCompany)) &&
        this.$auth.loggedIn &&
        this.$api.commons.vipProfile(this.$auth.user.data.nick)
      );
    },
  },
};
