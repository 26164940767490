import { showReportModal } from '~/vars/store/actions';
import {
  SET_INFO_REPORT_MODAL,
  TOGGLE_SHOW_REPORT_MODAL,
} from '~/vars/store/mutations';

export const state = () => ({
  info: null,
  showReportModal: false,
});

export const getters = {
  info: (state) => state.info,
  showReportModal: (state) => state.showReportModal,
};

export const actions = {
  [showReportModal]({ commit }, { show, info }) {
    commit(SET_INFO_REPORT_MODAL, info);
    commit(TOGGLE_SHOW_REPORT_MODAL, show);
  },
};

export const mutations = {
  [SET_INFO_REPORT_MODAL](state, info) {
    state.info = info;
  },
  [TOGGLE_SHOW_REPORT_MODAL](state, show) {
    state.showReportModal = show;
  },
};
