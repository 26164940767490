import { PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH } from '~/vars/session';

export default ({ app }, inject) => {
  const rules = {
    nick: [(value) => !!value || app.i18n.t('forms.validation.nick')],
    password: [
      (value) => !!value || app.i18n.t('forms.validation.password.required'),
      (value) =>
        (value && value.length >= PASSWORD_MIN_LENGTH) ||
        app.i18n.t('forms.validation.password.length'),
      (value) =>
        (value && value.length <= PASSWORD_MAX_LENGTH) ||
        app.i18n.t('forms.validation.password.max_length'),
    ],
    passwordLogin: [
      (value) => !!value || app.i18n.t('forms.validation.password.required'),
    ],
    surname: [(value) => !!value || app.i18n.t('forms.validation.surname')],
    image: [
      (value) =>
        !value ||
        value.size < 8388608 ||
        app.i18n.t('forms.validation.image.size'),
    ],
    title: [
      (value) =>
        !value ||
        (value && value.length <= 20) ||
        app.i18n.t('forms.validation.title.max_length'),
    ],
    userTitle: [
      (value) =>
        !value ||
        (value && value.length <= 25) ||
        app.i18n.t('forms.validation.title.user_max_length'),
    ],
    companyTitle: [
      (value) =>
        !value ||
        (value && value.length <= 60) ||
        app.i18n.t('forms.validation.title.company_max_length'),
    ],
    summary: [
      (value) =>
        !value ||
        (value && value.length <= 500) ||
        app.i18n.t('forms.validation.summary.max_length'),
    ],
    userBankingAccount: [
      (value) =>
        !!value || app.i18n.t('forms.validation.banking_account.required'),
    ],
    userBankingName: [
      (value) =>
        !!value || app.i18n.t('forms.validation.banking_name.required'),
    ],
    amount: [
      (value) => !!value || app.i18n.t('forms.validation.amount.required'),
      (value) =>
        value >= 0.1 || app.i18n.t('forms.validation.amount.valid_min'),
    ],
    address: [
      (value) => !!value || app.i18n.t('forms.validation.address.required'),
      (value) =>
        /^[a-zA-Z0-9]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)$/.test(
          value,
        ) || app.i18n.t('forms.validation.address.valid'),
    ],
    email: [
      (value) =>
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          value,
        ) || app.i18n.t('mailing_invitation.validation.email.valid'),
    ],
    messageSupport: [
      (value) => !!value || app.i18n.t('support.validation.message.required'),
    ],
    message: [
      (value) =>
        !!value || app.i18n.t('mailing_invitation.validation.message.required'),
    ],
    reason: [
      (value) => !!value || app.i18n.t('report.validation.reason.required'),
    ],
    observations: [
      (value) =>
        !!value || app.i18n.t('report.validation.observations.required'),
    ],
    phone: [(value) => !!value || app.i18n.t('forms.error.phone.required')],
    registerEmail: [
      (value) => !!value || app.i18n.t('forms.error.email.required'),
      (value) =>
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          value,
        ) || app.i18n.t('forms.error.email.valid'),
    ],
    objectName: [
      (value) =>
        (value && value.length <= 20) ||
        app.i18n.t('market.create_product.validation.title.required'),
      (value) =>
        /^[a-z0-9\-]+$/.test(value) ||
        app.i18n.t('market.create_product.validation.title_object'),
    ],
    productTitle: [
      (value) =>
        (value && /(?!^\s+$)^.*$/m.test(value)) ||
        app.i18n.t('market.create_product.validation.title.required'),
      (value) =>
        (value && value.length <= 50) ||
        app.i18n.t('market.create_product.validation.title.limit'),
    ],
    productCopies: [
      (value) =>
        !!value ||
        app.i18n.t('market.create_product.validation.copies.required'),
      (value) =>
        value <= 200 ||
        app.i18n.t('market.create_product.validation.copies.max'),
    ],
    productDescription: [
      (value) =>
        (value && /(?!^\s+$)^.*$/m.test(value)) ||
        app.i18n.t('market.create_product.validation.description.required'),
    ],
    productTerms: [
      (value) =>
        (value && /(?!^\s+$)^.*$/m.test(value)) ||
        app.i18n.t('market.create_product.validation.terms'),
    ],
    collectionTitle: [
      (value) =>
        (value && value.length <= 20) ||
        app.i18n.t('market.create_product.validation.title'),
    ],
    collectionDescription: [
      (value) =>
        !!value || app.i18n.t('market.create_product.validation.description'),
    ],
    bidPrice: [
      (value) =>
        (value && value >= 0) ||
        app.i18n.t('market.list_product.validation.bid'),
    ],
    date: [
      (value) =>
        (value && value.getDate() > new Date().getDate()) ||
        app.i18n.t('market.list_product.validation.expiration'),
    ],
    buyPrice: [
      (value) =>
        (value && value >= 0) ||
        app.i18n.t('market.list_product.validation.now'),
    ],
    category: [
      (value) =>
        !!value || app.i18n.t('market.list_product.validation.category'),
    ],
    terms: [
      (value) => !!value || app.i18n.t('market.list_product.validation.terms'),
    ],
    rating: [
      (value) =>
        (value > 0 && value <= 5) ||
        app.i18n.t('meetings.feedback.rating_error'),
    ],
    text: [(value) => !!value || app.i18n.t('meetings.feedback.text_error')],
    headerImage: [
      (value) =>
        !value ||
        value.size < 8388608 ||
        app.i18n.t('forms.validation.image.size'),
    ],
    roomTitle: [
      (value) =>
        !!value || app.i18n.t('meetings.create.validation.title.required'),
    ],
    roomAccess: [
      (value) =>
        !!value || app.i18n.t('meetings.create.validation.access.required'),
    ],
    roomPayment: [
      (value) =>
        !!value || app.i18n.t('meetings.create.validation.payment.required'),
    ],
    preCreateNick: [
      (value) => !!value || app.i18n.t('forms.error.nick.required'),
      (value) =>
        (value && value.length <= 64) ||
        app.i18n.t('forms.error.nick.max_length'),
      (value) =>
        (value && value.length >= 3) ||
        app.i18n.t('forms.error.nick.min_length'),
      (value) =>
        (value && /^[a-z0-9]+$/.test(value)) ||
        app.i18n.t('forms.error.nick.invalid'),
    ],
    preCreateInvitationCode: [
      (value) => !!value || app.i18n.t('forms.error.invitation_code.required'),
    ],
    preCreateTerms: [
      (value) => !!value || app.i18n.t('forms.error.terms_and_conditions'),
    ],
    recoverEmail: [
      (value) => !!value || app.i18n.t('forms.validation.email.required'),
      (value) =>
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          value,
        ) || app.i18n.t('forms.validation.email.valid'),
    ],
    invitationName: [(value) => !!value || app.i18n.t('forms.validation.name')],
    invitationRecommended: [
      (value) => !!value || app.i18n.t('forms.validation.recommended'),
    ],
    invitationEmail: [
      (value) => !!value || app.i18n.t('forms.validation.email.required'),
      (value) =>
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          value,
        ) || app.i18n.t('forms.validation.email.valid'),
    ],
    selection: [
      (value) =>
        !!value ||
        app.i18n.t('verification.signup.validation.selection.required'),
    ],
    verifyContactName: [
      (value) => !!value || app.i18n.t('forms.validation.contact_name'),
    ],
    verifyBusinessName: [
      (value) => !!value || app.i18n.t('forms.validation.business_name'),
    ],
    verifyPhone: [
      (value) => !!value || app.i18n.t('forms.validation.phone_number'),
    ],
    verifyEmail: [
      (value) => !!value || app.i18n.t('forms.validation.email.required'),
      (value) =>
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          value,
        ) || app.i18n.t('forms.validation.email.valid'),
    ],
    connectMessage: [
      (val) => val.length <= 140 || app.i18n.t('connect.max_length'),
      (val) => Boolean(val.length) || app.i18n.t('base.required_field'),
    ],
    investorsName: [(value) => !!value || app.i18n.t('forms.validation.name')],
    investorsEmail: [
      (value) => !!value || app.i18n.t('forms.validation.email.required'),
      (value) =>
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          value,
        ) || app.i18n.t('forms.validation.email.valid'),
    ],
    investorsPhone: [
      (value) => !!value || app.i18n.t('forms.validation.phone.required'),
    ],
    investorsAmount: [
      (value) => !!value || app.i18n.t('forms.validation.amount.investment'),
    ],
    indexAmount: [
      (value) => !!value || app.i18n.t('contribute.validation.required'),
      (value) => value >= 1 || app.i18n.t('contribute.validation.valid'),
    ],
    accountType: [
      (value) => !!value || app.i18n.t('forms.validation.account_type'),
    ],
    subject: [(value) => !!value || app.i18n.t('base.subject')],
    required: [
      (value) => !!value || app.i18n.t('forms.validation.field_required'),
    ],
    country: [
      (value) =>
        !!value ||
        app.i18n.t('stripe.onboarding.become_minter.step_3.country_required'),
    ],
    company: [
      (value) => !!value || app.i18n.t('account.investors.fields.company.rule'),
    ],
    companyName: [
      (value) => !!value || app.i18n.t('account.investors.fields.company.rule'),
    ],
  };

  inject('rules', rules);
};
