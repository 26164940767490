
import { UserBus } from '~/components/user/UsersBus';
import { handleErrors } from '~/utils/functions';
import { CustomError, CUSTOM_ERROR, USER_OPTION_DEFAULT } from '~/vars/api';
import Baseinput from '~/components/base/BaseInput.vue';
export default {
  components: {
    Baseinput,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    firstLogin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userLimits: null,
      valid: false,
      imageUrl: null,
      imageFile: null,
      coverUrl: null,
      coverFile: null,
      avatarImageError: null,
      bannerImageError: null,
      canValidate: false,
      formProfile: {
        userName: null,
        userSurname: null,
        userTitle: null,
        entityName: null,
        entityAddress: null,
        userSummary: null,
        userKnowledges: null,
        userLanguages: null,
        userLinks: null,
        videoLink: null,
      },
      rules: {
        bannerImage: [
          (value) => {
            if (value && value.size > 8388608)
              this.bannerImageError = this.$t('forms.validation.image.size');
            else if (value && !value.type.match('image.*'))
              this.bannerImageError = this.$t('forms.validation.image.type');
            else this.bannerImageError = null;
            return this.bannerImageError === null;
          },
        ],
        avatarImage: [
          (value) => {
            if (value && value.size > 8388608)
              this.avatarImageError = this.$t('forms.validation.image.size');
            else if (value && !value.type.match('image.*'))
              this.avatarImageError = this.$t('forms.validation.image.type');
            else this.avatarImageError = null;
            return this.avatarImageError === null;
          },
        ],
        title: this.$rules.userTitle,
        summary: this.$rules.summary,
        companyTitle: this.$rules.companyTitle,
        nick: this.$rules.nick,
        surname: this.$rules.surname,
        companyName: this.$rules.companyName,
        link: [
          (value) =>
            !value ||
            this.youtubeRegexp.test(value) ||
            this.$t('forms.validation.link'),
        ],
      },
      knowledgesArray: [],
      langsArray: [],
      socialLinksRegExp:
        /(?:https)?(?:\:\/\/)?(?:www\.)?((?:(?:linkedin\.com\/in|twitter\.com|instagram\.com|facebook\.com)(?:\/)(?:\w+))|(\w+\.medium\.com))/i,

      youtubeRegexp:
        /(?:https?:\/\/)?(?:www\.|m\.)?youtu(?:\.be\/|be.com\/\S*(?:watch|embed)(?:(?:(?=\/[^&\s\?]+(?!\S))\/)|(?:\S*v=|v\/)))([^&\s\?]+)/,
      loading: false,
      feedback: null,
      errorValidation: null,
      success: null,
    };
  },
  watch: {
    showModal(value) {
      if (value === false) {
        this.clear();
      } else if (value === true) {
        this.loadUserInfo();
      }
    },
    'formProfile.userLinks'(value) {
      if (
        value.length &&
        !this.socialLinksRegExp.test(value[value.length - 1])
      ) {
        this.$nextTick(() => this.formProfile.userLinks.pop());
      }
    },
    'formProfile.userName'(value) {
      this.validate();
    },
    'formProfile.userSurname'(value) {
      this.validate();
    },
    'formProfile.userTitle'(value) {
      this.validate();
    },
    'formProfile.entityName'(value) {
      this.validate();
    },
    'formProfile.entityAddress'(value) {
      this.validate();
    },
    'formProfile.userSummary'(value) {
      this.validate();
    },
    'formProfile.videoLink'(value) {
      this.validate();
    },
  },
  computed: {
    featuresEnabled() {
      return (
        !this.onlyCompanyMinter || (this.onlyCompanyMinter && this.isEntity)
      );
    },
    onlyCompanyMinter() {
      return this.$api.commons.onlyCompanyMinter();
    },
    userPaidFees() {
      return this.$api.commons.userPaidFees();
    },
    title() {
      return this.$t(
        `account.edit_profile.title.${this.firstLogin ? 'first' : 'rest'}.${
          this.$auth.user.data.user_option
        }`,
      );
    },
    bannerImage() {
      if (this.coverUrl) {
        return this.coverUrl;
      }
      return this.$auth.user.data.header_image.fullpath
        ? `${this.$auth.user.data.header_image.fullpath}?alt=media`
        : '';
    },
    getIconTop() {
      return `top: ${this.$vuetify.breakpoint.mobile ? '2rem' : '15px'};`;
    },
    validLink() {
      return !!this.$refs.inputLink && this.$refs.inputLink.valid;
    },
    isEntity() {
      return (
        this.$auth.user.data.user_option &&
        this.$auth.user.data.user_option.toLowerCase() !== USER_OPTION_DEFAULT
      );
    },
    entity() {
      return this.$t(
        `verification.base.entity_options.${this.$auth.user.data.user_option.toLowerCase()}`,
      );
    },
    titleLabel() {
      return this.$t(
        `account.edit_profile.fields.${this.isEntity ? 'slogan' : 'title'}`,
        {
          entity: this.entity,
        },
      );
    },
    getError() {
      if (this.errorValidation !== null) return this.errorValidation;
      else if (this.avatarImageError !== null) return this.avatarImageError;
      else if (this.bannerImageError !== null) return this.bannerImageError;
      else return null;
    },
  },
  fetchOnServer: false,
  async fetch() {
    if (!this.userPaidFees) {
      this.userLimits = await this.$api.user.getUserLimits();
      this.rules.bannerImage.push((value) => {
        if (
          value &&
          value.size >
            this.userLimits.limitCapacity -
              (this.userLimits.overlay + this.userLimits.firestore)
        )
          this.bannerImageError = this.$t(
            'market.create_product.validation.image.limit',
          );
        else this.bannerImageError = null;
        return this.bannerImageError === null;
      });
      this.rules.avatarImage.push((value) => {
        if (
          value &&
          value.size >
            this.userLimits.limitCapacity -
              (this.userLimits.overlay + this.userLimits.firestore)
        )
          this.avatarImageError = this.$t(
            'market.create_product.validation.image.limit',
          );
        else this.avatarImageError = null;
        return this.avatarImageError === null;
      });
    }
  },
  mounted() {
    this.loadUserInfo();
  },
  methods: {
    validate() {
      if (this.canValidate === true) this.$refs.form.validate();
    },
    /**
     * load user info from auth.user
     */
    loadUserInfo() {
      this.$refs.form.resetValidation();
      this.canValidate = false;
      this.formProfile = {
        userName: this.$auth.user.data.name,
        userSurname: this.$auth.user.data.surname,
        userTitle: this.$auth.user.data.title,
        userSummary: this.$auth.user.data.summary,
        entityName: this.$auth.user.data.entity_name,
        entityAddress: this.$auth.user.data.entity_address,
        userKnowledges: Object.keys(this.$auth.user.data.knowledges),
        userLanguages: Object.keys(this.$auth.user.data.languages),
        userLinks: Object.values(this.$auth.user.data.social_media || []),
        videoLink: this.$auth.user.data.video_url,
      };
      this.canValidate = true;
    },
    /**
     * obtain url from image file
     */
    createImage(file, isCoverImg) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const result = e.target.result;
        isCoverImg ? (this.coverUrl = result) : (this.imageUrl = result);
      };
      reader.readAsDataURL(file);
    },
    /**
     * if new image file, get new url
     */
    onFileChange(file, isCoverImg) {
      file && this.createImage(file, isCoverImg);
    },
    /**
     * get languages options from api for select input
     */
    async parsedLanguagesToSelect() {
      try {
        const response = await this.$api.user.getLanguages();
        if (response.data.data) {
          this.langsArray = Object.entries(response.data.data).map((lang) => {
            return { text: `${lang[1].en}`, value: lang[0] };
          });
        }
      } catch (error) {
        this.$toast.error(
          this.$t('account.edit_profile.fields.languages.error'),
        );
      }
    },
    /**
     * get knowledges options from api for select input
     */
    async parsedKnowledgesToSelect() {
      try {
        const response = await this.$api.user.getKnowledges();
        if (response.data.data) {
          this.knowledgesArray = Object.entries(response.data.data).map(
            (knows) => {
              return { text: `${knows[1].en}`, value: knows[0] };
            },
          );
        }
      } catch (error) {
        this.$toast.error(
          this.$t('account.edit_profile.fields.knowledges.error'),
        );
      }
    },
    /**
     * upload and edit image
     */
    async uploadImages() {
      if (!!this.imageFile) {
        this.feedback = this.$t('account.edit_profile.uploading.image');
        const profileImgUploaded = await this.$api.user.editUserImage({
          imageFile: this.imageFile,
        });
        if (profileImgUploaded.data.error) throw profileImgUploaded.data;
      }
      if (!!this.coverFile) {
        this.feedback = this.$t('account.edit_profile.uploading.image');
        const coverImg = await this.$api.user.editProfileHeaderImage({
          imageFile: this.coverFile,
        });
        if (coverImg.data.error) throw coverImg.data.message;
      }
    },

    /**
     * Upload and edit user video
     */

    async onUploadVideo() {
      try {
        this.loading = true;
        let uploaded;
        if (
          this.formProfile.videoLink !== null &&
          this.formProfile.videoLink !== ''
        ) {
          const id = this.formProfile.videoLink.match(this.youtubeRegexp)[1];
          const validatedLink = `https://www.youtube.com/embed/${id}`;
          uploaded = await this.$api.user.uploadProfileVideo(validatedLink);
        } else {
          uploaded = await this.$api.user.uploadProfileVideo(null);
        }
        if (uploaded.error) throw uploaded.message;
      } catch (error) {
        this.loading = false;
        handleErrors(error);
      }
    },

    /**
     * upload and edit user info
     */
    async uploadUser() {
      this.loading = true;
      try {
        if (!this.valid)
          throw new CustomError(
            this.$t('account.edit_profile.errors.validation'),
          );
        await this.uploadImages();
        await this.onUploadVideo();

        this.feedback = this.$t('account.edit_profile.uploading.info');
        const uploadedUser = await this.$api.user.editUserInfo({
          userInfo: this.formProfile,
        });

        if (uploadedUser.data.error) throw new Error(uploadedUser.data);
        this.loading = false;
        this.success = this.$t('base.done');
        // Update auth user info

        this.feedback = null;
        this.success = null;
        this.firstLogin ? this.$emit('next-step') : this.$emit('close-modal');
      } catch (error) {
        handleErrors(error);
        this.loading = false;
        let errorMessage = this.$t('account.edit_profile.errors.uploading');
        if (error.name && error.name.includes(CUSTOM_ERROR))
          errorMessage = error.message;
        this.errorValidation = errorMessage;
      }
    },
    /**
     * clear variables
     */
    clear() {
      this.imageUrl = null;
      this.imageFile = null;
      this.coverUrl = null;
      this.coverFile = null;
      this.loading = false;
      this.feedback = null;
      this.errorValidation = null;
      this.success = null;
    },
    openFileEditor(ref) {
      this.$refs[ref].$el.querySelector('input').click();
    },
  },
};
