import { MARKETPLACE_NAME } from '~/vars/api';

export default {
  login: 'Login',
  login_legacy: 'Legacy Login',
  sign_in: 'Sign in',
  member: 'Already a member?',
  no_member: "Don't have an account?",
  forgotten: 'Forgot password?',
  loading: `Connecting to your account and signing in to ${MARKETPLACE_NAME} Marketplace`,
  with_words: 'Login (Legacy Version)',
  without_words: 'Login',
  standard: 'Login (Standard Version)',
  back: 'Back to',
  login_in: {
    title: 'Please wait a moment',
    subtitle: 'Connecting to your account',
  },
  welcome: {
    title: 'Welcome back',
  },
  field: {
    nick: 'Username (Handle)',
    username: 'Enter your username',
    password: 'Password',
    enter_password: 'Enter Password',
    invitation_code: 'Invitation Code',
    security_key: 'Security 12 words',
    account_type: 'Account type',
  },
  recover: {
    recover: 'Recover Password',
    recovered: 'Have you recovered your password yet?',
    sent: 'Recover password email sent, please check your inbox',
    change: 'Change Password',
    changed: 'Password changed correctly',
  },
  email: {
    validation: {
      title: 'Email Validation Code',
      info: "Please, check your inbox and write down the code you've received to verify your email address.",
      info_2: 'Check your Spam, Promotions, folders in your mail.',
    },
    errors: {
      backup_file_not_found:
        'You may login via Legacy Login or contact support team if you cant go',
    },
  },
  phone: {
    validation: {
      title: 'Phone Validation Code',
      info: 'We have just sent you a code to your phone. Please enter the code below',
    },
  },
  sign_up: {
    title: 'Welcome to our Private Beta Version ',
    subtitle:
      'In order to create an account you may have in possession of an invitation code.',
    have_invitation: 'I already have an Invitation code',
    request_invitation: 'Request an Invitation code',
  },
  request_invitation: {
    fields: {
      name: 'Name',
      recommended: {
        label: `How did you get to ${MARKETPLACE_NAME} Marketplace?`,
        options: {
          g2c_web: 'Gate2Change web page',
          search_engine: 'Search Engine',
          email_newsletter: 'Email or newsletter',
          social_networks: 'Social Networks',
          relative_friend: 'Relative, friend or acquaintance',
          direct_invitation: 'Direct Invitation',
          other: 'Other',
        },
      },
      content: 'Comments',
    },
    send: 'Submiting Request Invitation form.',
    success:
      'Request sent  You are now in a wait list and as soon as there will be invitations available, we will send you one.',
    errors: {
      sending: 'Error submiting request invitation form.',
    },
  },
};
