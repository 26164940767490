export default {
  details: 'One to One details',
  booking_fee: 'Booking Fee',
  booking_fee_desc:
    "The cost of the 5 first minutes will be used as a guarantee if any one doesn't show up",
  full_time_price: 'Full Time Price with transaction cost',
  full_time_price_desc:
    "It would only be charged the total minutes you'll be in the Room",
  request: 'Request One To One',
  amount_per_min: 'Amount per minute with transaction cost',
  message_placeholder:
    'Sending a meaningful message could improve the chances of being accepted',
  estimated_duration_should_min: 'Must be minimum {minDuration} minutes',
  estimated_duration_max: 'Max duration is {maxDuration} minutes',
  date_must_be: 'Must be the present or a future day',
  sended_request: 'Request sent successfully',
  price_per_reservation: 'Price per reservation',
  time_spent: 'Time spent',
  not_has_received_one_to_one: 'Not has received any one to one',
  not_has_request_one_to_one: 'Not has request any one to one',
  public: 'Public',
  private: 'Private',
  invite: 'Invite only',
  total_pay: 'Total paid',
  total_earn: 'Total earned',
  pay__label: 'Pay per minute',
  pay_past_label: 'Paid per minute',
  payed_past_label: 'Paid per minute',
  earn__label: 'Earn per minute',
  earn_past_label: 'Earned per minute',
  earned_past_label: 'Earned per minute',
  room_record_help:
    'This Room will be recordered and you would be able to used it RoomTube.',
  room_record_past_helpt:
    'This Room was be recorded and you would be able to use it RoomTube',
  record: 'Record',
  send_invitation: 'Send invitations {amount}',
};
