
import { handleErrors } from '~/utils/functions';
export default {
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async unfollowHandler() {
      try {
        this.loading = true;
        const response = await this.$api.follow.unfollowUser(this.userId);
      } catch (error) {
        handleErrors(error);
        this.loading = false;
      }
    },
  },
};
