import { MARKETPLACE_NAME } from '~/vars/api';

export default {
  title: `Welcome to ${MARKETPLACE_NAME} Marketplace `,
  subtitle: `Before continue signing up your Company, Brand or Institution account you should know that you will can enjoy ${MARKETPLACE_NAME} Marketplace but for certain interactions we will request you to fill a simple form to help us checking that all info is correct.`,
  info_head: `This help us building a healthy ${MARKETPLACE_NAME} Marketplace comunity:`,
  info_list: {
    item_0: {
      text: `Avoiding misunderstandings with ${MARKETPLACE_NAME} Marketplace users`,
      icon: 'mdi-emoticon-confused-outline',
    },
    item_1: {
      text: 'Keeping out bad practices as identity fraud,',
      icon: 'mdi-account-check-outline',
    },
    item_2: {
      text: 'Setting a better and more secure place.',
      icon: 'mdi-shield-star-outline',
    },
  },
  select: {
    label: 'What are you signing up?',
  },
  tip: 'Please read information before continue.',
  validation: {
    selection: {
      required: 'Please select one option',
    },
  },
  account_type: {
    individual: 'User',
    company: 'Musician',
  },
};
