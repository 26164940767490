export default ({ $axios }) => ({
  /**
   * User report
   * @param {*} content_type
   * @param {*} item_id
   * @param {*} reason
   * @param {*} observation
   * @returns
   */
  async report(content_type, item_id, reason, observation) {
    try {
      return await $axios
        .post('/report/', {
          report: {
            content_type,
            item_id,
            reason,
            observation,
          },
        })
        .then((response) => response);
    } catch (error) {
      throw error;
    }
  },
});
