import { MARKETPLACE_NAME } from '~/vars/api';

export default {
  title: 'Contact us',
  subtitle: `Thanks for reaching out to ${MARKETPLACE_NAME} Marketplace. We are working hard to make your experience better every day. Tell us everything you want and we’ll get back to you ASAP.`,
  name: 'First name',
  email: 'Email',
  subject: 'Subject',
  message: 'Message',
  sending: 'Sending message',
  error: 'Error sending form',
  success: 'Thank you! Message sent',
  validation: {
    message: {
      required: 'Tell us everything you want',
    },
  },
};
