import SocketInstance from '~/socket/instance';
import SocketHandlers from '~/socket/handlers';

export default function (ctx, inject) {
  const socket = {
    instance: SocketInstance(ctx),
    handlers: SocketHandlers(ctx),
  };

  inject('socket', socket);
}
