
import { mapGetters } from 'vuex';
import { SIDEBAR_WIDTH_CLOSED, SIDEBAR_WIDTH_OPEN } from '~/vars/layout';

import BaseSidebarItem from '../sidebar/BaseSidebarItem.vue';
import { showSessionModal } from '~/vars/store/actions';
export default {
  components: {
    BaseSidebarItem,
    MessagesSidebarButton: () =>
      import('~/components/sidebar/MessagesSidebarButton.vue'),
  },
  data() {
    return {
      miniVariant: false,
      clipped: false,
      fixed: false,
      showInfo: false,
    };
  },
  computed: {
    ...mapGetters({
      getNotificationsCounter: 'appNotifications/getNotificationsCounter',
      privateRooms: 'rooms/getPrivateRooms',
      showNowRooms: 'rooms/thereIsNowRoom',
      sidebarAreOpened: 'ui/areSidebarOpened',
    }),
    messagesEnabled() {
      return this.$api.commons.messagesEnabled();
    },
    drawer: {
      get() {
        return this.sidebarAreOpened;
      },
      set(val) {
        this.$store.commit('ui/changeSidebarStatus', val);
      },
    },
    sidebarWidth() {
      const width = !this.drawer ? SIDEBAR_WIDTH_CLOSED : SIDEBAR_WIDTH_OPEN;
      if (this.$vuetify.breakpoint.mobile) {
        return !this.drawer ? '0px' : SIDEBAR_WIDTH_OPEN;
      }
      return width;
    },
    navigationOptions() {
      const navigationOptions = [
        {
          icon: 'store',
          title: this.$t('routes.market'),
          key: 'market',
          to: this.localePath({
            name: 'market-preview-marketplace',
          }),
          counter: 0,
        },
        {
          icon: 'group',
          title: this.$t('routes.sellers'),
          key: 'musicians',
          to: this.localePath({ name: 'musicians' }),
        },
      ];
      if (
        this.$auth.loggedIn &&
        this.$auth.user.data !== null &&
        typeof this.$auth.user.data.is_redeemer === 'number'
      )
        navigationOptions.push({
          icon: 'redeem',
          title: this.$t('routes.redeem'),
          key: 'redeem',
          to: this.localePath({
            name: 'redeem',
          }),
        });
      return navigationOptions;
    },
    footerOptions() {
      return [
        {
          icon: '',
          title: this.$t('routes.about'),
          key: 'about',
          to: 'https://www.gate2chain.com',
          nuxtLink: false,
        },
        {
          icon: '',
          title: this.$t('routes.t_c'),
          key: 'legal',
          to: this.localePath({
            name: 'legal-terms',
          }),
        },
        {
          icon: '',
          title: 'Support',
          key: 'support',
          to: this.localePath({
            name: 'support',
          }),
        },
      ];
    },
  },
  mounted() {
    this.drawer = !this.$vuetify.breakpoint.mobile;
    this.$emit('component-mounted');
  },
  methods: {
    OnClickInSignInBtn() {
      this.$store.dispatch(`session/${showSessionModal}`, true);
    },
    OnClickInSignUpBtn() {
      this.$router.push(this.localePath({ name: 'session-signup' }));
    },
    OnClickMintaMarketplaces() {
      window.open('https://www.mintamarketplaces.com/', '_blank');
    },
  },
};
