
import { USER_ACTION_VERIFY, USER_OPTION_DEFAULT } from '~/vars/api';
export default {
  props: {},
  computed: {
    USER_ACTION_VERIFY() {
      return USER_ACTION_VERIFY;
    },
    label() {
      return this.$t(
        `verification.button.${this.isEntity ? 'entity' : 'user'}`,
        {
          userOption: this.isEntity
            ? this.$t(
                `verification.base.entity_options.${this.$auth.user.data.user_option.toLowerCase()}`,
              )
            : '',
        },
      );
    },
    isEntity() {
      return (
        this.user.user_option &&
        this.$auth.user.data.user_option !== USER_OPTION_DEFAULT
      );
    },
  },
  methods: {},
};
