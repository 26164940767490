
import { handleErrors } from '~/utils/functions';
import { mapGetters } from 'vuex';

export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      loggingOut: 'session/loggingOut',
    }),
    shouldShowModal: {
      get() {
        return this.showModal;
      },
      set(value) {
        this.$emit('close-modal');
      },
    },
    modalSize() {
      return this.$vuetify.breakpoint.mobile ? '90%' : '50%';
    },
  },
  methods: {
    async handleAction() {
      try {
        // waiting for server.init to prevent undefined access to auth.user.data
        await this.$api.session.logout();
      } catch (error) {
        handleErrors(error);
      }
    },
  },
};
