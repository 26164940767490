import { USER_OPTION_COMPANY } from '~/vars/api';
import { addCompanies, addUsers, setSellers } from '~/vars/store/actions';
import {
  SET_REQUESTED_ALL_SELLERS,
  TOGGLE_REQUESTED_ALL_COMPANIES,
  TOGGLE_REQUESTED_ALL_USERS,
} from '~/vars/store/mutations';

export default ({ app, $axios }) => ({
  /*
   * USER
   */
  userInterval: null,
  usersInterval: null,

  getOneUserByNick(nick) {
    return new Promise((resolve, reject) => {
      $axios
        .get(`users/get-by-nickname/${nick}`)
        .then(({ data }) => {
          if (data.error || data.message.includes('not found'))
            reject({ statusCode: 404, message: data.message });
          return resolve(data.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  guestGetUsers() {
    return new Promise((resolve, reject) => {
      $axios
        .get(`users/-/users2`) //users
        .then(({ data }) => {
          return resolve(data.data);
        })
        .catch((e) => reject(e));
    });
  },
  guestGetUserById(id) {
    return new Promise((resolve, reject) => {
      $axios
        .get(`users/-/users2/${id}`) //users
        .then(({ data }) => {
          data.data.id = id;
          return resolve(data.data);
        })
        .catch((e) => reject(e));
    });
  },
  getUsersById() {
    return new Promise((resolve, reject) => {
      $axios
        .get(`users/${app.$auth.user.data.id}/users2`) //users
        .then(({ data }) => {
          return resolve(data.data);
        })
        .catch((e) => reject(e));
    });
  },
  getUsersByUserOption(userOption, privates) {
    const option =
      userOption === USER_OPTION_COMPANY
        ? 'teams'
        : userOption === 'sellers'
        ? 'sellers'
        : 'users';
    return new Promise((resolve, reject) => {
      const endPoint = `users/${option}${app.$auth.loggedIn ? '2' : ''}${
        privates === false ? '?privates=false' : ''
      }`;
      $axios
        .get(endPoint) //users
        .then(({ data }) => {
          return resolve(data.data);
        })
        .catch((e) => reject(e));
    });
  },
  async getAllUsersPublic() {
    let users;
    if (app.$api.commons.onlyShowCompany()) {
      users = await this.getAllCompanies();
    } else if (app.$api.commons.onlySellers()) {
      users = await this.getAllSellers();
    } else {
      users = await this.getAllUsers();
    }
    if (app.$api.commons.vipProfileEnabled()) {
      return users.filter((user) => app.$api.commons.vipProfile(user.nick));
    } else
      return users.filter(
        (user) =>
          user.is_verified === true && user.verification_status === 'complete',
      );
  },
  getAllSellers() {
    return new Promise(async (resolve, reject) => {
      if (app.store.getters['users/sellersAreRequested']) {
        this.usersInterval = setInterval(() => {
          let users = app.store.getters['users/getAllSellers'];
          if (users) {
            clearInterval(this.usersInterval);
            resolve(users);
          }
        }, 1000);
      } else {
        let users = app.store.getters['users/getAllSellers'];
        let usersAreLoaded = app.store.getters['users/sellersAreRequested'];
        if (users.length && usersAreLoaded) {
          resolve(users);
        } else {
          app.$api.user.getUsersByUserOption('sellers').then((data) => {
            const users = Object.values(data).sort(
              (a, b) => b.followers_counter - a.followers_counter,
            );
            app.store.dispatch(`users/${addUsers}`, users);
            app.store.dispatch(
              `users/${setSellers}`,
              users.map((user) => user.id),
            );
            app.store.commit(`users/${SET_REQUESTED_ALL_SELLERS}`, true);
            resolve(users);
          });
        }
      }
    });
  },
  getAllUsers() {
    return new Promise(async (resolve, reject) => {
      if (app.store.getters['users/allUsersAreRequested']) {
        this.usersInterval = setInterval(() => {
          let users = app.store.getters['users/getAllUsers'];
          if (users) {
            clearInterval(this.usersInterval);
            resolve(users);
          }
        }, 1000);
      } else {
        let users = app.store.getters['users/getAllUsers'];
        let usersAreLoaded = app.store.getters['users/getAllUsersAreLoaded'];
        if (users.length && usersAreLoaded) {
          resolve(users);
        } else {
          app.store.commit(`users/${TOGGLE_REQUESTED_ALL_USERS}`, true);
          app.$api.user.getUsersByUserOption().then((data) => {
            const users = Object.values(data).sort(
              (a, b) => b.followers_counter - a.followers_counter,
            );
            app.store.dispatch(`users/${addUsers}`, users);
            resolve(users);
          });
        }
      }
    });
  },
  getAllCompanies() {
    return new Promise(async (resolve, reject) => {
      if (
        app.store.getters['users/allUsersAreRequested'] ||
        app.store.getters['users/allCompaniesAreRequested']
      ) {
        this.usersInterval = setInterval(() => {
          let users = app.store.getters['users/getCompanies'];
          if (users) {
            clearInterval(this.usersInterval);
            resolve(users);
          }
        }, 1000);
      } else {
        let users = app.store.getters['users/getCompanies'];
        let temasAreLoaded =
          app.store.getters['users/getAllUsersAreLoaded'] ||
          app.store.getters['users/getCompaniesAreLoaded'];
        if (users.length && temasAreLoaded) {
          resolve(users);
        } else {
          app.store.commit(`users/${TOGGLE_REQUESTED_ALL_COMPANIES}`, true);
          app.$api.user
            .getUsersByUserOption(USER_OPTION_COMPANY, false)
            .then((data) => {
              const users = Object.values(data).sort(
                (a, b) => b.followers_counter - a.followers_counter,
              );
              app.store.dispatch(`users/${addCompanies}`, users);
              resolve(users);
            });
        }
      }
    });
  },
  getUserById(userId) {
    return new Promise(async (resolve, reject) => {
      if (userId === app.$auth.user.data.id) {
        resolve(this.$auth.user.data);
      }
      if (app.store.getters['users/userAreRequested'](userId)) {
        this.userInterval = setInterval(() => {
          let user = app.store.getters['users/getUser'](userId);
          if (user) {
            clearInterval(this.userInterval);
            resolve(user);
          }
        }, 1000);
      } else {
        let user = app.store.getters['users/getUser'](userId);
        if (user) {
          resolve(user);
        } else {
          app.store.commit('users/ADD_USER_REQUEST_USER', userId);
          app.$api.user.getOneUserById(userId).then((user) => {
            app.store.dispatch('users/addUser', user);
            resolve(user);
          });
        }
      }
    });
  },
  getOneUserById(id) {
    return new Promise((resolve, reject) => {
      $axios
        .get(`users/${app.$auth.user.data.id}/users2/${id}`) //users
        .then(({ data }) => {
          data.data.id = id;
          return resolve(data.data);
        })
        .catch((e) => reject(e));
    });
  },
  getUserLimits() {
    return new Promise((resolve, reject) => {
      $axios
        .get(`users/${app.$auth.user.data.id}/get-user-limits`) //users
        .then(({ data }) => {
          return resolve(data.data);
        })
        .catch((e) => reject(e));
    });
  },
  async fileUploaded({ size }) {
    return new Promise((resolve, reject) => {
      $axios
        .post(`users/${app.$auth.user.data.id}/file-uploaded`, {
          size,
        })
        .then(({ data }) => {
          return resolve(data.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * Connections
   */
  /**
   * Get user's connections for update
   */
  getUserConnections() {
    //continue here with the backend getter connections
    return new Promise((resolve, reject) => {
      $axios
        .post(`users/${app.$auth.user.data.id}/connect2/get`)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch((e) => reject(e));
    });
  },

  /**
   * Update logged user's connections in $auth.user.data.connections
   */
  async updateUserConnections() {
    const userConnections = await this.getUserConnections();
    app.store.dispatch('connections/setConnections', userConnections);
  },
  /**
   * Get all users
   */
  async getUsers() {
    return await $axios.get('users').then((response) => response);
  },
  /*
   * ACCOUNT
   */
  /**
   * Ge all languages availables
   * @returns
   */
  async getLanguages() {
    return await $axios.get('languages');
  },
  /**
   * Ge all languages availables
   * @returns
   */
  async getKnowledges() {
    return await $axios.get('knowledges');
  },
  /**
   * Edit user's info
   * @param {Object} userInfo
   * @returns
   */
  async editUserInfo({ userInfo }) {
    return await $axios.put(`users/${app.$auth.user.data.id}`, {
      name: userInfo.userName,
      surname: userInfo.userSurname,
      title: userInfo.userTitle,
      entity_name: userInfo.entityName,
      entity_address: userInfo.entityAddress,
      summary: userInfo.userSummary,
      languages: JSON.stringify(userInfo.userLanguages),
      knowledges: JSON.stringify(userInfo.userKnowledges),
      social_media: userInfo.userLinks,
    });
  },
  /**
   * Edit user's status
   * @param String status
   * @returns
   */
  async editUserStatus(status) {
    return await $axios.put(`users/${app.$auth.user.data.id}`, {
      is_online: status,
    });
  },
  /**
   * Edit user's avatar image
   * @param {Object} imageFile
   * @returns
   */
  async editUserImage({ imageFile }) {
    const formData = new FormData();
    formData.append('image', imageFile);

    return await $axios.post(`auth/profile-image`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  /**
   * Edit user's pricing model
   * @param {Object} pricingModel
   * @returns
   */
  async editUserPricingModel({ pricingModel }) {
    if (app.$api.commons.satsEnabled()) {
      return await $axios.put(`users/${app.$auth.user.data.id}`, {
        pricingModel,
      });
    }
  },
  /**
   * Add new postal addres to user info
   * @param {Object} address
   * @returns
   */
  async addNewPostalAddress(address) {
    return await $axios
      .post(`users/${app.$auth.user.data.id}/addaddress`, {
        address,
      })
      .then(({ data }) => data);
  },
  /**
   * Edit postal addres with id
   * @param {String} id
   * @param {Object} address
   * @returns
   */
  async editPostalAddress({ address, addressId }) {
    return await $axios
      .put(`users/${app.$auth.user.data.id}/updateaddress/${addressId}`, {
        address,
      })
      .then(({ data }) => data);
  },
  /**
   * Rewmove postal addres with id
   * @param {String} id
   * @returns
   */
  async removePostalAddress(addressId) {
    return await $axios
      .delete(`users/${app.$auth.user.data.id}/deleteaddress/${addressId}`)
      .then(({ data }) => data);
  },

  async contactSupport({ type, name, email, phone, company, amount, content }) {
    return await $axios
      .post(`mailing/${type}`, {
        userid: app.$auth.loggedIn ? app.$auth.user.data.id : undefined,
        name,
        email,
        phone,
        company,
        amount,
        content,
      })
      .then(({ data }) => data);
  },

  async sendContactSupport({
    name,
    email,
    userId,
    userNick,
    created,
    message,
    scope = 'support',
    subject,
  }) {
    try {
      const userEmail = app.$auth.loggedIn ? app.$auth.user.data.email : email;
      const textObject = {
        name,
        email: userEmail,
        subject,
        message,
      };
      const text = JSON.stringify(textObject);
      // `name: ${name} - email: ${userEmail} - subject: ${subject} - message: ${message}`;
      return await $axios
        .post(`/contact`, {
          form: { user_id: userId, user_nick: userNick, created, text, scope },
          name,
          email,
          userid: userId,
        })
        .then(({ data }) => data);
    } catch (error) {
      throw error;
    }
  },

  async subscribeNewsletter(email) {
    try {
      return await $axios
        .post(`/contact/subscribe-newsletter`, {
          email,
        })
        .then(({ data }) => data);
    } catch (error) {
      throw error;
    }
  },

  async unSubscribeNewsletter(email) {
    try {
      return await $axios
        .post(`/contact/unsubscribe-newsletter`, {
          email,
        })
        .then(({ data }) => data);
    } catch (error) {
      throw error;
    }
  },

  async sendPendingVerification({
    name,
    email,
    userId,
    userNick,
    created,
    phone,
    country,
    scope = 'pendingverification',
  }) {
    try {
      const textObject = {
        name,
        email,
        phone,
        country,
      };
      const text = JSON.stringify(textObject);
      return await $axios
        .post(`/contact`, {
          form: { user_id: userId, user_nick: userNick, created, text, scope },
          name,
          email,
          userid: userId,
        })
        .then(({ data }) => data);
    } catch (error) {
      throw error;
    }
  },

  async becomeAnInvestor({
    name,
    investor,
    country,
    amount,
    comment,
    company_name,
  }) {
    try {
      return await $axios
        .post('/investor', {
          form: {
            name,
            investor,
            country,
            amount,
            comment,
            company_name,
          },
        })
        .then((response) => response.data.error);
    } catch (error) {
      throw error;
    }
  },
  /**
   * PUBLIC PROFILE
   */
  /**
   * Get and set user's public profile info
   * @param {String} nick
   *
   */
  getUserProfile(nick) {
    return new Promise((resolve, reject) => {
      $axios
        .get(`users/${nick}/${app.$auth.loggedIn ? 'user-profile' : 'profile'}`)
        .then(({ data }) => {
          if (data.error || data.message.includes('not found'))
            reject({ statusCode: 404, message: data.message });
          const { profile: user, theme, features } = data.data;
          const seo = {
            title: user.nick,
            description: user.summary,
            img: user.profile_image
              ? user.profile_image.fullpath
              : '/images/default.png',
            pageUrl: '',
          };
          resolve({
            user,
            theme,
            features,
            seo,
          });
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * Get public profile themes
   *
   * @returns
   */
  getPublicProfileThemes() {
    return app.$auth.loggedIn
      ? new Promise((resolve, reject) => {
          $axios
            .get(`/themes`)
            .then(({ data }) => {
              if (data.error)
                reject({ statusCode: 404, message: data.message });
              resolve(data.data);
            })
            .catch((e) => {
              reject(e);
            });
        })
      : [];
  },

  /**
   * Set theme
   * @param {string} themeId
   * @returns
   */
  async setPublicProfileTheme(themeId) {
    return new Promise((resolve, reject) => {
      $axios
        .put(`auth/set-theme`, {
          theme_id: themeId,
        })
        .then(({ data }) => {
          if (data.error) reject({ statusCode: 404, message: data.message });
          resolve(true);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * Edit Header image
   * @param {Object} imageFile
   * @returns
   */
  async editProfileHeaderImage({ imageFile }) {
    const formData = new FormData();
    formData.append('image', imageFile);

    return await $axios.post(`auth/profile-header-image`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  async updateProfileStatus(status) {
    return new Promise((resolve, reject) => {
      $axios
        .post(`auth/update-status`, {
          status,
        })
        .then(({ data }) => resolve(data))
        .catch(() => {
          reject();
        });
    });
  },
  async uploadProfileVideo(link) {
    return new Promise((resolve, reject) => {
      $axios
        .post(`auth/profile-video`, {
          video_url: link,
        })
        .then(({ data }) => resolve(data))
        .catch(() => {
          reject();
        });
    });
  },

  /*
   * SiGN UP
   */
  /**
   * Create user on firebase firestore
   * @param {Object} userInfo User info from create-accounts steps
   * @returns object.data: {data, error...}
   */
  async createUser(nick) {
    const user = {
      nick,
    };
    return await $axios.post('users/create', { user });
  },
  /**
   * Register new user info
   * @param {Object} userInfo User info from create-accounts steps
   * @returns object.data: {data, error...}
   */
  async registerInfo(userInfo) {
    const {
      nick,
      codeCompany,
      password,
      email,
      prefix,
      phone,
      userOption,
      number,
    } = userInfo;
    const mobile_number = phone.replace(/[ -()#x]/g, '');
    const user = {
      email,
      nick,
      password,
      mobile_prefix: prefix,
      mobile_number,
      mobile: number,
      user_option: userOption,
      code_company: codeCompany,
      ukresident: 0,
    };
    return await $axios.post('users/register', { user });
  },
  /**
   * Create application user
   * @param {Object} {} { userauth: from $api.g2cSession.createUser response, userName: nick}
   * @returns
   */
  async createUserServerApp({ userauth, userName }) {
    const application = app.context.env.g2clibApplication;
    return await $axios.post('g2c/server/application/users/create', {
      userauth,
      application,
      nick: userName,
    });
  },
  /*
   * VALIDATIONS
   */
  /**
   * Check if user exists
   * @param {String} nick userName
   * @returns object.data: {data, error...}
   */
  async nickIsAvailable(nick) {
    return await $axios.get(`/users/check-nickname/${nick}`);
  },
  /**
   * Check if email is already used
   * @param {String} email email direcction
   * @returns object.data: {data, error...}
   */
  async emailIsAvailable(email) {
    return await $axios.get(`/users/check-email/${email}`);
  },
  /**
   * Check if mobile number is already used
   * @param {String} phone mobile number formated with prefix
   * @returns object.data: {data, error...}
   */
  async phoneIsAvailable(phone) {
    return await $axios.get(`/users/check-mobile/${phone}`);
  },
  /*
   * PASSWORD
   */
  /**
   * Send email to user with link 'password/reset/_token
   * @param {String} email email direction
   * @returns object.data: {data, error...}
   */
  async sendRecoverPasswordEmail(email) {
    return await $axios.get(`password/email/${email}`);
  },
  /**
   * Reset password with given token by $route.params.token
   * OBJECT
   * @param {String} password new account password
   * @param {String} token $route.params.token
   * @returns object.data: {data, error...}
   */
  async resetPasswordApi({ password, token }) {
    return await $axios.post('password/reset', { password, token });
  },
  /*
   * CODES
   */
  /**
   * Validate invitation code input by user
   * @param {String} code long string invitation code not used
   * @returns object.data: {data, error...}
   */
  async validateInvitationCode(code) {
    return await $axios.get(`/invitation-codes/validate-code/${code}`);
  },
  /**
   * Send email with code in order to pin-verify
   * @param {String} email email direction
   * @returns object.data: {data, error...}
   */
  async sendValidationCodeEmail(email) {
    return await $axios.post('/users/email-validation/send', {
      email,
    });
  },
  /**
   * Send sms with code in order to pin-verify
   * @param {String} phone mobile number formated with prefix
   * @returns object.data: {data, error...}
   */
  async sendValidationCodePhone(phone) {
    return await $axios.post('/twilio/services/verify/send-sms-verification', {
      to: phone,
    });
  },
  /**
   * Validate code sent by email and input by registered user
   * OBJECT
   * @param {String} nick nick registered
   * @param {String} code 4 digit string
   * @returns object.data: {data, error...}
   */
  async validateEmailRegister({ nick, code }) {
    return await $axios.post('/users/email-validation/validate-register', {
      nick,
      validation_code: code,
    });
  },
  /**
   * Validate code sent by email and input by logged user
   * OBJECT
   * @param {String} nick nick registered
   * @param {String} code 4 digit string
   * @returns object.data: {data, error...}
   */
  async validateEmailLogged({ nick, code }) {
    return await $axios.post('/users/email-validation/validate-logged', {
      nick,
      validation_code: code,
    });
  },
  /**
   * Validate code sent by email and input by user
   * OBJECT
   * @param {String} email email direction
   * @param {String} code 4 digit string
   * @returns object.data: {data, error...}
   */
  async validateEmailCode({ email, code }) {
    return await $axios.post('/users/email-validation/validate', {
      email,
      validation_code: code,
    });
  },
  /**
   * Validate code sent by sms and input by user on register
   * OBJECT
   * @param {String} nick  user nick registered
   * @param {String} code 4 digit string
   * @returns object.data: {data, error...}
   */
  async validateRegisteredPhoneCode({ code, mobile_number }) {
    const instance = $axios.create({
      baseURL: `${process.env.custodialBaseURL}`,
      headers: { 'x-authorization': app.context.env.custodialApiKey },
    });
    return await instance.$post(`/api/auth/create-login`, {
      code_number: code,
      mobile_number,
      application: app.context.env.custodialApplication,
    });
  },
  /**
   * Validate code sent by sms and input by user on login
   * OBJECT
   * @param {String} nick  user nick registered
   * @param {String} code 4 digit string
   * @returns object.data: {data, error...}
   */
  async validateLoginPhoneCode({ code, mobile_number }) {
    const instance = $axios.create({
      baseURL: `${process.env.custodialBaseURL}`,
      headers: { 'x-authorization': app.context.env.custodialApiKey },
    });
    return await instance.$post(`/api/auth/create-login`, {
      code_number: code,
      mobile_number,
      application: app.context.env.custodialApplication,
    });
  },
  async test() {
    const instance = $axios.create({
      baseURL: `${process.env.custodialBaseURL}`,
      headers: { 'x-authorization': app.context.env.custodialApiKey },
    });
    return await instance.$get(`/ping`);
  },

  /**
   * Validate code sent by sms and input by user
   * OBJECT
   * @param {String} phone  mobile number formated with prefix
   * @param {String} verifyServiceId long string from $api.user.sendValidationCodePhone response
   * @param {String} code 4 digit string
   * @returns object.data: {data, error...}
   */
  async validatePhoneCode({ code, verifyServiceId, nick }) {
    return await $axios.post(
      '/twilio/services/verify/check-verification-token',
      {
        verify_service_id: verifyServiceId,
        code,
        to: nick,
      },
    );
  },

  async retrieveMinterData() {
    return await $axios.get('/auth/my-minter-data');
  },
});
