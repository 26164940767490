
import { USER_ACTION_VERIFY } from '~/vars/api';
import { EventBus } from '~/components/eventBus/EventBus';
import { mapGetters } from 'vuex';
import { SHOW_G2C_IN_PROCESS_MODAL } from '~/vars/store/mutations';
export default {
  components: {},
  mixins: [],
  props: {
    isHeader: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      status: this.$auth.user.data.verification_status,
    };
  },
  computed: {
    ...mapGetters({
      g2cInProcess: 'processing/g2cInProcess',
    }),
    USER_ACTION_VERIFY() {
      return USER_ACTION_VERIFY;
    },
    isVerified() {
      return (
        this.$auth.user.data.is_verified &&
        this.$auth.user.data.verification_status === 'complete'
      );
    },
    label() {
      return this.isVerified ||
        this.$auth.user.data.verification_status === undefined ||
        this.$auth.user.data.verification_status === null ||
        this.$auth.user.data.verification_status === 'none'
        ? this.$t('market.create_product.label')
        : this.$t(`stripe.onboarding.${this.status}.button`);
    },
    icon() {
      return 'mint_product_white';
    },
  },
  watch: {},
  methods: {
    onBtnClicked(showVerifyModal) {
      if (this.isVerified) {
        this.showMintProduct();
      } else if (showVerifyModal) this.showVerifyModal();
    },
    showMintProduct() {
      if (this.g2cInProcess)
        this.$store.commit(`processing/${SHOW_G2C_IN_PROCESS_MODAL}`, true);
      else this.$router.push(this.localePath({ name: 'my-tokens-mint-form' }));
    },
    showVerifyModal() {
      this.$store.dispatch('ui/showVerifyModal', {
        status: true,
      });
    },
    async minterCheck() {
      this.status = await this.$api.pGatewayProvider.minterCheck();
    },
  },
  /*
   ** Lyfecycle hooks
   */
  created() {},
  mounted() {
    EventBus.$on('update-stripe', this.minterCheck);
  },
  beforeDestroy() {
    EventBus.$off('update-stripe');
  },
  updated() {},
};
