export default ({ app }) => ({
  /**
   * Aux function to create in and out streams if don't exist
   * Better here than in message as we avoid checking on EVERY message sent/recived
   *
   * @param path -> absolute parent path relative to user root tree
   * @param name -> tree name
   * @returns {Promise<unknown>}
   */
  async createPathIfNotFound({ path, name }) {
    const g2ctoken = app.$api.g2cSession.getToken();
    const rootPathTree = await this.getUserObjectList({
      g2ctoken,
      path,
      type: '~tree~',
    });

    const fullPath = (path === '/' ? '' : path) + '/' + name;
    const foundInPathTree = this.auxProcessTree(rootPathTree, fullPath);
    if (foundInPathTree === true) return 'Already exists';
    else {
      return await app.$api.g2cProvider.createUserObjectTree({
        g2ctoken,
        treePath: path,
        treeName: name,
      });
    }
  },
  auxProcessTree(tree, match) {
    try {
      if (tree.length === 0) return false;
      for (const index of tree) {
        if (index.fullPath === match) return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  },
  async getUserObjectList({ g2ctoken, path, type, mode = 'noobject' }) {
    return await app.$api.g2cProvider.getUserObjectList({
      g2ctoken,
      path,
      type,
      mode,
    });
  },

  /**
   * Sats
   */
  /**
   * Get Sats or Usd from the opposite
   * @param {Number} amount sats or usd to exchange
   * @param {String} mode SAT2USD || USD2SAT
   * @returns {Object} response: {data, error}
   */
  async exchangeRates({
    amount,
    mode = EXCHANGE_RATE_MODE_USD_TO_SAT,
    exchangeTX,
  }) {
    try {
      // eslint-disable-next-line no-undef
      const response = await app.$api.g2cProvider.exchangeRates({
        amount,
        mode,
      });
      if (typeof exchangeTX !== 'undefined') {
        if (exchangeTX === 'sell_sats') {
          if (mode.includes('2SAT')) {
            const result =
              response + (EXCHANGE_TYPE_DIFF_FACTOR * response) / 100;
            return Math.ceil(result);
          } else {
            const result =
              response - (EXCHANGE_TYPE_DIFF_FACTOR * response) / 100;
            return result;
          }
        }
        if (exchangeTX === 'buy_sats') {
          if (mode.includes('2SAT')) {
            const result =
              response - (EXCHANGE_TYPE_DIFF_FACTOR * response) / 100;
            return Math.floor(result);
          } else {
            const result =
              response + (EXCHANGE_TYPE_DIFF_FACTOR * response) / 100;
            return result;
          }
        }
      }
      return response;
    } catch (error) {
      console.log('error triggered from exchangeRates', error);
      throw error;
    }
  },
  async getUserBalance() {
    try {
      const g2ctoken = app.$api.g2cSession.getToken();
      const response = await app.$api.g2cProvider.getUserBalance({
        g2ctoken,
      });
      if (response === null) await app.$api.session.checkSession();
      return response;
    } catch (error) {
      console.log('error triggered from getUserBalance', error);
      throw error;
    }
  },
});
