import {
  SET_G2C_IN_PROCESS,
  SET_MARKET_OBJECT,
  REMOVE_MARKET_OBJECT,
  SET_TOKEN_MINTIN,
  ERROR_CREATING_MARKET_OBJECT,
  SHOW_G2C_IN_PROCESS_MODAL,
  CREATE_G2C_ALERT,
  REMOVE_G2C_ALERT,
  ADD_MESSAGE,
  REMOVE_MESSAGE,
} from '../vars/store/mutations';

export const state = () => ({
  g2cInProcess: false,
  marketObjects: [],
  messages: [],
  tokensMintin: null,
  showG2cInProcessModal: false,
  g2cAlert: null,
});

export const getters = {
  g2cInProcess: (state) => {
    return state.g2cInProcess;
  },
  isCreatingMarketObject: (state) => {
    return state.marketObjects.some((item) => item.creating === true);
  },
  isDownloadingMarketObject: (state) => {
    return state.marketObjects.some((item) => item.downloading === true);
  },
  isErrorMarketObject: (state) => {
    return state.marketObjects.some((item) => item.error === true);
  },
  getMarketObjectProcess: (state) => (fullPath) => {
    return state.marketObjects.find((item) => item.fullPath === fullPath);
  },
  getTokensMintin: (state) => state.tokensMintin,
  getTokenMintin: (state) => (symbol) => state.tokensMintin[symbol],
  showG2cInProcessModal: (state) => state.showG2cInProcessModal,
  g2cAlert: (state) => state.g2cAlert,
  getMessages: (state) => state.messages,
};
export const actions = {};
export const mutations = {
  [SET_G2C_IN_PROCESS](state, data) {
    state.g2cInProcess = data;
  },
  [SHOW_G2C_IN_PROCESS_MODAL](state, data) {
    state.showG2cInProcessModal = data;
  },
  [SET_MARKET_OBJECT](state, data) {
    console.log({ data });
    if (state.marketObjects.some((item) => item.fullPath === data.fullPath)) {
      state.marketObjects = state.marketObjects.map((item) => {
        if (item.fullPath === data.fullPath) {
          item = Object.assign(item, data);
          if (item.error) delete item.error;
        }
        return item;
      });
    } else state.marketObjects.push(data);
    state.marketObjects = state.marketObjects.filter((item) => {
      if (
        Object.entries(item).some(
          (value) =>
            value[0] !== 'fullPath' && value[1] !== null && value[1] !== false,
        )
      )
        return item;
    });
  },
  [ERROR_CREATING_MARKET_OBJECT](state, data) {
    if (state.marketObjects.some((item) => item.fullPath === data.fullPath)) {
      state.marketObjects = state.marketObjects.map((item) => {
        if (item.fullPath === data.fullPath) {
          item = Object.assign(item, data);
        }
        return item;
      });
    } else state.marketObjects.push(data);
    state.marketObjects = state.marketObjects.filter((item) => {
      if (
        Object.entries(item).some(
          (value) =>
            value[0] !== 'fullPath' && value[1] !== null && value[1] !== false,
        )
      )
        return item;
    });
  },
  [REMOVE_MARKET_OBJECT](state, data) {
    state.marketObjects = state.marketObjects.filter(
      (item) => item.fullPath !== data.fullPath,
    );
  },
  [ADD_MESSAGE](state, id) {
    if (id !== null && typeof id !== 'undefined') {
      if (!state.messages.some((item) => item === id)) {
        state.messages.push(id);
      }
    }
  },
  [REMOVE_MESSAGE](state, id) {
    if (id !== null && typeof id !== 'undefined')
      state.messages = state.messages.filter((item) => item !== id);
  },
  [SET_TOKEN_MINTIN](state, data) {
    console.log({ data });
    if (state.tokensMintin === null) state.tokensMintin = {};
    let tokenMintin = state.tokensMintin[data.symbol];
    if (data.remove && typeof tokenMintin !== 'undefined') {
      delete state.tokensMintin[data.symbol];
      state.g2cAlert = null;
    } else {
      if (typeof tokenMintin === 'undefined') tokenMintin = {};
      state.tokensMintin[data.symbol] = Object.assign(tokenMintin, data);

      if (data.status === 'mintin') state.g2cInProcess = true;
      if (data.status === 'error') {
        state.g2cAlert = {
          type: 'create_product',
          action: 'error',
          message: data.error,
          item: state.tokensMintin[data.symbol].item,
          name: state.tokensMintin[data.symbol].name,
        };
        state.g2cInProcess = false;
      }
      if (data.status === 'done') {
        state.g2cAlert = {
          type: 'create_product',
          item: state.tokensMintin[data.symbol].item,
          name: state.tokensMintin[data.symbol].name,
          action: 'success',
          message: '',
        };
        state.g2cInProcess = false;
      }
    }
  },
  [CREATE_G2C_ALERT](state, data) {
    state.g2cAlert = data;
  },
  [REMOVE_G2C_ALERT](state, data) {
    state.g2cAlert = null;
  },
};
