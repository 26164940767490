import {
  decryptContent,
  decryptMessage,
  encryptContent,
  encryptMessage,
  handleErrors,
} from '~/utils/functions';
import {
  CustomError,
  EXCHANGE_RATE_MODE_BSV_TO_USD,
  EXCHANGE_RATE_MODE_USD_TO_SAT,
  EXTRA_MINER_MARGIN,
  MESSAGES_STATUS_ACCEPTED,
  MESSAGES_STATUS_PENDING,
  MESSAGES_STATUS_PRECHARGED,
  MESSAGES_STATUS_PRECREATED,
  MESSAGES_STATUS_PREPAID,
  MESSAGES_STATUS_PRESHARED,
  MESSAGES_STATUS_REJECTED,
  MIN_VALUE_TO_CALCULATE_RATE,
  MESSAGES_STREAM_IN,
  MESSAGES_STREAM_OUT,
  MESSAGES_TYPE_PAID,
  MESSAGES_TYPE_FREE,
  BYTES_MINER_COMMISION,
  MESSAGES_TYPE_PRIVACY,
  G2C_MESSAGES_COMMISION_TYPE,
  MIN_BALANCE_SATS_MARGIN,
} from '~/vars/api';
import mpDB from '~/indexedDB/mpDB';
import {
  ADD_MESSAGE,
  ADD_MESSAGE_PENDING_TO_UNBLOCK,
  REMOVE_MESSAGE,
  SET_G2C_IN_PROCESS,
  SET_LOADING_CONNECTION_MESSAGES,
  TOGGLE_LOADING_CHATS,
} from '~/vars/store/mutations';
import { setChats, setConnectionMessages } from '~/vars/store/actions';

export default ({ app, $axios, store }, getTokens) => ({
  /**
   * Get all messages received and sent with chatname (nick) specified user
   * @param {OBJECT} chatname required, others could be optional
   * @returns OBJECT {ins, outs}
   */
  auxDecrypt(messagesin) {
    return new Promise((resolve, reject) => {
      let pending = Object.keys(messagesin).length;
      if (pending <= 0) return resolve(messagesin);
      let msgopen = {};
      Object.keys(messagesin).map(async (idx) => {
        var element = Object.assign({}, messagesin[idx]);

        if (
          element.message !== null &&
          typeof element.message !== 'undefined'
        ) {
          element.message = await decryptMessage({
            message: element.message,
            from: element.sourcenick,
            to: element.destinationnick,
            prefix: element.created_at,
          });
          element.open = true;
        }
        element.id = idx.includes(app.$auth.user.data.id)
          ? idx
          : element.messageid;
        element.stream =
          element.sourceid === app.$auth.user.data.id
            ? MESSAGES_STREAM_OUT
            : MESSAGES_STREAM_IN;
        msgopen[idx] = {};
        msgopen[idx] = Object.assign(msgopen[idx], element);
        pending--;
        if (pending <= 0) return resolve(msgopen);
      });
    });
  },

  getMessages({
    datefrom,
    dateto,
    chatname,
    limit,
    lastid,
    searchins,
    searchouts,
    status,
  }) {
    return new Promise(async (resolve, reject) => {
      let response = await $axios.post(
        `users/${app.$auth.$state.user.data.id}/message2`,
        {
          datefrom,
          dateto,
          chatname,
          limit,
          lastid,
          searchins,
          searchouts,
          status,
        },
      );
      let messages = await this.auxDecrypt(response.data.data.messages);

      return resolve({ data: { messages } });
    });
  },
  /**
   * Get the number of pending received messages
   * @param {Object} chatname (nick)
   * @returns Number
   */
  async getPendingMessages({ chatname }) {
    try {
      const data = (
        await this.getMessages({
          chatname,
          status: 'pending',
          searchouts: false,
        })
      ).data;
      if (data.ins === undefined) return 0;
      else return Object.keys(data.ins).length;
    } catch (error) {
      throw error;
    }
  },
  /**
   * Get Array of SENT and RECEIVED messages modified with type propperty (outs | ins) and sorted by date
   * @param {Object} chatname limit
   * @returns
   */
  async setAllChatsWithMessages() {
    if (!app.store.getters['messages/chatsAreLoaded']) {
      app.store.commit(`messages/${TOGGLE_LOADING_CHATS}`, true);
      const connections = Object.values(app.$auth.user.data.connections);
      const savedConversations = Object.values(await mpDB.getAllChats());
      return savedConversations.length
        ? await this.setConversationsFromIndexedDB(
            connections,
            savedConversations,
          )
        : await this.getAndSetConversations(connections);
    }
  },
  async setConversationsFromIndexedDB(connections, savedConversations) {
    return new Promise(async (resolve, reject) => {
      const conversations = {};
      const loggedUserId = app.$auth.user.data.id;
      // Check if all connections in current user are saved on IndexedDB if not in will we request
      if (savedConversations.length !== connections.length) {
        const connectionsToRequestConversation = connections.filter(
          ({ sourceid, destinationid }) => {
            const userIdOfConnection =
              destinationid === loggedUserId ? sourceid : destinationid;
            return !Boolean(
              savedConversations.find(
                (savedMsg) => savedMsg.id === userIdOfConnection,
              ),
            );
          },
        );
        await this.getAndSetConversations(
          connectionsToRequestConversation,
        ).then(() => {
          resolve();
        });
      }
      const promises = savedConversations.map(async ({ id, messages }) => {
        const decryptedConversation = await decryptContent(
          `${id}-${loggedUserId}`,
          messages,
        );
        return JSON.parse(decryptedConversation);
      });
      await Promise.allSettled(promises).then((results) => {
        results.forEach((result, ind) => {
          conversations[savedConversations[ind].id] = result.value;
        });
        app.store.dispatch(`messages/${setChats}`, conversations);
        resolve();
      });
    });
  },
  async getAndSetConversations(connections) {
    return new Promise(async (resolve, reject) => {
      const chats = {};
      const { nick, id } = app.$auth.user.data;
      const promises = connections.map(({ destinationnick, sourcenick }) => {
        const chatname =
          destinationnick === nick ? sourcenick : destinationnick;

        return this.getChatMessages({
          chatname,
          limit: 10,
        });
      });

      await Promise.allSettled(promises).then(async (results) => {
        const formatedResultsForIndexedDB = [];

        results.forEach(async ({ value }, ind) => {
          if (connections[ind]) {
            const { destinationid, sourceid } = connections[ind];
            const registerId = destinationid === id ? sourceid : destinationid;
            chats[registerId] = value;
            formatedResultsForIndexedDB.push({
              id: registerId,
              messages: await encryptContent(
                `${registerId}-${id}`,
                JSON.stringify(value),
              ),
            });
          }
        });

        app.store.dispatch(`messages/${setChats}`, chats);
        mpDB.addChats(formatedResultsForIndexedDB);
        resolve(chats);
      });
    });
  },
  setAllConnectionMessages() {
    return new Promise(async (resolve, reject) => {
      try {
        if (!app.store.getters['messages/areConnectionMsgsLoaded']) {
          app.store.commit(`messages/${SET_LOADING_CONNECTION_MESSAGES}`, true);
          const connections = Object.values(
            store.state['connections'].connections,
          );
          const connectionMsgsSaved = await mpDB.getAllConnectionMsgs();
          connectionMsgsSaved.length
            ? await this.setConnectionMsgsFromIndexedDB(
                connections,
                connectionMsgsSaved,
              )
            : await this.getAndSetConectionsMsg(connections);
        }
        resolve();
      } catch {
        reject();
      }
    });
  },
  async setConnectionMsgsFromIndexedDB(connections, connectionMsgsSaved) {
    return new Promise(async (resolve, reject) => {
      const messages = {};
      const loggedUserId = app.$auth.user.data.id;
      // Check if all connections in current user are saved on IndexedDB if not in will we request
      if (connectionMsgsSaved.length !== connections.length) {
        const connectionsToRequestMsg = connections.filter(
          ({ sourceid, destinationid }) => {
            const userIdOfConnection =
              destinationid === loggedUserId ? sourceid : destinationid;
            return !Boolean(
              connectionMsgsSaved.find(
                (savedMsg) =>
                  savedMsg.id === userIdOfConnection && savedMsg.message !== '',
              ),
            );
          },
        );
        this.getAndSetConectionsMsg(connectionsToRequestMsg)
          .then(resolve())
          .catch(reject());
      }
      const promises = connectionMsgsSaved.map(({ id, message }) =>
        decryptContent(`${id}-${loggedUserId}`, message),
      );
      await Promise.allSettled(promises).then((results) => {
        results.forEach((result, ind) => {
          messages[connectionMsgsSaved[ind].id] = result.value;
        });

        app.store.dispatch(`messages/${setConnectionMessages}`, messages);
        resolve();
      });
    });
  },
  async getAndSetConectionsMsg(connections) {
    return new Promise(async (resolve, reject) => {
      const messages = {};
      const promises = connections.map((connection) =>
        this.getConnectionMessage(connection),
      );
      Promise.allSettled(promises).then((results) => {
        const formatedResultsForIndexedDB = [];
        const loggedUserId = app.$auth.user.data.id;
        results.forEach(async (result, ind) => {
          if (connections[ind]) {
            const { destinationid, sourceid } = connections[ind];
            const registerId =
              destinationid === loggedUserId ? sourceid : destinationid;
            messages[registerId] = result.value;
            formatedResultsForIndexedDB.push({
              id: registerId,
              message: await encryptContent(
                `${registerId}-${loggedUserId}`,
                result.value,
              ),
            });
          }
        });

        app.store.dispatch(`messages/${setConnectionMessages}`, messages);
        mpDB.addConnectionMsgs(formatedResultsForIndexedDB);
        resolve(messages);
      });
    });
  },
  async getChatRegisters({ chatname, limit, datefrom, lastid, dateto }) {
    return new Promise(async (resolve, reject) => {
      try {
        // limit:1 Filter last message from all messages
        await this.getMessages({
          chatname,
          limit,
          datefrom,
          dateto,
          lastid,
          searchins: false,
          searchouts: false,
        }).then(async ({ data }) => {
          let messages = Object.values(data.messages);
          // Sort by timestamp of in and out MIGHT MOVE FORWARD
          messages = messages.sort((a, b) => {
            return a.created_at - b.created_at;
          });
          return resolve(messages);
        });
      } catch (error) {
        throw error;
      }
    });
  },
  async getAndSetG2cMessage(msgs) {
    app.store.commit(`processing/${SET_G2C_IN_PROCESS}`, true);
    return new Promise(async (resolve, reject) => {
      try {
        const g2cMessages = [];
        const { id } = app.$auth.user.data;
        for (const msg of msgs) {
          const messageIdSplited = msg.id.split('-');
          if (msg.connection) {
            const connection = await app.store.getters[
              'connections/getConnection'
            ](messageIdSplited[1]);
            await this.getAndSetConectionsMsg([connection]);
          } else {
            const sourceId = messageIdSplited[1];
            const destinationId = messageIdSplited[2];
            const conversationId = id === sourceId ? destinationId : sourceId;
            const messageStored = await app.store.getters[
              'messages/getChatWithUser'
            ](conversationId).find((message) => message.id === msg.id);
            if (typeof messageStored !== 'undefined' && messageStored.message) {
              const messageToUpdate = Object.assign({}, messageStored);
              messageToUpdate['opening'] = false;
              app.store.dispatch('messages/updateMessageFromChat', {
                loggedUserId: id,
                msg: messageToUpdate,
                conversationId,
              });
            } else {
              const messagesPending =
                app.store.getters['processing/getMessages'];
              if (!messagesPending.some((item) => item === msg.id)) {
                app.store.commit(`processing/${ADD_MESSAGE}`, msg.id);
                g2cMessages.push(msg);
              }
            }
          }
        }
        if (g2cMessages.length > 0) {
          const messagesOpened = await this.getG2cMessages(g2cMessages);
          for (const msgOpened of messagesOpened) {
            const messageIdSplited = msgOpened.id.split('-');
            const sourceId = messageIdSplited[1];
            const destinationId = messageIdSplited[2];
            const conversationId = id === sourceId ? destinationId : sourceId;
            msgOpened['opening'] = false;
            app.store.dispatch('messages/updateMessageFromChat', {
              loggedUserId: id,
              msg: msgOpened,
              conversationId,
            });
            app.store.commit(`processing/${REMOVE_MESSAGE}`, msgOpened.id);
          }
        }
        app.store.commit(`processing/${SET_G2C_IN_PROCESS}`, false);
        return resolve(true);
      } catch (error) {
        console.log({ error });
        reject(new CustomError('Impossible to retrieve message'));
      }
    });
  },
  // Aqui siguen encriptados
  async getChatMessages({ chatname, limit, datefrom, lastid, dateto }) {
    return new Promise(async (resolve, reject) => {
      try {
        const g2cMessages = [];
        // limit:1 Filter last message from all messages
        await this.getMessages({
          chatname,
          limit,
          datefrom,
          dateto,
          lastid,
          searchins: false,
          searchouts: false,
        }).then(async ({ data }) => {
          let messages = Object.values(data.messages);
          // Sort by timestamp of in and out MIGHT MOVE FORWARD
          messages = messages.sort((a, b) => {
            return a.created_at - b.created_at;
          });
          //Get paid messages in block
          // messages = await this.getG2cMessages(messages);
          messages = messages.map((message) => {
            if (
              message.status === MESSAGES_STATUS_ACCEPTED ||
              message.sourceid === app.$auth.user.data.id
            ) {
              message['opening'] = true;
              g2cMessages.push(message);
            }
            // if (message.type !== MESSAGES_TYPE_FREE && !message.open) {
            //   message['open'] = false;
            // }
            return message;
          });
          if (g2cMessages.length > 0) this.getAndSetG2cMessage(g2cMessages);
          return resolve(messages);
        });
      } catch (error) {
        throw error;
      }
    });
  },
  // Aux function to retrieve required paid messages from g2clib history
  async getG2cMessages(messagesInfo) {
    const messages = JSON.parse(JSON.stringify(messagesInfo));
    const historyArray = {};
    try {
      for (const x of messages) {
        if (x.type !== MESSAGES_TYPE_FREE) {
          if (x.stream === MESSAGES_STREAM_OUT) {
            if (
              typeof x.historyversionsrc === 'number' &&
              x.historyversionsrc > -1
            ) {
              const historyIdentificator = `${x.sourcenick.substring(1)}@${
                x.destinationnick
              }`;
              if (historyArray[historyIdentificator] === undefined)
                historyArray[historyIdentificator] = {
                  versions: [],
                  versionids: {},
                };
              historyArray[historyIdentificator].versions.push(
                x.historyversionsrc.toString(),
              );
              historyArray[historyIdentificator].versionids[
                x.historyversionsrc.toString()
              ] = x.id;
              historyArray[historyIdentificator].message = {
                from: x.sourcenick,
                to: x.destinationnick,
              };
            }
          } else if (
            typeof x.historyversiondst === 'number' &&
            x.stream === MESSAGES_STREAM_IN
          ) {
            if (x.historyversiondst > -1) {
              const historyIdentificator = `${x.sourcenick.substring(1)}@${
                x.destinationnick
              }`;
              if (historyArray[historyIdentificator] === undefined)
                historyArray[historyIdentificator] = {
                  versions: [],
                  versionids: {},
                };
              historyArray[historyIdentificator].versions.push(
                x.historyversiondst.toString(),
              );
              historyArray[historyIdentificator].versionids[
                x.historyversiondst.toString()
              ] = x.id;
              historyArray[historyIdentificator].message = {
                from: x.sourcenick,
                to: x.destinationnick,
              };
            }
          }
        }
      }
      // Replace message object by iot's content
      let historyfullsize = Object.keys(historyArray).length;
      if (historyfullsize <= 0) return messages;
      for (const h in historyArray) {
        const msghist = historyArray[h];
        var failed = false;

        const objects = await app.$api.g2cMessages.getUserObject({
          message: msghist.message,
          retries: 0,
          versions: msghist.versions,
        });
        if (failed === false) {
          for (const vindex in objects) {
            const v = objects[vindex];

            const msgindex = messages.findIndex(
              (element) => element.id === msghist.versionids[v.version],
            );

            messages[msgindex].message = JSON.parse(v.object).message;
            messages[msgindex].open = true;
          }
        }

        historyfullsize = historyfullsize - 1;
        if (historyfullsize <= 0) return messages;
      }
    } catch (e) {
      failed = true;
      if (
        typeof e.error !== 'undefined' &&
        e.error.includes('No versions to show')
      ) {
        throw e;
      }
    }

    //  messages.filter(async (x) => {
    //     if (historyArray[x.searchidx] === > -1) {
    //       historyArray.push({ [x.searchidx]: x.historyversion.toString() });
    //     }
    //   });
  },
  /**
   * Get last message of the conversation with chatname specified
   * @param {Object} chatname (nick)
   * @returns Object
   */
  async getLastMessage({ chatname }) {
    let lastMessage = {};
    try {
      const message = await this.getChatMessages({ chatname, limit: 1 });
      // if there is message
      if (message !== undefined) {
        lastMessage = Object.assign({}, message);
        this.storeLastMessage({ message: lastMessage });
      } else {
        // if there isn't message -> undefined -> connection message
        lastMessage = undefined;
      }
      return lastMessage;
    } catch (error) {
      throw error;
    }
  },
  async getConnectionMessage(connection) {
    let cleanMessage = await this.getStoredConnectionMessage({ connection });
    const loggedUserId = app.$auth.user.data.id;
    if (cleanMessage === null) {
      const { destinationnick, sourcenick, sourceid } = connection;
      const pathToSeek =
        sourceid === loggedUserId
          ? '/' + MESSAGES_STREAM_OUT
          : '/' + MESSAGES_STREAM_IN;
      const nickToSeek =
        sourceid === loggedUserId ? destinationnick : sourcenick;
      const version =
        sourceid === loggedUserId
          ? connection.historyversionsrc
          : connection.historyversiondst;
      const { data } = await app.$api.connections.getConnectionObject(
        destinationnick === app.$auth.user.data.nick
          ? sourcenick
          : destinationnick,
        pathToSeek,
        nickToSeek,
        version !== undefined ? [`${version}`] : ['1'],
      );

      cleanMessage = data.object.message;
      this.storeConnectionMessage({ connection, message: cleanMessage });
    }
    return cleanMessage;
  },
  async storeConnectionMessage({ connection, message }) {
    const id =
      connection.sourceid === app.$auth.user.data.id
        ? connection.destinationid
        : connection.sourceid;
    app.store.dispatch('messages/setConnectionMessage', {
      messageId: id,
      rawMessage: message,
    });
  },
  async getStoredConnectionMessage({ connection }) {
    const id =
      connection.sourceid === app.$auth.user.data.id
        ? connection.destinationid
        : connection.sourceid;
    const storedMessage = await app.store.getters[
      'messages/getConnectionMessage'
    ](id);
    return storedMessage ? storedMessage : null;
  },
  async storeLastMessage({ message }) {
    const rawMessage = await encryptMessage({
      prefix: message.created_at,
      from: message.sourcenick,
      to: message.destinationnick,
      message: message.message,
    });
    const storedMessage = {
      [`${message.id}`]: rawMessage,
    };
    app.store.dispatch('messages/setLastMessage', {
      connection: `${message.sourceid}-${message.destinationid}`,
      message: JSON.stringify(storedMessage),
    });
  },
  async getStoredLastMessage({ message }) {
    const storedMessage = await app.store.getters['messages/getLastMessage'](
      `${message.sourceid}-${message.destinationid}`,
    );
    if (storedMessage && storedMessage[message.id]) {
      const cleanStoredMessage = await decryptMessage({
        prefix: message.created_at,
        from: message.sourcenick,
        to: message.destinationnick,
        message: storedMessage[message.id],
      });
      return cleanStoredMessage;
    }
    return null;
  },
  /**
   *
   * @param id
   * @returns {Promise<any>}
   */
  // Firestore ddbb storage
  async createMessage({
    from,
    to,
    datestamp,
    message,
    status,
    paymentauth,
    amount,
    type,
    historydatestampsrc,
    historyversionsrc,
    size,
  }) {
    const requestbsvexchange =
      amount !== 0
        ? await app.$api.commons.exchangeRates(1, EXCHANGE_RATE_MODE_BSV_TO_USD)
        : undefined;
    let msgcopy;
    if (message !== null && message !== undefined) {
      msgcopy = await encryptMessage({
        prefix: datestamp,
        from,
        to,
        message: `${message}`,
      });
    } else msgcopy = message;

    return await $axios.post(
      `users/${app.$auth.user.data.id}/message2/create`,
      {
        from,
        to,
        datestamp,
        message: msgcopy,
        status,
        paymentauth,
        satoshis: amount,
        requestbsvexchange,
        type,
        historydatestampsrc: historydatestampsrc,
        historyversionsrc: historyversionsrc,
        historydatestampdst: -1,
        historyversiondst: -1,
        size,
      },
    );
  },

  async updateMessage({
    message,
    status,
    destinationid,
    sourceid,
    type,
    paymentauth,
    requestbsvexchange,
    acceptbsvexchange,
    historydatestampsrc,
    historyversionsrc,
    historydatestampdst,
    historyversiondst,
    datestamp,
    from,
    to,
    messageid,
    size,
  }) {
    let msgcopy;
    if (message !== null && message !== undefined) {
      msgcopy = await encryptMessage({
        prefix: datestamp,
        from,
        to,
        message: `${message}`,
      });
    } else msgcopy = message;
    return $axios.put(
      `users/${app.$auth.user.data.id}/message2/update/${messageid}`,
      {
        paymentauth,
        message: msgcopy,
        status,
        destinationid,
        sourceid,
        type,
        requestbsvexchange,
        acceptbsvexchange,
        historydatestampdst: historydatestampdst,
        historyversiondst: historyversiondst,
        historydatestampsrc: historydatestampsrc,
        historyversionsrc: historyversionsrc,
        size,
      },
    );
  },
  async getMessageCost(value) {
    try {
      const messageCost = parseFloat(
        await app.$api.commons.exchangeRates(
          MIN_VALUE_TO_CALCULATE_RATE +
            Math.ceil(value.length) * BYTES_MINER_COMMISION,
          EXCHANGE_RATE_MODE_SAT_TO_USD,
        ),
      );
      return messageCost;
    } catch (error) {
      handleErrors(error);
    }
  },
  /**
   * Get text message from message object
   * @param message Object
   * @returns {Promise<unknown>}
   */
  // CLEANUP THIS MESS
  async getObjectMessage({ message }) {
    // Paid message
    if (
      (message.stream === MESSAGES_STREAM_OUT &&
        message.type !== MESSAGES_TYPE_FREE) ||
      (message.type !== MESSAGES_TYPE_FREE &&
        message.stream === MESSAGES_STREAM_IN &&
        message.status === MESSAGES_STATUS_ACCEPTED)
    ) {
      //Comes unencrypted from g2clib
      //  const g2ctoken = this.getToken();
      //  return new Promise(async (resolve, reject) => {
      // REMPORRY FIX

      return message.message; //Clear
      //
      //return resolve('*WARNING! COMES FROM DDBB*' + messageContent);
      // END TEMPORY FIX
      //  try {
      //    await app.$api.g2cMessages
      //      .getUserObject({ message, g2ctoken })
      //      .then(async (response) => {
      //        if (response === undefined) {
      //          return reject(Error('Undefined response'));
      //        }
      //        if (response.error !== undefined) {
      //          return reject(new Error(response.error));
      //        } else {
      //          return resolve(response.message);
      //        }
      //      })
      //      .catch((e) => {
      //        return resolve('Message not available');
      //      });
      //  } catch (error) {
      //    return reject(error);
      //  }
      //  });
      // Free message (volatile)
    } else if (
      (message.stream === MESSAGES_STREAM_IN &&
        [MESSAGES_STATUS_ACCEPTED, MESSAGES_STATUS_PENDING].includes(
          message.status,
        ) &&
        message.type === MESSAGES_TYPE_FREE) ||
      message.stream === MESSAGES_STREAM_OUT
    ) {
      const messageContent =
        message.message; /*decrypted from ddbb loader await decryptMessage({
        prefix: message.created_at,
        from: message.sourcenick,
        to: message.destinationnick,
        message: message.message,
      });*/

      return messageContent;
    }
  },

  async walletPropose({ to, amount }) {
    const from = app.$auth.user.data.nick;
    const description = `Message payment from @${from} to @${to}`;
    return await app.$api.payments.walletProposePayment({
      destinationnick: to,
      amount,
      description,
      commissiontype: G2C_MESSAGES_COMMISION_TYPE,
    });
  },
  async walletAccept(paymentauth) {
    if (!app.$api.commons.satsEnabled())
      return await this.walletDismiss(paymentauth);
    return await app.$api.payments.walletConditionalAccept({
      paymentauth,
    });
  },
  async walletDismiss(paymentauth) {
    return await app.$api.payments.walletRejectPayment({
      paymentauth,
    });
  },
  async sendNewMessage({ option, userNick, message, amount, totalCost }) {
    const to = userNick;
    const from = app.$auth.user.data.nick;
    const datestamp = new Date().getTime();
    const prefix = datestamp;
    let rawMessage = message; /*await encryptMessage({
      prefix,
      from,
      to,
      message,
    });*/
    let paidMessage = null;
    const blockMessage = option !== MESSAGES_TYPE_FREE;
    const payment = !app.$api.commons.satsEnabled()
      ? false
      : option === MESSAGES_TYPE_PAID;
    if (blockMessage) {
      let userHasEnoughBalance = true;
      if (app.$api.commons.userPaidFees()) {
        userHasEnoughBalance = await app.$api.commons.userHasEnoughBalance(
          totalCost,
        );
      }
      if (userHasEnoughBalance) {
        paidMessage = await this.sendPaidMessage({
          to,
          from,
          datestamp,
          rawMessage,
          amount,
          option: !app.$api.commons.satsEnabled()
            ? MESSAGES_TYPE_PRIVACY
            : option,
        });
      } else {
        throw new CustomError(app.i18n.t('connections.not_funds'));
      }
    }
    if (
      (paidMessage !== null && blockMessage) ||
      (paidMessage === null && !blockMessage)
    ) {
      await this.createMessage({
        to,
        from,
        datestamp,
        paymentauth: payment ? paidMessage.paymentAuth : null,
        message: rawMessage, // paid ? paidMessage.rawMessage : rawMessage,
        status: blockMessage ? paidMessage.status : MESSAGES_STATUS_PENDING,
        amount: payment ? paidMessage.exchangedCost : 0,
        type: option,
        historydatestampsrc: blockMessage ? paidMessage.historyDatestamp : -1,
        historyversionsrc: blockMessage ? paidMessage.historyVersion : -1,
        size: blockMessage ? paidMessage.size : null,
      });
    } else throw null;
  },

  async sendPaidMessage({ to, from, datestamp, rawMessage, amount, option }) {
    let exchangedCost = amount;
    if (app.$api.commons.fiatEnabled()) {
      exchangedCost = await app.$api.commons.exchangeRates(
        amount,
        EXCHANGE_RATE_MODE_USD_TO_SAT,
      );
    }
    // Wallet payment and create object can be paralelized
    const promises = [];

    // Start object create depending on if it already exists or not
    promises.push(
      await app.$api.g2cMessages.createMessageObject({
        from,
        to,
        message: rawMessage,
        datestamp,
        streaming: MESSAGES_STREAM_OUT,
      }),
    );
    const payment = option === MESSAGES_TYPE_PAID;
    if (payment) {
      // Start wallet poropose
      promises.push(
        await this.walletPropose({
          amount: exchangedCost,
          to,
        }),
      );
    }

    return Promise.allSettled(promises).then(async (results) => {
      const passedWallet = payment ? results[1].status === 'fulfilled' : true;
      const passedObject = results[0].status === 'fulfilled';

      let error;
      let status = null;
      let paymentAuth = null;
      let historyVersion = -1;
      let historyDatestamp = -1;
      let size = null;
      if (passedWallet === true && passedObject === true) {
        paymentAuth = payment ? results[1].value.paymentauth : null;
        historyVersion = results[0].value.historyVersion;
        historyDatestamp = results[0].value.historyDatestamp;
        size = results[0].value.objectSize;
        status = MESSAGES_STATUS_PENDING;
      } else if (passedObject === false && passedWallet === false) {
        // Try again
        error = app.i18n.t('connections.messages.errors.create.generic');
      } else if (passedObject === false) {
        // Failed object creation but payment ok -> prepaid
        if (payment) {
          paymentAuth = results[1].value.paymentauth;
        }
        status = MESSAGES_STATUS_PREPAID;
        error = app.i18n.t('connections.messages.errors.create.object');
      } else if (passedWallet === false) {
        // Failed wallet but object created ok -> precreated
        paymentAuth = null;
        status = MESSAGES_STATUS_PRECREATED;
        error = app.i18n.t('connections.messages.errors.create.payment');
        historyVersion = results[0].value.historyVersion;
        historyDatestamp = results[0].value.historyDatestamp;
        size = results[0].value.objectSize;
      }
      if (status !== null)
        return {
          paymentAuth,
          rawMessage,
          status,
          exchangedCost,
          historyVersion,
          historyDatestamp,
          size,
        };

      if (error) {
        app.$toast.error(error);
        return null;
      }
    });
  },
  // message es el objeto entero, content es solo el texto
  async continueSendNewMessage({ to, from, message, contentMessage }) {
    const amount = message.satoshis;
    const requestbsvexchange = message.requestbsvexchange;
    let paymentAuth = message.paymentauth;
    let datestamp = message.created_at;
    let historydatestampsrc = -1;
    let historyversionsrc = -1;
    let size = null;
    let error = null;
    if (message.status === MESSAGES_STATUS_PREPAID) {
      const messageObject = await app.$api.g2cMessages.createMessageObject({
        from,
        to,
        message: contentMessage,
        datestamp,
        streaming: MESSAGES_STREAM_OUT,
      });
      if (messageObject.error) {
        error = app.i18n.t('connections.messages.errors.create.object');
      } else {
        historyVersionsrc = messageObject.historyVersion;
        historyDatestampsrc = messageObject.historyDatestamp;
        size = messageObject.objectSize;
      }
    } else if (
      message.status === MESSAGES_STATUS_PRECREATED &&
      message.type === MESSAGES_TYPE_PAID
    ) {
      const walletPropose = await this.walletPropose({
        amount,
        to,
      });
      if (walletPropose.error) {
        error = app.i18n.t('connections.messages.errors.create.payment');
      }
      paymentAuth = walletPropose.data.paymentauth;
      requestbsvexchange = await app.$api.commons.exchangeRates(
        1,
        EXCHANGE_RATE_MODE_BSV_TO_USD,
      );
    }
    if (error === null) {
      await this.updateMessage({
        paymentauth: paymentAuth,
        datestamp,
        from,
        to,
        destinationid: message.destinationid,
        sourceid: message.sourceid,
        type: message.type,
        messageid: message.id,
        message: null,
        status: MESSAGES_STATUS_PENDING,
        requestbsvexchange,
        historydatestampsrc,
        historyversionsrc,
        size,
      });
    } else {
      app.$toast.error(error);
      throw null;
    }
  },
  async userCanAcceptMessage() {
    const userBalance = await app.$api.commons.getUserBalance();
    return userBalance.balanceSats > MIN_BALANCE_SATS_MARGIN;
  },
  async acceptMessage({ from, message }) {
    const to = app.$auth.user.data.nick;
    const promises = [];
    const userCanAccept = app.$api.commons.userPaidFees()
      ? await this.userCanAcceptMessage()
      : true;

    if (app.$api.commons.userPaidFees() && !userCanAccept)
      throw new CustomError(app.i18n.t('connections.not_funds'));

    promises.push(
      await app.$api.g2cMessages.createMessageObject({
        from,
        to,
        message: message.message,
        datestamp: message.created_at,
        streaming: MESSAGES_STREAM_IN,
      }),
    );
    const messageIdSplited = message.id.split('-');
    const { id } = app.$auth.user.data;
    const sourceId = messageIdSplited[1];
    const destinationId = messageIdSplited[2];
    const conversationId = id === sourceId ? destinationId : sourceId;
    app.store.dispatch('messages/updateMessageFromChat', {
      loggedUserId: app.$auth.user.data.id,
      msg: { ...message, open: true },
      conversationId,
    });
    const paidMessage = process.env.satsTransparencyEnabled
      ? false
      : message.type === MESSAGES_TYPE_PAID;
    if (paidMessage)
      promises.push(await this.walletAccept(message.paymentauth));
    Promise.allSettled(promises).then(async (results) => {
      const passedShare = results[0].status === 'fulfilled';
      const passedWallet = paidMessage
        ? results[1].status === 'fulfilled'
        : true;
      let error;
      let status = null;
      let paymentAuth = message.paymentauth;
      let historydatestampdst = -1;
      let historyversiondst = -1;
      let acceptbsvexchange = paidMessage
        ? await app.$api.commons.exchangeRates(1, EXCHANGE_RATE_MODE_BSV_TO_USD)
        : -1;
      let size = null;
      if (passedWallet === true && passedShare === true) {
        status = MESSAGES_STATUS_ACCEPTED;
        paymentAuth = null;
        historydatestampdst = results[0].value.historyDatestamp;
        historyversiondst = results[0].value.historyVersion;
        size = results[0].value.objectSize;
      } else if (passedShare === false && passedWallet === false) {
        // Try again
        error = app.i18n.t('connections.messages.errors.accept.generic');
      } else if (passedShare === false) {
        status = MESSAGES_STATUS_PRECHARGED;
        paymentauth = null;
        error = app.i18n.t('connections.messages.errors.accept.object');
      } else if (passedWallet === false) {
        status = MESSAGES_STATUS_PRESHARED;
        error = app.i18n.t('connections.messages.errors.accept.payment');
        historydatestampdst = results[0].value.historyDatestamp;
        historyversiondst = results[0].value.historyVersion;
        size = results[0].value.objectSize;
      }
      if (status !== null) {
        await this.updateMessage({
          status,
          paymentauth: paymentAuth,
          message: null,
          datestamp: message.created_at,
          from,
          to,
          destinationid: message.destinationid,
          sourceid: message.sourceid,
          type: message.type,
          messageid: message.id,
          acceptbsvexchange,
          historydatestampdst,
          historyversiondst,
          size,
        });
      }
      // autoaccept dont show toast
      // if (error) app.$toast.error(error);
    });
  },
  async continueAcceptMessage({ from, message }) {
    const to = app.$auth.user.data.nick;

    let paymentAuth = message.paymentauth;
    let acceptbsvexchange = message.acceptbsvexchange;
    let error = null;
    let historydatestampdst = -1;
    let historyversiondst = -1;
    let size = null;
    if (message.status === MESSAGES_STATUS_PRECHARGED) {
      const messageObject = await app.$api.g2cMessages.createMessageObject({
        from,
        to,
        message: message.message,
        datestamp: message.created_at,
        streaming: MESSAGES_STREAM_IN,
      });

      if (messageObject.error) {
        error = app.i18n.t('connections.messages.errors.accept.object');
      } else {
        historydatestampdst = messageObject.historyDatestamp;
        historyversiondst = messageObject.historyVersion;
        size = messageObject.objectSize;
      }
    } else if (
      message.status === MESSAGES_STATUS_PRESHARED &&
      message.type === MESSAGES_TYPE_PAID &&
      !process.env.satsTransparencyEnabled
    ) {
      const walletAccept = await this.walletAccept(paymentauth);

      if (walletAccept.error) {
        error = app.i18n.t('connections.messages.errors.accept.payment');
      } else {
        paymentAuth = null;
        acceptbsvexchange = await app.$api.commons.exchangeRates(
          1,
          EXCHANGE_RATE_MODE_BSV_TO_USD,
        );
      }
    }
    if (error === null) {
      await this.updateMessage({
        status: MESSAGES_STATUS_ACCEPTED,
        messageid: message.id,
        paymentauth: paymentAuth,
        message: null,
        datestamp: message.created_at,
        from,
        to,
        destinationid: message.destinationid,
        sourceid: message.sourceid,
        type: message.type,
        acceptbsvexchange,
        historydatestampdst,
        historyversiondst,
        size,
      });
    } else {
      app.$toast.error(error);
    }
  },
  async rejectMessage(messageToReject) {
    if (messageToReject.paymentauth) {
    }
    let result =
      messageToReject.type === MESSAGES_TYPE_PAID
        ? await this.walletDismiss(messageToReject.paymentauth).catch(
            (error) => {
              if (error.message.includes('rejected transaction'))
                console.log(error);
              return {};
            },
          )
        : {};

    if (!result.error) {
      const message = {
        status: MESSAGES_STATUS_REJECTED,
        paymentauth: null,
        message: null,
        messageid: messageToReject.id,
        datestamp: messageToReject.created_at,
        from: messageToReject.sourcenick,
        to: messageToReject.destinationnick,
        destinationid: messageToReject.destinationid,
        sourceid: messageToReject.sourceid,
        type: messageToReject.type,
      };

      await this.updateMessage(message);
    } else {
      app.$toast.error('Failed to reject message: ' + result.error);
    }
  },
});
