import { MARKETPLACE_NAME } from '~/vars/api';

export default {
  errors: {
    title: 'An error ocurred',
    currencies: `Error fetching available currencies, please try again or contact ${MARKETPLACE_NAME} Marketplace team support if it persists`,
  },
  add_funds: {
    title: 'Add Sats',
    subtitle: 'Send from another wallet',
    box: {
      base: {
        copy: 'Copied',
      },
      send: {
        copied: 'Paymail Copied',
        copy: 'Copy Paymail',
        first_line: 'Send from',
        second_line: 'another wallet',
        paymail: 'Paymail',
        address: 'BSV Address',
        tip: 'Send BSV from other wallet or exchange using your Network Paymail',
      },
      // buy: {
      //   first_line: 'Buy with',
      //   second_line: 'credit card',
      //   tip: 'You can deposit in your wallet Sats buying them with your Bank.',
      // },
    },
    modal: {
      //   bank: {
      //     title: 'Buy Sats with your Bank',
      //     subtitle: {
      //       buy_info:
      //         'Please select your actual currency (or wich operates your Bank) and set the amount in that currency to buy.',
      //       resume_info:
      //         'Before make the buy, please check and confirm transaction info.',
      //       transfer_info:
      //         'Use the next banking information to send the money to ${MARKETPLACE_NAME} with your bank.',
      //     },
      //     fields: {
      //       currency: {
      //         label: 'Select currency',
      //       },
      //       amount: {
      //         label: 'Amount to buy',
      //         hint: 'Beta testing limit {amount}',
      //       },
      //       amount_received: {
      //         label: 'Amount to receive',
      //       },
      //       buttons: {
      //         buy: 'Buy with Credit Card',
      //         cancel: 'Cancel buy Sats',
      //         transfer_made: 'I made the banking transfer',
      //         transfer_later: "I'll send the money later",
      //       },
      //     },
      //     table: {
      //       exchange: 'Current Exchange Rate',
      //       fee: '${MARKETPLACE_NAME} fee {fee}%',
      //       total: 'Amount to exchange',
      //       hint: 'Final amount calculated with exchange rate at the moment ${MARKETPLACE_NAME} receives the money',
      //       amount: 'Amount to transfer:',
      //       account: 'Bank Account:',
      //       transfer_id: {
      //         label: 'Transfer Identification / Concept',
      //         warning:
      //           "Don't forget to include this reference. It's unique and without it we can't identify your transfer",
      //       },
      //       entity: 'Banking entity:',
      //       copy: {
      //         account: {
      //           success: 'Account copied successfully ',
      //           title: 'Copy Account',
      //         },
      //         reference: {
      //           success: 'Transaction reference copied successfully ',
      //           title: 'Copy Transaction Reference',
      //         },
      //         error: 'Error copying to clipboard',
      //       },
      //     },
      //     confirm: {
      //       title: 'Before lose all transfer info',
      //       subtitle:
      //         "Apparently, you are attempting to change or close some important information, be sure of have kept safe this information because if you continue the information will be cleared. Cancel and keep it safe if you haven't.",
      //     },
      //   },
      // },
      // free_sats: {
      //   error: 'Error, try again',
      //   claimed: 'Free sats claimed',
      // },
    },
  },
  send_funds: {
    base: {
      title: 'Send Sats',
      amount: 'Amount',
      send: 'Send Sats',
      sent: 'Sent',
      checking: 'Checking available Sats',
      preparing: 'Adding security layer',
    },
    external: {
      title: 'Send Sats to an external paymail',
      input: 'External Paymail Address',
    },
    internal: {
      title: `Send Sats to a ${MARKETPLACE_NAME} Marketplace user`,
      input: 'User (Handle)',
    },
    errors: {
      unexpected: 'Error in wallet transaction',
    },
  },
  my_assets: {
    title: {
      assets: 'My Items',
      mints: 'My Mints',
      validated_tickets: 'Validated Tickets',
      my_tickets: 'My Tickets',
      assets_history: 'My Items History',
    },
    info: {
      title: {
        mints: "You haven't mint anything yet",
        assets: "You don't have Items yet",
        validated_tickets: "You haven't validated any ticket yet",
        my_tickets: "You don't have any ticket",
        assets_history: {
          false: 'You have not let go of any Item',
          true: 'You have not had any inbound Item',
        },
      },
      discover: 'Discover the Market',
      subtitle: {
        all: 'Try creating your own product ',
        sale: 'List some of your products  ',
      },
    },
    details: {
      of_total: {
        copies: {
          info: 'Copies: {used} of {total}',
          bid: 'Copies: {used} of {total}',
          now: 'Copies: {used} of {total}',
        },
        tickets: {
          info: 'Tickets: {used} of {total}',
          bid: 'Tickets: {used} of {total}',
          now: 'Tickets: {used} of {total}',
        },
      },
      copies: {
        info: '{used} Copies',
        bid: '{used} Copies',
        now: '{used} Copies',
      },
      tickets: {
        info: '{used} Tickets',
        bid: '{used} Tickets',
        now: '{used} Tickets',
      },
      offering: {
        auction: 'Current bid: {amount}',
        buy: 'Price: {amount}',
      },
      creator: 'Created by ',
      owner: 'Owned by ',
      minted: 'Minted by ',
      redeemer: 'To Redeem by ',
      received_from: 'Owned by',
      others: 'Owned by other users',
      royalty: 'Royalty Fee: {unit}{royalty}',
      not_listed: 'Not listed',
      on_sale: 'On Sale',
      on_auction: 'Auction',
      on_processing: 'Processing ending...',
      deleted: 'Deleted',
      highest: 'Highest bid: {amount}',
      get_qr: 'Make sure you have got your QR Code before need to validate',
      expired_ticket: 'This ticket has expirated',
      validation_date: 'Redeemed at {date}',
      expiration_date: 'Expires at {date}',
      ticket_expiration: 'This is a Ticket, valid until {date}',
      see_tx: 'See tx',
    },
    redeem: {
      title: 'Welcome to the Redeem space',
      info: 'Here yo ucan scan the QR code to redeem tickets',
      sidebar_label: 'Redeem',
      button: 'Scan Ticket',
      section_title: 'Tickets Redeemed',
      header: {
        tickets: '{tickets} tickets redeemed',
      },
    },
    options: {
      delist: {
        label: 'Delist Item',
      },
      redeem: {
        label: 'Delete Item',
      },
      create_qr: {
        label: 'Redeem your Item',
      },
    },
    button: 'List to Market',
    manage_ticket: 'Manage Ticket',
    read_qr: {
      btn: 'Redeem Ticket',
      title: {
        target: 'Please target the Qr Code to read Ticket',
        confirm: 'Please verify ticket info and confirm Validation',
      },
      expired_ticket: 'This ticket has expirated',
      validate: 'Redeem Ticket',
      doc_type: {
        0: 'Please check NIF/NIE:',
        1: 'Please check Passport: ',
        2: 'Please check Drive License: ',
      },
      validation: {
        loading: 'Checking ticket',
        failed: 'Not valid ticket!',
        success: 'Valid ticket!',
      },
    },
    create_qr: {
      btn: 'QR Code',
      title: 'Get or Download your QR Code',
      subtitle:
        'Please select the copy to redeem and show the Qr Code generated to the responsible to redeem this item',
      select: 'Select the copy to redeem',
      create: 'Get QR Code',
      download: 'Download QR Code',
      warning: {
        info: "You won't be able to transfer or sell this ticket after you generate its QR Code.",
      },
      doc_option: {
        label:
          'Please select identity document to verify you when confirm the ticket validation',
        select: 'Select document',
        doc_id: 'Document Identification',
        validation: {
          required: 'Document required',
        },
      },
      doc_option_select: 'Select document',
      doc_options: {
        nif: 'NIF/NIE',
        passport: 'Passport',
        drive: 'Drive License',
      },
      errors: {
        fetch: `Error fetching QR Code, please try again or contact ${MARKETPLACE_NAME} Marketplace Support`,
        already_propose: `This QR Code has been created already, please contact ${MARKETPLACE_NAME} Marketplace Support`,
      },
    },
    tickets: {
      options: {
        delete: {
          label: 'Delete',
        },
        transfer: {
          label: 'Transfer',
        },
      },
    },
    assets_history: {
      details: {
        copies: {
          info: 'Nº tickets: {used}',
        },
        minted: 'Minted by ',
        redeemer: 'Redeemed by ',
        royalty: 'Royalty Fee: {unit}{royalty}',
        validation_date: 'at {date}',
        shipping: {
          delivery: {
            label: {
              out: 'Shipping Address',
              in: 'Address where receive',
            },
            title: {
              out: 'Address to Deliver',
              in: 'Address where receive',
            },
            subtitle: {
              out: 'Here you have the address where you need to deliver your physical product',
              in: 'This is the addres where you has to receive your physical product',
            },
          },
          pickup: {
            label: {
              in: 'Pick-up Address',
              out: 'Address to pick-up',
            },
            title: {
              in: 'Pick-up Address',
              out: 'Address to pick-up',
            },
            subtitle: {
              in: 'You can pick-up your physical product in this address showing your Item.',
              out: 'This is the address where user will come to pick-up the physical product',
            },
          },
          undefined: {
            label: {
              in: 'Pick-up Address',
              out: 'Address to pick-up',
            },
            title: {
              in: 'Pick-up Address',
              out: 'Address to pick-up',
            },
            subtitle: {
              in: 'Details for shipping not provided, please contact with seller',
              out: 'Details for shipping not provided, please contact with customer',
            },
          },
        },
        subscope: {
          transfer: {
            out: 'Transferred to ',
            in: 'Transferred from',
          },
          buynow: {
            out: 'Sold to ',
            in: 'Bought to',
          },
          auction: {
            out: 'Auction won by ',
            in: 'Auction won to',
          },
          ticket_transfer: {
            out: 'Redeemed by ',
            in: 'Owned by ',
          },
          redeem: {
            out: 'Deleted by ',
            in: 'Deleted by ',
          },
        },
      },
      options: {
        true: 'Items In',
        false: 'Items Out',
      },
    },
  },
  safe_box: {
    title: 'Safe and secure',
    subtitle: `Everything you can think of is now mintable with ${MARKETPLACE_NAME} `,
  },
  limits: {
    mints: {
      title: 'Items Minted',
      info: {
        title: '{used} of {available} Items Minted',
        text: 'You have created {usedPercent}% of the available Items, if you want to create more Items, ',
        contact: 'contact us',
      },
      type: 'Items',
    },
    capacity: {
      title: 'Data storage',
      info: {
        title: '{used} {usedSize} of {available} {totalSize} of data storage',
        text: 'You have used {usedPercent}% of the data storage, if you want to have more, ',
        contact: 'contact us',
      },
      type: 'mb',
    },
  },
};
