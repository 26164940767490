import { MARKETPLACE_NAME } from '~/vars/api';

export default {
  writing_blockchain: {
    first: 'We are creating your account.',
    second: 'Don’t close this window please.',
  },
  add_category: {
    first: `Meanwhile, did you know with ${MARKETPLACE_NAME} Marketplace you’ll be able to Mint Items super easy, sell them and gain every time they’re resold…`,
  },
  waiting: {
    first: 'This process might take a minute… Thanks  your patience.',
  },
  blockchain: {
    first: 'We’re building the buildings  as fast as we can…',
    second: 'Getting everything ready… Almost done',
  },
  error: {
    first: 'Something went wrong, your account was not created.',
    second: 'Please try again.',
    third: 'Make sure your Internet connection is save.',
    fourth: 'This time will work!',
  },
};
