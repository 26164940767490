import {
  EXCHANGE_RATE_MODE_EUR_TO_SAT,
  EXCHANGE_RATE_MODE_USD_TO_SAT,
  EXTRA_MINER_MARGIN,
  MIN_BALANCE_SATS_MARGIN,
} from '~/vars/api';
import {
  MARKET_FILTERS,
  MY_BIDS_FILTERS,
  MY_OFFERINGS_FILTERS,
  MY_PRODUCT_FILTERS,
  PROFILE_PRODUCT_FILTERS,
} from '~/vars/market';

export default ({ app }) => ({
  /**
   * Modules
   */
  vipProfileEnabled() {
    return (
      process.env.vipProfiles !== null &&
      typeof process.env.vipProfiles !== 'undefined'
    );
  },
  vipProfileFormated() {
    if (typeof process.env.vipProfiles === 'string')
      return JSON.parse(process.env.vipProfiles);
    if (
      typeof process.env.vipProfiles !== 'undefined' &&
      process.env.vipProfiles !== null &&
      typeof process.env.vipProfiles === 'object' &&
      process.env.vipProfiles.length > 0
    )
      return process.env.vipProfiles;
    return null;
  },
  vipProfile(nick) {
    return (
      !this.vipProfileFormated() ||
      this.vipProfileFormated().some((vip) => vip === nick)
    );
  },
  onlySellers() {
    return process.env.onlySellers === 'yes';
  },
  onlyShowCompany() {
    return process.env.onlyShowCompany === 'yes';
  },
  followsEnabled() {
    return process.env.followsEnabled === 'yes';
  },
  connectionsEnabled() {
    return process.env.connectionsEnabled === 'yes';
  },
  messagesEnabled() {
    return process.env.messagesEnabled === 'yes';
  },
  companyCode() {
    return process.env.companyCode === 'yes';
  },
  onlyCompanyMinter() {
    return process.env.onlyCompanyMinter === 'yes';
  },
  onlyCompanySeller() {
    return process.env.onlyCompanySeller === 'yes';
  },
  individualProfileEnabled() {
    return process.env.individualProfileEnabled === 'yes';
  },
  companyProfileEnabled() {
    return process.env.companyProfileEnabled === 'yes';
  },
  showVerifiedUser() {
    return process.env.showVerifiedUser === 'yes';
  },
  auctionEnabled() {
    return process.env.auctionEnabled === 'yes';
  },
  fixedPriceEnabled() {
    return process.env.fixedPriceEnabled === 'yes';
  },
  homeURL() {
    return process.env.homeURL;
  },
  mintOptions() {
    return process.env.mintOptions
      ? typeof process.env.mintOptions === 'string'
        ? JSON.parse(process.env.mintOptions).options
        : process.env.mintOptions.options
      : ['product', 'service', 'ticket', 'investment'];
  },
  marketFilters() {
    if (!process.env.marketFilters) {
      return MARKET_FILTERS;
    } else {
      const filters =
        typeof process.env.marketFilters === 'string'
          ? JSON.parse(process.env.marketFilters).filters
          : process.env.marketFilters.filters;
      return MARKET_FILTERS.filter(({ key }) =>
        filters.some((item) => item === key),
      );
    }
  },
  myProductFilters() {
    if (!process.env.myProductFiltersFilters) {
      return MY_PRODUCT_FILTERS;
    } else {
      const filters =
        typeof process.env.myProductFiltersFilters === 'string'
          ? JSON.parse(process.env.myProductFiltersFilters).filters
          : process.env.myProductFiltersFilters.filters;
      return MY_PRODUCT_FILTERS.filter(({ key }) =>
        filters.some((item) => item === key),
      );
    }
  },
  profileProductFilters() {
    if (!process.env.profileProductFilters) {
      return PROFILE_PRODUCT_FILTERS;
    } else {
      const filters =
        typeof process.env.profileProductFilters === 'string'
          ? JSON.parse(process.env.profileProductFilters).filters
          : process.env.profileProductFilters.filters;
      return PROFILE_PRODUCT_FILTERS.filter(({ key }) =>
        filters.some((item) => item === key),
      );
    }
  },
  myOfferingsFilters() {
    if (!process.env.myOfferingsFilters) {
      return MY_OFFERINGS_FILTERS;
    } else {
      const filters =
        typeof process.env.myOfferingsFilters === 'string'
          ? JSON.parse(process.env.myOfferingsFilters).filters
          : process.env.myOfferingsFilters.filters;
      return MY_OFFERINGS_FILTERS.filter(({ key }) =>
        filters.some((item) => item === key),
      );
    }
  },
  myBidsFilters() {
    if (!process.env.myBidsFilters) {
      return MY_BIDS_FILTERS;
    } else {
      const filters =
        typeof process.env.myBidsFilters === 'string'
          ? JSON.parse(process.env.myBidsFilters).filters
          : process.env.myBidsFilters.filters;
      return MY_BIDS_FILTERS.filter(({ key }) =>
        filters.some((item) => item === key),
      );
    }
  },
  /**
   * Sats
   */
  maxObjectSize() {
    return this.userPaidFees()
      ? process.env.maxObjectBlock
      : process.env.maxObjectOverlay;
  },
  userPaidFees() {
    return process.env.userPaidFees === 'yes';
  },
  satsEnabled() {
    return process.env.satsEnabled === 'yes';
  },
  fiatEnabled() {
    return process.env.fiatEnabled === 'yes';
  },
  pGatewayProvider() {
    return process.env.pGatewayProvider;
  },
  kycProvider() {
    return process.env.kycProvider && process.env.kycProvider !== 'no'
      ? process.env.kycProvider
      : null;
  },
  async exchangeRates(
    amount,
    mode = EXCHANGE_RATE_MODE_USD_TO_SAT,
    exchangeTX,
  ) {
    return await app.$api.g2cCommons.exchangeRates({
      amount,
      mode,
      exchangeTX,
    });
  },
  async getUserBalance() {
    return await app.$api.g2cCommons.getUserBalance();
  },
  async userHasEnoughBalance(cost, balanceSats = false) {
    const userBalance = await this.getUserBalance();
    // Added some extra miner margin
    if (this.fiatEnabled() && !balanceSats) {
      return userBalance.balanceUSD >= cost + EXTRA_MINER_MARGIN;
    }
    // const extraMinerMarginSats = await this.exchangeRates(
    //   EXTRA_MINER_MARGIN,
    //   EXCHANGE_RATE_MODE_EUR_TO_SAT,
    // );
    return userBalance.balanceSats >= cost + MIN_BALANCE_SATS_MARGIN;
  },
  /**
   * Currencies
   */

  async getBankingCurrencies() {
    return new Promise(async (resolve, reject) => {
      resolve(
        this.satsEnabled()
          ? CURRENCIES
          : CURRENCIES.filter((item) => item.iso !== 'Satoshi'),
      );
    });
  },
});
const CURRENCIES = [
  {
    iso: 'USD',
    symbol: '$',
    country: 'us',
    currency: 'United States dollar',
    exchange: 1087051,
  },
  {
    iso: 'EUR',
    symbol: '€',
    country: 'eu',
    currency: 'Euro',
    exchange: 1228367.63,
  },
  {
    iso: 'Satoshi',
    symbol: 'Sats',
    country: 'ww',
    currency: 'BSV Satoshi',
    exchange: 1,
    src: 'https://firebasestorage.googleapis.com/v0/b/networksv-prod.appspot.com/o/uploads%2Fprofile-images%2F052b7da9-6b3b-4b7f-85e7-07ab191fc02c.png',
  },
  // {
  //   iso: 'GBP',
  //   symbol: '£',
  //   country: 'uk',
  //   currency: 'Pound',
  //   exchange: 1400339.1,
  // },
];
