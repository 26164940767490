
export default {
  computed: {
    areGuestUser() {
      return !this.$auth.loggedIn;
    },
    isLoggedUser() {
      if (this.$auth.loggedIn) {
        return (
          this.$auth.user.data.id === this.user.id ||
          this.$auth.user.data.nick === this.user.nick
        );
      }
      return false;
    },
    loggedUserName() {
      if (!this.areGuestUser) {
        if (this.user && this.user.name)
          return `${this.user.name}${
            this.user.surname ? ' ' + this.user.surname : ''
          }`;
        return `@${this.user.nick}`;
      }
      return '';
    },
    isHost() {
      if (!this.areGuestUser && this.room) {
        return this.room.roomhostid === this.$auth.user.data.id;
      } else if (!this.areGuestUser && this.meeting) {
        return this.meeting.roomhostid === this.$auth.user.data.id;
      }
      return false;
    },
  },
};
