
import {
  SESSION_WELCOME,
  USER_ACTION_NO_REQUIRED,
  USER_ACTION_REQUIRED,
  USER_ENTITY_ONBOARDED,
  USER_ENTITY_VERIFIED,
  USER_LOGGED,
  USER_ONBOARDED,
  USER_OPTION_COMPANY,
  USER_OPTION_DEFAULT,
  USER_VERIFIED,
} from '~/vars/api';
import { showSessionModal, showVerifyEntityModal } from '~/vars/store/actions';
export default {
  props: {
    action: {
      type: String,
      default: USER_ACTION_NO_REQUIRED,
    },
    block: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      validator: (val) =>
        [
          'primary',
          'primary-light',
          'secondary',
          'tertiary',
          'quartiary',
          'dismiss',
          'success',
          'white',
          'text-root',
          'error',
          'text',
          'grey',
          'secondary-active',
          'primary-dark',
          'white-secondary',
        ].indexOf(val) !== -1,
      required: true,
    },
    id: {
      type: String,
      required: false,
    },
    customLoader: {
      type: Boolean,
      default: false,
    },
    depressed: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
    },
    customIcon: {
      type: String,
    },
    iconSize: {
      type: String,
      default: 'medium',
    },
    iconColor: {
      type: String,
      validator: (val) =>
        [
          'primary',
          'secondary',
          'tertiary',
          'quartiary',
          'success',
          'white',
          'text-root',
        ].indexOf(val) !== -1,
    },
    iconSolo: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    slotData: {
      type: Object,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    route: {
      type: String,
    },
    text: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: false,
      default: undefined,
    },
    plain: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loadingVerification: false,
    loadingOnboarding: false,
  }),
  computed: {
    loader() {
      return this.loading ? 'loading' : false;
    },
    textColor() {
      const colors = {
        primary: 'white',
        secondary: 'primary',
        tertiary: 'white',
        success: 'white',
        quartiary: 'primary',
        dismiss: 'primary',
        white: 'primary',
        grey: 'white',
        active: 'white',
        ['secondary-active']: 'white',
        'primary-dark': 'white',
        ['white-secondary']: 'secondary-active',
      };
      if (this.text || this.outlined) {
        return `${this.color}--text`;
      }
      return `${colors[this.color]}--text`;
    },
    bg() {
      const colors = {
        primary: 'white',
        secondary: 'primary',
        tertiary: 'white',
        success: 'white',
        'primary-dark': 'primary-dark',
        'white-secondary': 'white',
      };
      if (this.text && !this.plain) {
        return colors[this.color];
      }
      return '';
    },
    fiatEnabled() {
      return this.$api.commons.fiatEnabled();
    },
    isLogged() {
      return this.$auth.loggedIn;
    },
    isVerified() {
      return (
        this.$auth.user.data.is_verified === true &&
        this.$auth.user.data.verification_status === 'complete'
      );
    },
    isUser() {
      return this.$auth.user.data.user_option === USER_OPTION_DEFAULT;
    },
    isCompany() {
      return this.$auth.user.data.user_option === USER_OPTION_COMPANY;
    },
    companyNeedVerify() {
      return (
        this.isCompany &&
        USER_ACTION_REQUIRED[this.action][USER_ENTITY_VERIFIED]
      );
    },
    userNeedVerify() {
      return this.isUser && USER_ACTION_REQUIRED[this.action][USER_VERIFIED];
    },
    companyNeedOnboarding() {
      return (
        this.fiatEnabled &&
        this.isCompany &&
        USER_ACTION_REQUIRED[this.action][USER_ENTITY_ONBOARDED]
      );
    },
    userNeedOnboarding() {
      return (
        this.fiatEnabled &&
        this.isUser &&
        USER_ACTION_REQUIRED[this.action][USER_ONBOARDED]
      );
    },
    isOnboarded() {
      return (
        this.$auth.user.data.stripe_account_id &&
        this.$auth.user.data.onboarding_status === 'complete'
      );
    },
  },
  methods: {
    onBtnClicked(event) {
      if (!this.isLogged && USER_ACTION_REQUIRED[this.action][USER_LOGGED]) {
        this.$store.dispatch(`session/${showSessionModal}`, SESSION_WELCOME);
        return;
      } else if (this.isLogged) {
        if (
          !this.isVerified &&
          (this.userNeedVerify || this.companyNeedVerify)
        ) {
          if (this.$api.commons.kycProvider()) this.showVerifyModal();
          else this.showMintersModal();
          return;
        } else {
          if (this.fiatEnabled && !this.isOnboarded) {
            if (this.userNeedOnboarding || this.companyNeedOnboarding) {
              this.showMintersModal();
              return;
            }
          }
        }
      }
      this.$emit('clicked', event);
    },

    showVerifyModal() {
      this.loadingVerification = true;
      this.$store.dispatch('ui/showVerifyModal', {
        status: true,
        data: {
          action: this.action,
        },
      });
      this.loadingVerification = false;
    },
    showMintersModal() {
      this.loadingOnboarding = true;
      this.$store.dispatch('ui/showMinterModal', {
        status: true,
        data: {
          action: this.action,
        },
      });
      this.loadingOnboarding = false;
    },
  },
};
