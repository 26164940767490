export const MARKET_CATEGORIES = [
  'products',
  'collections',
  'creators',
  'invitations',
  'favorites',
  'art_events',
  'music',
  'gourmet',
  'gaming',
  'travel',
  'collectibles',
  'auctions',
];
export const MYPRODUCTS_CATEGORIES = ['all', 'my_creations', 'my_listings'];
export const CATEGORIES = [
  'art',
  'music',
  'fashion',
  'travel',
  'membership',
  'collectibles',
  'entertainment',
  'metaverse',
  'gaming',
  'photo',
  'education',
];

export const MARKET_FILTERS_DEFAULT = {
  stores: ['any'],
  categories: ['any'],
  type: ['any'],
  offer: ['any'],
};

export const MARKET_FILTERS = [
  // {
  //   key: 'access',
  //   multiple: false,
  //   options: [
  //     {
  //       key: 'any',
  //     },
  //     {
  //       key: 'public',
  //     },
  //     {
  //       key: 'invitations',
  //     },
  //   ],
  // },
  {
    key: 'type',
    multiple: true,
    options: [
      { key: 'any' },
      {
        key: 'product_physical',
      },
      {
        key: 'product_digital',
      },
      {
        key: 'ticket',
      },
      {
        key: 'service',
      },
    ],
  },
  {
    key: 'categories',
    multiple: true,
    options: [
      {
        key: 'any',
      },
      {
        key: 'art',
      },
      {
        key: 'music',
      },
      {
        key: 'fashion',
      },
      {
        key: 'travel',
      },
      {
        key: 'membership',
      },
      {
        key: 'collectibles',
      },
      {
        key: 'entertainment',
      },
      {
        key: 'metaverse',
      },
      {
        key: 'gaming',
      },
      {
        key: 'photo',
      },
      {
        key: 'education',
      },
    ],
  },
  {
    key: 'offer',
    multiple: false,
    options: [
      { key: 'any' },
      {
        key: 'auction',
      },
      {
        key: 'buy',
      },
    ],
  },
];

export const MY_PRODUCT_FILTERS_DEFAULT = {
  categories: ['any'],
  type: ['any'],
  creation: ['any'],
};

export const MY_PRODUCT_FILTERS = [
  {
    key: 'categories',
    multiple: false,
    options: [
      {
        key: 'any',
      },
      {
        key: 'art_events',
      },
      {
        key: 'music',
      },
      {
        key: 'gourmet',
      },
      {
        key: 'gaming',
      },
      {
        key: 'travel',
      },
      {
        key: 'collectibles',
      },
    ],
  },
  {
    key: 'type',
    multiple: false,
    options: [
      { key: 'any' },
      {
        key: 'auction',
      },
      {
        key: 'buy',
      },
    ],
  },
];

export const PROFILE_PRODUCT_FILTERS_DEFAULT = {
  categories: ['any'],
  type: ['any'],
  creation: ['any'],
};

export const PROFILE_PRODUCT_FILTERS = [
  {
    key: 'categories',
    multiple: false,
    options: [
      {
        key: 'any',
      },
      {
        key: 'art_events',
      },
      {
        key: 'music',
      },
      {
        key: 'gourmet',
      },
      {
        key: 'gaming',
      },
      {
        key: 'travel',
      },
      {
        key: 'collectibles',
      },
    ],
  },
  {
    key: 'type',
    multiple: false,
    options: [
      { key: 'any' },
      {
        key: 'auction',
      },
      {
        key: 'buy',
      },
    ],
  },
];

export const MY_OFFERINGS_BIDS_FILTERS_DEFAULT = {
  status: ['active'],
  type: ['any'],
  offer: ['any'],
};

export const MY_OFFERINGS_FILTERS = [
  {
    key: 'status',
    multiple: false,
    options: [
      {
        key: 'any',
      },
      {
        key: 'active',
      },
      {
        key: 'sold',
      },
    ],
  },
  {
    key: 'type',
    multiple: true,
    options: [
      { key: 'any' },
      {
        key: 'product_physical',
      },
      {
        key: 'product_digital',
      },
      {
        key: 'ticket',
      },
      {
        key: 'service',
      },
    ],
  },
  {
    key: 'offer',
    multiple: false,
    options: [
      { key: 'any' },
      {
        key: 'auction',
      },
      {
        key: 'buy',
      },
    ],
  },
];
export const MY_BIDS_FILTERS = [
  {
    key: 'status',
    multiple: false,
    options: [
      {
        key: 'any',
      },
      {
        key: 'active',
      },
      {
        key: 'closed',
      },
    ],
  },
  {
    key: 'type',
    multiple: true,
    options: [
      { key: 'any' },
      {
        key: 'product_physical',
      },
      {
        key: 'product_digital',
      },
      {
        key: 'ticket',
      },
      {
        key: 'service',
      },
    ],
  },
  {
    key: 'offer',
    multiple: false,
    options: [
      { key: 'any' },
      {
        key: 'auction',
      },
      {
        key: 'buy',
      },
    ],
  },
];
