export default {
  mint: {
    title: {
      writing: 'We are writing...',
      represent: 'An Item is a unique representation of something…',
      together: 'We’re putting all the blocks together…',
      patience: 'This can take several minutes, thank you for you patience…',
    },
    subtitle: {
      writing: 'Don’t close nor refresh this window please.',
      represent: 'Don’t close nor refresh this window please.',
      together: 'Don’t close nor refresh this window please.',
      patience: 'Don’t close nor refresh this window please.',
    },
  },
  listing: {
    title: {
      listing: 'Listing your Item on the Market',
    },
    status: {
      preparing: '{done} of {total} copies prepared',
      uploading: 'Sending request to list',
      listing: 'Request received',
      checking: '{done} of {total} copies checked',
      publishing: 'Publishing offering',
      done: 'List to market done!',
    },
    subtitle: {
      listing: 'Don’t close nor refresh this window please.',
    },
  },
  transfer: {
    title: {
      transferring: 'Transferring your Item',
    },
    status: {
      transferred: '{done} of {total} copies transferred',
      uploading: 'Uploading Transfer',
      done: 'Transfer done!',
    },
    subtitle: {
      transferring: 'Don’t close nor refresh this window please.',
    },
  },
  redeem: {
    title: {
      redeeming: 'Deleting your Item',
    },
    status: {
      redeemed: '{done} of {total} copies deleted',
      uploading: 'Uploading Deletion',
      done: 'Delete Item done!',
    },
    subtitle: {
      redeeming: 'Don’t close nor refresh this window please.',
    },
  },
};
