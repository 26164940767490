import { CustomError, INVESTMENT_AMOUNTS } from '~/vars/api';

export default ({ $axios, app }) => ({
  /**
   * Become a minter
   */
  async becomeMinter(country) {
    try {
      return $axios
        .post('/payment-gateway/onboarding-minter/begin', { country })
        .then(({ data }) => {
          if (data.error) {
            if (data.message.includes('Mobile number already in use'))
              data.message = app.i18n.t('stripe.onboarding.errors.phone_use');
            else {
              if (data.message.includes('Email address already in use'))
                data.message = app.i18n.t(
                  'stripe.onboarding.errors.email_used',
                );
              else
                data.message = app.i18n.t(
                  'stripe.onboarding.errors.refreshing',
                );
              app.$sentry.captureException(data.message);
            }
          }
          return data;
        });
    } catch (error) {
      app.$sentry.captureException(error);
      throw error;
    }
  },

  /**
   * Success page, check minter is enabled
   */
  async minterCheck() {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await $axios
          .get('/payment-gateway/onboarding-minter/check')
          .then(({ data }) => data);
        if (response.error) {
          throw new Error(response.message);
        }
        const info = response.data;

        if (info.minter_completed === true) {
          resolve('success');
        } else if (info.details_submitted === false)
          resolve('continue_onboarding');
        else if (
          info.currently_due.length >= 0 &&
          info.pending_verification.length > 0
        )
          resolve('verifying');
        else if (
          info.currently_due.length > 0 &&
          info.currently_due.some(
            (item) =>
              item.includes('verification.additional_document') ||
              INVESTMENT_AMOUNTS.includes('verification.document'),
          ) &&
          info.pending_verification.length === 0
        )
          resolve('pending');
        else resolve('continue_onboarding');
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors &&
          error.response.data.errors.some((error) =>
            error.includes('User not yet onboarded'),
          )
        )
          return resolve('become_minter');
        app.$sentry.captureException(error);
        reject(error);
      }
    });
  },

  async minterRefresh() {
    try {
      return await $axios
        .get('/payment-gateway/onboarding-minter/refresh')
        .then(({ data }) => {
          if (data.error) {
            if (data.message.includes('Mobile number already in use'))
              data.message = app.i18n.t('stripe.onboarding.errors.phone_use');
            else {
              data.message = app.i18n.t('stripe.onboarding.errors.refreshing');
              app.$sentry.captureException(data.message);
            }
          }
          return data;
        });
    } catch (error) {
      app.$sentry.captureException(error);
      throw error;
    }
  },

  async minterLogin() {
    try {
      return await $axios
        .get('/payment-gateway/login-account')
        .then(({ data }) => {
          return data;
        });
    } catch (error) {
      app.$sentry.captureException(error);
      throw error;
    }
  },

  /**
   * Buy now
   * @param {*} itemName
   * @param {*} itemQuantity
   */
  async buyNow({ itemId, quantity }) {
    try {
      return await $axios
        .post('/order/create', {
          checkout: {
            items: [
              {
                itemId,
                quantity,
              },
            ],
            scope: 'market',
          },
        })
        .then((response) => response.data.data.id);
    } catch (error) {
      throw error;
    }
  },

  /**
   * Send order_id
   * @param {*} order_id
   */
  sendOrderId(order_id) {
    try {
      return $axios.post('/payment-gateway/checkout/create', { order_id });
    } catch (error) {
      throw error;
    }
  },

  /**
   * Checkout pay now
   * @param {*} sessionId
   */

  async checkoutPay(sessionId) {
    try {
      return await $axios
        .post('/payment-gateway/checkout/retrieve', { session_id: sessionId })
        .then((response) => response.data);
    } catch (error) {
      throw error;
    }
  },
  /**
   * Get STRIPE user balance
   */
  async getBalance() {
    try {
      return await $axios
        .get('/payment-gateway/getuserbalance')
        .then((response) => response.data);
    } catch (error) {
      throw error;
    }
  },

  /**
   * Get STRIPE user currencies availables
   */
  async getUserCurrencies() {
    try {
      const balance = await this.getBalance();
      if (balance.error) throw new Error(balance.message);
      const userCurrencies = balance.data.available.map(
        ({ currency }) => currency,
      );
      return userCurrencies;
    } catch (error) {
      throw error;
    }
  },

  /**
   * check if customer has payment method attached.
   * If no seller_id it will take platform as seller
   * @param {*} seller_id
   */
  async hasPaymentMethodAttached(order_id) {
    try {
      const response = await $axios
        .post('/payment-gateway/check-payment-methods', {
          order_id,
        })
        .then(({ data }) => data.data);
      return response;
    } catch (error) {
      throw error;
    }
  },

  /**
   * Create a setup intent
   */
  async createSetupIntent() {
    try {
      const response = await $axios
        .post('/payment-gateway/create-setup-intent', {
          type: 'card',
        })
        .then(({ data }) => data.data);
      return response;
    } catch (error) {
      throw error;
    }
  },

  /**
   * Pay token - Fetches a payment intent and captures the client secret and status of payment intent
   * @param {*} order_id
   * @param {*} future_usage
   * @param {*} use_pm_attached
   */
  async createCheckoutPaymentIntent({
    order_id,
    future_usage,
    use_pm_attached,
    require_authentication,
    checkoutType,
  }) {
    try {
      const response = await $axios
        .post('/payment-gateway/create-checkout-payment-intent', {
          order_id,
          future_usage,
          use_pm_attached,
          require_authentication,
          checkoutType,
        })
        .then(({ data }) => data.data);
      return response;
    } catch (error) {
      throw error;
    }
  },

  /**
   * Pay token - Fetches a payment intent, update and confirm payment for a payment method attached to customer
   * @param {*} payment_intent_id
   * @param {*} options
   * @param {*} use_pm_attached
   */
  async updateAndConfirmCheckoutPaymentIntent(
    order_id,
    payment_intent_id,
    options,
    use_pm_attached,
  ) {
    try {
      const response = await $axios
        .post('/payment-gateway/update-and-confirm-checkout-payment-intent', {
          order_id,
          payment_intent_id,
          options,
          use_pm_attached,
        })
        .then(({ data }) => data.data);
      return response;
    } catch (error) {
      throw error;
    }
  },

  /**
   * Fetches a payment intent and captures the client secret
   * @param {*} order_id
   * @param {*} type
   */
  async createPaymentIntent(order_id, type) {
    try {
      const response = await $axios
        .post('/payment-gateway/create-payment-intent', {
          order_id,
          type,
        })
        .then(({ data }) => data.data);
      return response;
    } catch (error) {
      throw error;
    }
  },

  /**
   * Create customer
   */
  async createCustomer(order_id) {
    try {
      const response = await $axios
        .post('/payment-gateway/create-customer', {
          order_id,
        })
        .then(({ data }) => data.data);
      return response;
    } catch (error) {
      throw error;
    }
  },

  /**
   * Remove payment method attached to customer
   * @param {*} payment_method
   */
  async removePaymentMethod(payment_method, order_id) {
    try {
      const response = await $axios
        .post('/payment-gateway/remove-payment-method', {
          payment_method,
          order_id,
        })
        .then(({ data }) => data);
      return response;
    } catch (error) {
      throw error;
    }
  },
});
