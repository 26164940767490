
export default {
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      required: true,
      default: 'logo',
    },
    ext: {
      type: String,
      default: 'png',
    },
  },
};
