import { MARKETPLACE_NAME } from '~/vars/api';

export default {
  base: {
    show: `Do you want to send by email to users who are not part of ${MARKETPLACE_NAME} Marketplace?`,
    title: 'Send {type}',
    subtitle: `Send an email to your contacts to take part of ${MARKETPLACE_NAME} Marketplace and be able to get invited to this {type}`,
    type: {
      product_listed: 'Product Listed',
    },
    btn: 'Send email',
  },
  fields: {
    message: {
      label: 'Message',
    },
    email: {
      label: 'Email',
      placeholder: 'Add emails to send',
    },
    btn: {
      send: 'Send',
      add: 'Add to list',
    },
  },
  validation: {
    message: {
      required: 'Any message will be appreciated by your contact',
    },
    email: {
      required: 'Please enter an email',
      valid: 'Please enter a valid email format',
    },
  },
  errors: {
    generic: 'Error sending email',
  },
};
