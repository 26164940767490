import { MARKETPLACE_NAME } from '~/vars/api';

export default {
  public_profile: {
    base: {
      purchase_success: 'Successfully purchased ',
      unlock: 'Unlock feature {amount}',
    },
    errors: {
      purchasing: 'Error purchasing feature',
    },
    theme: {
      title: 'Choose theme',
    },
    header_image: {
      title: 'Upload Header Image',
      btn: 'Upload',
      uploading_image: 'Uploading Header Image',
      errors: {
        uploading_image: 'Error uploading Header Image, please try again.',
      },
    },
    state: {
      void: 'No state updated',
      tip: 'Update your state for rest of users to see',
    },
    video: {
      title: 'Video',
      btn: 'Upload',
      empty: 'Upload a video',
      upload: 'Link of your video ',
      hint: 'https://www.youtube.com/embed/gQtCEVgbPaI',
    },
    visits: {
      label: 'visits',
    },
    connections: {
      label: 'connections',
    },
    products: {
      label: 'Products',
    },
    public_products: {
      label: 'Public Items',
    },
    collections: {
      label: 'Collections',
    },
  },
  user_menu: {
    logged: 'Logged as',
    profile: {
      company: 'Profile',
      individual: 'Info',
    },
    activity: 'Activity',
    settings: 'Settings',
    logout: 'Logout',
  },
  edit_profile: {
    title: {
      first: {
        company: 'Complete your profile',
        individual: 'Complete your info',
      },
      rest: {
        company: 'Edit your profile',
        individual: 'Edit your info',
      },
    },
    button: 'Edit profile',
    uploading: {
      image: 'Uploading avatar image',
      info: 'Uploading user info',
    },
    fields: {
      name: 'Name',
      surname: 'Last Name',
      slogan: 'Slogan',
      title: 'Title',
      entity_name: 'Full Name',
      entity_address: 'Address',
      name_field: {
        user: 'Enter your name',
        company: 'Enter company name',
      },
      entity: {
        name: 'Enter your entity name',
        address: 'Enter address',
      },
      surname_field: {
        user: 'Enter your surname',
      },
      title_field: {
        title: 'Enter your title',
      },
      summary: {
        label: 'Summary',
        placeholder: 'Description about yourself',
      },
      knowledges: {
        label: 'Knowledges',
        placeholder: 'Categories',
        error: 'Error loading knowledges',
      },
      languages: {
        label: 'Languages',
        placeholder: 'Languages you speak',
        error: 'Error loading languages',
      },
      social: {
        label: 'Social Media',
        placeholder:
          'URL of LinkedIn, Medium, Twitter, Facebook, Instagram. Press Enter to add a new one',
        add: 'Add URL',
        remove: 'Remove URL',
      },
    },
    errors: {
      validation: "At least one field isn't valid",
      uploading: 'Error uploading user info',
    },
  },
  wallet: {
    base: {
      loading: 'Loading balance',
    },
  },
  activity: {
    fetching: 'Fetching your Activity',
    base: {
      no_description: 'No description provided',
    },
    sections: {
      wallet: 'Wallet',
      market: 'Market',
    },
    table: {
      amount: 'Amount',
      title: 'Activity',
      search: 'Search',
      sort: 'Sort by',
      page: 'Page',
      loading: 'Loading your wallet activities, please wait...',
      no_result: 'No matching activities found.',
      headers: {
        date: 'Date',
        event: 'Event',
        type: 'Type',
        amount: 'Amount',
        amount_paid: 'Amount paid',
        amount_received: 'Amount received',
        receipt: 'Receipt',
        token_name: 'Item name',
        user_name: 'User name',
      },
      subheaders: {
        description: 'Description',
        payment: 'Payment',
        commission_app: 'App commission',
        fee_miner: 'Miner fee',
        total: 'TOTAL',
        tx_id: 'Tx ID',
      },
      events: {
        sell: 'Sell',
        buy: 'Buy',
        royalty: 'Royalty',
        beneficiary: 'Beneficiary',
        auction: 'Auction won',
        buynow: 'Bought',
      },
      descriptions: {
        payment: {
          event: 'External payment',
          external:
            'Payment received from {user} ({paymail}) with message: {description}}',
        },
      },
      tool_tip: {
        transaction: {
          wiva: `Transaction paid by ${MARKETPLACE_NAME} Marketplace`,
        },
      },
      footer: {
        title: 'Tx per page',
      },
    },
    descriptions: {
      tree: 'Message blockchain writings (Metanet)',
      userConnection: 'Connection blockchain writings (Metanet)',
      userMessage: 'Message blockchain writings (Metanet)',
      userObject: '{fileName} file blockchain writings (Metanet)',
      testFile: '{fileName} (testFile) file blockchain writing (Metanet)',
    },
    none: {
      title: 'No Activity yet...',
      subtitle: `Here you'll find all the acctivity you'll make in ${MARKETPLACE_NAME} Marketplace`,
    },
  },
  settings: {
    sections: {
      general: 'General',
      paymentmethod: 'Payment method',
      billing: 'Billing',
    },
    contact: {
      title: 'Information',
      fields: {
        email: 'Email',
        phone: 'Phone number',
      },
      actions: {
        change_email: 'Change Email',
        change_phone: 'Change Phone',
      },
    },
    address: {
      title: 'Postal Addresses',
      set_default: {
        title: 'Set Default Postal Address',
        info: 'Are you sure you want to set this postal addres as your default Postal Address?',
      },
    },
    stripe: {
      title: `${MARKETPLACE_NAME} Marketplace Account`,
      verified: 'Verified user',
      button: 'Edit Account',
    },
    api: {
      title: 'Api Marketplace',
      api_key: 'Api Key:',
      info: 'Use this Api Key as bearer in your calls',
      button: 'Show Api Key',
    },
    notification: {
      title: 'Notifications',
      available: 'Available',
      not_ready: 'Not ready yet',
      fields: {
        direct_payments: 'Direct Payments',
        connections: 'Connection Request/Accept',
        messages: 'Message Request/Accept',
        rooms: {
          request: 'Room Request',
          confirmed: 'Room Confirmed',
          upcoming: 'Room Upcoming',
        },
      },
    },
    currency: {
      title: 'Currency',
      coins: {
        usd: 'USD',
      },
    },
    pin: {
      title: 'Set a Wallet pin',
    },
    share: {
      title: 'Invite friends',
    },
    links: {
      logout: 'Logout',
      support: 'Support & FAQs',
      conditions: 'Privacy Policy and Terms & Conditions',
      deactivate: 'Deactivate account',
    },
    payment_method: {
      title: 'Payment method',
      loading_saved: 'Loading Payment Methods',
      remove: 'Removing payment method',
      add_new: 'Add new payment method',
      add_pm: 'Add payment method',
      errors: {
        remove: 'Error removing payment method',
        no_such: 'No such payment method',
      },
    },
  },
  pricing: {
    title: 'Pricing',
    tip: 'You can configure your minimun connection and message price',
    header: 'Minimum prices:',
    connection: 'Connection Request',
    message: 'Receive Message',
    room: 'Room $/Minute',
  },
};
