import { waitNseconds } from '~/utils/functions';
import {
  G2C_CONNECTIONS_TYPE,
  G2C_CONNECTIONS_ENCODING,
  G2C_CONNECTIONS_PERMISSIONS,
  G2C_CONNECTIONS_COMMISION_TYPE,
  MESSAGES_STREAM_OUT,
  MESSAGES_STREAM_IN,
  CustomError,
} from '~/vars/api';

export default ({ app }) => ({
  response: null,
  async createConnectionsFolder(name) {
    return await app.$api.g2cCommons.createPathIfNotFound({
      path: '/',
      name,
    });
  },

  // User created connection
  async createConnectionObject({ streaming, from, to, message, datestamp }) {
    try {
      const g2ctoken = app.$api.g2cSession.getToken();
      const counterPart = streaming === MESSAGES_STREAM_IN ? from : to;
      const type = G2C_CONNECTIONS_TYPE;
      const path = `/${streaming}`;
      const permissions = G2C_CONNECTIONS_PERMISSIONS;
      const encoding = G2C_CONNECTIONS_ENCODING;

      const data = {
        from,
        to,
        message,
        timestamp: datestamp,
      };
      // CREATE REQUIRED PATHS ( once per userlifetime)

      const checkPath = await app.$api.g2cCommons
        .createPathIfNotFound({
          path: '/',
          name: streaming,
        })
        .catch((e) => {
          console.log(e);
          throw e;
        });
      if (checkPath !== 'Already exists') await waitNseconds(3);
      // eslint-disable-next-line no-undef
      const response = await app.$api.g2cProvider.createUserObject({
        g2ctoken,
        type,
        path,
        name: `${counterPart}`,
        data: JSON.stringify(data),
        encoding,
        permissions,
      });
      return response;
    } catch (error) {
      console.log('error triggered from promise', error);
      throw new Error(error);
    }
  },

  async getConnectionUserObject({ stream, name, mode = 'full' }) {
    const g2ctoken = app.$api.g2cSession.getToken();
    const path = `/${
      stream === 'in' ? MESSAGES_STREAM_IN : MESSAGES_STREAM_OUT
    }`;
    return await app.$api.g2cProvider.getUserObject({
      g2ctoken,
      path,
      name,
      mode,
    });
  },

  // MODIFY FOR BOTH STREAMS
  async getConnectionUserObjectList({ stream, mode = 'noobject' }) {
    const type = G2C_CONNECTIONS_TYPE;
    const g2ctoken = app.$api.g2cSession.getToken();
    let inConnections = [];
    let outConnections = [];
    if (typeof stream === 'undefined' || stream === 'in') {
      inConnections = await app.$api.g2cCommons.getUserObjectList({
        g2ctoken,
        path: '/' + MESSAGES_STREAM_IN,
        type,
        mode,
      });
    }
    if (typeof stream === 'undefined' || stream === 'out') {
      outConnections = await app.$api.g2cCommons.getUserObjectList({
        g2ctoken,
        path: '/' + MESSAGES_STREAM_OUT,
        type,
        mode,
      });
    }

    const objects = outConnections.concat(inConnections);
    return objects;
  },

  // On production stage will remove old mode retry
  async getConnectionObject({ destinationnick, pathToSeek, nickToSeek }) {
    try {
      const g2ctoken = app.$api.g2cSession.getToken();
      const versions = await app.$api.g2cProvider.getUserObjectHistoric({
        g2ctoken,
        path: pathToSeek,
        name: nickToSeek,
        type: 'userConnection',
      });

      //To be sure it exists, it whould be 1
      if (Object.keys(versions).length < 1)
        return reject(new Error('Connectionmessage not found'));
      else {
        return {
          data: {
            object: JSON.parse(versions[Object.keys(versions)[0]].object),
          },
        };
      }
    } catch (error) {
      const message = `@connections: ${error.message} at path "${MESSAGES_STREAM_IN}/${destinationnick}`;
      // eslint-disable-next-line no-console
      console.log('getConnectionObject -- Error -- ', message);
      throw new Error(message);
    }
  },
});
