
export default {
  props: {
    kind: {
      type: String,
      default: '',
    },
    userInfo: {
      type: Object,
    },
    loadingFromParent: {
      type: Boolean,
      default: false,
    },
    disableFromParent: {
      type: Boolean,
      default: false,
    },
    process: {
      type: String,
      default: 'signup',
    },
  },
  data() {
    return {
      valid: false,
      verifyServiceId: null,
      verificationCode: null,
      phoneCodeVerified: false,
      hideNumber: '',
      loading: false,
      feedback: null,
      errorValidation: null,
    };
  },
  watch: {
    loadingFromParent(data) {
      this.loading = data;
    },
    loading(value) {
      this.$emit('loading', value);
    },
  },
  methods: {
    /**
     * Get verification code phone and validate
     * @returns {Promise<void>}
     */
    async verificateEmailCode() {
      this.loading = true;
      this.feedback = this.$t('base.loading');
      try {
        let validation;
        let data;
        switch (this.process) {
          case 'signup':
            validation = await this.$api.user.validateEmailRegister({
              nick: this.userInfo.nick,
              code: this.verificationCode,
            });

            break;
          case 'login':
            validation = await this.$api.user.validateEmailLogged({
              nick: this.userInfo.nick,
              code: this.verificationCode,
            });
            data = validation.data.data;
            break;

          default:
            validation = {
              data: { error: true, message: 'process not found' },
            };
            break;
        }
        if (validation.data.error) throw validation.data;
        this.feedback = null;
        this.loading = false;
        this.errorValidation = null;
        this.$emit('next-step', data);
      } catch (error) {
        this.feedback = null;
        this.loading = false;
        if (error.message.includes('Not Found')) {
          this.errorValidation = this.$t(
            'login.email.errors.backup_file_not_found',
          );
        } else {
          this.errorValidation = this.$t('forms.error.email.verification');
        }
      }
    },
    /**
     * Get verification code phone and validate
     * @returns {Promise<void>}
     */
    async verificatePhoneCode() {
      this.loading = true;
      this.feedback = this.$t('base.loading');
      try {
        let validation;
        let data;
        switch (this.process) {
          case 'signup':
            validation = await this.$api.user.validateRegisteredPhoneCode({
              code: this.verificationCode,
              mobile_number: this.userInfo.number,
            });
            break;
          case 'login':
            validation = await this.$api.user.validateLoginPhoneCode({
              code: this.verificationCode,
              mobile_number: this.userInfo.number,
            });
            break;
          default:
            validation = { error: true, message: 'process not found' };

            break;
        }

        if (validation.data === 'pending') {
          throw validation.data;
        }
        if (validation.data.verified === 'pending') {
          throw 'error verifying code';
        }
        if (validation.error === true) {
          throw validation.message;
        }
        if (validation.data.verified && validation.data.verified === true) {
          data = validation.data;
          data['code'] = this.verificationCode;
        }
        this.feedback = null;
        this.loading = false;
        this.errorValidation = null;
        this.$emit('next-step', data);
      } catch (error) {
        this.feedback = null;
        this.loading = false;
        if (error === 'pending') {
          this.errorValidation = this.$t('forms.error.phone.waiting');
          setTimeout(() => {
            this.errorValidation = null;
          }, 5000);
        } else {
          this.errorValidation = this.$t('forms.error.phone.verification');
        }
      }
    },
  },
};
