
import { showSessionModal } from '~/vars/store/actions';
import LoginModal from '~/components/modals/LoginModal.vue';
import UserMixinHandlerLoggedUser from '~/components/user/UserMixinHandleLoggedUser.vue';
import { EventBus } from '../eventBus/EventBus';
import { USER_OPTION_COMPANY } from '~/vars/api';
export default {
  mixins: [UserMixinHandlerLoggedUser],
  components: {
    LoginModal,
  },
  props: {
    showOptions: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    creatingAccount() {
      return this.$route.path.includes('signup');
    },
    screenWidth() {
      return this.$vuetify.breakpoint.width;
    },
    logoToShow() {
      return this.screenWidth >= 426 || this.screenWidth === 0
        ? 'logo'
        : 'logo_without_crown';
    },
    isCompany() {
      return (
        this.$auth.loggedIn &&
        this.$auth.user.data.user_option === USER_OPTION_COMPANY
      );
    },
    onlyCompanyMinter() {
      return this.$api.commons.onlyCompanyMinter();
    },
    showVerifyBtn() {
      return (
        (!this.onlyCompanyMinter ||
          (this.onlyCompanyMinter && this.isCompany)) &&
        this.$auth.loggedIn &&
        this.$api.commons.vipProfile(this.$auth.user.data.nick)
      );
    },
  },
  methods: {
    toggleSideBar() {
      this.$store.commit('ui/toggleSidebar');
    },
    OnClickInSignInBtn() {
      if (this.$route.path.includes('/password/reset'))
        this.$router.push(this.localePath({ name: '/' }));
      this.$store.dispatch(`session/${showSessionModal}`, true);
    },
    OnClickInSignUpBtn() {
      this.$router.push(this.localePath({ name: 'session-signup' }));
    },
  },
  mounted() {
    EventBus.$on('open-login-modal', () => {
      this.$store.dispatch(`session/${showSessionModal}`, true);
    });
  },
};
