import { MARKETPLACE_NAME } from '~/vars/api';

export default {
  redirecting: {
    head: 'Redirecting Verification Provider',
    title: 'Redirecting to Verification Provider',
    info_1:
      'If everything goes well you will be redirected to Verification Provider.',
    info_2: `Otherwise, if you are not being redirected please check if you allow ${MARKETPLACE_NAME} Marketplace to show popups.`,
  },
  verify: {
    cta: {
      info: 'To {action} we need to verify your identity as a {userOption}',
      button: 'Verify Identity',
      actions: {
        connect: 'connect with other users',
        product_create: 'mint',
        product_buy: 'buy product',
        product_bid: 'make an offer',
        product_list: 'list a product to market',
      },
    },
    unverified: {
      title: 'Verify your identity',
      // boton
      button: 'Verify your Identity',
      // info de la modal que se abre
      info_minter:
        'To Create an Item we need to verify your identity. This helps us make sure every Item belongs to a real person',
      info_seller:
        'To Sell an Item we need to verify your identity. This helps us make sure every sale is by a real person',
    },
  },
  pending: {
    title: 'Verification Identity provider is verifying your info',
    close: 'Close this tab and wait till we notify you',
    timer: {
      full: 'In {timer} {time} this page will be closed',
      hours: 'hours',
      minutes: 'minutes',
      seconds: 'seconds',
      second: 'second',
    },
  },
  errors: {
    pending: 'Verification Provider is verifying your info',
    generic: 'Service not available',
  },
};
