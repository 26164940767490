export const SsrCarousel = () => import('../../node_modules/vue-ssr-carousel/index.js' /* webpackChunkName: "components/ssr-carousel" */).then(c => wrapFunctional(c.default || c))
export const BaseBadge = () => import('../../components/base/BaseBadge.vue' /* webpackChunkName: "components/base-badge" */).then(c => wrapFunctional(c.default || c))
export const BaseBtn = () => import('../../components/base/BaseBtn.vue' /* webpackChunkName: "components/base-btn" */).then(c => wrapFunctional(c.default || c))
export const BaseBtnLoader = () => import('../../components/base/BaseBtnLoader.vue' /* webpackChunkName: "components/base-btn-loader" */).then(c => wrapFunctional(c.default || c))
export const BaseCardSkeletonLoader = () => import('../../components/base/BaseCardSkeletonLoader.vue' /* webpackChunkName: "components/base-card-skeleton-loader" */).then(c => wrapFunctional(c.default || c))
export const BaseCopiesPrice = () => import('../../components/base/BaseCopiesPrice.vue' /* webpackChunkName: "components/base-copies-price" */).then(c => wrapFunctional(c.default || c))
export const BaseCurrencyAmount = () => import('../../components/base/BaseCurrencyAmount.vue' /* webpackChunkName: "components/base-currency-amount" */).then(c => wrapFunctional(c.default || c))
export const BaseEditPencil = () => import('../../components/base/BaseEditPencil.vue' /* webpackChunkName: "components/base-edit-pencil" */).then(c => wrapFunctional(c.default || c))
export const BaseFavorites = () => import('../../components/base/BaseFavorites.vue' /* webpackChunkName: "components/base-favorites" */).then(c => wrapFunctional(c.default || c))
export const BaseFeedbackError = () => import('../../components/base/BaseFeedbackError.vue' /* webpackChunkName: "components/base-feedback-error" */).then(c => wrapFunctional(c.default || c))
export const BaseFilterMenu = () => import('../../components/base/BaseFilterMenu.vue' /* webpackChunkName: "components/base-filter-menu" */).then(c => wrapFunctional(c.default || c))
export const BaseGrid = () => import('../../components/base/BaseGrid.vue' /* webpackChunkName: "components/base-grid" */).then(c => wrapFunctional(c.default || c))
export const BaseHandleSelect = () => import('../../components/base/BaseHandleSelect.vue' /* webpackChunkName: "components/base-handle-select" */).then(c => wrapFunctional(c.default || c))
export const BaseHeader = () => import('../../components/base/BaseHeader.vue' /* webpackChunkName: "components/base-header" */).then(c => wrapFunctional(c.default || c))
export const BaseHorizontalButton = () => import('../../components/base/BaseHorizontalButton.vue' /* webpackChunkName: "components/base-horizontal-button" */).then(c => wrapFunctional(c.default || c))
export const BaseHorizontalCard = () => import('../../components/base/BaseHorizontalCard.vue' /* webpackChunkName: "components/base-horizontal-card" */).then(c => wrapFunctional(c.default || c))
export const BaseIcon = () => import('../../components/base/BaseIcon.vue' /* webpackChunkName: "components/base-icon" */).then(c => wrapFunctional(c.default || c))
export const BaseIconNew = () => import('../../components/base/BaseIconNew.vue' /* webpackChunkName: "components/base-icon-new" */).then(c => wrapFunctional(c.default || c))
export const BaseImgPreview = () => import('../../components/base/BaseImgPreview.vue' /* webpackChunkName: "components/base-img-preview" */).then(c => wrapFunctional(c.default || c))
export const BaseInfoAmount = () => import('../../components/base/BaseInfoAmount.vue' /* webpackChunkName: "components/base-info-amount" */).then(c => wrapFunctional(c.default || c))
export const BaseInfoBox = () => import('../../components/base/BaseInfoBox.vue' /* webpackChunkName: "components/base-info-box" */).then(c => wrapFunctional(c.default || c))
export const BaseInfoContent = () => import('../../components/base/BaseInfoContent.vue' /* webpackChunkName: "components/base-info-content" */).then(c => wrapFunctional(c.default || c))
export const BaseInfoTip = () => import('../../components/base/BaseInfoTip.vue' /* webpackChunkName: "components/base-info-tip" */).then(c => wrapFunctional(c.default || c))
export const BaseInput = () => import('../../components/base/BaseInput.vue' /* webpackChunkName: "components/base-input" */).then(c => wrapFunctional(c.default || c))
export const BaseLoadingContent = () => import('../../components/base/BaseLoadingContent.vue' /* webpackChunkName: "components/base-loading-content" */).then(c => wrapFunctional(c.default || c))
export const BaseLoadingModal = () => import('../../components/base/BaseLoadingModal.vue' /* webpackChunkName: "components/base-loading-modal" */).then(c => wrapFunctional(c.default || c))
export const BaseMailingInvitationBtn = () => import('../../components/base/BaseMailingInvitationBtn.vue' /* webpackChunkName: "components/base-mailing-invitation-btn" */).then(c => wrapFunctional(c.default || c))
export const BaseMailingInvitationModal = () => import('../../components/base/BaseMailingInvitationModal.vue' /* webpackChunkName: "components/base-mailing-invitation-modal" */).then(c => wrapFunctional(c.default || c))
export const BaseModal = () => import('../../components/base/BaseModal.vue' /* webpackChunkName: "components/base-modal" */).then(c => wrapFunctional(c.default || c))
export const BaseNavbar = () => import('../../components/base/BaseNavbar.vue' /* webpackChunkName: "components/base-navbar" */).then(c => wrapFunctional(c.default || c))
export const BaseNotificationBadge = () => import('../../components/base/BaseNotificationBadge.vue' /* webpackChunkName: "components/base-notification-badge" */).then(c => wrapFunctional(c.default || c))
export const BaseNotificationsHandler = () => import('../../components/base/BaseNotificationsHandler.vue' /* webpackChunkName: "components/base-notifications-handler" */).then(c => wrapFunctional(c.default || c))
export const BaseNotificationsHandlerUserMessage = () => import('../../components/base/BaseNotificationsHandlerUserMessage.vue' /* webpackChunkName: "components/base-notifications-handler-user-message" */).then(c => wrapFunctional(c.default || c))
export const BaseOptionsBtns = () => import('../../components/base/BaseOptionsBtns.vue' /* webpackChunkName: "components/base-options-btns" */).then(c => wrapFunctional(c.default || c))
export const BasePageTabs = () => import('../../components/base/BasePageTabs.vue' /* webpackChunkName: "components/base-page-tabs" */).then(c => wrapFunctional(c.default || c))
export const BasePageTabsBtn = () => import('../../components/base/BasePageTabsBtn.vue' /* webpackChunkName: "components/base-page-tabs-btn" */).then(c => wrapFunctional(c.default || c))
export const BasePickupInfo = () => import('../../components/base/BasePickupInfo.vue' /* webpackChunkName: "components/base-pickup-info" */).then(c => wrapFunctional(c.default || c))
export const BaseProgressCard = () => import('../../components/base/BaseProgressCard.vue' /* webpackChunkName: "components/base-progress-card" */).then(c => wrapFunctional(c.default || c))
export const BaseReport = () => import('../../components/base/BaseReport.vue' /* webpackChunkName: "components/base-report" */).then(c => wrapFunctional(c.default || c))
export const BaseReportBtn = () => import('../../components/base/BaseReportBtn.vue' /* webpackChunkName: "components/base-report-btn" */).then(c => wrapFunctional(c.default || c))
export const BaseReportModal = () => import('../../components/base/BaseReportModal.vue' /* webpackChunkName: "components/base-report-modal" */).then(c => wrapFunctional(c.default || c))
export const BaseSettingsIosModal = () => import('../../components/base/BaseSettingsIosModal.vue' /* webpackChunkName: "components/base-settings-ios-modal" */).then(c => wrapFunctional(c.default || c))
export const BaseShareBtn = () => import('../../components/base/BaseShareBtn.vue' /* webpackChunkName: "components/base-share-btn" */).then(c => wrapFunctional(c.default || c))
export const BaseShowUser = () => import('../../components/base/BaseShowUser.vue' /* webpackChunkName: "components/base-show-user" */).then(c => wrapFunctional(c.default || c))
export const BaseStepper = () => import('../../components/base/BaseStepper.vue' /* webpackChunkName: "components/base-stepper" */).then(c => wrapFunctional(c.default || c))
export const BaseTag = () => import('../../components/base/BaseTag.vue' /* webpackChunkName: "components/base-tag" */).then(c => wrapFunctional(c.default || c))
export const BaseTypeWriter = () => import('../../components/base/BaseTypeWriter.vue' /* webpackChunkName: "components/base-type-writer" */).then(c => wrapFunctional(c.default || c))
export const BaseUserFullName = () => import('../../components/base/BaseUserFullName.vue' /* webpackChunkName: "components/base-user-full-name" */).then(c => wrapFunctional(c.default || c))
export const BaseUserHeaderSkeleton = () => import('../../components/base/BaseUserHeaderSkeleton.vue' /* webpackChunkName: "components/base-user-header-skeleton" */).then(c => wrapFunctional(c.default || c))
export const BaseVerifyIcon = () => import('../../components/base/BaseVerifyIcon.vue' /* webpackChunkName: "components/base-verify-icon" */).then(c => wrapFunctional(c.default || c))
export const CommonsAddImagesMultiple = () => import('../../components/commons/AddImagesMultiple.vue' /* webpackChunkName: "components/commons-add-images-multiple" */).then(c => wrapFunctional(c.default || c))
export const CommonsBackendPaginateMixinHandler = () => import('../../components/commons/BackendPaginateMixinHandler.vue' /* webpackChunkName: "components/commons-backend-paginate-mixin-handler" */).then(c => wrapFunctional(c.default || c))
export const CommonsCustomFilters = () => import('../../components/commons/CustomFilters.vue' /* webpackChunkName: "components/commons-custom-filters" */).then(c => wrapFunctional(c.default || c))
export const CommonsCustomSelectableFilters = () => import('../../components/commons/CustomSelectableFilters.vue' /* webpackChunkName: "components/commons-custom-selectable-filters" */).then(c => wrapFunctional(c.default || c))
export const CommonsCustomSelectableFiltersCopy = () => import('../../components/commons/CustomSelectableFiltersCopy.vue' /* webpackChunkName: "components/commons-custom-selectable-filters-copy" */).then(c => wrapFunctional(c.default || c))
export const CommonsG2cProcessMixinHandler = () => import('../../components/commons/G2cProcessMixinHandler.vue' /* webpackChunkName: "components/commons-g2c-process-mixin-handler" */).then(c => wrapFunctional(c.default || c))
export const CommonsOfflineWarning = () => import('../../components/commons/OfflineWarning.vue' /* webpackChunkName: "components/commons-offline-warning" */).then(c => wrapFunctional(c.default || c))
export const CommonsPaginateMixinHandler = () => import('../../components/commons/PaginateMixinHandler.vue' /* webpackChunkName: "components/commons-paginate-mixin-handler" */).then(c => wrapFunctional(c.default || c))
export const CommonsSearchByWordsFilter = () => import('../../components/commons/SearchByWordsFilter.vue' /* webpackChunkName: "components/commons-search-by-words-filter" */).then(c => wrapFunctional(c.default || c))
export const CommonsTabletWarning = () => import('../../components/commons/TabletWarning.vue' /* webpackChunkName: "components/commons-tablet-warning" */).then(c => wrapFunctional(c.default || c))
export const CommonsTransitionsLoader = () => import('../../components/commons/TransitionsLoader.vue' /* webpackChunkName: "components/commons-transitions-loader" */).then(c => wrapFunctional(c.default || c))
export const CompanyLogo = () => import('../../components/company/CompanyLogo.vue' /* webpackChunkName: "components/company-logo" */).then(c => wrapFunctional(c.default || c))
export const CollectionsCollectionCard = () => import('../../components/collections/CollectionCard.vue' /* webpackChunkName: "components/collections-collection-card" */).then(c => wrapFunctional(c.default || c))
export const CollectionsCollectionMainBanner = () => import('../../components/collections/CollectionMainBanner.vue' /* webpackChunkName: "components/collections-collection-main-banner" */).then(c => wrapFunctional(c.default || c))
export const ConnectionsConnectionInformation = () => import('../../components/connections/ConnectionInformation.vue' /* webpackChunkName: "components/connections-connection-information" */).then(c => wrapFunctional(c.default || c))
export const CreateAccountPinVerify = () => import('../../components/create-account/PinVerify.vue' /* webpackChunkName: "components/create-account-pin-verify" */).then(c => wrapFunctional(c.default || c))
export const CreateAccountRegisterEmail = () => import('../../components/create-account/RegisterEmail.vue' /* webpackChunkName: "components/create-account-register-email" */).then(c => wrapFunctional(c.default || c))
export const CreateAccountRegisterPhone = () => import('../../components/create-account/RegisterPhone.vue' /* webpackChunkName: "components/create-account-register-phone" */).then(c => wrapFunctional(c.default || c))
export const CreateAccountStepsNavbar = () => import('../../components/create-account/StepsNavbar.vue' /* webpackChunkName: "components/create-account-steps-navbar" */).then(c => wrapFunctional(c.default || c))
export const CreateAccountTwelveWordsGenerator = () => import('../../components/create-account/TwelveWordsGenerator.vue' /* webpackChunkName: "components/create-account-twelve-words-generator" */).then(c => wrapFunctional(c.default || c))
export const EventBus = () => import('../../components/eventBus/EventBus.js' /* webpackChunkName: "components/event-bus" */).then(c => wrapFunctional(c.default || c))
export const HomeCarousel = () => import('../../components/home/HomeCarousel.vue' /* webpackChunkName: "components/home-carousel" */).then(c => wrapFunctional(c.default || c))
export const HomeFavouritesProductsCarousel = () => import('../../components/home/HomeFavouritesProductsCarousel.vue' /* webpackChunkName: "components/home-favourites-products-carousel" */).then(c => wrapFunctional(c.default || c))
export const HomeFeatureCard = () => import('../../components/home/HomeFeatureCard.vue' /* webpackChunkName: "components/home-feature-card" */).then(c => wrapFunctional(c.default || c))
export const HomeMainBanner = () => import('../../components/home/HomeMainBanner.vue' /* webpackChunkName: "components/home-main-banner" */).then(c => wrapFunctional(c.default || c))
export const HomeVideo = () => import('../../components/home/HomeVideo.vue' /* webpackChunkName: "components/home-video" */).then(c => wrapFunctional(c.default || c))
export const LayoutBottomBar = () => import('../../components/layout/LayoutBottomBar.vue' /* webpackChunkName: "components/layout-bottom-bar" */).then(c => wrapFunctional(c.default || c))
export const LayoutFooter = () => import('../../components/layout/LayoutFooter.vue' /* webpackChunkName: "components/layout-footer" */).then(c => wrapFunctional(c.default || c))
export const LayoutLogin = () => import('../../components/layout/LayoutLogin.vue' /* webpackChunkName: "components/layout-login" */).then(c => wrapFunctional(c.default || c))
export const LayoutMarketBtn = () => import('../../components/layout/LayoutMarketBtn.vue' /* webpackChunkName: "components/layout-market-btn" */).then(c => wrapFunctional(c.default || c))
export const LayoutNotificationsBtn = () => import('../../components/layout/LayoutNotificationsBtn.vue' /* webpackChunkName: "components/layout-notifications-btn" */).then(c => wrapFunctional(c.default || c))
export const LayoutSideBar = () => import('../../components/layout/LayoutSideBar.vue' /* webpackChunkName: "components/layout-side-bar" */).then(c => wrapFunctional(c.default || c))
export const LayoutTopBar = () => import('../../components/layout/LayoutTopBar.vue' /* webpackChunkName: "components/layout-top-bar" */).then(c => wrapFunctional(c.default || c))
export const LayoutTopBarAccountBtn = () => import('../../components/layout/LayoutTopBarAccountBtn.vue' /* webpackChunkName: "components/layout-top-bar-account-btn" */).then(c => wrapFunctional(c.default || c))
export const LayoutTopBarWalletBtn = () => import('../../components/layout/LayoutTopBarWalletBtn.vue' /* webpackChunkName: "components/layout-top-bar-wallet-btn" */).then(c => wrapFunctional(c.default || c))
export const LayoutVerifyBtn = () => import('../../components/layout/LayoutVerifyBtn.vue' /* webpackChunkName: "components/layout-verify-btn" */).then(c => wrapFunctional(c.default || c))
export const MarketCompaniesFilter = () => import('../../components/market/MarketCompaniesFilter.vue' /* webpackChunkName: "components/market-companies-filter" */).then(c => wrapFunctional(c.default || c))
export const MarketGrid = () => import('../../components/market/MarketGrid.vue' /* webpackChunkName: "components/market-grid" */).then(c => wrapFunctional(c.default || c))
export const MarketHorizontalCard = () => import('../../components/market/MarketHorizontalCard.vue' /* webpackChunkName: "components/market-horizontal-card" */).then(c => wrapFunctional(c.default || c))
export const MarketHorizontalTicket = () => import('../../components/market/MarketHorizontalTicket.vue' /* webpackChunkName: "components/market-horizontal-ticket" */).then(c => wrapFunctional(c.default || c))
export const MarketProductGroup = () => import('../../components/market/MarketProductGroup.vue' /* webpackChunkName: "components/market-product-group" */).then(c => wrapFunctional(c.default || c))
export const MarketProductHeader = () => import('../../components/market/MarketProductHeader.vue' /* webpackChunkName: "components/market-product-header" */).then(c => wrapFunctional(c.default || c))
export const MarketProductTicket = () => import('../../components/market/MarketProductTicket.vue' /* webpackChunkName: "components/market-product-ticket" */).then(c => wrapFunctional(c.default || c))
export const MarketProductBidStatus = () => import('../../components/market/ProductBidStatus.vue' /* webpackChunkName: "components/market-product-bid-status" */).then(c => wrapFunctional(c.default || c))
export const MarketTermsExample1 = () => import('../../components/market/TermsExample1.vue' /* webpackChunkName: "components/market-terms-example1" */).then(c => wrapFunctional(c.default || c))
export const MarketTermsExample2 = () => import('../../components/market/TermsExample2.vue' /* webpackChunkName: "components/market-terms-example2" */).then(c => wrapFunctional(c.default || c))
export const MarketTermsExample3 = () => import('../../components/market/TermsExample3.vue' /* webpackChunkName: "components/market-terms-example3" */).then(c => wrapFunctional(c.default || c))
export const MarketTermsExamples = () => import('../../components/market/TermsExamples.vue' /* webpackChunkName: "components/market-terms-examples" */).then(c => wrapFunctional(c.default || c))
export const MarketingDisclaimer = () => import('../../components/marketing/MarketingDisclaimer.vue' /* webpackChunkName: "components/marketing-disclaimer" */).then(c => wrapFunctional(c.default || c))
export const MessagesMessageConnectionHandler = () => import('../../components/messages/MessageConnectionHandler.vue' /* webpackChunkName: "components/messages-message-connection-handler" */).then(c => wrapFunctional(c.default || c))
export const MessagesConversation = () => import('../../components/messages/MessagesConversation.vue' /* webpackChunkName: "components/messages-conversation" */).then(c => wrapFunctional(c.default || c))
export const MessagesNotHasConnections = () => import('../../components/messages/MessagesNotHasConnections.vue' /* webpackChunkName: "components/messages-not-has-connections" */).then(c => wrapFunctional(c.default || c))
export const MessagesSendNew = () => import('../../components/messages/MessagesSendNew.vue' /* webpackChunkName: "components/messages-send-new" */).then(c => wrapFunctional(c.default || c))
export const MessagesSendNewTransparency = () => import('../../components/messages/MessagesSendNewTransparency.vue' /* webpackChunkName: "components/messages-send-new-transparency" */).then(c => wrapFunctional(c.default || c))
export const MessagesUsersList = () => import('../../components/messages/MessagesUsersList.vue' /* webpackChunkName: "components/messages-users-list" */).then(c => wrapFunctional(c.default || c))
export const MessagesUsersListItem = () => import('../../components/messages/MessagesUsersListItem.vue' /* webpackChunkName: "components/messages-users-list-item" */).then(c => wrapFunctional(c.default || c))
export const MintersCard = () => import('../../components/minters/MintersCard.vue' /* webpackChunkName: "components/minters-card" */).then(c => wrapFunctional(c.default || c))
export const MintersModal = () => import('../../components/minters/MintersModal.vue' /* webpackChunkName: "components/minters-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsConfirmLogoutModal = () => import('../../components/modals/ConfirmLogoutModal.vue' /* webpackChunkName: "components/modals-confirm-logout-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsG2cAlertModal = () => import('../../components/modals/G2cAlertModal.vue' /* webpackChunkName: "components/modals-g2c-alert-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsLoginModal = () => import('../../components/modals/LoginModal.vue' /* webpackChunkName: "components/modals-login-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsTermsModal = () => import('../../components/modals/TermsModal.vue' /* webpackChunkName: "components/modals-terms-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsWelcomeModal = () => import('../../components/modals/WelcomeModal.vue' /* webpackChunkName: "components/modals-welcome-modal" */).then(c => wrapFunctional(c.default || c))
export const PagesSeoMixin = () => import('../../components/pages/SeoMixin.vue' /* webpackChunkName: "components/pages-seo-mixin" */).then(c => wrapFunctional(c.default || c))
export const ProfileBodyGrid = () => import('../../components/profile/ProfileBodyGrid.vue' /* webpackChunkName: "components/profile-body-grid" */).then(c => wrapFunctional(c.default || c))
export const ProfileBodyGridSkeleton = () => import('../../components/profile/ProfileBodyGridSkeleton.vue' /* webpackChunkName: "components/profile-body-grid-skeleton" */).then(c => wrapFunctional(c.default || c))
export const ProfileCard = () => import('../../components/profile/ProfileCard.vue' /* webpackChunkName: "components/profile-card" */).then(c => wrapFunctional(c.default || c))
export const ProfileCardsMixin = () => import('../../components/profile/ProfileCardsMixin.vue' /* webpackChunkName: "components/profile-cards-mixin" */).then(c => wrapFunctional(c.default || c))
export const ProfileCollectionsCard = () => import('../../components/profile/ProfileCollectionsCard.vue' /* webpackChunkName: "components/profile-collections-card" */).then(c => wrapFunctional(c.default || c))
export const ProfileHeaderBanner = () => import('../../components/profile/ProfileHeaderBanner.vue' /* webpackChunkName: "components/profile-header-banner" */).then(c => wrapFunctional(c.default || c))
export const ProfileHeaderCard = () => import('../../components/profile/ProfileHeaderCard.vue' /* webpackChunkName: "components/profile-header-card" */).then(c => wrapFunctional(c.default || c))
export const ProfileHeaderCardSkeleton = () => import('../../components/profile/ProfileHeaderCardSkeleton.vue' /* webpackChunkName: "components/profile-header-card-skeleton" */).then(c => wrapFunctional(c.default || c))
export const ProfileHeaderImageEdition = () => import('../../components/profile/ProfileHeaderImageEdition.vue' /* webpackChunkName: "components/profile-header-image-edition" */).then(c => wrapFunctional(c.default || c))
export const ProfilePaymailCard = () => import('../../components/profile/ProfilePaymailCard.vue' /* webpackChunkName: "components/profile-paymail-card" */).then(c => wrapFunctional(c.default || c))
export const ProfileProductsCard = () => import('../../components/profile/ProfileProductsCard.vue' /* webpackChunkName: "components/profile-products-card" */).then(c => wrapFunctional(c.default || c))
export const ProfilePublicProductsCard = () => import('../../components/profile/ProfilePublicProductsCard.vue' /* webpackChunkName: "components/profile-public-products-card" */).then(c => wrapFunctional(c.default || c))
export const ProfilePublicationsCard = () => import('../../components/profile/ProfilePublicationsCard.vue' /* webpackChunkName: "components/profile-publications-card" */).then(c => wrapFunctional(c.default || c))
export const ProfileSidebar = () => import('../../components/profile/ProfileSidebar.vue' /* webpackChunkName: "components/profile-sidebar" */).then(c => wrapFunctional(c.default || c))
export const ProfileStateCard = () => import('../../components/profile/ProfileStateCard.vue' /* webpackChunkName: "components/profile-state-card" */).then(c => wrapFunctional(c.default || c))
export const ProfileThemeEdition = () => import('../../components/profile/ProfileThemeEdition.vue' /* webpackChunkName: "components/profile-theme-edition" */).then(c => wrapFunctional(c.default || c))
export const ProfileVideoCard = () => import('../../components/profile/ProfileVideoCard.vue' /* webpackChunkName: "components/profile-video-card" */).then(c => wrapFunctional(c.default || c))
export const ProfileVisitsCounter = () => import('../../components/profile/ProfileVisitsCounter.vue' /* webpackChunkName: "components/profile-visits-counter" */).then(c => wrapFunctional(c.default || c))
export const SessionPreCreateForm = () => import('../../components/session/PreCreateForm.vue' /* webpackChunkName: "components/session-pre-create-form" */).then(c => wrapFunctional(c.default || c))
export const SessionRequestInvitationForm = () => import('../../components/session/RequestInvitationForm.vue' /* webpackChunkName: "components/session-request-invitation-form" */).then(c => wrapFunctional(c.default || c))
export const SessionSignUpContainer = () => import('../../components/session/SessionSignUpContainer.vue' /* webpackChunkName: "components/session-sign-up-container" */).then(c => wrapFunctional(c.default || c))
export const SessionUserOptionSelectModal = () => import('../../components/session/UserOptionSelectModal.vue' /* webpackChunkName: "components/session-user-option-select-modal" */).then(c => wrapFunctional(c.default || c))
export const SessionVerifyEntityButton = () => import('../../components/session/VerifyEntityButton.vue' /* webpackChunkName: "components/session-verify-entity-button" */).then(c => wrapFunctional(c.default || c))
export const SessionVerifyEntityModal = () => import('../../components/session/VerifyEntityModal.vue' /* webpackChunkName: "components/session-verify-entity-modal" */).then(c => wrapFunctional(c.default || c))
export const SessionLogin = () => import('../../components/session/login.vue' /* webpackChunkName: "components/session-login" */).then(c => wrapFunctional(c.default || c))
export const SessionRecoverPassword = () => import('../../components/session/recover-password.vue' /* webpackChunkName: "components/session-recover-password" */).then(c => wrapFunctional(c.default || c))
export const SessionSignup = () => import('../../components/session/signup.vue' /* webpackChunkName: "components/session-signup" */).then(c => wrapFunctional(c.default || c))
export const SessionWelcome = () => import('../../components/session/welcome.vue' /* webpackChunkName: "components/session-welcome" */).then(c => wrapFunctional(c.default || c))
export const SettingsCard = () => import('../../components/settings/SettingsCard.vue' /* webpackChunkName: "components/settings-card" */).then(c => wrapFunctional(c.default || c))
export const SettingsVerifyOtpCode = () => import('../../components/settings/SettingsVerifyOtpCode.vue' /* webpackChunkName: "components/settings-verify-otp-code" */).then(c => wrapFunctional(c.default || c))
export const SidebarBaseSidebarItem = () => import('../../components/sidebar/BaseSidebarItem.vue' /* webpackChunkName: "components/sidebar-base-sidebar-item" */).then(c => wrapFunctional(c.default || c))
export const SidebarMessagesSidebarButton = () => import('../../components/sidebar/MessagesSidebarButton.vue' /* webpackChunkName: "components/sidebar-messages-sidebar-button" */).then(c => wrapFunctional(c.default || c))
export const SidebarContactSupport = () => import('../../components/sidebar/SidebarContactSupport.vue' /* webpackChunkName: "components/sidebar-contact-support" */).then(c => wrapFunctional(c.default || c))
export const SidebarNotificationItemSkeletonLoader = () => import('../../components/sidebar-notification/SidebarNotificationItemSkeletonLoader.vue' /* webpackChunkName: "components/sidebar-notification-item-skeleton-loader" */).then(c => wrapFunctional(c.default || c))
export const SidebarNotificationListItem = () => import('../../components/sidebar-notification/SidebarNotificationListItem.vue' /* webpackChunkName: "components/sidebar-notification-list-item" */).then(c => wrapFunctional(c.default || c))
export const StripeCheckoutPaymentElements = () => import('../../components/stripe/CheckoutPaymentElements.vue' /* webpackChunkName: "components/stripe-checkout-payment-elements" */).then(c => wrapFunctional(c.default || c))
export const StripeHandlePaymentMethods = () => import('../../components/stripe/HandlePaymentMethods.vue' /* webpackChunkName: "components/stripe-handle-payment-methods" */).then(c => wrapFunctional(c.default || c))
export const StripePaymentMethodItem = () => import('../../components/stripe/PaymentMethodItem.vue' /* webpackChunkName: "components/stripe-payment-method-item" */).then(c => wrapFunctional(c.default || c))
export const StripePaymentRequestButton = () => import('../../components/stripe/PaymentRequestButton.vue' /* webpackChunkName: "components/stripe-payment-request-button" */).then(c => wrapFunctional(c.default || c))
export const UserAvatar = () => import('../../components/user/UserAvatar.vue' /* webpackChunkName: "components/user-avatar" */).then(c => wrapFunctional(c.default || c))
export const UserCardsAvatar = () => import('../../components/user/UserCardsAvatar.vue' /* webpackChunkName: "components/user-cards-avatar" */).then(c => wrapFunctional(c.default || c))
export const UserHeader = () => import('../../components/user/UserHeader.vue' /* webpackChunkName: "components/user-header" */).then(c => wrapFunctional(c.default || c))
export const UserMixinHandleLoggedUser = () => import('../../components/user/UserMixinHandleLoggedUser.vue' /* webpackChunkName: "components/user-mixin-handle-logged-user" */).then(c => wrapFunctional(c.default || c))
export const UserMixinHandler = () => import('../../components/user/UserMixinHandler.vue' /* webpackChunkName: "components/user-mixin-handler" */).then(c => wrapFunctional(c.default || c))
export const UserSocialMediaIcon = () => import('../../components/user/UserSocialMediaIcon.vue' /* webpackChunkName: "components/user-social-media-icon" */).then(c => wrapFunctional(c.default || c))
export const UserStatusSelection = () => import('../../components/user/UserStatusSelection.vue' /* webpackChunkName: "components/user-status-selection" */).then(c => wrapFunctional(c.default || c))
export const UserUsersBus = () => import('../../components/user/UsersBus.js' /* webpackChunkName: "components/user-users-bus" */).then(c => wrapFunctional(c.default || c))
export const UserUsersGrid = () => import('../../components/user/UsersGrid.vue' /* webpackChunkName: "components/user-users-grid" */).then(c => wrapFunctional(c.default || c))
export const UserUsersMixinHandler = () => import('../../components/user/UsersMixinHandler.vue' /* webpackChunkName: "components/user-users-mixin-handler" */).then(c => wrapFunctional(c.default || c))
export const VerificationVerifyModal = () => import('../../components/verification/VerifyModal.vue' /* webpackChunkName: "components/verification-verify-modal" */).then(c => wrapFunctional(c.default || c))
export const AccountActivityContentTable = () => import('../../components/account/activity/AccountActivityContentTable.vue' /* webpackChunkName: "components/account-activity-content-table" */).then(c => wrapFunctional(c.default || c))
export const AccountActivityHeaderTable = () => import('../../components/account/activity/AccountActivityHeaderTable.vue' /* webpackChunkName: "components/account-activity-header-table" */).then(c => wrapFunctional(c.default || c))
export const AccountMyassetsShowShippingInfoModal = () => import('../../components/account/myassets/ShowShippingInfoModal.vue' /* webpackChunkName: "components/account-myassets-show-shipping-info-modal" */).then(c => wrapFunctional(c.default || c))
export const AccountMyassetsWalletAsset = () => import('../../components/account/myassets/WalletAsset.vue' /* webpackChunkName: "components/account-myassets-wallet-asset" */).then(c => wrapFunctional(c.default || c))
export const AccountMyassetsWalletAssetHistoryHeader = () => import('../../components/account/myassets/WalletAssetHistoryHeader.vue' /* webpackChunkName: "components/account-myassets-wallet-asset-history-header" */).then(c => wrapFunctional(c.default || c))
export const AccountMyassetsWalletHorizontalAssetHistory = () => import('../../components/account/myassets/WalletHorizontalAssetHistory.vue' /* webpackChunkName: "components/account-myassets-wallet-horizontal-asset-history" */).then(c => wrapFunctional(c.default || c))
export const AccountEditModal = () => import('../../components/account/profile/AccountEditModal.vue' /* webpackChunkName: "components/account-edit-modal" */).then(c => wrapFunctional(c.default || c))
export const AccountPricingCard = () => import('../../components/account/profile/AccountPricingCard.vue' /* webpackChunkName: "components/account-pricing-card" */).then(c => wrapFunctional(c.default || c))
export const AccountProfileCard = () => import('../../components/account/profile/AccountProfileCard.vue' /* webpackChunkName: "components/account-profile-card" */).then(c => wrapFunctional(c.default || c))
export const AccountProfileEditForm = () => import('../../components/account/profile/AccountProfileEditForm.vue' /* webpackChunkName: "components/account-profile-edit-form" */).then(c => wrapFunctional(c.default || c))
export const AccountProfileFirstLoginModal = () => import('../../components/account/profile/AccountProfileFirstLoginModal.vue' /* webpackChunkName: "components/account-profile-first-login-modal" */).then(c => wrapFunctional(c.default || c))
export const AccountProfileWordsBackupModal = () => import('../../components/account/profile/AccountProfileWordsBackupModal.vue' /* webpackChunkName: "components/account-profile-words-backup-modal" */).then(c => wrapFunctional(c.default || c))
export const AccountApiInfoCard = () => import('../../components/account/settings/AccountApiInfoCard.vue' /* webpackChunkName: "components/account-api-info-card" */).then(c => wrapFunctional(c.default || c))
export const AccountContactInfoCard = () => import('../../components/account/settings/AccountContactInfoCard.vue' /* webpackChunkName: "components/account-contact-info-card" */).then(c => wrapFunctional(c.default || c))
export const AccountCurrencyCard = () => import('../../components/account/settings/AccountCurrencyCard.vue' /* webpackChunkName: "components/account-currency-card" */).then(c => wrapFunctional(c.default || c))
export const AccountHandleAddress = () => import('../../components/account/settings/AccountHandleAddress.vue' /* webpackChunkName: "components/account-handle-address" */).then(c => wrapFunctional(c.default || c))
export const AccountInviteFriendsCard = () => import('../../components/account/settings/AccountInviteFriendsCard.vue' /* webpackChunkName: "components/account-invite-friends-card" */).then(c => wrapFunctional(c.default || c))
export const AccountStripeInfoCard = () => import('../../components/account/settings/AccountStripeInfoCard.vue' /* webpackChunkName: "components/account-stripe-info-card" */).then(c => wrapFunctional(c.default || c))
export const AccountWebNotificationsCard = () => import('../../components/account/settings/AccountWebNotificationsCard.vue' /* webpackChunkName: "components/account-web-notifications-card" */).then(c => wrapFunctional(c.default || c))
export const AccountAddFundsModal = () => import('../../components/account/wallet/AccountAddFundsModal.vue' /* webpackChunkName: "components/account-add-funds-modal" */).then(c => wrapFunctional(c.default || c))
export const AccountLimitsCapacity = () => import('../../components/account/wallet/AccountLimitsCapacity.vue' /* webpackChunkName: "components/account-limits-capacity" */).then(c => wrapFunctional(c.default || c))
export const AccountLimitsMints = () => import('../../components/account/wallet/AccountLimitsMints.vue' /* webpackChunkName: "components/account-limits-mints" */).then(c => wrapFunctional(c.default || c))
export const AccountMyAssets = () => import('../../components/account/wallet/AccountMyAssets.vue' /* webpackChunkName: "components/account-my-assets" */).then(c => wrapFunctional(c.default || c))
export const AccountMyAssetsHistoryList = () => import('../../components/account/wallet/AccountMyAssetsHistoryList.vue' /* webpackChunkName: "components/account-my-assets-history-list" */).then(c => wrapFunctional(c.default || c))
export const AccountMyAssetsList = () => import('../../components/account/wallet/AccountMyAssetsList.vue' /* webpackChunkName: "components/account-my-assets-list" */).then(c => wrapFunctional(c.default || c))
export const AccountSendFundsExternal = () => import('../../components/account/wallet/AccountSendFundsExternal.vue' /* webpackChunkName: "components/account-send-funds-external" */).then(c => wrapFunctional(c.default || c))
export const AccountSendFundsInternal = () => import('../../components/account/wallet/AccountSendFundsInternal.vue' /* webpackChunkName: "components/account-send-funds-internal" */).then(c => wrapFunctional(c.default || c))
export const AccountSendFundsModal = () => import('../../components/account/wallet/AccountSendFundsModal.vue' /* webpackChunkName: "components/account-send-funds-modal" */).then(c => wrapFunctional(c.default || c))
export const AccountWalletCard = () => import('../../components/account/wallet/AccountWalletCard.vue' /* webpackChunkName: "components/account-wallet-card" */).then(c => wrapFunctional(c.default || c))
export const AccountWalletSafe = () => import('../../components/account/wallet/AccountWalletSafe.vue' /* webpackChunkName: "components/account-wallet-safe" */).then(c => wrapFunctional(c.default || c))
export const AccountWalletConfirmAlertModal = () => import('../../components/account/wallet/ConfirmAlertModal.vue' /* webpackChunkName: "components/account-wallet-confirm-alert-modal" */).then(c => wrapFunctional(c.default || c))
export const HomeConnectionsCarousel = () => import('../../components/home/connections/HomeConnectionsCarousel.vue' /* webpackChunkName: "components/home-connections-carousel" */).then(c => wrapFunctional(c.default || c))
export const HomeMinterFeatured = () => import('../../components/home/minters/HomeMinterFeatured.vue' /* webpackChunkName: "components/home-minter-featured" */).then(c => wrapFunctional(c.default || c))
export const HomeMintersFeaturedCarousel = () => import('../../components/home/minters/HomeMintersFeaturedCarousel.vue' /* webpackChunkName: "components/home-minters-featured-carousel" */).then(c => wrapFunctional(c.default || c))
export const MarketBaseAddBeneficiary = () => import('../../components/market/base/AddBeneficiary.vue' /* webpackChunkName: "components/market-base-add-beneficiary" */).then(c => wrapFunctional(c.default || c))
export const MarketBaseAddCollection = () => import('../../components/market/base/AddCollection.vue' /* webpackChunkName: "components/market-base-add-collection" */).then(c => wrapFunctional(c.default || c))
export const MarketBaseAddFileG2cObjects = () => import('../../components/market/base/AddFileG2cObjects.vue' /* webpackChunkName: "components/market-base-add-file-g2c-objects" */).then(c => wrapFunctional(c.default || c))
export const MarketBaseAddOwnerCollection = () => import('../../components/market/base/AddOwnerCollection.vue' /* webpackChunkName: "components/market-base-add-owner-collection" */).then(c => wrapFunctional(c.default || c))
export const MarketBaseAddRoyalty = () => import('../../components/market/base/AddRoyalty.vue' /* webpackChunkName: "components/market-base-add-royalty" */).then(c => wrapFunctional(c.default || c))
export const MarketBaseCountryList = () => import('../../components/market/base/CountryList.vue' /* webpackChunkName: "components/market-base-country-list" */).then(c => wrapFunctional(c.default || c))
export const MarketBaseFilePreviewModal = () => import('../../components/market/base/FilePreviewModal.vue' /* webpackChunkName: "components/market-base-file-preview-modal" */).then(c => wrapFunctional(c.default || c))
export const MarketBaseFilterMixin = () => import('../../components/market/base/FilterMixin.vue' /* webpackChunkName: "components/market-base-filter-mixin" */).then(c => wrapFunctional(c.default || c))
export const MarketBaseHandleAccessOffering = () => import('../../components/market/base/HandleAccessOffering.vue' /* webpackChunkName: "components/market-base-handle-access-offering" */).then(c => wrapFunctional(c.default || c))
export const MarketBaseHandleAuctionCopies = () => import('../../components/market/base/HandleAuctionCopies.vue' /* webpackChunkName: "components/market-base-handle-auction-copies" */).then(c => wrapFunctional(c.default || c))
export const MarketBaseHandleBuyCopies = () => import('../../components/market/base/HandleBuyCopies.vue' /* webpackChunkName: "components/market-base-handle-buy-copies" */).then(c => wrapFunctional(c.default || c))
export const MarketBaseHandleDelivery = () => import('../../components/market/base/HandleDelivery.vue' /* webpackChunkName: "components/market-base-handle-delivery" */).then(c => wrapFunctional(c.default || c))
export const MarketBaseHandleG2cObjects = () => import('../../components/market/base/HandleG2cObjects.vue' /* webpackChunkName: "components/market-base-handle-g2c-objects" */).then(c => wrapFunctional(c.default || c))
export const MarketBaseHandleG2cObjectsPanel = () => import('../../components/market/base/HandleG2cObjectsPanel.vue' /* webpackChunkName: "components/market-base-handle-g2c-objects-panel" */).then(c => wrapFunctional(c.default || c))
export const MarketBaseHandleOwnerCollection = () => import('../../components/market/base/HandleOwnerCollection.vue' /* webpackChunkName: "components/market-base-handle-owner-collection" */).then(c => wrapFunctional(c.default || c))
export const MarketBaseHandlePaymentAddress = () => import('../../components/market/base/HandlePaymentAddress.vue' /* webpackChunkName: "components/market-base-handle-payment-address" */).then(c => wrapFunctional(c.default || c))
export const MarketBaseHandlePostalAddressItem = () => import('../../components/market/base/HandlePostalAddressItem.vue' /* webpackChunkName: "components/market-base-handle-postal-address-item" */).then(c => wrapFunctional(c.default || c))
export const MarketBaseHandlePostalAddressess = () => import('../../components/market/base/HandlePostalAddressess.vue' /* webpackChunkName: "components/market-base-handle-postal-addressess" */).then(c => wrapFunctional(c.default || c))
export const MarketBaseHandleRoyaltyPanel = () => import('../../components/market/base/HandleRoyaltyPanel.vue' /* webpackChunkName: "components/market-base-handle-royalty-panel" */).then(c => wrapFunctional(c.default || c))
export const MarketBaseHandleSymbols = () => import('../../components/market/base/HandleSymbols.vue' /* webpackChunkName: "components/market-base-handle-symbols" */).then(c => wrapFunctional(c.default || c))
export const MarketBaseHandleTicketOptions = () => import('../../components/market/base/HandleTicketOptions.vue' /* webpackChunkName: "components/market-base-handle-ticket-options" */).then(c => wrapFunctional(c.default || c))
export const MarketActionsProductHeader = () => import('../../components/market/base/MarketActionsProductHeader.vue' /* webpackChunkName: "components/market-actions-product-header" */).then(c => wrapFunctional(c.default || c))
export const MarketCheckoutDependencyUser = () => import('../../components/market/base/MarketCheckoutDependencyUser.vue' /* webpackChunkName: "components/market-checkout-dependency-user" */).then(c => wrapFunctional(c.default || c))
export const MarketCheckoutStep1 = () => import('../../components/market/base/MarketCheckoutStep1.vue' /* webpackChunkName: "components/market-checkout-step1" */).then(c => wrapFunctional(c.default || c))
export const MarketCheckoutStep2 = () => import('../../components/market/base/MarketCheckoutStep2.vue' /* webpackChunkName: "components/market-checkout-step2" */).then(c => wrapFunctional(c.default || c))
export const MarketCheckoutStep3 = () => import('../../components/market/base/MarketCheckoutStep3.vue' /* webpackChunkName: "components/market-checkout-step3" */).then(c => wrapFunctional(c.default || c))
export const MarketCheckoutSummary = () => import('../../components/market/base/MarketCheckoutSummary.vue' /* webpackChunkName: "components/market-checkout-summary" */).then(c => wrapFunctional(c.default || c))
export const MarketBasePostalAddress = () => import('../../components/market/base/PostalAddress.vue' /* webpackChunkName: "components/market-base-postal-address" */).then(c => wrapFunctional(c.default || c))
export const MarketBasePreviewCreateProduct = () => import('../../components/market/base/PreviewCreateProduct.vue' /* webpackChunkName: "components/market-base-preview-create-product" */).then(c => wrapFunctional(c.default || c))
export const MarketBasePreviewProductHeader = () => import('../../components/market/base/PreviewProductHeader.vue' /* webpackChunkName: "components/market-base-preview-product-header" */).then(c => wrapFunctional(c.default || c))
export const MarketBaseProductActionBtns = () => import('../../components/market/base/ProductActionBtns.vue' /* webpackChunkName: "components/market-base-product-action-btns" */).then(c => wrapFunctional(c.default || c))
export const MarketBaseProductAuctionAction = () => import('../../components/market/base/ProductAuctionAction.vue' /* webpackChunkName: "components/market-base-product-auction-action" */).then(c => wrapFunctional(c.default || c))
export const MarketBaseProductBtnActions = () => import('../../components/market/base/ProductBtnActions.vue' /* webpackChunkName: "components/market-base-product-btn-actions" */).then(c => wrapFunctional(c.default || c))
export const MarketBaseProductCard = () => import('../../components/market/base/ProductCard.vue' /* webpackChunkName: "components/market-base-product-card" */).then(c => wrapFunctional(c.default || c))
export const MarketBaseProductCardNew = () => import('../../components/market/base/ProductCardNew.vue' /* webpackChunkName: "components/market-base-product-card-new" */).then(c => wrapFunctional(c.default || c))
export const MarketBaseProductCardPriceContent = () => import('../../components/market/base/ProductCardPriceContent.vue' /* webpackChunkName: "components/market-base-product-card-price-content" */).then(c => wrapFunctional(c.default || c))
export const MarketBaseProductCopies = () => import('../../components/market/base/ProductCopies.vue' /* webpackChunkName: "components/market-base-product-copies" */).then(c => wrapFunctional(c.default || c))
export const MarketBaseProductCopyName = () => import('../../components/market/base/ProductCopyName.vue' /* webpackChunkName: "components/market-base-product-copy-name" */).then(c => wrapFunctional(c.default || c))
export const MarketBaseProductDependency = () => import('../../components/market/base/ProductDependency.vue' /* webpackChunkName: "components/market-base-product-dependency" */).then(c => wrapFunctional(c.default || c))
export const MarketBaseProductDescription = () => import('../../components/market/base/ProductDescription.vue' /* webpackChunkName: "components/market-base-product-description" */).then(c => wrapFunctional(c.default || c))
export const MarketBaseProductFavs = () => import('../../components/market/base/ProductFavs.vue' /* webpackChunkName: "components/market-base-product-favs" */).then(c => wrapFunctional(c.default || c))
export const MarketBaseProductHeader = () => import('../../components/market/base/ProductHeader.vue' /* webpackChunkName: "components/market-base-product-header" */).then(c => wrapFunctional(c.default || c))
export const MarketBaseProductHistory = () => import('../../components/market/base/ProductHistory.vue' /* webpackChunkName: "components/market-base-product-history" */).then(c => wrapFunctional(c.default || c))
export const MarketBaseProductImagesCarousel = () => import('../../components/market/base/ProductImagesCarousel.vue' /* webpackChunkName: "components/market-base-product-images-carousel" */).then(c => wrapFunctional(c.default || c))
export const MarketBaseProductMore = () => import('../../components/market/base/ProductMore.vue' /* webpackChunkName: "components/market-base-product-more" */).then(c => wrapFunctional(c.default || c))
export const MarketBaseProductOffers = () => import('../../components/market/base/ProductOffers.vue' /* webpackChunkName: "components/market-base-product-offers" */).then(c => wrapFunctional(c.default || c))
export const MarketBaseProductPrincipalImage = () => import('../../components/market/base/ProductPrincipalImage.vue' /* webpackChunkName: "components/market-base-product-principal-image" */).then(c => wrapFunctional(c.default || c))
export const MarketBaseProductRemaining = () => import('../../components/market/base/ProductRemaining.vue' /* webpackChunkName: "components/market-base-product-remaining" */).then(c => wrapFunctional(c.default || c))
export const MarketBaseProductShare = () => import('../../components/market/base/ProductShare.vue' /* webpackChunkName: "components/market-base-product-share" */).then(c => wrapFunctional(c.default || c))
export const MarketBaseProductSlider = () => import('../../components/market/base/ProductSlider.vue' /* webpackChunkName: "components/market-base-product-slider" */).then(c => wrapFunctional(c.default || c))
export const MarketBaseProductTerms = () => import('../../components/market/base/ProductTerms.vue' /* webpackChunkName: "components/market-base-product-terms" */).then(c => wrapFunctional(c.default || c))
export const MarketBaseProductTransaction = () => import('../../components/market/base/ProductTransaction.vue' /* webpackChunkName: "components/market-base-product-transaction" */).then(c => wrapFunctional(c.default || c))
export const MarketBaseProductUserRelated = () => import('../../components/market/base/ProductUserRelated.vue' /* webpackChunkName: "components/market-base-product-user-related" */).then(c => wrapFunctional(c.default || c))
export const MarketBasePublicProductCard = () => import('../../components/market/base/PublicProductCard.vue' /* webpackChunkName: "components/market-base-public-product-card" */).then(c => wrapFunctional(c.default || c))
export const MarketBaseSwitchOptions = () => import('../../components/market/base/SwitchOptions.vue' /* webpackChunkName: "components/market-base-switch-options" */).then(c => wrapFunctional(c.default || c))
export const MarketBaseTokenHistoric = () => import('../../components/market/base/TokenHistoric.vue' /* webpackChunkName: "components/market-base-token-historic" */).then(c => wrapFunctional(c.default || c))
export const MarketBaseTokenTag = () => import('../../components/market/base/TokenTag.vue' /* webpackChunkName: "components/market-base-token-tag" */).then(c => wrapFunctional(c.default || c))
export const MarketBaseUserBidStatus = () => import('../../components/market/base/UserBidStatus.vue' /* webpackChunkName: "components/market-base-user-bid-status" */).then(c => wrapFunctional(c.default || c))
export const MarketBaseUserDependency = () => import('../../components/market/base/UserDependency.vue' /* webpackChunkName: "components/market-base-user-dependency" */).then(c => wrapFunctional(c.default || c))
export const MarketButtonsAcceptProductBtn = () => import('../../components/market/buttons/AcceptProductBtn.vue' /* webpackChunkName: "components/market-buttons-accept-product-btn" */).then(c => wrapFunctional(c.default || c))
export const MarketButtonsCheckCommissionSchemaBtn = () => import('../../components/market/buttons/CheckCommissionSchemaBtn.vue' /* webpackChunkName: "components/market-buttons-check-commission-schema-btn" */).then(c => wrapFunctional(c.default || c))
export const MarketButtonsCreateTicket = () => import('../../components/market/buttons/CreateTicket.vue' /* webpackChunkName: "components/market-buttons-create-ticket" */).then(c => wrapFunctional(c.default || c))
export const MarketButtonsInviteOfferingBtn = () => import('../../components/market/buttons/InviteOfferingBtn.vue' /* webpackChunkName: "components/market-buttons-invite-offering-btn" */).then(c => wrapFunctional(c.default || c))
export const MarketButtonsLeaveAuctionBtn = () => import('../../components/market/buttons/LeaveAuctionBtn.vue' /* webpackChunkName: "components/market-buttons-leave-auction-btn" */).then(c => wrapFunctional(c.default || c))
export const MarketButtonsListProductModalBtn = () => import('../../components/market/buttons/ListProductModalBtn.vue' /* webpackChunkName: "components/market-buttons-list-product-modal-btn" */).then(c => wrapFunctional(c.default || c))
export const MarketButtonsManageTicketsBtn = () => import('../../components/market/buttons/ManageTicketsBtn.vue' /* webpackChunkName: "components/market-buttons-manage-tickets-btn" */).then(c => wrapFunctional(c.default || c))
export const MarketButtonsPaymentByWallet = () => import('../../components/market/buttons/PaymentByWallet.vue' /* webpackChunkName: "components/market-buttons-payment-by-wallet" */).then(c => wrapFunctional(c.default || c))
export const MarketButtonsPreviewProductBtn = () => import('../../components/market/buttons/PreviewProductBtn.vue' /* webpackChunkName: "components/market-buttons-preview-product-btn" */).then(c => wrapFunctional(c.default || c))
export const MarketButtonsProductDelistBtn = () => import('../../components/market/buttons/ProductDelistBtn.vue' /* webpackChunkName: "components/market-buttons-product-delist-btn" */).then(c => wrapFunctional(c.default || c))
export const MarketButtonsProductShowCheckoutSessionBtn = () => import('../../components/market/buttons/ProductShowCheckoutSessionBtn.vue' /* webpackChunkName: "components/market-buttons-product-show-checkout-session-btn" */).then(c => wrapFunctional(c.default || c))
export const MarketButtonsReadTicket = () => import('../../components/market/buttons/ReadTicket.vue' /* webpackChunkName: "components/market-buttons-read-ticket" */).then(c => wrapFunctional(c.default || c))
export const MarketButtonsRedeemProductModalBtn = () => import('../../components/market/buttons/RedeemProductModalBtn.vue' /* webpackChunkName: "components/market-buttons-redeem-product-modal-btn" */).then(c => wrapFunctional(c.default || c))
export const MarketButtonsRejectProductBtn = () => import('../../components/market/buttons/RejectProductBtn.vue' /* webpackChunkName: "components/market-buttons-reject-product-btn" */).then(c => wrapFunctional(c.default || c))
export const MarketButtonsShowInProfileBtn = () => import('../../components/market/buttons/ShowInProfileBtn.vue' /* webpackChunkName: "components/market-buttons-show-in-profile-btn" */).then(c => wrapFunctional(c.default || c))
export const MarketButtonsTransferProductModalBtn = () => import('../../components/market/buttons/TransferProductModalBtn.vue' /* webpackChunkName: "components/market-buttons-transfer-product-modal-btn" */).then(c => wrapFunctional(c.default || c))
export const MarketHorizontalCardSkeleton = () => import('../../components/market/loaders/MarketHorizontalCardSkeleton.vue' /* webpackChunkName: "components/market-horizontal-card-skeleton" */).then(c => wrapFunctional(c.default || c))
export const MarketModalsConfirmBidModal = () => import('../../components/market/modals/ConfirmBidModal.vue' /* webpackChunkName: "components/market-modals-confirm-bid-modal" */).then(c => wrapFunctional(c.default || c))
export const MarketModalsCreateQrModal = () => import('../../components/market/modals/CreateQrModal.vue' /* webpackChunkName: "components/market-modals-create-qr-modal" */).then(c => wrapFunctional(c.default || c))
export const MarketModalsDelistOfferingModal = () => import('../../components/market/modals/DelistOfferingModal.vue' /* webpackChunkName: "components/market-modals-delist-offering-modal" */).then(c => wrapFunctional(c.default || c))
export const MarketModalsHandleAddressModal = () => import('../../components/market/modals/HandleAddressModal.vue' /* webpackChunkName: "components/market-modals-handle-address-modal" */).then(c => wrapFunctional(c.default || c))
export const MarketModalsHandleBeneficiaryModal = () => import('../../components/market/modals/HandleBeneficiaryModal.vue' /* webpackChunkName: "components/market-modals-handle-beneficiary-modal" */).then(c => wrapFunctional(c.default || c))
export const MarketModalsHandleCollectionsModal = () => import('../../components/market/modals/HandleCollectionsModal.vue' /* webpackChunkName: "components/market-modals-handle-collections-modal" */).then(c => wrapFunctional(c.default || c))
export const MarketModalsHandleG2cObjectsModal = () => import('../../components/market/modals/HandleG2cObjectsModal.vue' /* webpackChunkName: "components/market-modals-handle-g2c-objects-modal" */).then(c => wrapFunctional(c.default || c))
export const MarketModalsHandleInvitationOfferingModal = () => import('../../components/market/modals/HandleInvitationOfferingModal.vue' /* webpackChunkName: "components/market-modals-handle-invitation-offering-modal" */).then(c => wrapFunctional(c.default || c))
export const MarketModalsHandleOwnerCollectionsModal = () => import('../../components/market/modals/HandleOwnerCollectionsModal.vue' /* webpackChunkName: "components/market-modals-handle-owner-collections-modal" */).then(c => wrapFunctional(c.default || c))
export const MarketModalsHandleRoyaltyModal = () => import('../../components/market/modals/HandleRoyaltyModal.vue' /* webpackChunkName: "components/market-modals-handle-royalty-modal" */).then(c => wrapFunctional(c.default || c))
export const MarketModalsHandleShowShippingModal = () => import('../../components/market/modals/HandleShowShippingModal.vue' /* webpackChunkName: "components/market-modals-handle-show-shipping-modal" */).then(c => wrapFunctional(c.default || c))
export const MarketModalsHandleTicketOptionsModal = () => import('../../components/market/modals/HandleTicketOptionsModal.vue' /* webpackChunkName: "components/market-modals-handle-ticket-options-modal" */).then(c => wrapFunctional(c.default || c))
export const MarketModalsListProductModal = () => import('../../components/market/modals/ListProductModal.vue' /* webpackChunkName: "components/market-modals-list-product-modal" */).then(c => wrapFunctional(c.default || c))
export const MarketModalsRedeemProductModal = () => import('../../components/market/modals/RedeemProductModal.vue' /* webpackChunkName: "components/market-modals-redeem-product-modal" */).then(c => wrapFunctional(c.default || c))
export const MarketModalsShowSuccessModal = () => import('../../components/market/modals/ShowSuccessModal.vue' /* webpackChunkName: "components/market-modals-show-success-modal" */).then(c => wrapFunctional(c.default || c))
export const MarketModalsTransferProductModal = () => import('../../components/market/modals/TransferProductModal.vue' /* webpackChunkName: "components/market-modals-transfer-product-modal" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleGenericInfo = () => import('../../components/market/single/GenericInfo.vue' /* webpackChunkName: "components/market-single-generic-info" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleInfoExpandableCard = () => import('../../components/market/single/InfoExpandableCard.vue' /* webpackChunkName: "components/market-single-info-expandable-card" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleRemaining = () => import('../../components/market/single/Remaining.vue' /* webpackChunkName: "components/market-single-remaining" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductDependency = () => import('../../components/market/single/SingleProductDependency.vue' /* webpackChunkName: "components/market-single-product-dependency" */).then(c => wrapFunctional(c.default || c))
export const MarketingSectionTitle = () => import('../../components/marketing/sections/MarketingSectionTitle.vue' /* webpackChunkName: "components/marketing-section-title" */).then(c => wrapFunctional(c.default || c))
export const MessagesBasicUserItemSkeletonLoader = () => import('../../components/messages/common/MessagesBasicUserItemSkeletonLoader.vue' /* webpackChunkName: "components/messages-basic-user-item-skeleton-loader" */).then(c => wrapFunctional(c.default || c))
export const MessagesDateSeparator = () => import('../../components/messages/common/MessagesDateSeparator.vue' /* webpackChunkName: "components/messages-date-separator" */).then(c => wrapFunctional(c.default || c))
export const MessagesSpeechBubble = () => import('../../components/messages/common/MessagesSpeechBubble.vue' /* webpackChunkName: "components/messages-speech-bubble" */).then(c => wrapFunctional(c.default || c))
export const MessagesStatusCheck = () => import('../../components/messages/common/MessagesStatusCheck.vue' /* webpackChunkName: "components/messages-status-check" */).then(c => wrapFunctional(c.default || c))
export const MintersBecomeMinterStep1 = () => import('../../components/minters/become-minters-steps/BecomeMinterStep_1.vue' /* webpackChunkName: "components/minters-become-minter-step1" */).then(c => wrapFunctional(c.default || c))
export const MintersBecomeMinterStep2 = () => import('../../components/minters/become-minters-steps/BecomeMinterStep_2.vue' /* webpackChunkName: "components/minters-become-minter-step2" */).then(c => wrapFunctional(c.default || c))
export const MintersBecomeMinterStep3 = () => import('../../components/minters/become-minters-steps/BecomeMinterStep_3.vue' /* webpackChunkName: "components/minters-become-minter-step3" */).then(c => wrapFunctional(c.default || c))
export const PagesCommonsStaticContent = () => import('../../components/pages/commons/StaticContent.vue' /* webpackChunkName: "components/pages-commons-static-content" */).then(c => wrapFunctional(c.default || c))
export const PagesCommonsStaticHeader = () => import('../../components/pages/commons/StaticHeader.vue' /* webpackChunkName: "components/pages-commons-static-header" */).then(c => wrapFunctional(c.default || c))
export const SessionSignup1 = () => import('../../components/session/signup/SessionSignup_1.vue' /* webpackChunkName: "components/session-signup1" */).then(c => wrapFunctional(c.default || c))
export const SessionSignup2 = () => import('../../components/session/signup/SessionSignup_2.vue' /* webpackChunkName: "components/session-signup2" */).then(c => wrapFunctional(c.default || c))
export const SessionSignup3 = () => import('../../components/session/signup/SessionSignup_3.vue' /* webpackChunkName: "components/session-signup3" */).then(c => wrapFunctional(c.default || c))
export const SessionSignup3Old = () => import('../../components/session/signup/SessionSignup_3_old.vue' /* webpackChunkName: "components/session-signup3-old" */).then(c => wrapFunctional(c.default || c))
export const SessionSignup4 = () => import('../../components/session/signup/SessionSignup_4.vue' /* webpackChunkName: "components/session-signup4" */).then(c => wrapFunctional(c.default || c))
export const SessionSignup5Old = () => import('../../components/session/signup/SessionSignup_5_old.vue' /* webpackChunkName: "components/session-signup5-old" */).then(c => wrapFunctional(c.default || c))
export const SidebarNotificationItemAsset = () => import('../../components/sidebar-notification/Types/NotificationItemAsset.vue' /* webpackChunkName: "components/sidebar-notification-item-asset" */).then(c => wrapFunctional(c.default || c))
export const SidebarNotificationItemConnection = () => import('../../components/sidebar-notification/Types/NotificationItemConnection.vue' /* webpackChunkName: "components/sidebar-notification-item-connection" */).then(c => wrapFunctional(c.default || c))
export const SidebarNotificationItemDirect = () => import('../../components/sidebar-notification/Types/NotificationItemDirect.vue' /* webpackChunkName: "components/sidebar-notification-item-direct" */).then(c => wrapFunctional(c.default || c))
export const SidebarNotificationItemGeneric = () => import('../../components/sidebar-notification/Types/NotificationItemGeneric.vue' /* webpackChunkName: "components/sidebar-notification-item-generic" */).then(c => wrapFunctional(c.default || c))
export const SidebarNotificationItemMarket = () => import('../../components/sidebar-notification/Types/NotificationItemMarket.vue' /* webpackChunkName: "components/sidebar-notification-item-market" */).then(c => wrapFunctional(c.default || c))
export const SidebarNotificationItemMessage = () => import('../../components/sidebar-notification/Types/NotificationItemMessage.vue' /* webpackChunkName: "components/sidebar-notification-item-message" */).then(c => wrapFunctional(c.default || c))
export const StripePayNow = () => import('../../components/stripe/pay/StripePayNow.vue' /* webpackChunkName: "components/stripe-pay-now" */).then(c => wrapFunctional(c.default || c))
export const StripeEditProfile = () => import('../../components/stripe/verification/StripeEditProfile.vue' /* webpackChunkName: "components/stripe-edit-profile" */).then(c => wrapFunctional(c.default || c))
export const StripeVerificationBtn = () => import('../../components/stripe/verification/StripeVerificationBtn.vue' /* webpackChunkName: "components/stripe-verification-btn" */).then(c => wrapFunctional(c.default || c))
export const UserBasicCard = () => import('../../components/user/cards/UserBasicCard.vue' /* webpackChunkName: "components/user-basic-card" */).then(c => wrapFunctional(c.default || c))
export const UserFullCard = () => import('../../components/user/cards/UserFullCard.vue' /* webpackChunkName: "components/user-full-card" */).then(c => wrapFunctional(c.default || c))
export const UserFullCardDetails = () => import('../../components/user/cards/UserFullCardDetails.vue' /* webpackChunkName: "components/user-full-card-details" */).then(c => wrapFunctional(c.default || c))
export const UserHorizontalCard = () => import('../../components/user/cards/UserHorizontalCard.vue' /* webpackChunkName: "components/user-horizontal-card" */).then(c => wrapFunctional(c.default || c))
export const UserCardModal = () => import('../../components/user/modals/UserCardModal.vue' /* webpackChunkName: "components/user-card-modal" */).then(c => wrapFunctional(c.default || c))
export const UserConnectModal = () => import('../../components/user/modals/UserConnectModal.vue' /* webpackChunkName: "components/user-connect-modal" */).then(c => wrapFunctional(c.default || c))
export const UserConnectNoSatsModal = () => import('../../components/user/modals/UserConnectNoSatsModal.vue' /* webpackChunkName: "components/user-connect-no-sats-modal" */).then(c => wrapFunctional(c.default || c))
export const UserModalAvatar = () => import('../../components/user/modals/UserModalAvatar.vue' /* webpackChunkName: "components/user-modal-avatar" */).then(c => wrapFunctional(c.default || c))
export const HomeCategoriesCarousel = () => import('../../components/home/categories/carousel/HomeCategoriesCarousel.vue' /* webpackChunkName: "components/home-categories-carousel" */).then(c => wrapFunctional(c.default || c))
export const HomeCategoriesNewsCarousel = () => import('../../components/home/categories/carousel/HomeCategoriesNewsCarousel.vue' /* webpackChunkName: "components/home-categories-news-carousel" */).then(c => wrapFunctional(c.default || c))
export const HomeCollectionsCarousel = () => import('../../components/home/collections/carousel/HomeCollectionsCarousel.vue' /* webpackChunkName: "components/home-collections-carousel" */).then(c => wrapFunctional(c.default || c))
export const MarketingSectionCategory = () => import('../../components/marketing/sections/categories/MarketingSectionCategory.vue' /* webpackChunkName: "components/marketing-section-category" */).then(c => wrapFunctional(c.default || c))
export const MarketingSectionCta2 = () => import('../../components/marketing/sections/cta/MarketingSectionCta2.vue' /* webpackChunkName: "components/marketing-section-cta2" */).then(c => wrapFunctional(c.default || c))
export const MarketingSectionHeader = () => import('../../components/marketing/sections/headers/MarketingSectionHeader.vue' /* webpackChunkName: "components/marketing-section-header" */).then(c => wrapFunctional(c.default || c))
export const UserCardsBtnsButtonConnectModal = () => import('../../components/user/cards/btns/ButtonConnectModal.vue' /* webpackChunkName: "components/user-cards-btns-button-connect-modal" */).then(c => wrapFunctional(c.default || c))
export const UserCardsBtnsProgressBar = () => import('../../components/user/cards/btns/ProgressBar.vue' /* webpackChunkName: "components/user-cards-btns-progress-bar" */).then(c => wrapFunctional(c.default || c))
export const UserCardBtns = () => import('../../components/user/cards/btns/UserCardBtns.vue' /* webpackChunkName: "components/user-card-btns" */).then(c => wrapFunctional(c.default || c))
export const UserCardsBtnsFollow = () => import('../../components/user/cards/btns/UserCardsBtnsFollow.vue' /* webpackChunkName: "components/user-cards-btns-follow" */).then(c => wrapFunctional(c.default || c))
export const UserCardsBtnsUnfollow = () => import('../../components/user/cards/btns/UserCardsBtnsUnfollow.vue' /* webpackChunkName: "components/user-cards-btns-unfollow" */).then(c => wrapFunctional(c.default || c))
export const UserHandleConnectionBtn = () => import('../../components/user/cards/btns/UserHandleConnectionBtn.vue' /* webpackChunkName: "components/user-handle-connection-btn" */).then(c => wrapFunctional(c.default || c))
export const UserHandleExpiredRequestBtns = () => import('../../components/user/cards/btns/UserHandleExpiredRequestBtns.vue' /* webpackChunkName: "components/user-handle-expired-request-btns" */).then(c => wrapFunctional(c.default || c))
export const UserHandleMessageBtn = () => import('../../components/user/cards/btns/UserHandleMessageBtn.vue' /* webpackChunkName: "components/user-handle-message-btn" */).then(c => wrapFunctional(c.default || c))
export const UserHandleRequestBtns = () => import('../../components/user/cards/btns/UserHandleRequestBtns.vue' /* webpackChunkName: "components/user-handle-request-btns" */).then(c => wrapFunctional(c.default || c))
export const UserPendingRequestBtns = () => import('../../components/user/cards/btns/UserPendingRequestBtns.vue' /* webpackChunkName: "components/user-pending-request-btns" */).then(c => wrapFunctional(c.default || c))
export const UserCardsComponentsEntityData = () => import('../../components/user/cards/components/EntityData.vue' /* webpackChunkName: "components/user-cards-components-entity-data" */).then(c => wrapFunctional(c.default || c))
export const UserConnections = () => import('../../components/user/cards/components/UserConnections.vue' /* webpackChunkName: "components/user-connections" */).then(c => wrapFunctional(c.default || c))
export const UserFollowers = () => import('../../components/user/cards/components/UserFollowers.vue' /* webpackChunkName: "components/user-followers" */).then(c => wrapFunctional(c.default || c))
export const UserKnowledges = () => import('../../components/user/cards/components/UserKnowledges.vue' /* webpackChunkName: "components/user-knowledges" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
