
import { USER_OPTION_COMPANY, USER_OPTION_DEFAULT } from '~/vars/api';
import { GROUP_TYPE_OFFERING } from '~/vars/api';
import { mapGetters } from 'vuex';
import UserMixinHandlerLoggedUser from '~/components/user/UserMixinHandleLoggedUser.vue';
import BaseUserFullName from '~/components/base/BaseUserFullName.vue';
import UserCardsAvatar from '../UserCardsAvatar.vue';
import UserFollowers from './components/UserFollowers.vue';
import UserConnections from './components/UserConnections.vue';
export default {
  mixins: [UserMixinHandlerLoggedUser],
  components: {
    BaseUserFullName,
    UserCardsAvatar,
    UserFollowers,
    UserConnections,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    avatarSize: {
      type: Number,
      default: 230,
    },
    showDisconnectBtn: {
      type: Boolean,
      default: false,
    },
    showing: {
      type: Boolean,
      default: false,
    },
    fixedActions: {
      type: Boolean,
      default: true,
    },
    showClose: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    products: [],
    loadingProducts: false,
  }),
  computed: {
    ...mapGetters({
      checkIfHasNotificationWithUser: 'notifications/hasNotificationWithUser',
    }),
    connectionsEnabled() {
      return this.$api.commons.connectionsEnabled();
    },
    followsEnabled() {
      return this.$api.commons.followsEnabled();
    },
    showBtns() {
      return this.connectionsEnabled || this.followsEnabled;
    },
    onlyCompanyMinter() {
      return this.$api.commons.onlyCompanyMinter();
    },
    showProfile() {
      return (
        (!this.onlyCompanyMinter ||
          (this.onlyCompanyMinter && this.isCompany)) &&
        this.$api.commons.vipProfile(this.user.nick)
      );
    },
    hasNotificationWithUser() {
      return this.checkIfHasNotificationWithUser(this.user.id);
    },
    iconEdit() {
      return this.isLoggedUser ? 'mdi-account-edit' : null;
    },
    iconClose() {
      return 'mdi-close';
    },
    socialMedia() {
      return this.user.social_media;
    },
    accountPage() {
      return this.$route.path.includes('account');
    },
    isEntity() {
      return this.user.user_option !== USER_OPTION_DEFAULT;
    },
    GROUP_TYPE_OFFERING() {
      return GROUP_TYPE_OFFERING;
    },
    isCompany() {
      return this.user.user_option === USER_OPTION_COMPANY;
    },
    followersCounter() {
      return this.user.followers_counter || 0;
    },
  },
  methods: {
    onProductClicked(product) {
      const token = product.groups[0];

      this.$router.push(
        this.localePath({
          name: 'market-product-id',
          params: {
            id:
              token.type === GROUP_TYPE_OFFERING &&
              token.offering.status !== 'closed'
                ? token.offeringid
                : token.offeringid,
          },
        }),
      );
      this.closeModal();
    },
    onIconCloseClicked() {
      this.closeModal();
    },
    onIconEditClicked() {
      this.isLoggedUser ? this.openEditModal() : null;
    },
    openEditModal() {
      this.$router.push(this.localePath({ name: 'account-profile' }));
      this.$emit('edit-modal');
    },
    closeModal() {
      this.$emit('btn-close-clicked');
    },
    onViewProfileBtnClicked() {
      this.$store.dispatch('users/hiddeUserModal');
    },
    imageProduct(product) {
      let image;
      if (product.contract.image.charAt(0) === '[')
        image = JSON.parse(product.contract.image).map(
          (item) => item + (item.endsWith('?alt=media') ? '' : '?alt=media'),
        )[0];
      else
        image =
          product.contract.image +
          (product.contract.image.endsWith('?alt=media') ? '' : '?alt=media');
      return image;
    },
  },
  async mounted() {
    try {
      const result = await this.$api.market.getOfferings({
        issuernick: this.user.nick,
        ownernick: this.user.nick,
        status: ['closed', 'nobids'],
      });
      console.log({ result });
      this.loadingProducts = result && result.items.length;
      /* Only we show 3 products and products with opened offerings */
      this.products = result.items;
      this.$nextTick(() => {
        this.loadingProducts = false;
      });
    } catch {
      this.loadingProducts = false;
    }
  },
};
