
import { handleErrors } from '~/utils/functions';
import { USER_PLATFORM } from '~/vars/api';
import {
  ADD_USER_NICK_REQUEST_USER,
  ADD_USER_REQUEST_USER,
} from '~/vars/store/mutations';
/*
   This mixin is made to fetch user in case user ins't in the store
   Required userId/userNick in data on merged component to works
*/
export default {
  data: () => ({
    user: null,
    userInterval: null,
  }),
  computed: {
    userFullName() {
      return this.user
        ? this.user.name
          ? `${this.user.name}`
          : `@${this.user.nick}`
        : '';
    },
  },
  fecthOnServer: false,
  async fetch() {
    if (
      this.$auth.loggedIn &&
      (this.userId === this.$auth.user.data.id ||
        this.userNick === this.$auth.user.data.nick)
    ) {
      this.user = this.$auth.user.data;
      return;
    }
    if (this.userId === USER_PLATFORM.id) {
      this.user = USER_PLATFORM;
      return;
    }
    try {
      const requestedBy = this.userId ? this.userId : this.userNick;
      if (!requestedBy) throw 'No undefined/null userid/usernick';
      if (this.$store.getters['users/userAreRequested'](requestedBy)) {
        await new Promise(async (resolve, reject) => {
          this.userInterval = setInterval(() => {
            let user = this.$store.getters['users/getUser'](requestedBy);
            if (user) {
              this.user = user;
              clearInterval(this.userInterval);
              return resolve();
            }
          }, 1000);
        });
      } else {
        let user = this.$store.getters['users/getUser'](requestedBy);
        if (user) {
          this.user = user;
        } else {
          const added = this.userId
            ? ADD_USER_REQUEST_USER
            : ADD_USER_NICK_REQUEST_USER;
          this.$store.commit(`users/${added}`, requestedBy);
          const method = this.userId
            ? this.$auth.loggedIn
              ? 'getOneUserById'
              : 'guestGetUserById'
            : 'getOneUserByNick';
          this.user = await this.$api.user[method](requestedBy);
          this.$store.dispatch('users/addUser', this.user);
        }
      }
    } catch (error) {
      handleErrors(error);
    }
  },
};
