
import {
  MARKET,
  NOTIFICATIONS_ICON_BY_ACTIVITY,
  NOTIFICATIONS_PATH_BY_ACTIVITY,
  ASSET,
  MINTER,
  MESSAGE,
  CONNECTION,
} from '~/vars/api';
import UserMixinHandler from '~/components/user/UserMixinHandler.vue';

export default {
  props: {
    userId: {
      type: String,
    },
    notification: {
      type: Object,
    },
  },
  components: {
    NotificationItemGeneric: () =>
      import('./Types/NotificationItemGeneric.vue'),
    NotificationItemAsset: () => import('./Types/NotificationItemAsset.vue'),
    NotificationItemMarket: () => import('./Types/NotificationItemMarket.vue'),
    NotificationItemDirect: () => import('./Types/NotificationItemDirect.vue'),
    NotificationItemConnection: () =>
      import('./Types/NotificationItemConnection.vue'),
    NotificationItemMessage: () =>
      import('./Types/NotificationItemMessage.vue'),
  },
  mixins: [UserMixinHandler],
  data() {
    return {};
  },
  computed: {
    fiatEnabled() {
      return this.$api.commons.fiatEnabled();
    },
    getClass() {
      return this.notification.read
        ? 'notification-item--read'
        : 'notification-item--unread';
    },
    svgList() {
      return {
        direct: '/svg/icons/wallet_avatar.svg',
        minter: '/svg/icons/minter_avatar.svg',
        purchase: '/svg/icons/crown.svg',
        update: '/svg/icons/crown.svg',
      };
    },
    image() {
      if (
        this.notificationSection.includes('market') ||
        this.notificationSection.includes('asset')
      )
        return this.notificationPayload.tokenImage.endsWith('?alt=media')
          ? this.notificationPayload.tokenImage
          : `${this.notificationPayload.tokenImage}${'?alt=media'}`;
      else if (
        this.notificationSection.includes('message') ||
        this.notificationSection.includes('connection')
      ) {
        return this.user.profile_image.fullpath.endsWith('?alt=media')
          ? this.user.profile_image.fullpath
          : `${this.user.profile_image.fullpath}${'?alt=media'}`;
      } else {
        return this.svgList[this.notificationSection];
      }
    },
    componentType() {
      return `notification-item-${
        ['connection', 'direct', 'message', 'asset', 'market'].includes(
          this.notificationSection,
        )
          ? this.notificationSection
          : 'generic'
      }`;
    },
    notificationPayload() {
      return Object.assign(
        {
          sourceid: this.notificationAction.includes('accepted')
            ? this.notification.destinationid
            : this.notification.sourceid,
          itemmarket: this.notification.itemmarket,
        },
        this.notification.payload,
      );
    },
    // sidebar notification by activity
    notificationIcon() {
      return NOTIFICATIONS_ICON_BY_ACTIVITY[this.notification.activity];
    },
    notificationSection() {
      return this.notification.activity.split('_')[0];
    },
    notificationAction() {
      let action = this.notification.activity.split('_').slice(1).join('_');
      if (action.includes('orderpaid')) {
        return `${action}_${
          this.notification.sourceid === this.$auth.user.data.id
            ? 'client'
            : 'owner'
        }`;
      } else return action;
    },
    // sidebar notification section by section
    notificationTitle() {
      return this.$t(`sidebar.section.${this.notificationSection}.title`);
    },
    notificationType() {
      return this.notification.type
        ? this.$t(
            `sidebar.section.${this.notificationSection}.type.${this.notification.type}`,
          )
        : this.notification.type;
    },
    notificationAccess() {
      return this.notification.access
        ? this.$t(
            `sidebar.section.${this.notificationSection}.access.${this.notification.access}`,
          )
        : this.notification.access;
    },
    notificationI18nPath() {
      return `sidebar.section.${this.notificationSection}.description.${
        this.notificationAction
      }${
        this.notificationAction.includes('bidcreated_owner')
          ? `.${this.notification.payload.time}.text`
          : this.notificationAction.includes('orderpaid_owner') ||
            this.notificationAction.includes('royaltypaid') ||
            this.notificationAction.includes('beneficiarypaid')
          ? this.notificationPayload.amountSats
            ? this.fiatEnabled
              ? '.wallet_fiat'
              : '.wallet'
            : '.stripe'
          : ''
      }`;
    },
    // relative date of notification from actual date
    formatedDate() {
      return this.$moment(this.notification.createdat).calendar(null, {
        sameDay: '[Today] kk:mm',
        nextDay: '[Tomorrow]',
        nextWeek: '[Next] dddd',
        lastDay: '[Yesterday]',
        lastWeek: '[Last] dddd',
        sameElse: 'DD/MM/YY',
      });
    },
    // where to route when clicked by activity
  },
  methods: {
    /**
     * emit where to route when clicked to know if it is needed to refresh &| hide drawer
     */
    routeTo() {
      if ([MINTER].includes(this.notificationSection)) {
        document.getElementById('notifications-btn').click();
        this.$store.dispatch('ui/showVerifyModal', {
          status: true,
        });
      } else {
        const { name, params, query } = this.getUriNotification();
        this.$router.push(this.localeRoute({ name, params, query }));
        this.$emit('route-to', name);
      }
    },
    getUriNotification() {
      switch (this.notificationSection) {
        case MESSAGE:
        case CONNECTION:
          let { name, params } = NOTIFICATIONS_PATH_BY_ACTIVITY[MESSAGE];
          params[params.dynamic] = this.user.id;
          return { name, params };
        case ASSET:
          if (this.notification.activity) {
            const { name, params } =
              NOTIFICATIONS_PATH_BY_ACTIVITY[this.notification.activity];
            const query = {};
            if (this.notification.payload.itemmarket) {
              params[params.dynamic] = this.notification.payload.itemmarket;
              if (this.notification.activity.includes('transfer'))
                query.type = 'available';
            }
            return { name, params, query };
          }
        case MARKET:
          if (this.notification.activity) {
            const { name, params } =
              NOTIFICATIONS_PATH_BY_ACTIVITY[this.notification.activity];
            const query = {};
            const itemmarket = this.notification.payload.itemmarket;
            if (itemmarket) {
              params[params.dynamic] = itemmarket;
            }
            return { name, params, query };
          }
        default:
          // rest of routes
          return NOTIFICATIONS_PATH_BY_ACTIVITY[this.notification.activity];
      }
    },
  },
};
