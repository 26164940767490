import { decryptMessage, encryptMessage, sat2Usd } from '~/utils/functions';
import {
  MIN_VALUE_TO_CALCULATE_RATE,
  EXCHANGE_RATE_MODE_SAT_TO_USD,
  CONNECTION,
  CONNECTION_STATUS_ACCEPTED,
  CONNECTION_STATUS_REJECTED,
  CONNECTION_STATUS_PENDING,
  CONNECTION_STATUS_PREPAID,
  CONNECTION_STATUS_PRECREATED,
  CONNECTION_STATUS_PRECHARGED,
  CONNECTION_STATUS_PRESHARED,
  MESSAGES_STREAM_OUT,
  MESSAGES_STREAM_IN,
  EXCHANGE_RATE_MODE_BSV_TO_USD,
  CONNECTION_STATUS_NO_CONNECTION,
  MINER_COMISSION,
  BYTES_MINER_COMMISION,
  EXTRA_MINER_MARGIN,
  MIN_BALANCE_SATS_MARGIN,
  CustomError,
  CUSTOM_ERROR,
  G2C_CONNECTIONS_COMMISION_TYPE,
} from '~/vars/api';

import { encryptContent } from '~/utils/functions';
import mpDB from '~/indexedDB/mpDB';

export default ({ app, $axios }) => ({
  async messageConnectRate(
    msg,
    amount,
    type = 'message',
    createdConnectionFolder,
  ) {
    let commission;
    let createFolder = 0;
    let connectionFolder = createdConnectionFolder;
    if (type === 'connection') {
      let outConnections;
      if (typeof createdConnectionFolder === 'undefined') {
        const g2ctoken = app.$api.g2cSession.getToken();
        outConnections = await app.$api.g2cCommons.getUserObjectList({
          g2ctoken,
          path: '/' + MESSAGES_STREAM_OUT,
          type,
        });
      }
      if (
        createdConnectionFolder === false ||
        (outConnections && outConnections.length === 0)
      ) {
        connectionFolder = false;
        createFolder = MIN_VALUE_TO_CALCULATE_RATE;
      }
    }
    if (app.$api.commons.fiatEnabled()) {
      commission = await app.$api.commons.exchangeRates(
        Math.ceil(msg.length) * BYTES_MINER_COMMISION +
          MIN_VALUE_TO_CALCULATE_RATE +
          createFolder,
        EXCHANGE_RATE_MODE_SAT_TO_USD,
      );
    } else {
      commission =
        Math.ceil(Math.ceil(msg.length) * BYTES_MINER_COMMISION) +
        MIN_VALUE_TO_CALCULATE_RATE +
        createFolder;
    }
    const total = amount + commission;
    let finalTotal = total * MINER_COMISSION;
    if (!app.$api.commons.fiatEnabled()) {
      finalTotal = Math.ceil(total * MINER_COMISSION);
    }
    if (type === 'connection') return { total: finalTotal, connectionFolder };
    return finalTotal;
  },
  async checkConnection(user) {
    const connectionUserObjectList =
      await app.$api.g2cConnections.getConnectionUserObjectList({
        stream: 'out',
      });
    const userConnection = connectionUserObjectList.find(
      (item) => item.name === user.nick,
    );
    let connectionObject;
    if (typeof userConnection !== 'undefined') {
      connectionObject = await app.$api.g2cConnections.getConnectionUserObject({
        stream: 'out',
        name: user.nick,
      });
      if (
        connectionObject.type !== 'userConnection' ||
        connectionObject.isDeleted === true
      )
        connectionObject = undefined;
    }
    return app.store.dispatch('users/showUserConnectModal', {
      user,
      connectionObject,
    });
  },
  async createConnectionsFolder() {
    await app.$api.g2cConnections.createConnectionsFolder(MESSAGES_STREAM_IN);
    return await app.$api.g2cConnections.createConnectionsFolder(
      MESSAGES_STREAM_OUT,
    );
  },
  async createConnectionObject({ streaming, from, to, datestamp, message }) {
    try {
      const result = await app.$api.g2cConnections.createConnectionObject({
        streaming,
        from,
        to,
        message,
        datestamp,
      });
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  async createConnection({
    prefix,
    to,
    from,
    destinationUserId,
    paymentauth,
    message,
    status,
    amount,
    datestamp,
    historyversionsrc,
    historydatestampsrc,
    size,
  }) {
    const rawMessage = await encryptMessage({
      prefix,
      from,
      to,
      message: message,
    });
    const requestbsvexchange = await app.$api.commons.exchangeRates(
      1,
      EXCHANGE_RATE_MODE_BSV_TO_USD,
    );
    app.store.dispatch('messages/setConnectionMessage', {
      messageId: destinationUserId,
      rawMessage: message,
    });

    const registerId = destinationUserId;
    const formatedResultForIndexedDB = {
      id: registerId,
      message: await encryptContent(
        `${registerId}-${app.$auth.user.data.id}`,
        message,
      ),
    };
    mpDB.updateConnectionMsg(formatedResultForIndexedDB);
    const result = await $axios.post(
      `users/${app.$auth.user.data.id}/connect2/create`,
      //  `users/${app.$auth.user.data.id}/connections/create`,
      {
        destination_user_id: destinationUserId,
        status,
        paymentauth,
        message: rawMessage,
        satoshis: amount,
        requestbsvexchange,
        datestamp,
        historyversionsrc,
        historydatestampsrc,
        size,
      },
    );
    return result;
  },

  async walletPropose({ amount, to, type = CONNECTION }) {
    const from = app.$auth.user.data.nick;
    const description = `Connection payment from @${from} to @${to}`;
    return await app.$api.payments.walletProposePayment({
      destinationnick: to,
      amount,
      commissiontype: G2C_CONNECTIONS_COMMISION_TYPE,
      description,
    });
  },
  async walletAccept(paymentauth) {
    if (!app.$api.commons.satsEnabled())
      return await this.walletDismiss(paymentauth);
    return await app.$api.payments.walletConditionalAccept({
      paymentauth,
    });
  },
  async walletDismiss(paymentauth) {
    return await app.$api.payments.walletRejectPayment({
      paymentauth,
    });
  },

  async cancelConnection({ userId, destinationid, sourceid, connectionid }) {
    return await this.updateConnection({
      userId,
      destinationid,
      sourceid,
      paymentauth: '',
      connectionid,
      status: CONNECTION_STATUS_NO_CONNECTION,
    });
  },
  async rejectConnection({ userId, destinationid, sourceid, connectionid }) {
    return await this.updateConnection({
      userId,
      destinationid,
      sourceid,
      paymentauth: null,
      status: CONNECTION_STATUS_NO_CONNECTION,
      connectionid,
      message: null,
    });
  },
  async updateConnection({
    userId,
    destinationid,
    sourceid,
    paymentauth,
    status,
    message,
    amount,
    requestbsvexchange,
    acceptbsvexchange,
    connectionid,
    historydatestampdst,
    historydatestampsrc,
    historyversiondst,
    historyversionsrc,
    size,
  }) {
    const loggedUserId = app.$auth.user.data.id;
    return await $axios.put(`users/${loggedUserId}/connect2/update`, {
      destination_user_id: userId,
      destinationid,
      sourceid,
      status: status,
      paymentauth: paymentauth,
      message: message,
      satoshis: amount,
      requestbsvexchange,
      acceptbsvexchange,
      connectionid,
      historydatestampdst,
      historyversiondst,
      historydatestampsrc,
      historyversionsrc,
      size,
    });
  },
  async userCanAcceptConnection() {
    const userBalance = await app.$api.commons.getUserBalance();
    return userBalance.balanceSats > MIN_BALANCE_SATS_MARGIN;
  },
  async updateExpiration(connection) {
    return new Promise(async (resolve, reject) => {
      try {
        let walletPropose;
        let exchangedCost = 0;
        let requestbsvexchange = 0;
        if (app.$api.commons.satsEnabled() && app.$api.commons.userPaidFees()) {
          if (connection.paymentauth !== '') {
            const walletDismissed = await this.walletDismiss(
              connection.paymentauth,
            ).catch((error) => {
              if (error.name.includes(CUSTOM_ERROR)) return reject(error);
              console.log(error);
            });
          }
          let amount = connection.satoshis;

          if (app.$api.commons.fiatEnabled()) {
            amount =
              sat2Usd(connection.satoshis, connection.requestbsvexchange) *
              MINER_COMISSION;
          }
          if (!app.$api.commons.userHasEnoughBalance(amount))
            return reject(app.i18n.t('connections.not_funds'));
          exchangedCost = connection.satoshis;
          if (app.$api.commons.fiatEnabled()) {
            exchangedCost = await app.$api.commons.exchangeRates(amount);
          }
          requestbsvexchange = await app.$api.commons.exchangeRates(
            1,
            EXCHANGE_RATE_MODE_BSV_TO_USD,
          );
          walletPropose = await this.walletPropose({
            amount: exchangedCost,
            to: connection.destinationnick,
          });
          if (walletPropose.error)
            return reject(app.i18n.t('connections.error.create.payment'));
        }
        resolve(
          await $axios.put(
            `users/${app.$auth.user.data.id}/connect2/updateExpiration`,
            {
              sourceid: app.$auth.user.data.id,
              destinationid: connection.destinationid,
              connectionid: `${connection.sourceid}-${connection.destinationid}`,
              paymentauth:
                typeof walletPropose !== 'undefined'
                  ? walletPropose.data.paymentauth
                  : null,
              satoshis: exchangedCost,
              requestbsvexchange,
            },
          ),
        );
      } catch (error) {
        reject(error);
      }
    });
  },
  async getConnectionObject(destinationnick, pathToSeek, nickToSeek, versions) {
    return await app.$api.g2cConnections.getConnectionObject({
      destinationnick,
      pathToSeek,
      nickToSeek,
      versions,
    });
  },
  async connectToUser({
    userNick,
    userId,
    message,
    amount,
    previousConnection = null,
  }) {
    const to = userNick;
    const from = app.$auth.user.data.nick;
    const datestamp =
      previousConnection === null
        ? new Date().getTime()
        : JSON.parse(previousConnection.object).timestamp;
    const exchangedCost =
      app.$api.commons.fiatEnabled() && app.$api.commons.satsEnabled()
        ? await app.$api.commons.exchangeRates(amount)
        : amount;

    // Wallet payment and create object can be paralelized
    const promises = [];
    // Start object create
    promises.push(
      previousConnection === null
        ? await this.createConnectionObject({
            streaming: MESSAGES_STREAM_OUT,
            to,
            from,
            message,
            datestamp,
          }).catch((e) => {
            throw e;
          })
        : Object.assign(
            {
              historyDatestamp: previousConnection.updated,
              historyVersion: previousConnection.version,
              objectSize: previousConnection.size,
            },
            previousConnection,
          ),
    );

    if (app.$api.commons.userPaidFees()) {
      // Start wallet poropose
      promises.push(
        await this.walletPropose({
          amount: exchangedCost,
          to,
        }),
      );
    }

    return Promise.allSettled(promises).then(async (results) => {
      const passedWallet = app.$api.commons.userPaidFees()
        ? results[1].status === 'fulfilled'
        : true;
      const passedObject = results[0].status === 'fulfilled';
      let error;
      let status = null;
      let paymentAuth = null;
      let size = null;
      if (app.$api.commons.userPaidFees()) console.log(results[1].value);
      console.log(results[0].value);
      if (passedWallet === true && passedObject === true) {
        paymentAuth = app.$api.commons.userPaidFees()
          ? results[1].value.paymentauth
          : undefined;
        status = CONNECTION_STATUS_PENDING;
        size = results[0].value.objectSize;
      } else if (passedObject === false && passedWallet === false) {
        // Try again
        error = app.i18n.t('connections.error.create.generic');
      } else if (passedObject === false) {
        // Failed object creation but payment ok -> prepaid
        paymentAuth = app.$api.commons.userPaidFees()
          ? results[1].value.paymentauth
          : undefined;
        status = CONNECTION_STATUS_PREPAID;
        error = app.i18n.t('connections.error.create.object');
      } else if (passedWallet === false) {
        // Failed wallet but object created ok -> precreated
        paymentAuth = null;
        status = CONNECTION_STATUS_PRECREATED;
        size = results[0].value.objectSize;
        error = app.i18n.t('connections.error.create.payment');
      }
      if (status !== null) {
        const prefix = results[0].value.historyDatestamp;
        if (results[0].value.historyVersion === 1) {
          const historydatestampsrc = results[0].value.historyDatestamp;
          const historyversionsrc = results[0].value.historyVersion;
          await this.createConnection({
            prefix,
            to,
            from,
            destinationUserId: userId,
            paymentauth: paymentAuth,
            message,
            status,
            amount: exchangedCost,
            datestamp,
            historyversionsrc,
            historydatestampsrc,
            size,
          });
        } else {
          const rawMessage = await encryptMessage({
            prefix,
            from,
            to,
            message: message,
          });
          const requestbsvexchange = await app.$api.commons.exchangeRates(
            1,
            EXCHANGE_RATE_MODE_BSV_TO_USD,
          );
          const historydatestampsrc = results[0].value.historyDatestamp;
          const historyversionsrc = results[0].value.historyVersion;
          await this.updateConnection({
            prefix,
            to,
            from,
            destinationUserId: userId,
            paymentauth: paymentAuth,
            message: rawMessage,
            status,
            amount: exchangedCost,
            requestbsvexchange,
            datestamp,
            userId: app.$auth.user.data.id,
            destinationid: userId,
            sourceid: app.$auth.user.data.id,
            connectionid: `${app.$auth.user.data.id}-${userId}`,
            historyversionsrc,
            historydatestampsrc,
            size,
          });
        }
      }
      if (error) {
        app.$toast.error(error);
        throw null;
      } else {
        return true;
      }
    });
  },
  async continueConnectToUser({ to, from, userId, connection, message }) {
    const amount = connection.satoshis;
    const requestbsvexchange = connection.requestbsvexchange;
    let paymentAuth = connection.paymentauth;
    let size = null;

    let error = null;
    if (connection.status === CONNECTION_STATUS_PREPAID) {
      const createdObject = await this.createConnectionObject({
        streaming: MESSAGES_STREAM_OUT,
        to,
        from,
        message,
        datestamp: connection.created_at,
      });
      if (createdObject.error) {
        error = app.i18n.t('connections.errors.create.object');
      }
      size = createdObject.data.objectSize;
    } else if (
      connection.status === CONNECTION_STATUS_PRECREATED &&
      app.$api.commons.userPaidFees()
    ) {
      const walletPropose = await this.walletPropose({
        amount,
        to,
      });
      if (walletPropose.error) {
        error = app.i18n.t('connections.errors.create.payment');
      }
      paymentAuth = walletPropose.data.paymentauth;
      requestbsvexchange = await app.$api.commons.exchangeRates(
        1,
        EXCHANGE_RATE_MODE_BSV_TO_USD,
      );
    }
    if (error === null) {
      await this.updateConnection({
        userId,
        destinationid: connection.destinationid,
        sourceid: connection.sourceid,
        paymentauth: paymentAuth,
        message: connection.message,
        status: CONNECTION_STATUS_PENDING,
        amount,
        datestamp: connection.created_at,
        requestbsvexchange,
        connectionid: `${connection.sourceid}-${connection.destinationid}`,
        size,
      });
    } else {
      app.$toast.error(error);
      throw null;
    }
  },

  // New procedure is like create but changing paths
  async createDestinationUserObjectAccept({ from, to, datestamp, message }) {
    return await this.createConnectionObject({
      streaming: MESSAGES_STREAM_IN,
      from,
      to,
      datestamp,
      message,
    });
  },

  async acceptConnectionParallel({ tasks, connection, message }) {
    const promises = [];
    promises.push(await tasks.acceptPayment());
    promises.push(await tasks.createObject());
    return Promise.allSettled(promises).then(async (results) => {
      const passedShare = results[1].status === 'fulfilled';
      const passedWallet = results[0].status === 'fulfilled';
      // const passedWallet = false;
      let error;
      let status = null;
      let paymentAuth = connection.paymentauth;

      if (passedWallet === true && passedShare === true) {
        status = CONNECTION_STATUS_ACCEPTED;
        paymentAuth = null;
        message = null;
      } else if (passedShare === false && passedWallet === false) {
        // Try again
        error = app.i18n.t('connections.errors.accept.generic');
      } else if (passedShare === false) {
        status = CONNECTION_STATUS_PRECHARGED;
        paymentauth = null;
        error = app.i18n.t('connections.errors.accept.object');
      } else if (passedWallet === false) {
        status = CONNECTION_STATUS_PRESHARED;
        error = app.i18n.t('connections.errors.accept.payment');
      }
      return {
        status,
        paymentAuth,
        message,
        error,
        objectCreated: results[1].value,
      };
    });
  },
  async acceptConnectionSeries({ tasks, connection, message }) {
    let error;
    let status = null;
    let paymentAuth = connection.paymentauth;
    let objectCreated;
    try {
      const walletAccepted = await tasks.acceptPayment();
      if (walletAccepted.error) {
        error = app.i18n.t('connections.errors.accept.payment');
        status = CONNECTION_STATUS_PRESHARED;
      }
      if (status === null) {
        objectCreated = await tasks.createObject();
        if (objectCreated.error) {
          error = app.i18n.t('connections.errors.accept.object');
          status = CONNECTION_STATUS_PRECHARGED;
          paymentauth = null;
          throw new Error(error);
        }

        if (status === null) {
          status = CONNECTION_STATUS_ACCEPTED;
          paymentAuth = null;
          message = null;
        }
      }
      return {
        status,
        paymentAuth,
        message,
        error,
        objectCreated: objectCreated !== undefined ? objectCreated : null,
      };
    } catch (e) {
      error =
        e.message && e.message.includes('Please, try again later')
          ? e.message
          : app.i18n.t('connections.errors.accept.generic');
      return {
        status,
        paymentAuth,
        message,
        error,
        objectCreated: objectCreated !== undefined ? objectCreated : null,
      };
    }
  },
  async acceptConnection({ userNick, userId, connection, message, datestamp }) {
    const from = userNick;
    const to = app.$auth.user.data.nick;
    const prefix = datestamp;
    let response = null;

    const messageClear = await decryptMessage({
      prefix,
      from,
      to,
      message,
    });

    //Might require decrypt message ?

    const acceptPayment = () =>
      typeof connection.paymentauth !== 'undefined' &&
      connection.paymentauth !== null
        ? this.walletAccept(connection.paymentauth)
        : true;
    const createObject = () =>
      this.createDestinationUserObjectAccept({
        from,
        to,
        datestamp,
        message: messageClear,
      });

    const tasks = { acceptPayment, createObject };

    const userCanAccept = app.$api.commons.userPaidFees()
      ? await this.userCanAcceptConnection()
      : true;

    if (
      !app.$api.commons.userPaidFees() ||
      (app.$api.commons.userPaidFees() && !userCanAccept)
    ) {
      response = await this.acceptConnectionSeries({
        tasks,
        connection,
        message,
      });
    } else {
      response = await this.acceptConnectionParallel({
        tasks,
        connection,
        message,
      });
    }

    if (response.status !== null) {
      const acceptbsvexchange = await app.$api.commons.exchangeRates(
        1,
        EXCHANGE_RATE_MODE_BSV_TO_USD,
      );
      if (response.status === CONNECTION_STATUS_ACCEPTED) {
        app.store.dispatch('messages/setConnectionMessage', {
          messageId: connection.sourceid,
          rawMessage: messageClear,
        });
        const { destinationid, sourceid } = connection;
        const registerId =
          destinationid === app.$auth.user.data.id ? sourceid : destinationid;
        const formatedResultForIndexedDB = {
          id: registerId,
          message: await encryptContent(
            `${registerId}-${app.$auth.user.data.id}`,
            messageClear,
          ),
        };
        mpDB.updateConnectionMsg(formatedResultForIndexedDB);
      }
      await this.updateConnection({
        userId,
        destinationid: connection.destinationid,
        sourceid: connection.sourceid,
        status: response.status,
        paymentauth: response.paymentAuth,
        message: response.message,
        requestbsvexchange: connection.requestbsvexchange,
        acceptbsvexchange,
        connectionid: `${connection.sourceid}-${connection.destinationid}`,
        historyversionsrc: connection.historyversionsrc
          ? connection.historyversionsrc
          : 1,
        historydatestampsrc: connection.historydatestampsrc
          ? connection.historydatestampsrc
          : connection.created_at,
        historydatestampdst:
          response.objectCreated !== null
            ? response.objectCreated.historyDatestamp
            : undefined,
        historyversiondst:
          response.objectCreated !== null
            ? response.objectCreated.historyVersion
            : undefined,
        size: response.objectCreated.objectSize,
      });
    }
    if (response.error) throw new CustomError(response.error);
  },
  async continueAcceptConnection({
    userNick,
    userId,
    connection,
    datestamp,
    message,
  }) {
    const from = userNick;
    const to = app.$auth.user.data.nick;
    const prefix = datestamp;

    const messageClear = await decryptMessage({
      prefix,
      from,
      to,
      message,
    });

    let paymentAuth = connection.paymentauth;
    let acceptbsvexchange = connection.acceptbsvexchange;
    let error = null;
    let size = null;
    if (connection.status === CONNECTION_STATUS_PRECHARGED) {
      const sharedObject = await this.createDestinationUserObjectAccept({
        from,
        to,
        datestamp,
        messageClear,
      });

      if (sharedObject.error) {
        error = app.i18n.t('connections.errors.accept.object');
      } else {
        size = sharedObject.data.objectSize;
        message = null;
      }
    } else if (connection.status === CONNECTION_STATUS_PRESHARED) {
      if (typeof paymentAuth !== 'undefined' && paymentAuth !== null) {
        const walletAccept = await this.walletAccept(paymentAuth);

        if (walletAccept.error) {
          error = app.i18n.t('connections.errors.accept.payment');
        } else {
          paymentAuth = null;
          acceptbsvexchange = await app.$api.commons.exchangeRates(
            1,
            EXCHANGE_RATE_MODE_BSV_TO_USD,
          );
          message = null;
        }
      }
    }
    if (error === null) {
      await this.updateConnection({
        userId,
        destinationid: connection.destinationid,
        sourceid: connection.sourceid,
        status: CONNECTION_STATUS_ACCEPTED,
        paymentauth: paymentAuth,
        //?? WHY  amount: connection.satoshis,
        message,
        requestbsvexchange: connection.requestbsvexchange,
        acceptbsvexchange,
        connectionid: `${connection.sourceid}-${connection.destinationid}`,
        size,
      });
    } else {
      app.$toast.error(error);
    }
  },
});
