import { render, staticRenderFns } from "./error.vue?vue&type=template&id=dc5ca670&scoped=true&"
import script from "./error.vue?vue&type=script&lang=js&"
export * from "./error.vue?vue&type=script&lang=js&"
import style0 from "./error.vue?vue&type=style&index=0&id=dc5ca670&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc5ca670",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CompanyLogo: require('/tmp/build_fc5c3f7c/components/company/CompanyLogo.vue').default,BaseBtn: require('/tmp/build_fc5c3f7c/components/base/BaseBtn.vue').default})
