import { MARKETPLACE_NAME } from '~/vars/api';

export default {
  hail: {
    first: 'Welcome {user} ',
    later: 'Hi again {user} ',
  },
  message: {
    fill_info: `Fill your name in your profile information to appear on ${MARKETPLACE_NAME} Marketplace and start receving and requesting connections.`,
    connections: {
      refresh: 'Accept to update connections',
    },
    notification: {
      safari_not_allowed:
        "Sorry but this browser doesn't give support to web notifications",
      edit_config:
        'You want to stay informed of what is happening with your Network',
      check_permission_browser:
        'Please check in the address bar or in your browser settings to allow notifications',
    },
  },
  actions: {
    edit_profile: 'Edit Profile',
    edit_notification: 'Edit Notification',
  },
};
