export default (ctx) => session_middleware(ctx);

const session_middleware = ({ app, redirect, route, from }) => {
  const g2ctoken = app.$api.g2cSession.getToken();
  const hasTokens = typeof g2ctoken !== 'undefined';
  console.log('HAS TOKENS', hasTokens);
  if (app.$auth.loggedIn && hasTokens) {
    return redirect('/');
  }
};
