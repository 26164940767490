
import { mapGetters } from 'vuex';
import {
  TOGGLE_SHOW_USER_MODAL,
  SET_SELECTED_USER_MODAL,
} from '~/vars/store/mutations';
import UserMixinHandler from '~/components/user/UserMixinHandler.vue';
export default {
  mixins: [UserMixinHandler],
  props: {
    showDisconnectBtn: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      showUserModal: 'users/showUserCardModal',
      selectedUserModal: 'users/selectedUserModal',
      optionsUserModal: 'users/optionsUserModal',
    }),
    shouldShowUserModal: {
      get() {
        return this.showUserModal;
      },
      set(newVal) {
        this.$store.dispatch('users/hiddeUserModal');
      },
    },
    hasUserSetted() {
      return this.user && Object.entries(this.user).length;
    },
    userId() {
      return this.selectedUserModal.id || this.selectedUserModal.userid;
    },
  },
  watch: {
    showUserModal(val) {
      if (!val) {
        this.$store.commit(`users/${SET_SELECTED_USER_MODAL}`, {});
      }
    },
  },
};
