
import VerifyEntityButton from '~/components/session/VerifyEntityButton.vue';
import { handleErrors } from '~/utils/functions';
import { CUSTOM_ERROR } from '~/vars/api';
import { mapGetters } from 'vuex';
import { showReportModal } from '~/vars/store/actions';
export default {
  components: { VerifyEntityButton },
  data() {
    return {
      valid: false,
      errorValidation: null,
      successValidation: null,
      reportResponse: null,
      loading: false,
      formReport: {
        reason: null,
        observations: null,
      },
      rules: {
        reason: this.$rules.reason,
        observations: this.$rules.observations,
      },
    };
  },
  computed: {
    ...mapGetters({
      showReportModal: 'report/showReportModal',
      info: 'report/info',
    }),
    shouldShowReportModal: {
      get() {
        return this.showReportModal;
      },
      set(value) {
        this.$store.dispatch(`report/${showReportModal}`, {
          show: value,
          info: null,
        });
      },
    },
    reasonItems() {
      return [
        {
          code: 'fraudulent',
          value: this.$t('report.base.reasons.fraudulent_use.value'),
          text: this.$t('report.base.reasons.fraudulent_use.text'),
        },
        {
          code: 'identity_fraud',
          value: this.$t('report.base.reasons.identity_fraud.value'),
          text: this.$t('report.base.reasons.identity_fraud.text'),
        },
        {
          code: 'abuse',
          value: this.$t('report.base.reasons.obscene.value'),
          text: this.$t('report.base.reasons.obscene.text'),
        },
        {
          code: 'spam',
          value: this.$t('report.base.reasons.spam.value'),
          text: this.$t('report.base.reasons.spam.text'),
        },
      ];
    },
    isVerified() {
      return this.$auth.user.data.is_verified;
    },
  },
  methods: {
    async sendReport() {
      try {
        if (this.valid) {
          this.loading = true;
          await this.$api.report.report(
            this.info.type,
            this.info.id,
            this.formReport.reason.code,
            this.formReport.observations,
          );
          this.loading = false;
          this.successValidation = this.$t('report.base.success');
        }
      } catch (error) {
        let errorReport = this.$t('report.errors.generic');
        if (error.name === CUSTOM_ERROR) errorReport = error.message;
        this.errorValidation = errorReport;
        this.loading = false;
        handleErrors(error);
      }
    },
    closeModal() {
      if (!this.loading) {
        this.clear();
        this.shouldShowReportModal = false;
      }
    },
    clear() {
      this.$refs.form.resetValidation();
      this.formReport = {
        reason: null,
        observations: null,
      };
      this.errorValidation = null;
    },
  },
};
