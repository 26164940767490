import { render, staticRenderFns } from "./LoginModal.vue?vue&type=template&id=156e2396&scoped=true&"
import script from "./LoginModal.vue?vue&type=script&lang=js&"
export * from "./LoginModal.vue?vue&type=script&lang=js&"
import style0 from "./LoginModal.vue?vue&type=style&index=0&id=156e2396&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "156e2396",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseInput: require('/tmp/build_fc5c3f7c/components/base/BaseInput.vue').default,BaseBtn: require('/tmp/build_fc5c3f7c/components/base/BaseBtn.vue').default,BaseFeedbackError: require('/tmp/build_fc5c3f7c/components/base/BaseFeedbackError.vue').default})
