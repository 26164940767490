import {
  SET_LOADING,
  SET_CONNECTION_MESSAGE,
  SET_LAST_MESSAGE,
  SET_CHATS,
  TOGGLE_LOADING_CHATS,
  TOGGLE_LOADED_CHATS,
  ADD_CONN_MSG_TO_CHATS,
  ADD_MSGS_TO_CHAT,
  UPDATE_MSGS_ON_CHAT,
  UPDATE_MSG_TO_CHAT,
  SET_CONNECTION_MESSAGES,
  SET_LOADING_CONNECTION_MESSAGES,
  ADD_MESSAGE_PENDING_TO_UNBLOCK,
} from '../vars/store/mutations';

import {
  setLoadingMessage,
  setConnectionMessage,
  setLastMessage,
  setChats,
  prepareChats,
  addMsgsToChat,
  updateMessagesFromChat,
  updateMessageFromChat,
  updateConversation,
  setConnectionMessages,
  addMessagePendingToUnblock,
} from '../vars/store/actions';
import { EventBus } from '~/components/eventBus/EventBus';
import { MESSAGES_STREAM_IN, MESSAGES_STREAM_OUT } from '~/vars/api';
import { encryptContent } from '~/utils/functions';
import mpDB from '~/indexedDB/mpDB';

const getDefaultState = () => ({
  chats: {},
  loadingChats: false,
  loadedChats: false,
  loading: null,
  connectionMessages: {},
  connectionMessagesLoading: false,
  connectionMessagesAreLoaded: false,
  lastMessages: {},
  pendingToUnblock: [],
  unblockMessage: null,
});

export const state = getDefaultState();

export const getters = {
  chatsAreLoaded: (state) => state.loadedChats,
  getLoadingChats: (state) => state.loadingChats,
  getChats: (state) => state.chats,
  getChatWithUser: (state) => (userId) => state.chats[userId] || [],
  isLoading: (state) => {
    return state.loading;
  },
  areConnectionMsgsLoaded: (state) => state.connectionMessagesAreLoaded,
  getAreConnectionMsgsLoading: (state) => state.connectionMessagesLoading,
  getConnectionMessage: (state) => (id) => state.connectionMessages[id],
  getLastMessage: (state) => (id) => state.lastMessages[id],
  getPendingToUnblock: (state) => state.pendingToUnblock,
  getUnblockMessage: (state) => state.unblockMessage,
};

export const actions = {
  [setLoadingMessage]({ commit }, messageId) {
    commit(SET_LOADING, messageId);
  },
  [setConnectionMessage]({ commit }, { messageId, rawMessage }) {
    commit(SET_CONNECTION_MESSAGE, { messageId, rawMessage });
  },
  [setChats]({ commit }, chats) {
    commit(SET_CHATS, chats);
    commit(TOGGLE_LOADING_CHATS, false);
    commit(TOGGLE_LOADED_CHATS, true);
  },
  [addMsgsToChat](
    { commit, dispatch },
    { loggedUserId, conversationId, msgs, unshift },
  ) {
    commit(ADD_MSGS_TO_CHAT, { conversationId, msgs, unshift });
  },
  [updateMessagesFromChat](
    { commit, dispatch },
    { conversationId, msgs, loggedUserId },
  ) {
    commit(UPDATE_MSGS_ON_CHAT, { conversationId, msgs });
  },
  [updateMessageFromChat](
    { commit, dispatch },
    { conversationId, msg, loggedUserId },
  ) {
    commit(UPDATE_MSG_TO_CHAT, { conversationId, msg });
  },
  async [updateConversation]({ getters }, { conversationId, loggedUserId }) {
    const conversationUpdated = getters
      .getChatWithUser(conversationId)
      .filter(({ connection }) => !connection);
    if (conversationUpdated.length) {
      const conversationWithoutConnectMsg = conversationUpdated.filter(
        ({ connection }) => !connection,
      );
      const fromIndex =
        conversationWithoutConnectMsg.length < 10
          ? 0
          : conversationWithoutConnectMsg.length - 10;
      const messagesToSave = conversationWithoutConnectMsg.splice(
        fromIndex,
        conversationWithoutConnectMsg.length,
      );
      mpDB.putChat({
        id: conversationId,
        messages: await encryptContent(
          `${conversationId}-${loggedUserId}`,
          JSON.stringify(messagesToSave),
        ),
      });
    }
  },
  [setLoadingMessage]({ commit }, messageId) {
    commit(SET_LOADING, messageId);
  },
  [setConnectionMessages]({ commit, rootState }, connectionMsgs) {
    commit(SET_CONNECTION_MESSAGES, { connectionMsgs, rootState });
  },
  [setLastMessage]({ commit }, { connection, message }) {
    commit(SET_LAST_MESSAGE, { connection, message });
  },
  [addMessagePendingToUnblock]({ commit }, messageId) {
    commit(ADD_MESSAGE_PENDING_TO_UNBLOCK, messageId);
  },
};

export const mutations = {
  [SET_LOADING](state, messageId) {
    state.loading = messageId;
  },
  [SET_CONNECTION_MESSAGE](state, { messageId, rawMessage }) {
    state.connectionMessages[messageId] = rawMessage;
  },
  [SET_CHATS](state, chats) {
    state.chats = chats;
  },
  [ADD_MSGS_TO_CHAT](state, { msgs, conversationId, unshift }) {
    const chatsNewRef = { ...state.chats };
    const conversation = chatsNewRef[conversationId]
      ? chatsNewRef[conversationId]
      : [];
    let chatNewRef = [...conversation];
    chatNewRef = unshift ? msgs.concat(chatNewRef) : chatNewRef.concat(msgs);
    chatsNewRef[conversationId] = chatNewRef;
    state.chats = chatsNewRef;
  },
  [UPDATE_MSGS_ON_CHAT](state, { conversationId, msgs }) {
    const conversation = state.chats[conversationId];
    const chatNewRef = [...conversation];
    msgs.forEach((msg) => {
      let indexOfMsg = null;
      conversation.forEach((message, ind) => {
        if (
          message.id === msg.id &&
          (message.status !== msg.status || (!message.open && msg.open))
        ) {
          indexOfMsg = ind;
        }
      });
      if (indexOfMsg !== null) {
        chatNewRef[indexOfMsg] = msg;
      }
    });
    state.chats[conversationId] = chatNewRef;
  },
  [UPDATE_MSG_TO_CHAT](state, { conversationId, msg }) {
    const conversation = state.chats[conversationId];
    const chatNewRef = [...conversation];
    let indexOfMsg = null;
    conversation.forEach((message, ind) => {
      if (message.id === msg.id) {
        indexOfMsg = ind;
      }
    });
    if (indexOfMsg !== null) {
      chatNewRef[indexOfMsg] = msg;
      state.chats[conversationId] = chatNewRef;
    }
  },
  [TOGGLE_LOADING_CHATS](state, loading) {
    state.loadingChats = loading;
  },
  [TOGGLE_LOADED_CHATS](state, loaded) {
    state.loadedChats = loaded;
  },
  [SET_LOADING](state, messageId) {
    if (messageId === null) {
      state.pendingToUnblock = state.pendingToUnblock.filter(
        (id) => id !== state.loading,
      );
      if (state.pendingToUnblock.length > 0)
        state.unblockMessage = state.pendingToUnblock[0];
      else state.unblockMessage = null;
    }
    state.loading = messageId;
  },
  [SET_LOADING_CONNECTION_MESSAGES](state, loading) {
    state.connectionMessagesLoading = loading;
  },
  [SET_CONNECTION_MESSAGES](state, { connectionMsgs, rootState }) {
    const newRef = Object.assign({}, state.connectionMessages);

    state.connectionMessages = Object.assign(newRef, connectionMsgs);
    if (
      rootState.connections.connections.length ===
      Object.keys(state.connectionMessages).length
    )
      state.connectionMessagesAreLoaded = true;
  },

  [SET_LAST_MESSAGE](state, { connection, message }) {
    state.lastMessages[connection] = message;
  },
  [ADD_MESSAGE_PENDING_TO_UNBLOCK](state, messageId) {
    state.pendingToUnblock.push(messageId);
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};
