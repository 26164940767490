import {
  GROUP_TYPE_AVAILABLE,
  GROUP_TYPE_INVOLVED,
  GROUP_TYPE_OFFERING,
  MARKET_ITEM_OFFERING,
  MARKET_ITEM_PRODUCT,
} from '~/vars/api';
import {
  setLoadingProducts,
  setToken,
  setTokenSerie,
} from '~/vars/store/actions';
import {
  ADD_TOKEN_OBJECT,
  RESET_STATE_TOKENS,
  SET_LOADING_PRODUCTS,
  SET_TOKEN_SERIE_ID,
  SET_UNIQUE_TOKEN,
} from '~/vars/store/mutations';

const getDefaultState = () => ({
  myProductsIdsList: [],
  offeringsIdsList: [],
  myOfferingsIdsList: [],
  myBidsIdsList: [],
  myProducts: {},
  offerings: {},
  loadingProducts: false,
});
export const state = getDefaultState();

export const getters = {
  loadingProducts: (state) => state.loadingProducts,
  getMyProductIds: (state) => state.myProductsIdsList,
  getOfferingIds: (state) => state.offeringsIdsList,
  getMyOfferingIds: (state) => state.myOfferingsIdsList,
  getMyBidsIds: (state) => state.myBidsIdsList,
  getTokenSerieId: (state) => (tokenSerieId) =>
    state.myProductsIdsList.find((id) => id === tokenSerieId) ||
    state.offeringsIdsList.find((id) => id === tokenSerieId),
  getMyProducts: (state) => Object.values(state.myProducts),
  getProduct: (state) => (tokenSerieId) =>
    state.myProducts[tokenSerieId] || state.offerings[tokenSerieId],
  // getTokenObject:
  //   (state, getters) =>
  //   ({ tokenSerieId, nftUniqueId }) =>
  //     getters.getUniqueToken({ tokenSerieId, nftUniqueId }).tokenObject.object,
  getTokenObject: (state, getters) => (tokenSerieId) =>
    getters.getProduct(tokenSerieId)
      ? getters.getProduct(tokenSerieId).tokenObject
      : null,
  getTokenContract: (state, getters) => (tokenSerieId) =>
    getters.getProduct(tokenSerieId).contract,
  getTokenList: (state, getters) => (tokenSerieId) =>
    getters.getProduct(tokenSerieId).nftlist,
  getUniqueToken:
    (state, getters) =>
    ({ tokenSerieId, nftUniqueId }) =>
      getters.getProduct(tokenSerieId).groups.reduce((found, group) => {
        const isFound = group.nftlist.find(
          (token) => token.nftuniqueid === nftUniqueId,
        );
        if (isFound) found = isFound;
        return found;
      }, {}),
  getTokensGrouped: (state, getters) => (tokenSerieId) => {
    return getters
      .getProduct(tokenSerieId)
      .groups.filter((item) => item.nftlist.length > 0);
  },
  getAvailableGroup: (state, getters) => (tokenSerieId) =>
    getters
      .getProduct(tokenSerieId)
      .groups.find(({ type }) => type === GROUP_TYPE_AVAILABLE),
  getAuctionGroup:
    (state, getters) =>
    ({ tokenSerieId, offeringIdToGet }) => {
      return getters
        .getProduct(tokenSerieId)
        .groups.find(({ offeringid }) => offeringid === offeringIdToGet);
    },
  getInvolvedGroup: (state, getters) => (tokenSerieId) =>
    getters
      .getProduct(tokenSerieId)
      .groups.find(({ type }) => type === GROUP_TYPE_INVOLVED),
  getTotalCopies: (state, getters) => (tokenSerieId) =>
    getters
      .getProduct(tokenSerieId)
      .groups.reduce(total, (item) => total + item.nftlist.length, 0),
};

export const actions = {
  [setTokenSerie]({ commit }, { element, belonging, list }) {
    commit(SET_TOKEN_SERIE_ID, { element, belonging, list });
  },
  [setToken]({ commit }, { token, tokenSerieId, belonging }) {
    commit(SET_UNIQUE_TOKEN, { token, tokenSerieId, belonging });
  },
  [setLoadingProducts]({ commit }, loading) {
    commit(SET_LOADING_PRODUCTS, loading);
  },
};
export const mutations = {
  [RESET_STATE_TOKENS](state) {
    Object.assign(state, getDefaultState());
  },
  [SET_TOKEN_SERIE_ID](state, { element, belonging, list }) {
    const belonginglist = `${list ? list : belonging}IdsList`;
    const id =
      belonging === MARKET_ITEM_PRODUCT
        ? element.contract.tokenSerieId
        : element.groups.find((item) => item.type === GROUP_TYPE_OFFERING)
            .offeringid;
    if (!state[belonginglist].find((itemId) => itemId === id))
      state[belonginglist].push(id);
    state[belonging][id] = element;
  },
  [ADD_TOKEN_OBJECT](state, { tokenSerieId, tokenObject }) {
    state.myProducts[tokenSerieId].tokenObject = tokenObject;
  },
  [SET_UNIQUE_TOKEN](state, { token, tokenSerieId, belonging }) {
    const list = state[belonging][tokenSerieId].nftlist;
    const index = list.findIndex(({ _id }) => _id === token._id);
    if (index !== -1) {
      const oldToken = list[index];
      list[index] = Object.assign(oldToken, token);
      state[belonging][tokenSerieId].nftlist = list;
    } else {
      state[belonging][tokenSerieId].nftlist.push(token);
    }
    if (
      token.auction &&
      !state[belonging][tokenSerieId].auctions.find(
        (auction) => auction.offeringid === token.auction.offeringid,
      )
    )
      state[belonging][tokenSerieId].auctions.push(token.auction);
  },
  [SET_LOADING_PRODUCTS](state, loading) {
    state.loadingProducts = loading;
  },
};
