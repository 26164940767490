export default ({ app, redirect, route, from, to }) => {
  if (
    ((!app.$auth.loggedIn || !app.$auth.user.data) &&
      route.path.includes('settings')) ||
    (route.fullPath.includes === '/messages' &&
      !app.$api.commons.messagesEnabled())
  ) {
    return redirect('/');
  } else if (
    route.fullPath.includes('/account/activity/') &&
    !(route.fullPath.includes('wallet') || route.fullPath.includes('market'))
  ) {
    return redirect(
      app.localePath({
        name: `account-activity-market`,
      }),
    );
  } else if (
    route.fullPath.includes('/settings/paymentmethod') &&
    !app.$api.commons.fiatEnabled()
  )
    return redirect(
      app.localePath({
        name: `settings-general`,
      }),
    );
  if (
    !app.$auth.loggedIn &&
    (route.path.includes('/checkout') || route.path.includes('/account'))
  )
    return redirect('/');
  if (route.path === '/') {
    if (
      process.env.vipProfile !== null &&
      typeof process.env.vipProfile !== 'undefined'
    )
      redirect(`/${process.env.vipProfile}`);
    else if (!process.env.homeURL) {
      const params = Object.assign({}, route.params);
      redirect(
        app.localePath({
          name: 'market-preview-marketplace',
          query: params,
        }),
      );
    }
  }
  // if (
  //   route.path.includes('contribute') &&
  //   route.path.includes('market') &&
  //   app.$auth.$storage.getCookie('tokenpm') === true
  // )
  //   return redirect(app.localePath({ name: 'market-preview-marketplace' }));
  // if (
  //   process.env.NODE_ENV === 'production' &&
  //   route.path.includes('preview') &&
  //   app.$auth.$storage.getCookie('tokenpm') !== true
  // )
  //   return redirect(app.localePath({ name: 'market-contribute' }));
};
