export default class G2CLib {
  constructor({ libsURL }) {
    this.useWorker = typeof Worker !== 'undefined';
    this.pendingRequests = {};

    if (this.useWorker) {
      const libsURLS = JSON.stringify(libsURL);
      const libsURLURI = encodeURIComponent(libsURLS);
      this.worker = new Worker(`/G2CLibWorker.js?libsURL=${libsURLURI}`);
      this.worker.onmessage = (event) => {
        this.handleWorkerMessage(event);
      };
    } /*else {
            const scriptElement = document.createElement('script');
            scriptElement.src = 'https://api.gate2chain.com:1975/libs/g2clib.min.js';
            document.body.appendChild(scriptElement);
        }*/
  }

  executeWorker(fn, args) {
    return new Promise(async (resolve, reject) => {
      const requestId = Date.now().toString();
      this.pendingRequests[requestId] = { resolve, reject };
      console.log('pre-send-worker', args);
      this.worker.postMessage({ fn, args, requestId });
    });
  }

  async executeLocally(fn, args) {
    try {
      console.log('LOCALLY', fn, args);

      const result = await window[fn](...args);
      console.log({ result });
      return result;
    } catch (e) {
      throw e;
    }
  }

  execute(fn, args) {
    if (this.useWorker) {
      return this.executeWorker(fn, args);
    } else {
      return this.executeLocally(fn, args);
    }
  }

  handleWorkerMessage(event) {
    console.log('WORKER RESPONSE', event);
    const { requestId, result, error } = event.data;
    const request = this.pendingRequests[requestId];

    if (!request) return;
    delete this.pendingRequests[requestId];
    const { resolve, reject } = request;
    if (error) {
      reject(error);
    } else {
      resolve(result);
    }
  }

  g2c_getRandomWords() {
    return this.execute('g2c_getRandomWords');
  }
  g2c_exchangeRates(...args) {
    return this.execute('g2c_exchangeRates', [...args]);
  }
  g2c_getBalance(...args) {
    return this.execute('g2c_getBalance', [...args]);
  }
  g2c_walletDirectSend(...args) {
    return this.execute('g2c_walletDirectSend', [...args]);
  }
  g2cclient_walletConditionalPropose(...args) {
    return this.execute('g2cclient_walletConditionalPropose', [...args]);
  }
  g2c_walletConditionalReject(...args) {
    return this.execute('g2c_walletConditionalReject', [...args]);
  }
  g2c_walletConditionalAccept(...args) {
    return this.execute('g2c_walletConditionalAccept', [...args]);
  }
  g2cclient_createUserObjectTree(...args) {
    return this.execute('g2cclient_createUserObjectTree', [...args]);
  }
  g2c_getUserObject(...args) {
    return this.execute('g2c_getUserObject', [...args]);
  }
  g2c_getUserObjectList(...args) {
    return this.execute('g2c_getUserObjectList', [...args]);
  }
  g2c_getUserObjectHistoric(...args) {
    return this.execute('g2c_getUserObjectHistoric', [...args]);
  }
  g2cclient_createUserObject(...args) {
    return this.execute('g2cclient_createUserObject', [...args]);
  }
  g2cclient_updateUserObject(...args) {
    return this.execute('g2cclient_updateUserObject', [...args]);
  }
  g2cclient_tokenGet(...args) {
    return this.execute('g2cclient_tokenGet', [...args]);
  }
  g2cclient_tokenCreate(...args) {
    return this.execute('g2cclient_tokenCreate', [...args]);
  }
  g2cclient_tokenTransfer(...args) {
    return this.execute('g2cclient_tokenTransfer', [...args]);
  }
  g2cclient_tokenConditionalPropose(...args) {
    return this.execute('g2cclient_tokenConditionalPropose', [...args]);
  }
  g2c_tokenConditionalAccept(...args) {
    return this.execute('g2c_tokenConditionalAccept', [...args]);
  }
  g2c_tokenConditionalReject(...args) {
    return this.execute('g2c_tokenConditionalReject', [...args]);
  }
  g2cclient_tokenRedeem(...args) {
    return this.execute('g2cclient_tokenRedeem', [...args]);
  }
}
