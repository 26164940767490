
import VerifyEntityButton from '~/components/session/VerifyEntityButton.vue';
import { handleErrors } from '~/utils/functions';
import { CUSTOM_ERROR } from '~/vars/api';
import { mapGetters } from 'vuex';
import {
  setContetMailingModal,
  showMailingInvitationModal,
} from '~/vars/store/actions';
import { SET_EMAIL_LIST_MAILING_MODAL } from '~/vars/store/mutations';
export default {
  components: { VerifyEntityButton },
  data() {
    return {
      valid: false,
      errorValidation: null,
      mailingResponse: null,
      formMailing: {
        emailList: [],
        email: null,
        message: null,
        info: null,
      },
      loading: false,
      rules: {
        email: this.$rules.email,
        message: this.$rules.message,
      },
    };
  },
  computed: {
    ...mapGetters({
      showMailingInvitationModal: 'mailing/showMailingInvitationModal',
      info: 'mailing/info',
    }),
    shouldShowMailingInvitationModal: {
      get() {
        return this.showMailingInvitationModal;
      },
      set(value) {
        this.$store.dispatch(`mailing/${showMailingInvitationModal}`, {
          show: value,
          info: null,
          action: 'send',
        });
      },
    },
    isVerified() {
      return this.$auth.user.data.is_verified;
    },
  },
  methods: {
    async sendMailing() {
      try {
        this.loading = true;
        if (this.info.action === 'send') {
          this.$toast.info('Mailing invitations system coming soon');
          this.mailingInvitationResponse = { data: 'ok' };
          if (this.mailingInvitationResponse.error)
            throw this.mailingInvitationResponse.error;
        } else {
          this.$store.dispatch(`mailing/${setContetMailingModal}`, {
            message: this.formMailing.message,
            emailList: this.formMailing.emailList,
          });
          this.shouldShowMailingInvitationModal = false;
        }
        this.loading = false;
      } catch (error) {
        let errorMailingInvitation = this.$t(
          'mailing_invitation.errors.generic',
        );
        if (error.name === CUSTOM_ERROR) errorMailingInvitation = error.message;
        this.errorValidation = errorMailingInvitation;
        this.loading = false;
        handleErrors(error);
      }
    },
    filterEmails(email) {
      this.formMailing.emailList = this.formMailing.emailList.filter(
        (item) => item !== email,
      );
    },
    addEmails() {
      if (
        !this.formMailing.emailList.find(
          (item) => this.formMailing.email === item,
        )
      ) {
        this.formMailing.emailList.push(this.formMailing.email);
      }
      this.formMailing.email = null;
      this.$refs.email.resetValidation();
    },
    closeModal() {
      if (!this.loading) {
        this.clear();
        this.shouldShowMailingInvitationModal = false;
      }
    },
    clear() {
      this.$refs.form.resetValidation();
      this.formMailing = {
        emailList: [],
        email: null,
        info: null,
        message: null,
      };
      this.errorValidation = null;
    },
  },
};
