export default {
  home: 'Home',
  sellers: 'Musicians',
  network: 'Network',
  connections: 'Messages',
  rooms: 'Rooms',
  my_rooms: 'My Rooms',
  market: 'Market',
  account: 'Account',
  profile: 'Profile',
  wallet: 'Wallet',
  activity: 'Activity',
  settings: 'Settings',
  redeem: 'Redeem',
  about: 'About',
  support: 'Support',
  legal: 'Legal',
  t_c: 'T&C',
};
