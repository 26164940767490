const getDefaultState = () => ({
  sidebarOpened: false,
  showMinterModal: false,
  showVerifyModal: false,
  modalData: {},
  showPreviewModal: false,
  previewImgs: [],
  previewOptions: {},
});

export const state = getDefaultState();

export const getters = {
  areSidebarOpened: (state) => state.sidebarOpened,
  showMinterModal: (state) => state.showMinterModal,
  showVerifyModal: (state) => state.showVerifyModal,
  modalData: (state) => state.modalData,
  showPreviewModal: (state) => state.showPreviewModal,
  previewImgs: (state) => state.previewImgs,
  previewOptions: (state) => state.previewOptions,
};

export const actions = {
  showMinterModal({ commit }, { status, data }) {
    if (data) commit('setDataForModal', data);
    commit('changeMinterModalStatus', status);
  },
  showVerifyModal({ commit }, { status, data }) {
    if (data) commit('setDataForModal', data);
    commit('changeVerifyModalStatus', status);
  },
  setImgsToPreview({ commit }, { images, options }) {
    commit('setPreviewImgs', { images, options });
    commit('togglePreviewModal', true);
  },
  hidePreviewModal({ commit }) {
    commit('setPreviewImgs', { images: [], options: {} });
    commit('togglePreviewModal', false);
  },
};

export const mutations = {
  setPreviewImgs(state, { images, options }) {
    state.previewImgs = images;
    state.previewOptions = options;
  },
  togglePreviewModal(state, show) {
    state.showPreviewModal = show;
  },
  setDataForModal(state, data) {
    state.modalData = data;
  },
  changeVerifyModalStatus(state, status) {
    state.showVerifyModal = status;
  },
  toggleVerifyModal(state) {
    state.showVerifyModal = !state.showVerifyModal;
  },
  changeMinterModalStatus(state, status) {
    state.showMinterModal = status;
  },
  toggleMinterModal(state) {
    state.showMinterModal = !state.showMinterModal;
  },
  changeSidebarStatus(state, status) {
    state.sidebarOpened = status;
  },
  toggleSidebar(state) {
    state.sidebarOpened = !state.sidebarOpened;
  },
};
