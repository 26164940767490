export default ({ $axios }) => ({
  /**
   * Follow a user
   */
  async followUser(uid) {
    try {
      return await $axios
        .post('/rels/follow', { uid })
        .then((response) => response.data.data);
    } catch (error) {
      throw error;
    }
  },

  /**
   * Unfollow a user
   */
  async unfollowUser(uid) {
    try {
      return await $axios
        .post('/rels/unfollow', { uid })
        .then((response) => response.data.data);
    } catch (error) {
      throw error;
    }
  },

  /**
   * Check user following
   */
  async userFollowing(followed) {
    try {
      return await $axios
        .post('/rels/check/is-following', { followed })
        .then((response) => response.data.data);
    } catch (error) {
      throw error;
    }
  },

  /**
   * Check user is follower
   */
  async userIsFollower(follower) {
    try {
      return await $axios
        .post('/rels/check/is-follower', { follower })
        .then((response) => response.data.data);
    } catch (error) {
      throw error;
    }
  },
});
