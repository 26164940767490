/*
  notificationsWithUsersById: {
    [userInteractId] : {
      [CONNECTION]: "Connection request sended",
      [ONE_TO_ONE]: "One to one request sended",
      [MESSAGE]: "Message request received",
      [ROOMS]: [],
    }
  }

example:
  notificationsWithUsersById: {
    '0N4LAq8N4FYkiWy48azu': {
      [CONNECTION]: {
        status: 'success', [sucess, error, info]
        message: 'Connection request sended' use i18n keys
      },
      [MESSAGE]: {
        [id]:{
          status: 'success', [sucess, error, info]
          message: 'Connection request sended' use i18n keys
        }
      },
      [ROOMS]: {
        [id] : {
          status: 'success', [sucess, error, info]
          message: 'Connection request sended' use i18n keys
        }
      }
    },
  },
  notificationsByRoomId: {
    [idroom] : {
      [idUser]: {
        status: 'success', [sucess, error, info]
        message: 'Connection request sended' use i18n keys
      }
    },
  },
*/
import Vue from 'vue';
import {
  RESET_STATE_NOTIFICATIONS,
  INIT_NOTIFICATION_OBJECT_WITH_USER,
  INIT_NOTIFICATION_OBJECT_WITH_ROOM,
  ADD_NOTIFICATION_WITH_USER,
  ADD_NOTIFICATION_WITH_ROOM,
  REMOVE_NOTIFICATIONS_TYPE_WITH_USER,
  REMOVE_NOTIFICATIONS_WITH_ROOM_BY_ID,
} from '../vars/store/mutations';

import {
  feedbackNotificationHandler,
  addNotification,
  addNotificationWithUser,
  addNotificationWithRoom,
  removeNotifications,
  removeNotificationsWithUser,
  removeNotificationsWithRoom,
} from '../vars/store/actions';

const getDefaultState = () => ({
  notificationsWithUsersById: {},
  notificationsWithRoomById: {},
});

export const state = getDefaultState();

export const getters = {
  notificationByUserId: (state) => (userId) => {
    return state.notificationsWithUsersById[userId];
  },
  notificationsByRoomId: (state) => (roomId) => {
    return state.notificationsWithRoomById[roomId];
  },
  hasNotificationWithUser: (state) => (userId) => {
    const notificationsWithUser =
      state.notificationsWithUsersById[userId] || {};
    if (Object.keys(notificationsWithUser).length > 0) {
      const hasNotification = Object.values(notificationsWithUser).filter(
        (notification) => notification !== undefined,
      );
      return Boolean(hasNotification.length);
    }
    return false;
  },
  hasNotificationWithRoom: (state) => (roomId) => {
    const notificationsWithRoom = state.notificationsWithRoomById[roomId] || {};
    if (Object.keys(notificationsWithRoom).length > 0) {
      const hasNotification = Object.values(notificationsWithRoom).filter(
        (user) => user !== undefined,
      );
      return Boolean(hasNotification.length);
    }
    return false;
  },
  allNotificationsWithUserById: (state) => (userId) => {
    return state.notificationsWithUsersById[userId]
      ? Object.values(state.notificationsWithUsersById[userId]).filter(
          (notification) => notification !== undefined,
        )
      : [];
  },
  allNotificationsWithRoomById: (state) => (roomId) => {
    return state.notificationsWithRoomById[roomId]
      ? Object.entries(state.notificationsWithRoomById[roomId]).filter(
          (user) => user[1] !== undefined,
        )
      : [];
  },
  notificationWithUserByType: (state) => (userId, notificationType) => {
    const hasNotificationsByUserId = state.notificationsWithUsersById[userId];
    return hasNotificationsByUserId
      ? hasNotificationsByUserId[notificationType]
      : [];
  },
  hasNotificationByTypeWithUser: (state) => (userId, notificationType) => {},
};

export const actions = {
  [feedbackNotificationHandler]({ commit, dispatch }, data) {
    if (data.show) {
      dispatch('addNotification', {
        userId: data.userId,
        roomId: data.roomId,
        notificationMessage: data.message,
        notificationType: data.type,
        notificationStatus: data.status,
      });
    } else {
      dispatch('removeNotifications', {
        userId: data.userId,
        roomId: data.roomId,
        notificationType: data.type,
      });
    }
  },
  [addNotification]({ commit, dispatch }, data) {
    if (data.roomId) {
      dispatch('addNotificationWithRoom', data);
    }
    dispatch('addNotificationWithUser', data);
  },
  [addNotificationWithUser]({ commit, getters }, data) {
    if (!getters.notificationByUserId(data.userId)) {
      commit('INIT_NOTIFICATION_OBJECT_WITH_USER', data.userId);
    }
    commit('ADD_NOTIFICATION_WITH_USER', data);
  },
  [addNotificationWithRoom]({ commit, getters }, data) {
    if (!getters.notificationsByRoomId(data.roomId)) {
      commit('INIT_NOTIFICATION_OBJECT_WITH_ROOM', data.roomId);
    }
    commit('ADD_NOTIFICATION_WITH_ROOM', data);
  },
  [removeNotifications]({ commit, dispatch }, data) {
    dispatch('removeNotificationsWithRoom', data);
    dispatch('removeNotificationsWithUser', data);
  },
  [removeNotificationsWithUser]({ commit, getters }, data) {
    commit('REMOVE_NOTIFICATIONS_TYPE_WITH_USER', { data, getters });
  },
  [removeNotificationsWithRoom]({ commit }, data) {
    commit('REMOVE_NOTIFICATIONS_WITH_ROOM_BY_ID', data);
  },
};

export const mutations = {
  [RESET_STATE_NOTIFICATIONS](state) {
    Object.assign(state, getDefaultState());
  },
  [INIT_NOTIFICATION_OBJECT_WITH_USER](state, userId) {
    Vue.set(state.notificationsWithUsersById, userId, {});
  },
  [INIT_NOTIFICATION_OBJECT_WITH_ROOM](state, roomId) {
    Vue.set(state.notificationsWithRoomById, roomId, {});
  },
  [ADD_NOTIFICATION_WITH_USER](state, data) {
    const userId = data.userId;
    const type = data.notificationType;
    const notification = {
      status: data.notificationStatus,
      message: data.notificationMessage,
    };
    Vue.set(state.notificationsWithUsersById[userId], type, notification);
  },
  [ADD_NOTIFICATION_WITH_ROOM](state, data) {
    const userId = data.userId;
    const notification = {
      status: data.notificationStatus,
      message: data.notificationMessage,
    };
    Vue.set(state.notificationsWithRoomById[data.roomId], userId, notification);
  },
  [REMOVE_NOTIFICATIONS_TYPE_WITH_USER](state, { data, getters }) {
    if (state.notificationsWithUsersById[data.userId]) {
      Vue.set(
        state.notificationsWithUsersById[data.userId],
        [data.notificationType],
        undefined,
      );
      if (!getters.hasNotificationWithUser(data.userId))
        Vue.set(state.notificationsWithUsersById, data.userId, undefined);
    }
  },
  [REMOVE_NOTIFICATIONS_WITH_ROOM_BY_ID](state, data) {
    if (state.notificationsWithRoomById[data.roomId]) {
      Vue.set(
        state.notificationsWithRoomById[data.roomId],
        [data.userId],
        undefined,
      );
      if (!getters.hasNotificationWithRoom(data.roomId))
        Vue.set(state.notificationsWithRoomById, data.roomId, undefined);
    }
  },
};
