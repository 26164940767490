
export default {
  expose: ['$refs'],
  components: {},
  mixins: [],
  props: {
    subtitle: {
      type: String,
      required: false,
    },
    model: {
      type: [String, Number],
      default: null,
    },
    id: {
      type: String,
      required: false,
    },
    inputRef: {
      type: String,
      required: false,
    },
    rules: {
      type: Array,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    autocorrect: {
      type: String,
      default: 'off',
    },
    autocapitalize: {
      type: String,
      default: 'none',
    },
    required: {
      type: Boolean,
      default: true,
    },
    validateOnBlur: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
    },
    errorsMessage: {
      type: [String, Array],
    },
    type: {
      type: String,
    },
    autocomplete: {
      type: [String, Boolean],
      default: '',
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    prefix: {
      type: String,
      required: false,
    },
    suffix: {
      type: String,
      required: false,
    },
    preppendInnerIcon: {
      type: String,
      required: false,
    },
    hideDetails: {
      type: [String, Boolean],
    },
    counter: {
      type: Number,
      required: false,
    },
    clearable: {
      type: Boolean,
      default: false,
      required: false,
    },
    lowerCase: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    modelProp: {
      get() {
        return this.model;
      },
      set(val) {
        this.$emit('update:model', this.lowerCase ? val.toLowerCase() : val);
      },
    },
  },
  watch: {},
  methods: {
    blurEvent(event) {
      this.$emit('blur-event', event);
    },
    focusEvent(event) {
      this.$emit('focus-event', event);
    },
  },
  /*
   ** Lyfecycle hooks
   */
  created() {},
  mounted() {},
  updated() {},
};
