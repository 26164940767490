import { showSessionModal } from '~/vars/store/actions';
export default ({ app }) => ({
  response: null,
  errors: [
    { key: 'getUsert', message: app.i18n.t('base.errors.get_user') },
    { key: 'not logged in', message: app.i18n.t('base.errors.not_logged_in') },
    { key: 'defaultnull', message: app.i18n.t('base.errors.unexpected') },
    {
      key: 'Mempool rejected transaction',
      message: app.i18n.t('base.errors.network'),
    },
    {
      key: 'token is undefined',
      message: app.i18n.t('base.errors.network'),
    },
  ],
  findError(error) {
    return (
      this.errors.find((e) => error.includes(e.key)) ||
      this.errors.find((e) => e.key === 'defaultnull')
    );
  },
  g2cCallBack({ data, error }) {
    this.response = {};
    // if No data and no error -> unexpected error
    const hasConnectionToInternet = navigator.onLine;
    const closeAction = {
      text: 'x',
      onClick: (e, toastObject) => {
        toastObject.goAway(0);
      },
    };
    const reloadAction = {
      text: app.i18n.t('base.reload'),
      onClick: (e, toastObject) => {
        location.reload();
      },
    };
    const logoutAction = {
      text: app.i18n.t('logout.toast_error'),
      onClick: (e, toastObject) => {
        this.forceLogout();
        toastObject.goAway(0);
      },
    };
    if (!hasConnectionToInternet || (error && error.includes('to fetch'))) {
      return false;
    }
    if (
      (data === null || data === undefined) &&
      (error === null || error === undefined)
    ) {
      app.$toast.show(app.i18n.t('base.errors.unexpected'), {
        icon: 'alert-circle',
        reloadAction,
      });
      throw new Error(app.i18n.t('base.errors.unexpected'));
    }
    // if error, which one of kind of errors
    if (error) {
      const errorObject = this.findError(error);
      let action;
      if (errorObject.key.includes('getUsert')) {
        action = [logoutAction];
      } else {
        action = Object.assign({}, closeAction);
      }
      app.$toast.show(errorObject.message, {
        icon: 'alert-circle',
        action,
      });
      throw new Error(errorObject.message);
    }
    this.response = data;
    return data;
  },
  getToken() {
    return app.$auth.$storage.getLocalStorage('g2ctoken');
  },
  getSession() {
    const g2ctoken = app.$auth.$storage.getLocalStorage('g2ctoken');
    const application = app.context.env.g2clibApplication;
    const sourcenick = app.$auth.user.data.nick;
    const nick = sourcenick;
    return { g2ctoken, application, sourcenick, nick };
  },
  setToken({ g2ctoken }) {
    app.$auth.$storage.setLocalStorage('g2ctoken', g2ctoken);
  },
  async forceLogout() {
    try {
      await app.$auth.logout();
      await app.$api.session.logout();
    } catch (error) {
      app.$api.session.removeTokens();
    } finally {
      app.store.dispatch(`session/${showSessionModal}`, true);
    }
  },
  /*
   * LOG
   */
  /**
   * G2c check session
   * @param {Boolean} isSafari for safari devices
   * @returns {Object} response: {data, error: errorMsg}
   */
  async checkSession({ isSafari }) {
    try {
      const g2ctoken = this.getToken();
      if (g2ctoken === null || typeof g2ctoken === 'undefined')
        throw 'invalid session';
      const { data, error } = await g2c_checkSession({
        g2ctoken,
        application: app.context.env.g2clibApplication,
      });
      // Data represents if session is active
      if (!data || typeof data === 'undefined') {
        throw error;
      }
      return true;
    } catch (e) {
      const error = new Error(`${nick} session expired, payload: ${data}`);
      error.name = 'Session Expired';
      app.$sentry.captureException(error);
      let message = 'Your session has expired';
      if (isSafari) {
        message = `${message}: Safari clear sessions in 7 days from last entry and extend it 7 days more with each visit.`;
      }
      app.$api.session.logout();
      app.$toast.show(message, {
        icon: 'alert-circle',
      });
      return false;
    }
  },

  async login(userwords, application, nick, closeothersessions) {
    try {
      // eslint-disable-next-line no-unreachable
      const { data, error } = await g2cclient_loginUser({
        userwords,
        application,
        nick,
        fast: false,
        closeothersessions,
      });

      if (error) {
        let errorMsg;
        // general for wrong words @g2clib.i_createIdentity ??
        if (
          error.includes('12 words') ||
          error.includes('collection provided is not valid')
        ) {
          errorMsg = app.i18n.t('forms.error.words');
        } else if (error.includes('Already logged in')) {
          errorMsg = app.i18n.t('base.errors.already_logged_in');
        } else {
          errorMsg = app.i18n.t('forms.error.message');
        }
        throw errorMsg;
      }
      return data;

      // eslint-disable-next-line no-unreachable
    } catch (error) {
      reject(error);
    }
  },
  /**
   * Callback function for $api.G2c.login
   * @param {Object} data
   * @param {Object} error
   * @returns
   */
  onUserLogged({ data, error }) {
    this.response = {};
    if (error) {
      let errorMsg;
      if (error.includes('12 words')) {
        errorMsg = app.i18n.t('forms.error.words');
      } else {
        errorMsg = app.i18n.t('forms.error.message');
      }
      this.response.error = errorMsg;
    }
    this.response.data = data;
    return data;
  },

  async logoutUser(sessionData) {
    try {
      const response = await g2cclient_logoutUser(sessionData);
      if (response === undefined) {
        throw new Error('Undefined response');
      }
      if (response.hasOwnProperty('error')) {
        throw new Error(response.error);
      } else {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },

  onUserLogout({ data, error }) {
    if (error) {
      /*
       * Pending improve error messages when logout failed
       */
      let errorMsg = app.i18n.t('logout.error');
      app.$toast.error(errorMsg);
      return errorMsg;
    }
    this.response = data;
    return data;
  },
  /*
   * TWELVE WORDS GENERATOR
   */
  /**
   * Get 12 random words for security key
   * @returns {String} 12 words security key
   */
  getRandomWords() {
    try {
      return g2c_getRandomWords();
      // eslint-disable-next-line no-unreachable
    } catch (error) {
      throw error;
    }
  },
  /*
   * TWELVE WORDS BACKUP GENERATOR
   */
  /**
   * Returns 12 random words backup object
   * @returns {String} 12 words security key
   */
  async g2cWordsBackup(apphash, userwords) {
    const g2ctoken = app.$api.g2cSession.getToken();
    try {
      if (g2ctoken) {
        const params = {
          g2ctoken,
          userwords,
          apphash,
        };
        const response = await g2cclient_backupWords(params);
        if (response.error) {
          throw response.error;
        }
        return response.data.chunkArray;
      } else throw new Error('No logged user');
    } catch (error) {
      throw error;
    }
  },
  /*
   * TWELVE WORDS RESTORE
   */
  /**
   * Returns 12 random words from fil backup
   * @returns {String} 12 words security key
   */
  async g2cWordsRestore(apphash, chunkArray) {
    const application = app.context.env.g2clibApplication;
    const nick = app.$auth.user.data.nick;
    try {
      const response = await g2cclient_restoreWords({
        application,
        nick,
        chunkArray,
        apphash,
      });
      if (response.error) {
        throw response.error;
      }
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  /*
   *  USER CREATION
   */
  /**
   * G2C user create
   * OBJECT
   * @param {String} userName nick
   * @param {String} words security key
   * @returns {Object} response: {data, error}
   */
  async createUser({ userName, words }) {
    const application = app.context.env.g2clibApplication;
    try {
      const response = await g2cclient_createUser({
        userwords: words,
        application,
        nick: userName,
      });
      this.response = {};
      if (!response.data && !response.error)
        throw new Error('Undefined response');
      if (response.error) throw new Error(error);
      this.response.data = response.data;
      return this.response;
      // eslint-disable-next-line no-unreachable
    } catch (error) {
      throw error;
    }
  },

  /*
   *  ACTIVITY
   */
  /**
   *
   * OBJECT
   * @param {Number} dateFrom
   * @param {Number} dateTo
   * @returns {Object} response: {data, error}
   */
  async getUserTransactions({ dateFrom, dateTo }) {
    const g2ctoken = app.$api.g2cSession.getToken();
    try {
      return g2c_getTransactions({
        g2ctoken,
        datefrom: dateFrom,
        dateto: dateTo,
      });
    } catch (error) {
      throw error;
    }
  },
});
